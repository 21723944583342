import React, { useEffect, useState } from 'react'
import Loader from '../components/Loader'
import { FetchDashFromAPI, FetchFromAPI } from '../components/ajx'
import Dash from '../components/dash';
import { Col, Row } from 'react-bootstrap';
import { DateButtons, PlatformButtons } from '../components/dateButtons';
import { useAppContext } from '../context/appContext';


const Index = (props) => {
    const appValue = useAppContext();
    var REACT_APP_API_URL = appValue.apiUrl;
    const [date, setDate] = useState(1)
    const [platform, setPlatform] = useState("all")
    const [dashAPI, setDashAPI] = useState({
        urls: [
            {
                name: "dash",
                url: REACT_APP_API_URL + "/reports/dash/" + appValue.user.apid + "/all?date=" + date
            },
            {
                name: "account",
                url: REACT_APP_API_URL + "/admin/get/account/" + appValue.user.apid
            },
        ],
        method: "GET",

    })

    const { NODE_ENV } = process.env;

    useEffect(() => {
        complete = false
        setDashAPI({
            urls: [
                {
                    name: "dash",
                    url: REACT_APP_API_URL + "/reports/dash/" + appValue.user.apid + "/all?date=" + date
                },
                {
                    name: "account",
                    url: REACT_APP_API_URL + "/admin/get/account/" + appValue.user.apid
                },
            ],
            method: "GET",
            
        })
    }, [date])


    var { results, errors, complete } = FetchDashFromAPI(dashAPI)

    if (complete) {
        if (NODE_ENV == 'development') {
            console.log(results)
        }
        return errors.length > 0 ? (<><span>Error: {errors}</span></>) : (
            <>
                <Row className='dateButtons'>
                    <Col lg={{ span: 6 }}>
                        <PlatformButtons platform={platform} setPlatform={setPlatform} account={results["account"]} />
                    </Col>
                    <Col lg={{ span: 6 }}>
                        <DateButtons setDate={setDate} date={date} />
                    </Col>
                </Row>
                {(Object.keys(results["dash"].data[platform].kpi).length === 0) ? (
                    <span>No Data Available </span>
                ) : (
                    
                    <>
                        <Dash dash={results["dash"].data[platform]} account={results["account"]} /></>
                )}
            </>
        )
    }
    return <><div><span><Loader></Loader></span></div></>;

}
export default (Index)
