const advertisers_info = [
    {
        label: 'Advertisers',
        input: 'dropdown',
        name: 'aid',
        options: [           ]
    }
]


const CreativeRefresh = {
    titles: ["Advertisers"],
    fields: [
        advertisers_info
    ]
}

export { advertisers_info, CreativeRefresh }