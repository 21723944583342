import { Tooltip,OverlayTrigger } from 'react-bootstrap'


const OnHover = (props) => {

    return (
        <OverlayTrigger
        overlay={<Tooltip id={props.id}>{props.tooltip}</Tooltip>}
        placement="top"
        delayShow={300}
        delayHide={150}
        >
        {props.children}
        </OverlayTrigger>
        )

}

export default OnHover