import { React, useEffect, useState } from 'react'
import { Row, Col, Tab, Nav } from 'react-bootstrap'
import Card from '../../../components/Card'
import Loader from '../../../components/Loader'
import { CountEntities } from '../../../components/common'
import { useParams } from 'react-router-dom'
import { FetchDashFromAPI, FetchFromAPI } from '../../../components/ajx'
import ReactTable from '../../../components/table'

import { columnsOrder, columnsGroup } from '../../../components/gridColumns'
// AOS
import AOS from 'aos'
import '../../../../node_modules/aos/dist/aos'
import '../../../../node_modules/aos/dist/aos.css'
// Import Swiper styles

import Dash from '../../../components/dash';
import {DateButtons} from '../../../components/dateButtons';
import Cookies from "universal-cookie";
import { OrderMetaModal } from '../../../components/Modals'
import { MetaOrderForm } from '../../../util/meta-order-input'
import { ModalButton } from '../../../components/ModalSettings'
import { MetaCampaignModel, MetaOrderModel } from '../../../components/Models'
import Crumbs from '../../../components/crumbs'
import { DefaultValue, MultiDefault } from '../../../components/selectDefaults'
import InputSettings from '../../../components/InputSettings'
import { MetaCampaignForm } from '../../../util/meta-campaign-input'
import { useAppContext } from '../../../context/appContext'



const MetaCampaign = (props) => {
    const appValue = useAppContext();
    const [modal, setModal] = useState(false);
    const [campaign, setCampaign] = useState(MetaCampaignModel);
    const [order, setOrder] = useState({MetaOrderModel});
    const [initialLoad, setInitialLoad] = useState(true);
    const [date, setDate] = useState(1)
    const cleanUrl = appValue.cleanUrl

    const { cid } = useParams()

    const cookies = new Cookies();
    const user = cookies.get('aplus-user');
    var REACT_APP_API_URL = appValue.apiUrl;
    let url = REACT_APP_API_URL + "/meta/campaign/update/" + cid
    const [dashAPI, setDashAPI] = useState({
        urls: [
            {
                name: "campaign",
                url: REACT_APP_API_URL + "/campaign/" + cid + "/" + "Meta"
            },
            {
                name: "orders",
                url: REACT_APP_API_URL + "/order/list/small/" + cid
            },
            {
                name: "groups",
                url: REACT_APP_API_URL + "/group/list/all/" + cid
            },
            {
                name: "dash",
                url: REACT_APP_API_URL + "/reports/dash/campaign/" + user.aid + "/" + cid + "/" +  "meta" + "?date=" + date
            },
            {
                name: "account",
                url: REACT_APP_API_URL + "/admin/get/account/" + user.apid
            },
            {
                name: "crumbs",
                url: REACT_APP_API_URL + "/util/breadcrumb/campaigns/meta/" + cid
            },

        ],
        method: "GET",

    })
    useEffect(() => {

        AOS.init({
            startEvent: 'DOMContentLoaded',
            disable: function () {
                var maxWidth = 996;
                return window.innerWidth < maxWidth;
            },
            throttleDelay: 10,
            once: true,
            duration: 700,
            offset: 10
        });

    })
    useEffect(() => {
        complete = false
        setDashAPI({
            urls: [
                {
                    name: "campaign",
                    url: REACT_APP_API_URL + "/campaign/" + cid + "/" + "Meta"
                },
                {
                    name: "orders",
                    url: REACT_APP_API_URL + "/order/list/small/" + cid
                },
                {
                    name: "groups",
                    url: REACT_APP_API_URL + "/group/list/all/" + cid
                },
                {
                    name: "dash",
                    url: REACT_APP_API_URL + "/reports/dash/campaign/" + user.aid + "/" + cid + "/" +  "meta" + "?date=" + date
                },
                {
                    name: "account",
                    url: REACT_APP_API_URL + "/admin/get/account/" + user.apid
                },
                {
                    name: "crumbs",
                    url: REACT_APP_API_URL + "/util/breadcrumb/campaigns/meta/" + cid
                },
    
            ],
            method: "GET",
    
        })
    }, [date])


    const { NODE_ENV } = process.env;
    var { results, errors, complete } = FetchDashFromAPI(dashAPI)

    if (complete) {
        if (NODE_ENV === 'development') {
            console.log(results)
        }
        if (initialLoad) {
            setInitialLoad(false)
            campaign.id = results["campaign"].data.meta_id;
            campaign.account_id = results["campaign"].data.meta_account_id;
            campaign.name = results["campaign"].data.name;
            campaign.status = results["campaign"].data.meta_campaign.status;
            campaign.objective = results['campaign'].data.meta_campaign.objective;
            campaign.special_ad_categories = results['campaign'].data.meta_campaign.special_ad_categories;
            campaign.special_ad_category_country = results['campaign'].data.meta_campaign.special_ad_category_country;
            campaign.bid_strategy = results['campaign'].data.meta_campaign.bid_strategy;
            campaign.daily_budget = results['campaign'].data.meta_campaign.daily_budget;
            campaign.source_campaign_id = results['campaign'].data.meta_campaign.source_campaign_id;
            campaign.buying_type = results["campaign"].data.meta_campaign.buying_type;
            campaign.adlabels = results["campaign"].data.meta_campaign.adlabels;

            if (results['campaign'].data.meta_campaign.start_time) {
                let startTime = results['campaign'].data.meta_campaign.start_time.split('T');
                campaign.start_time = startTime[0];
            }

            if (results['campaign'].data.meta_campaign.end_time) {
                let endTime = results['campaign'].data.meta_campaign.end_time.split('T');
                campaign.end_time = endTime[0];
            }
        }

        console.log('campaign', campaign)

        let activeOrders = CountEntities(results["orders"].data);
        let activeGroups = CountEntities(results["groups"].data)

        if (results["clients"]) {
            for (let i = 0; i < results["clients"].data.length; i++) {
                const c = results["clients"].data[i];
                if (c.platform === "Meta") {
                    if (c.ad_labels && c.ad_labels.length > 0) {
                        for (let i = 0; i < c.ad_labels.length; i++) {
                            const a = c.ad_labels[i]
                            const ad_label = `${a.name} | ${a.id}`
                            MetaCampaignForm.fields[2][4].options.push({ label: ad_label, value: a.id, name: "adlabels" })
                            if (campaign.adlabels) {
                            MetaCampaignForm.fields[2][4].value = MultiDefault(campaign.adlabels, MetaCampaignForm.fields[2][4].options)
                            }
                        }
                    }
                }
            }
        }
        if (results["campaigns"]) {
            for (let i = 0; i < results["campaigns"].data.length; i++) {
                const c = results["campaigns"].data[i]
                if (c.platform === "Meta") {
                    const c_label = `${c.name} | ${c.platform_id}`;
                    MetaCampaignForm.fields[2][2].options.push({ label: c_label, value: c.platform_id });
                    MetaCampaignForm.fields[2][2].value = MultiDefault(campaign.source_campaign_id, MetaCampaignForm.fields[2][2].options);
                }
            }
        }

        return errors.length > 0 ? (<><span>Error: {errors}</span></>) : (
            <>
                <Tab.Container defaultActiveKey="first">
                    <Row>
                        <Col lg="12">
                            <Card>
                                <Card.Body>
                                    
                                    <Crumbs data={results["crumbs"].data} platform="meta" type="campaign" />

                                    <div className="d-flex flex-wrap align-items-center justify-content-between">
                                        <div className="d-flex flex-wrap align-items-center">
                                            <div className="d-flex flex-wrap align-items-center mb-3 mb-sm-0">
                                                <h4 className="me-2 h4">{results["campaign"].data.name}</h4>
                                                <span> - Campaign</span>
                                            </div>
                                        </div>
                                        <Nav as="ul" className="d-flex nav-pills mb-0 text-center profile-tab" data-toggle="slider-tab" id="profile-pills-tab" role="tablist">
                                            <Nav.Item as="li">
                                                <Nav.Link eventKey="first" className="button">Dash</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item as="li">
                                                <Nav.Link eventKey="fourth" className="button">Settings</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item as="li">
                                                <Nav.Link eventKey="second" className="button">Orders</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item as="li">
                                                <Nav.Link eventKey="third" className="button">Groups</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg="6">
                            <Card>
                                <Card.Header>
                                    <div className="header-title">
                                        <h4 className="card-title">Snapshot</h4>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <ul className="list-inline m-0 p-0">
                                        <li className="d-flex mb-2">
                                            <div className="news-icon me-3">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-ubuntu" viewBox="0 0 16 16"><path d="M2.273 9.53a2.273 2.273 0 1 0 0-4.546 2.273 2.273 0 0 0 0 4.547Zm9.467-4.984a2.273 2.273 0 1 0 0-4.546 2.273 2.273 0 0 0 0 4.546ZM7.4 13.108a5.535 5.535 0 0 1-3.775-2.88 3.273 3.273 0 0 1-1.944.24 7.4 7.4 0 0 0 5.328 4.465c.53.113 1.072.169 1.614.166a3.253 3.253 0 0 1-.666-1.9 5.639 5.639 0 0 1-.557-.091Zm3.828 2.285a2.273 2.273 0 1 0 0-4.546 2.273 2.273 0 0 0 0 4.546Zm3.163-3.108a7.436 7.436 0 0 0 .373-8.726 3.276 3.276 0 0 1-1.278 1.498 5.573 5.573 0 0 1-.183 5.535 3.26 3.26 0 0 1 1.088 1.693ZM2.098 3.998a3.28 3.28 0 0 1 1.897.486 5.544 5.544 0 0 1 4.464-2.388c.037-.67.277-1.313.69-1.843a7.472 7.472 0 0 0-7.051 3.745Z"></path></svg>
                                            </div>
                                            <p className="news-detail mb-0">{activeOrders} - Active Orders</p>
                                        </li>
                                        <li className="d-flex">
                                            <div className="new-icon me-3">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-collection-fill" viewBox="0 0 16 16"><path d="M0 13a1.5 1.5 0 0 0 1.5 1.5h13A1.5 1.5 0 0 0 16 13V6a1.5 1.5 0 0 0-1.5-1.5h-13A1.5 1.5 0 0 0 0 6v7zM2 3a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 0-1h-11A.5.5 0 0 0 2 3zm2-2a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 0-1h-7A.5.5 0 0 0 4 1z"></path></svg>
                                            </div>
                                            <p className="news-detail mb-0">{activeGroups} - Active Groups</p>

                                        </li>
                                    </ul>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg="6">
                            <Card>
                                <Card.Header>
                                    <div className="header-title">
                                        <h4 className="card-title">Campaign Info</h4>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <div className="mb-1">Campaign Id: {results["campaign"].data.meta_id}</div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg="12">
                            <Tab.Content className="profile-content">
                                <Tab.Pane eventKey="second" id="order">
                                    <Card>
                                        <Card.Header className="d-flex justify-content-between">
                                            <div className="header-title">
                                                <h4 className="card-title">Order List</h4>
                                            </div>
                                            <div>
                                                <ModalButton name="New Meta Order" setModal={setModal} />
                                            </div>
                                        </Card.Header>
                                        <Card.Body className="px-0">
                                            <div className="table-responsive" style={{ width: `100%` }}>
                                                <ReactTable item={results["orders"]} col={columnsOrder} page_size={20} row_size={20} />
                                            </div>
                                            <div>
                                                <OrderMetaModal order={order} setOrder={setOrder} data={MetaOrderForm} modal={modal} setModal={setModal} baseUrl={REACT_APP_API_URL} />
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Tab.Pane >
                                <Tab.Pane eventKey="third" id="group">
                                    <Card>
                                        <Card.Header className="d-flex justify-content-between">
                                            <div className="header-title">
                                                <h4 className="card-title">Group List</h4>
                                            </div>
                                            {/* <div>
                                    <Button className="text-center btn-dark btn-icon mt-lg-0 mt-md-0 mt-3" variant="dark" >
                                       <i className="btn-inner">
                                          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                             <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                          </svg>
                                       </i>
                                       <span>New Group</span>
                                    </Button>
                                 </div> */}
                                        </Card.Header>
                                        <Card.Body className="px-0">
                                            <div className="table-responsive" style={{ width: `100%` }}>
                                                <ReactTable item={results["groups"]} col={columnsGroup} page_size={20} row_size={20} />
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Tab.Pane>
                                <Tab.Pane eventKey="first" id="dash">
                                    <Row className='dateButtons_sub'>
                                        <Col lg={{ span: 6, offset: 6 }}>
                                        <DateButtons setDate={setDate} date={date} />
                                        </Col>
                                    </Row>
                                        <Dash dash={results["dash"].data["meta"]} account={results["account"]} />
                                </Tab.Pane >
                                <Tab.Pane eventKey="fourth" id="settings">
                                    <Row>
                                        <Col lg="12">
                                            <Row>
                                                <InputSettings
                                                    title="Add New Campaign"
                                                    mode="edit"
                                                    form={MetaCampaignForm}
                                                    url={url}
                                                    formId={"campaignForm"}
                                                    model={[campaign, setCampaign]}

                                                />
                                            </Row>
                                        </Col>
                                    </Row>
                                </Tab.Pane >
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </>
        );
    }
    return <><div><span><Loader></Loader></span></div></>;
}
export default (MetaCampaign)