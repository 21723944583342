import { React, useState } from 'react'
import { Row, Col, Button, Form } from 'react-bootstrap'
import Card from '../../../components/Card'
import Select from 'react-select'
import axios from 'axios'
import Loader from '../../../components/Loader'

import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useAppContext } from '../../../context/appContext';
import AsyncSelect from 'react-select/async'
import { FormatNumber } from '../../../components/common'

const generateRandom = () => {
    var length = 8,
        charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
        retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
}


const Explorer = (props) => {

    const appValue = useAppContext();


    //Load list of DMA, CITY, ZIP, STATE, COUNTY into searchable values select react

    const defCol = [{}]
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [dimensions, setDimensions] = useState([])
    const [metrics, setMetrics] = useState([])
    const [columns, setColumns] = useState(defCol)
    const [dimensionHeader, setDimensionHeader] = useState([])
    const [filter, setFilter] = useState([])

    const dimensionsOptions = [
        { value: "households", label: "Number of Households" },
        { value: "whouseholds", label: "White Households" },
        { value: "bhouseholds", label: "Black Households" },
        { value: "nhouseholds", label: "Native Households" },
        { value: "ahouseholds", label: "Asian Households" },
        { value: "phouseholds", label: "Pacific Households" },
        { value: "mhouseholds", label: "Mixed Households" },
        { value: "hhouseholds", label: "Part Hispanic Households" },
        { value: "ohouseholds", label: "Other Households" },
        { value: "income", label: "Household Income" },
        { value: "population", label: "Population" },
        { value: "zip", label: "Zip" },
        { value: "city", label: "City" },
        { value: "state", label: "State" },
        { value: "county", label: "County" },
        { value: "tz", label: "Timezone" },
        { value: "dma", label: "DMA" },
    ]

    const pageSizes = [10, 25, 50, 100];

    var REACT_APP_API_URL = appValue.apiUrl;
    const { NODE_ENV } = process.env;

    const [query, setQuery] = useState("");
    var loadOpt = []

    const loadOptions = () => {
        if (query.length >= 4) {
            var REACT_APP_API_URL = appValue.apiUrl;
            let url = REACT_APP_API_URL + "/audience/explorer/" + `${query}`
            return new Promise((resolve, reject) => {
                axios.get(url, appValue.config).then((response) => {
                    if (response.data) {
                        loadOpt = response.data.data
                        // for (let i = 0; i < response.data.data.length; i++) {
                        //     loadOpt.push(response.data)
                        // }                        
                        resolve(loadOpt)
                    } else {
                        reject(response.error)
                    }
                })
            })
        }
    };

    const LoadReport = (event) => {
        event.preventDefault();
        setLoading(true)
        //Build Columns     
        let col = []
        for (let i = 0; i < dimensions.length; i++) {
            switch (dimensions[i]) {
                case "zip":
                case "city":
                case "state":
                case "county":
                case "tz":
                case "dma":
                    col.push({ field: dimensions[i], headerName: dimensionHeader[i], flex: 1, sortable: true, type: "string" })
                    break;
                default:
                    col.push({ field: dimensions[i], headerName: dimensionHeader[i], flex: 1, sortable: true, type: "number" })
            }
        }
        setColumns(col)
        let postData = {
            "fields": dimensions,
            "filter": filter,
        }
        console.log(postData);
        return axios.post(REACT_APP_API_URL + "/audience/explorer", postData, appValue.config).then((response) => {
            if (response.data) {
                let formattedData = FormatGoJSON(response.data)
                setData(formattedData)
                setLoading(false)
                if (NODE_ENV == 'development') {
                    console.log(response.data)
                }
            } else {
                <p>{response.error}</p>
            }
        })
    }

    const FormatGoJSON = (data) => {
        let formattedData
        let arrData = data.data.results
        if (arrData == null) {
            formattedData = {}
            return formattedData
        }
        let arrCol = data.data.columns
        let len = Object.keys(arrData).length
        for (let i = 0; i < len; i++) {

            let jsonObj = {}
            let nLen = Object.keys(arrData[i]).length
            // let values = Object.keys(arrData[i]).map(function (key) {return arrData[i]});
            // console.log(values) 
            let numberHouseholds;
            for (let x = 0; x < nLen; x++) {
                // console.log(arrData[i][x])

                jsonObj[arrCol[i][x].toLowerCase()] = arrData[i][x];

            }
            let keys = Object.keys(jsonObj)

            for (let n = 0; n < Object.keys(jsonObj).length; n++) {
                switch (keys[n].toLowerCase()) {
                    case "whouseholds":
                    case "bhouseholds":
                    case "nhouseholds":
                    case "phouseholds":
                    case "mhouseholds":
                    case "ohouseholds":
                    case "hhouseholds":
                    case "ahouseholds":
                        if (jsonObj.hasOwnProperty("households")) {
                            let householdTotal = jsonObj["households"]
                            jsonObj[keys[n]] = parseInt(jsonObj[keys[n]]).toLocaleString('en') + " (" + Math.round((jsonObj[keys[n]] / householdTotal) * 100) + "%)"
                            break;
                        } else {
                            jsonObj[keys[n]] = parseInt(jsonObj[keys[n]]).toLocaleString('en')
                            break;
                        }
                    case "households":
                    case "population":
                        jsonObj[keys[n]] = parseInt(jsonObj[keys[n]]).toLocaleString('en')
                        break;
                    case "income":
                        jsonObj[keys[n]] = "$" + FormatNumber(jsonObj[keys[n]])
                        break;
                }

            }
            let newJson = JSON.stringify(jsonObj)

            if (i == 0) {
                formattedData = { "data": [jsonObj] }
            } else {
                formattedData.data.push(jsonObj)
            }
        }
        return formattedData
    }


    const OnDimensionChange = (event) => {
        let dimensionArr = [];
        let dimensionHead = []
        for (let i = 0; i < event.length; i++) {
            dimensionArr.push(event[i].value)
            dimensionHead.push(event[i].label)
        }
        setDimensions(dimensionArr)
        setDimensionHeader(dimensionHead)
    }
    const OnLocationChange = (event) => {
        let locationArr = [];
        for (let i = 0; i < event.length; i++) {
            locationArr.push(event[i].value)
        }
        setFilter(locationArr)
    }



    return <>
        <div>
            <Row>
                <Col sm="12">
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">Audience Explorer</h4>
                            </div>
                            {/* <div>
                                <Button className="text-center btn-dark btn-icon mt-lg-0 mt-md-0 mt-3" variant="dark" >
                                    <i className="btn-inner">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                        </svg>
                                    </i>
                                    <span>New Report</span>
                                </Button>
                            </div> */}
                        </Card.Header>
                        <Card.Body className="px-1">
                            <Row>
                                <div className="mb-4 px-5" style={{ 'z-index': 1000000 }}>
                                    <Form onSubmit={LoadReport}>
                                        <Form.Group as={Row}>
                                            <Row>
                                                <Col lg="4" sm="4">
                                                    <Form.Label>Locations</Form.Label>

                                                    <AsyncSelect
                                                        placeholder={"Enter Text To Search"}
                                                        isMulti
                                                        isSearchable
                                                        name="locations"
                                                        loadOptions={loadOptions}
                                                        onInputChange={(value) => setQuery(value)}
                                                        onChange={event => OnLocationChange(event)}
                                                    />
                                                </Col>
                                                <Col lg="4" sm="4">
                                                    <Form.Label>Dimensions</Form.Label>
                                                    <Select
                                                        isMulti
                                                        isSearchable
                                                        name="dimensions"
                                                        options={dimensionsOptions}
                                                        onChange={event => OnDimensionChange(event)}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="2" className="mt-3">
                                                    <Button variant="dark" type='submit' >
                                                        Run Audience
                                                    </Button>{' '}
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Form>
                                </div>
                            </Row>
                            <Row>
                                <Col lg="12" sm="12">
                                    {(loading) ? (<Loader></Loader>) : (<></>)}
                                    {(data.data && !loading) ? (
                                        <DataGrid
                                            rows={data.data ?? []}
                                            columns={columns}
                                            // pageSize={page_size}
                                            // rowsPerPageOptions={[row_size]}
                                            // checkboxSelection
                                            getRowId={(row) => generateRandom()}
                                            // initalState={{filter: {filterModel:{filter}}}}
                                            slots={{ toolbar: GridToolbar }}
                                            slotProps={{
                                                toolbar: {
                                                    showQuickFilter: true,
                                                    quickFilterProps: { debounceMs: 500 },
                                                },
                                            }}
                                            autoHeight
                                        />
                                    ) : (
                                        <></>
                                    )}

                                </Col>

                            </Row>

                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    </>

    return
}
export default (Explorer)
