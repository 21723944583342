const general_info = [
    {
        label: 'Name',
        input: 'input-text',
        name: 'name',
        size: '4',
    },
    {
        label: 'Type',
        input: 'dropdown',
        name: 'campaignType',
        size: '4',
        options: [
            { label: 'Standard', value: 'Standard', name: 'campaignType' },
            { label: 'Programmatic Guaranteed', value: 'ProgrammaticGuaranteed', name: 'campaignType' }
        ]
    },
    {
        label: 'Pacing Mode',
        input: 'input-text',
        name: 'pacingMode',
        size: '4',
    },
    {
        label: 'Custom ROAS Type',
        input: 'input-text',
        name: 'customROASType',
        size: '4',
    },
    {
        label: 'Start Date',
        input: 'date',
        name: 'startDate',
        size: '4'
    },
    {
        label: 'End Date',
        input: 'date',
        name: 'endDate',
        size: '4'
    }
]

const budget_info = [
    {
        label: 'Budget in Impressions',
        input: 'input-number',
        name: 'budgetInImpressions',
        size: '4',
    },
    {
        label: 'Impressions Only Budgeting Cpm',
        input: 'input-number',
        name: 'impressionsOnlyBudgetingCpm',
        size: '4',
    },
    {
        label: 'Budget Currency Code',
        input: 'input-text',
        name: 'budget.currencyCode',
        size: '4',
    },
    {
        label: 'Budget Amount',
        input: 'input-number',
        name: 'budget.amount',
        size: '4',
    },
    {
        label: 'Daily Budget Currency Code',
        input: 'input-text',
        name: 'dailyBudget.currencyCode',
        size: '4',
    },
    {
        label: 'Daily Budget Amount',
        input: 'input-number',
        name: 'dailyBudget.amount',
        size: '4',
    },
    {
        label: 'Daily Budget in Impressions',
        input: 'input-number',
        name: 'dailyBudgetInImpressions',
        size: '4',
    }
]

const primary_goal = [
    {
        label: 'Maximize Reach',
        input: 'boolean',
        name: 'primaryGoal.maximizeReach',
        size: '4'
    },
    {
        label: 'Maximize LTV Incremental Reach',
        input: 'boolean',
        name: 'primaryGoal.maximizeLtcIncrementalReach',
        size: '4'
    },
    {
        label: 'CPA in Advertiser Currency Code',
        input: 'input-text',
        name: 'primaryGoal.cpainAdvertiserCurrency.currencyCode',
        size: '4',
    },
    {
        label: 'CPA in Advertiser Currency Amount',
        input: 'input-number',
        name: 'primaryGoal.cpainAdvertiserCurrency.amount',
        size: '4',
    },
    {
        label: 'CTR In Percent',
        input: 'input-number',
        name: 'primaryGoal.ctrinPercent',
        size: '4'
    },
    {
        label: 'Nielsen OTP In Percent',
        input: 'input-number',
        name: 'primaryGoal.nielsenOTPInPercent',
        size: '4'
    },
    {
        label: 'CPC in Advertiser Currency Code',
        input: 'input-text',
        name: 'primaryGoal.cpcinAdvertiserCurrency.currencyCode',
        size: '4',
    },
    {
        label: 'CPC in Advertiser Currency Amount',
        input: 'input-number',
        name: 'primaryGoal.cpcinAdvertiserCurrency.amount',
        size: '4'
    },
    {
        label: 'Maximize Conversion Revenue',
        input: 'boolean',
        name: 'primaryGoal.maximizeConversionRevenue',
        size: '4'
    },
    {
        label: 'Return on Ad Spend Percent',
        input: 'input-number',
        name: 'primaryGoal.returnOnAdSpendPercent',
        size: '4'
    },
    {
        label: 'VCR in Percent',
        input: 'input-number',
        name: 'primaryGoal.vcrinPercent',
        size: '4'
    },
    {
        label: 'Viewability in Percent',
        input: 'input-number',
        name: 'primaryGoal.viewabilityInPercent',
        size: '4'
    },
    {
        label: 'VCPM in Advertiser Currency Code',
        input: 'input-text',
        name: 'primaryGoal.vcpminAdvertiserCurrency.currencyCode',
        size: '4',
    },
    {
        label: 'VCPM in Advertiser Currency Amount',
        input: 'input-number',
        name: 'primaryGoal.vcpminAdvertiserCurrency.amount',
        size: '4'
    },
    {
        label: 'CPCV in Advertiser Currency Code',
        input: 'input-text',
        name: 'primaryGoal.cpcvinAdvertiserCurrency.currencyCode',
        size: '4',
    },
    {
        label: 'CPCV in Advertiser Currency Amount',
        input: 'input-number',
        name: 'primaryGoal.cpcvinAdvertiserCurrency.amount',
        size: '4'
    },
    {
        label: 'Miaozhen OTP in Percent',
        input: 'input-number',
        name: 'primaryGoal.miaozhenOTPInPercent',
        size: '4'
    },
]

const secondary_goal = [
    {
        label: 'Maximize Reach',
        input: 'boolean',
        name: 'secondaryGoal.maximizeReach',
        size: '4'
    },
    {
        label: 'Maximize LTV Incremental Reach',
        input: 'boolean',
        name: 'secondaryGoal.maximizeLtcIncrementalReach',
        size: '4'
    },
    {
        label: 'CPA in Advertiser Currency Code',
        input: 'input-text',
        name: 'secondaryGoal.cpainAdvertiserCurrency.currencyCode',
        size: '4',
    },
    {
        label: 'CPA in Advertiser Currency Amount',
        input: 'input-number',
        name: 'secondaryGoal.cpainAdvertiserCurrency.amount',
        size: '4',
    },
    {
        label: 'CTR In Percent',
        input: 'input-number',
        name: 'secondaryGoal.ctrinPercent',
        size: '4'
    },
    {
        label: 'Nielsen OTP In Percent',
        input: 'input-number',
        name: 'secondaryGoal.nielsenOTPInPercent',
        size: '4'
    },
    {
        label: 'CPC in Advertiser Currency Code',
        input: 'input-text',
        name: 'secondaryGoal.cpcinAdvertiserCurrency.currencyCode',
        size: '4',
    },
    {
        label: 'CPC in Advertiser Currency Amount',
        input: 'input-number',
        name: 'secondaryGoal.cpcinAdvertiserCurrency.amount',
        size: '4'
    },
    {
        label: 'Maximize Conversion Revenue',
        input: 'boolean',
        name: 'secondaryGoal.maximizeConversionRevenue',
        size: '4'
    },
    {
        label: 'Return on Ad Spend Percent',
        input: 'input-number',
        name: 'secondaryGoal.returnOnAdSpendPercent',
        size: '4'
    },
    {
        label: 'VCR in Percent',
        input: 'input-number',
        name: 'secondaryGoal.vcrinPercent',
        size: '4'
    },
    {
        label: 'Viewability in Percent',
        input: 'input-number',
        name: 'secondaryGoal.viewabilityInPercent',
        size: '4'
    },
    {
        label: 'VCPM in Advertiser Currency Code',
        input: 'input-text',
        name: 'secondaryGoal.vcpminAdvertiserCurrency.currencyCode',
        size: '4',
    },
    {
        label: 'VCPM in Advertiser Currency Amount',
        input: 'input-number',
        name: 'secondaryGoal.vcpminAdvertiserCurrency.amount',
        size: '4'
    },
    {
        label: 'CPCV in Advertiser Currency Code',
        input: 'input-text',
        name: 'secondaryGoal.cpcvinAdvertiserCurrency.currencyCode',
        size: '4',
    },
    {
        label: 'CPCV in Advertiser Currency Amount',
        input: 'input-number',
        name: 'secondaryGoal.cpcvinAdvertiserCurrency.amount',
        size: '4'
    },
    {
        label: 'Miaozhen OTP in Percent',
        input: 'input-number',
        name: 'secondaryGoal.miaozhenOTPInPercent',
        size: '4'
    },
]

const tertiary_goal = [
    {
        label: 'Maximize Reach',
        input: 'boolean',
        name: 'tertiaryGoal.maximizeReach',
        size: '4'
    },
    {
        label: 'Maximize LTV Incremental Reach',
        input: 'boolean',
        name: 'tertiaryGoal.maximizeLtcIncrementalReach',
        size: '4'
    },
    {
        label: 'CPA in Advertiser Currency Code',
        input: 'input-text',
        name: 'tertiaryGoal.cpainAdvertiserCurrency.currencyCode',
        size: '4',
    },
    {
        label: 'CPA in Advertiser Currency Amount',
        input: 'input-number',
        name: 'tertiaryGoal.cpainAdvertiserCurrency.amount',
        size: '4',
    },
    {
        label: 'CTR In Percent',
        input: 'input-number',
        name: 'tertiaryGoal.ctrinPercent',
        size: '4'
    },
    {
        label: 'Nielsen OTP In Percent',
        input: 'input-number',
        name: 'tertiaryGoal.nielsenOTPInPercent',
        size: '4'
    },
    {
        label: 'CPC in Advertiser Currency Code',
        input: 'input-text',
        name: 'tertiaryGoal.cpcinAdvertiserCurrency.currencyCode',
        size: '4',
    },
    {
        label: 'CPC in Advertiser Currency Amount',
        input: 'input-number',
        name: 'tertiaryGoal.cpcinAdvertiserCurrency.amount',
        size: '4'
    },
    {
        label: 'Maximize Conversion Revenue',
        input: 'boolean',
        name: 'tertiaryGoal.maximizeConversionRevenue',
        size: '4'
    },
    {
        label: 'Return on Ad Spend Percent',
        input: 'input-number',
        name: 'tertiaryGoal.returnOnAdSpendPercent',
        size: '4'
    },
    {
        label: 'VCR in Percent',
        input: 'input-number',
        name: 'tertiaryGoal.vcrinPercent',
        size: '4'
    },
    {
        label: 'Viewability in Percent',
        input: 'input-number',
        name: 'tertiaryGoal.viewabilityInPercent',
        size: '4'
    },
    {
        label: 'VCPM in Advertiser Currency Code',
        input: 'input-text',
        name: 'tertiaryGoal.vcpminAdvertiserCurrency.currencyCode',
        size: '4',
    },
    {
        label: 'VCPM in Advertiser Currency Amount',
        input: 'input-number',
        name: 'tertiaryGoal.vcpminAdvertiserCurrency.amount',
        size: '4'
    },
    {
        label: 'CPCV in Advertiser Currency Code',
        input: 'input-text',
        name: 'tertiaryGoal.cpcvinAdvertiserCurrency.currencyCode',
        size: '4',
    },
    {
        label: 'CPCV in Advertiser Currency Amount',
        input: 'input-number',
        name: 'tertiaryGoal.cpcvinAdvertiserCurrency.amount',
        size: '4'
    },
    {
        label: 'Miaozhen OTP in Percent',
        input: 'input-number',
        name: 'tertiaryGoal.miaozhenOTPInPercent',
        size: '4'
    },
]

const frequency_settings = [
    {
        label: 'Lifetime Frequency Cap',
        input: 'input-number',
        name: 'frequencySettings.lifetimeFrequencyCap',
        size: '4'
    },
    {
        label: 'Frequency Cap',
        input: 'input-number',
        name: 'frequencySettings.frequencyCap',
        size: '4'
    },
    {
        label: 'Frequency Period In Minutes',
        input: 'input-number',
        name: 'frequencySettings.frequencyPeriodInMinutes',
        size: '4'
    }
]

const partner_fees = [
    {
        label: 'Partner CPC Fee Currency Code',
        input: 'input-text',
        name: 'partnerCPCFee.currencyCode',
        size: '4',
    },
    {
        label: 'Partner CPC Fee Amount',
        input: 'input-number',
        name: 'partnerCPCFee.amount',
        size: '4',
    },
    {
        label: 'Partner CPM Fee Currency Code',
        input: 'input-text',
        name: 'partnerCPMFee.currencyCode',
        size: '4',
    },
    {
        label: 'Partner CPM Fee Amount',
        input: 'input-number',
        name: 'partnerCPMFee.amount',
        size: '4',
    },
    {
        label: 'Partner Cost Percentage Fee',
        input: 'input-number',
        name: 'partnerCostPercentageFee',
        size: '4'
    }
]

const CampaignTradeDeskForm = {
    titles: ["General Info", "Budget", "Primary Goal", "Secondary Goal", "Tertiary Goal", "Frequency Settings", "Partner Fees"],
    fields: [
        general_info,
        budget_info,
        primary_goal,
        secondary_goal,
        tertiary_goal,
        frequency_settings,
        partner_fees,
    ]
}

export { CampaignTradeDeskForm }