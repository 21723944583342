import { React, useState } from 'react'
import { Row, Col, Button, Form, Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Card from '../../../components/Card'
import axios from 'axios'
import Cookies from 'universal-cookie';
import Loader from '../../../components/Loader'
import ReactTable from '../../../components/table'
import { FetchFromAPI } from '../../../components/ajx'
import { useAppContext } from '../../../context/appContext'

import { GlobalChangeEvent, Status } from '../../../components/common'
import Select from 'react-select'



export default function AccountList() {
   const appValue = useAppContext();
   const [values, setValues] = useState({
      name: "",
      id: "",
      dv_partner_id: 0,
      meta_account_id: "",
      trade_desk_partner_id: "",
      search_account_id:"",
      currency: "",
      status: "",
      timezone: "",
   });
   const cookies = new Cookies();
   const tokenValue = cookies.get('aplus-token');
   const [show, setShow] = useState(false);
   const [showDelete, setShowDelete] = useState(false);

   const [newAccount, setNewAccount] = useState(true);

   const handleClose = () => {
      setShow(false);
      setShowDelete(false);
   }
   function handleShow(params, type) {
      if (type === "edit") {
         console.log("PARAMS: ",params)
         setValues({
            name: params.row.name,
            dv_partner_id: params.row.dv_partner_id,
            meta_account_id: params.row.meta_account_id,
            trade_desk_partner_id: params.row.trade_desk_partner_id,
            search_account_id:params.row.search_account_id,
            status: params.row.status,
            currency: params.row.currency,
            id: params.row._id,
            timezone: params.row.timezone,
         });
         setNewAccount(false);
      } else {
         setValues({
            name: "",
            dv_partner_id: 0,
            meta_account_id: "",
            search_account_id:"",
            trade_desk_partner_id: "",
            currency: "",
            status: "",
            id: "",
            timezone: "",
         });
         setNewAccount(true);
      }

      if (type === "delete") {
         setShowDelete(true);
         console.log("Delete Called")
         setValues({ id: params.row._id })
      } else {
         setShow(true);
      }
   }
   var REACT_APP_API_URL = appValue.apiUrl;
   const { NODE_ENV } = process.env;

   const accountCreate = async (json) => {
      const url = REACT_APP_API_URL + '/admin/create/account';
      const config = {
         headers: {
            Token: tokenValue,
         }
      };

      return axios.post(url, json, config).then((response) => {
         if (response.data) {
            if (NODE_ENV == 'development') {
               console.log(response.data);
            }
            window.location.reload(true)

         } else {
            <p>{response.error}</p>
         }
      })
   }

   const accountEdit = async (json) => {
      console.log("updating...")

      const url = REACT_APP_API_URL + '/admin/update/account';
      const config = {
         headers: {
            Token: tokenValue,
         }
      };

      return axios.put(url, json, config).then((response) => {
         if (response.data) {
            if (NODE_ENV == 'development') {

               console.log(response.data);
            }
            window.location.reload(true)
         } else {
            <p>{response.error}</p>
         }
      })
   }

   const accountDelete = async (json) => {
      if (NODE_ENV == 'development') {
         console.log("deleting...")

      }
      const url = REACT_APP_API_URL + '/admin/delete/account';
      const config = {
         headers: {
            Token: tokenValue,
         },
         data: json
      };

      return axios.delete(url, config).then((response) => {
         if (response.data) {
            if (NODE_ENV == 'development') {
               console.log(response.data);
            }
            window.location.reload(true)
         } else {
            <p>{response.error}</p>
         }
      })
   }
   function handleDelete(event) {
      event.preventDefault();
      if (NODE_ENV == 'development') {
         console.log(event.target.id.value)
      }
      const json = JSON.stringify({
         id: event.target.id.value,
      });
      accountDelete(json);
      handleClose();
   }



   function handleSubmit(event) {
      event.preventDefault();
      if (NODE_ENV == 'development') {
         console.log(newAccount);
      }
      var currency;
      var timezone;
      if (results["dv_accounts"].data) {
         for (let i = 0; i < results["dv_accounts"].data.length; i++) {
            if (results["dv_accounts"].data[i].DV_Partner_Id == event.target.dv.value) {
               currency = results["dv_accounts"].data[i].Currency
               timezone = results["dv_accounts"].data[i].Timezone
               break;
            }
         }
      } else if (results["meta_accounts"].data) {
         for (let i = 0; i < results["meta_accounts"].data.length; i++) {
            
            if (results["meta_accounts"].data[i].meta_account_id == event.target.meta.value) {
               currency = results["dv_accounts"].data[i].meta_ad_account.currency
               timezone = results["dv_accounts"].data[i].meta_ad_account.timezone_name
               break;
            }
         }
      }
      const json = JSON.stringify(values);
      console.log(json)
      if (newAccount) {
         accountCreate(json)
      } else {
         accountEdit(json);
      }
      handleClose();
   }

   const accountAPI = {
      urls: [
         {
            name: "accounts",
            url: REACT_APP_API_URL + "/admin/list/accounts"
         },
         {
            name: "dv_accounts",
            url: REACT_APP_API_URL + "/admin/list/accounts/dv"
         },
         {
            name: "meta_accounts",
            url: REACT_APP_API_URL + "/admin/list/accounts/meta"
         },
         {
            name: "search_accounts",
            url: REACT_APP_API_URL + "/admin/list/accounts/search"
         },
         {
            name: "td_accounts",
            url: REACT_APP_API_URL + "/admin/list/accounts/td"
         },
      ],
      method: "GET",

   }

   const { results, errors, complete } = FetchFromAPI(accountAPI)
   if (complete) {
      console.log(results)
      const dvAccountOptions = []
      const metaAccountOptions = []
      const searchAccountOptions = []
      const tdAccountOptions = []

      if (results["dv_accounts"].data) {
         for (let i = 0; i < results["dv_accounts"].data.length; i++) {
            dvAccountOptions.push({ value: results["dv_accounts"].data[i].DV_Partner_Id, label: results["dv_accounts"].data[i].Name })
         }
      }
      if (results["meta_accounts"].data) {

         for (let i = 0; i < results["meta_accounts"].data.length; i++) {
            metaAccountOptions.push({ value: results["meta_accounts"].data[i].meta_account_id, label: results["meta_accounts"].data[i].name })
         }
      }
      if (results["search_accounts"].data) {

         for (let i = 0; i < results["search_accounts"].data.length; i++) {
            searchAccountOptions.push({ value: results["search_accounts"].data[i].accountId, label: results["search_accounts"].data[i].name })
         }
      }
      if (results["td_accounts"].data) {
            const a = results["td_accounts"].data
            tdAccountOptions.push({ value: a.trade_desk_partner_id, label: a.name + ' | ' + a.trade_desk_partner_id})
      }


      const columns = [
         {
            field: 'Name', headerName: 'Account Name', flex: 1, sortable: true, renderCell: (params) => (
               params.row.name
            ),
         },
         { field: 'DV_id', headerName: 'DV360 Id', flex: 1, renderCell: (params) => params.row.dv_partner_id },
         { field: 'Meta_id', headerName: 'Meta Id', flex: 1, renderCell: (params) => params.row.meta_account_id },
         { field: 'Search_id', headerName: 'Search Id', flex: 1, renderCell: (params) => params.row.search_account_id },
         { field: 'Trade_Desk_id', headerName: 'Trade Desk Id', flex: 1, renderCell: (params) => params.row.trade_desk_partner_id },

         {
            field: 'Status', headerName: 'Status', flex: 1, sortable: true, renderCell: (params) => {
               return <>
                  <span className={`badge ${params.row.status ? "bg-success" : "bg-danger"}`}>{params.row.status ? "Active" : "Inactive"}</span>
               </>
            }
         },
         { field: '_id', headerName: 'ID', flex: 1 },
         {
            field: "action",
            headerName: "Action",
            sortable: false,
            renderCell: (params) => {
               const onClick = (e) => {
                  e.stopPropagation(); // don't select this row after clicking

                  const api = params.api;
                  const thisRow = {};

                  api
                     .getAllColumns()
                     .filter((c) => c.field !== "__check__" && !!c)
                     .forEach(
                        (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
                     );

                  return alert(JSON.stringify(thisRow, null, 4));
               };

               return <><Link className="btn btn-sm btn-icon btn-warning table-sm" data-toggle="tooltip" data-placement="top" title="" data-original-title="Edit" to="#" onClick={() => { handleShow(params, "edit") }}>
                  <span className="btn-inner">
                     <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                        <path fillRule="evenodd" clipRule="evenodd" d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                        <path d="M15.1655 4.60254L19.7315 9.16854" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                     </svg>
                  </span>
               </Link>{' '}
                  <p></p>
                  <Link className="btn btn-sm btn-icon btn-danger" data-toggle="tooltip" data-placement="top" title="" data-original-title="Delete" to="#" onClick={() => { handleShow(params, "delete") }}>
                     <span className="btn-inner">
                        <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
                           <path d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                           <path d="M20.708 6.23975H3.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                           <path d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                        </svg>
                     </span>
                  </Link>{' '}</>
            }
         }
      ];
      return errors.length > 0 ? (<><span>Error: {errors}</span></>) : (
         <>
            <div>
               <Row>
                  <Col sm="12">
                     <Card>
                        <Card.Header className="d-flex justify-content-between">
                           <div className="header-title">
                              <h4 className="card-title">Account List</h4>
                           </div>
                           <div>
                              <Button className="text-center btn-icon mt-lg-0 mt-md-0 mt-3" variant="dark" onClick={() => { handleShow("none", "add") }}>
                                 <i className="btn-inner">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                       <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                    </svg>
                                 </i>
                                 <span>New Account</span>
                              </Button>
                              {/* Modal Popup */}
                              <Modal show={show} onHide={handleClose}>
                                 <Modal.Header closeButton>
                                    <Modal.Title>Add new account</Modal.Title>
                                 </Modal.Header>
                                 <Modal.Body>
                                    <Form onSubmit={
                                       (event) => {
                                          handleSubmit(event);
                                          // userpush()
                                          // handleClose()
                                       }
                                    }>
                                       <Form.Group className="mb-3" >
                                          <Form.Label>Name</Form.Label>
                                          <Form.Control type="text" defaultValue={values.name} name="name" placeholder="Account Name" required  onBlur={(e)=>  {GlobalChangeEvent("name", e, values, false, setValues,0)}}/>
                                       </Form.Group>
                                       <Form.Group>
                                          <Form.Label>Meta Account</Form.Label>
                                          <Select name="meta" options={metaAccountOptions} defaultValue={() => {
                                             console.log(metaAccountOptions,values)
                                             for (let i = 0; i < metaAccountOptions.length; i++) {
                                                if (metaAccountOptions[i].value === String(values.meta_account_id)) {
                                                   return metaAccountOptions[i]
                                                }
                                             }                                             
                                          }} onChange={(e)=>  {GlobalChangeEvent("meta_account_id", e, values, false, setValues,0)}}>

                                          </Select>
                                       </Form.Group>
                                       <Form.Group>
                                          <Form.Label>Search360 Account</Form.Label>
                                          <Select name="search" options={searchAccountOptions} defaultValue={() => {
                                             for (let i = 0; i < searchAccountOptions.length; i++) {
                                                if (searchAccountOptions[i].value === values.search_account_id) {
                                                   return searchAccountOptions[i]
                                                }
                                             }
                                          }} onChange={(e)=>  {GlobalChangeEvent("search_account_id", e, values, false, setValues,0)}}>

                                          </Select>
                                       </Form.Group>
                                       <Form.Group>
                                          <Form.Label>DV360 Account</Form.Label>
                                          <Select name="dv" options={dvAccountOptions} defaultValue={() => {
                                             for (let i = 0; i < dvAccountOptions.length; i++) {
                                                if (dvAccountOptions[i].value === values.dv_partner_id) {
                                                   return dvAccountOptions[i]
                                                }
                                             }
                                          }} onChange={(e)=>  {GlobalChangeEvent("dv_partner_id", e, values, false, setValues,0)}}>

                                          </Select>
                                       </Form.Group>
                                       <Form.Group>
                                          <Form.Label>Trade Desk Account</Form.Label>
                                          <Select name="td" options={tdAccountOptions} defaultValue={() => {
                                             for (let i = 0; i < tdAccountOptions.length; i++) {
                                                if (tdAccountOptions[i].value === values.trade_desk_partner_id) {
                                                   return tdAccountOptions[i]
                                                }
                                             }
                                          }}  onChange={(e)=>  {GlobalChangeEvent("trade_desk_partner_id", e, values, false, setValues,0)}}>

                                          </Select>
                                       </Form.Group>
                                       <Form.Group className="form-group">
                                          <Form.Label htmlFor="status">Status:</Form.Label>
                                          <Status value={values.status} name="status" required  onChange={(e)=>  {GlobalChangeEvent("status", e, values, false, setValues,0)}}/>
                                       </Form.Group>                                       
                                       <div className="d-flex justify-content-between">

                                          <Button variant="danger" onClick={handleClose}>
                                             Cancel
                                          </Button>
                                          <Button variant="dark" type='submit' >
                                             Save
                                          </Button>{' '}
                                       </div>
                                    </Form>
                                 </Modal.Body>
                              </Modal>

                              {/* Modal Popup - Delete */}
                              <Modal show={showDelete} onHide={handleClose}>
                                 <Modal.Header closeButton>
                                    <Modal.Title>Delete user</Modal.Title>
                                 </Modal.Header>
                                 <Modal.Body>
                                    <Form onSubmit={
                                       (event) => {
                                          handleDelete(event);
                                       }
                                    }>
                                       <Form.Group className="mb-3" >
                                          <Form.Control type="hidden" defaultValue={values.id} name="id" />
                                       </Form.Group>
                                       <div className="d-flex justify-content-between">
                                          <Button variant="danger" onClick={handleClose}>
                                             Cancel
                                          </Button>
                                          <Button variant="dark" type='submit' >
                                             Delete
                                          </Button>{' '}
                                       </div>
                                    </Form>
                                 </Modal.Body>
                              </Modal>
                           </div>
                        </Card.Header>
                        <Card.Body className="px-0">
                           <div className="table-responsive" style={{ display: 'flex', height: 'auto' }}>
                              <ReactTable item={results["accounts"]} col={columns} page_size={20} row_size={20} />
                           </div>
                        </Card.Body>
                     </Card>
                  </Col>
               </Row>
            </div>
         </>
      )
   }
   return <><tr><td><Loader></Loader></td></tr></>;
}