import { React, useEffect, useState } from 'react'
import { Row, Col, Tab, Nav, Button, Anchor } from 'react-bootstrap'
import Card from '../../../components/Card'
import Loader from '../../../components/Loader'
import { DateFormat, CountEntities, Micros, ConvertBudget } from '../../../components/common'
import { useParams } from 'react-router-dom'
import { FetchDashFromAPI, FetchFromAPI } from '../../../components/ajx'
import ReactTable from '../../../components/table'

import { columnsOrder, columnsGroup } from '../../../components/gridColumns'
// AOS
import AOS from 'aos'
import '../../../../node_modules/aos/dist/aos'
import '../../../../node_modules/aos/dist/aos.css'

// Import Swiper styles
import Dash from '../../../components/dash';
import {DateButtons} from '../../../components/dateButtons';
import { OrderModal } from '../../../components/Modals'
import { OrderForm } from '../../../util/order-input'
import { ModalButton } from '../../../components/ModalSettings'
import { CampaignModel, OrderModel } from '../../../components/Models'
import Crumbs from '../../../components/crumbs'
import CampaignForm from '../../../util/campaign-input'
import InputSettings from '../../../components/InputSettings'
import { useAppContext } from '../../../context/appContext'


const Campaign = (props) => {
    const appValue = useAppContext();
    const [modal, setModal] = useState(false);
    const [campaign, setCampaign] = useState(CampaignModel)
    const [order, setOrder] = useState(OrderModel)
    const [initialLoad, setInitialLoad] = useState(true)
    const [date, setDate] = useState(1)
    const { cid } = useParams()
    var REACT_APP_API_URL = appValue.apiUrl;
    const [dashAPI, setDashAPI] = useState({
        urls: [
            {
                name: "campaign",
                url: REACT_APP_API_URL + "/campaign/" + cid+"/"+"DV"
            },
            {
                name: "targeting",
                url: REACT_APP_API_URL + "/lib/targeting"
            },
            {
                name: "orders",
                url: REACT_APP_API_URL + "/order/list/campaign/" + cid
            },
            {
                name: "groups",
                url: REACT_APP_API_URL + "/group/list/campaign/" + cid
            },
            {
                name: "dash",
                url: REACT_APP_API_URL + "/reports/dash/campaign/" + appValue.user.aid + "/" + cid+"/"+"DV" + "?date=" + date
            },
            {
                name: "account",
                url: REACT_APP_API_URL + "/admin/get/account/" + appValue.user.apid
            },
            {
                name: "crumbs",
                url: REACT_APP_API_URL + "/util/breadcrumb/campaigns/DV/" + cid
            }

        ],
        method: "GET",

    })

    useEffect(() => {

        AOS.init({
            startEvent: 'DOMContentLoaded',
            disable: function () {
                var maxWidth = 996;
                return window.innerWidth < maxWidth;
            },
            throttleDelay: 10,
            once: true,
            duration: 700,
            offset: 10
        });

    })



    const { NODE_ENV } = process.env;
    useEffect(() => {
        complete = false
        setDashAPI({
            urls: [
                {
                    name: "campaign",
                    url: REACT_APP_API_URL + "/campaign/" + cid+"/"+"DV"
                },
                {
                    name: "targeting",
                    url: REACT_APP_API_URL + "/lib/targeting"
                },
                {
                    name: "orders",
                    url: REACT_APP_API_URL + "/order/list/campaign/" + cid
                },
                {
                    name: "groups",
                    url: REACT_APP_API_URL + "/group/list/campaign/" + cid
                },
                {
                    name: "dash",
                    url: REACT_APP_API_URL + "/reports/dash/campaign/" + appValue.user.aid + "/" + cid+"/"+"DV" + "?date=" + date
                },
                {
                    name: "account",
                    url: REACT_APP_API_URL + "/admin/get/account/" + appValue.user.apid
                },
                {
                    name: "crumbs",
                    url: REACT_APP_API_URL + "/util/breadcrumb/campaigns/DV/" + cid
                }
    
            ],
            method: "GET",
    
        })
    }, [date])
    var { results, errors, complete } = FetchDashFromAPI(dashAPI)

    let url = REACT_APP_API_URL + "/campaign/update/" + cid

    if (complete) {
        if (NODE_ENV == 'development') {
            console.log(results)
        }
        if (initialLoad) {
            setInitialLoad(false)
            CampaignForm.fields[0][0].options.push({label: results["campaign"].data.dv_advertiser_id.toString(),value:results["campaign"].data.dv_advertiser_id.toString()})
            campaign.id = results["campaign"].data.dv_campaign_id.toString();
            OrderForm.fields[0][0].options.push({label: campaign.id,value:campaign.id})
            campaign.advertiser_id = results["campaign"].data.dv_advertiser_id.toString();
            order.advertiser_id = results["campaign"].data.dv_advertiser_id.toString();
            order.campaign_id = results["campaign"].data.dv_campaign_id.toString();
            campaign.name = results["campaign"].data.name;
            campaign.status = results["campaign"].data.dv_campaign.entityStatus;
            if (results["campaign"].data.dv_campaign.campaignGoal) {

                campaign.performance_goal.type = results["campaign"].data.dv_campaign.campaignGoal.campaignGoalType;
                campaign.performance_goal.perf_type = results["campaign"].data.dv_campaign.campaignGoal.performanceGoal.performanceGoalType;
                campaign.performance_goal.amount = (results["campaign"].data.dv_campaign.campaignGoal.performanceGoal.performanceGoalAmountMicros/Micros).toString();

            }
            if (results["campaign"].data.dv_campaign.frequencyCap.maxImpressions) {
                campaign.frequency_cap.unlimited = false
                campaign.frequency_cap.count = results["campaign"].data.dv_campaign.frequencyCap.timeUnitCount
                campaign.frequency_cap.timeunit = results["campaign"].data.dv_campaign.frequencyCap.timeUnit
                campaign.frequency_cap.max_impressions = results["campaign"].data.dv_campaign.frequencyCap.maxImpressions

            } else {
                campaign.frequency_cap.unlimited = true
            }
            if (results["campaign"].data.dv_campaign.campaignBudgets) {
                let sDate = results["campaign"].data.dv_campaign.campaignBudgets[0].dateRange.startDate
                let eDate = results["campaign"].data.dv_campaign.campaignBudgets[0].dateRange.endDate
                campaign.campaign_budget.amount = (results["campaign"].data.dv_campaign.campaignBudgets[0].budgetAmountMicros / Micros).toString()
                campaign.campaign_budget.unit = ConvertBudget(results["campaign"].data.dv_campaign.campaignBudgets[0].budgetUnit)
                campaign.campaign_budget.name = results["campaign"].data.dv_campaign.campaignBudgets[0].displayName
                campaign.campaign_budget.start_date = DateFormat(sDate.year, sDate.month, sDate.day)
                campaign.campaign_budget.end_date = DateFormat(eDate.year, eDate.month, eDate.day)
            }
            if (results["campaign"].data.dv_campaign.campaignGoal.performanceGoal.performanceGoalAmountMicros) {
                campaign.performance_goal.metric = "$"
            } else {
                campaign.performance_goal.metric = "%"
            }

        }

        let activeOrders = 0
        let activeGroups = 0
        if(results["orders"] && results["orders"].data) {
            activeOrders = CountEntities(results["orders"].data);
        }
        if(results["groups"] && results["groups"].data){
            activeGroups = CountEntities(results["groups"].data)

        }


        return errors.length > 0 ? (<><span>Error: {errors}</span></>) : (
            <>
                <Tab.Container defaultActiveKey="first">
                    <Row>
                        <Col lg="12">
                            <Card>
                                <Card.Body>
                                    <Crumbs data={results["crumbs"].data} platform="DV" type="campaign" />

                                    <div className="d-flex flex-wrap align-items-center justify-content-between">
                                        <div className="d-flex flex-wrap align-items-center">
                                            <div className="d-flex flex-wrap align-items-center mb-3 mb-sm-0">
                                                <h4 className="me-2 h4">{campaign.name}</h4>
                                                <span> - Campaign</span>
                                            </div>
                                        </div>
                                        <Nav as="ul" className="d-flex nav-pills mb-0 text-center profile-tab" data-toggle="slider-tab" id="profile-pills-tab" role="tablist">
                                            <Nav.Item as="li">
                                                <Nav.Link eventKey="first" className="button">Dash</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item as="li">
                                                <Nav.Link eventKey="fourth" className="button">Settings</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item as="li">
                                                <Nav.Link eventKey="second" className="button">Orders</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item as="li">
                                                <Nav.Link eventKey="third" className="button">Groups</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg="6">
                            <Card>
                                <Card.Header>
                                    <div className="header-title">
                                        <h4 className="card-title">Snapshot</h4>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <ul className="list-inline m-0 p-0">
                                        <li className="d-flex mb-2">
                                            <div className="news-icon me-3">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-ubuntu" viewBox="0 0 16 16"><path d="M2.273 9.53a2.273 2.273 0 1 0 0-4.546 2.273 2.273 0 0 0 0 4.547Zm9.467-4.984a2.273 2.273 0 1 0 0-4.546 2.273 2.273 0 0 0 0 4.546ZM7.4 13.108a5.535 5.535 0 0 1-3.775-2.88 3.273 3.273 0 0 1-1.944.24 7.4 7.4 0 0 0 5.328 4.465c.53.113 1.072.169 1.614.166a3.253 3.253 0 0 1-.666-1.9 5.639 5.639 0 0 1-.557-.091Zm3.828 2.285a2.273 2.273 0 1 0 0-4.546 2.273 2.273 0 0 0 0 4.546Zm3.163-3.108a7.436 7.436 0 0 0 .373-8.726 3.276 3.276 0 0 1-1.278 1.498 5.573 5.573 0 0 1-.183 5.535 3.26 3.26 0 0 1 1.088 1.693ZM2.098 3.998a3.28 3.28 0 0 1 1.897.486 5.544 5.544 0 0 1 4.464-2.388c.037-.67.277-1.313.69-1.843a7.472 7.472 0 0 0-7.051 3.745Z"></path></svg>
                                            </div>
                                            <p className="news-detail mb-0">{activeOrders} - Active Orders</p>
                                        </li>
                                        <li className="d-flex">
                                            <div className="new-icon me-3">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-collection-fill" viewBox="0 0 16 16"><path d="M0 13a1.5 1.5 0 0 0 1.5 1.5h13A1.5 1.5 0 0 0 16 13V6a1.5 1.5 0 0 0-1.5-1.5h-13A1.5 1.5 0 0 0 0 6v7zM2 3a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 0-1h-11A.5.5 0 0 0 2 3zm2-2a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 0-1h-7A.5.5 0 0 0 4 1z"></path></svg>
                                            </div>
                                            <p className="news-detail mb-0">{activeGroups} - Active Groups</p>

                                        </li>
                                    </ul>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg="6">
                            <Card>
                                <Card.Header>
                                    <div className="header-title">
                                        <h4 className="card-title">Campaign Info</h4>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <div className="mb-1">Campaign Id: {campaign.id}</div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg="12">
                            <Tab.Content className="profile-content">
                                <Tab.Pane eventKey="second" id="order">
                                    <Card>
                                        <Card.Header className="d-flex justify-content-between">
                                            <div className="header-title">
                                                <h4 className="card-title">Order List</h4>
                                            </div>
                                            <div>
                                                <ModalButton name="New Order" setModal={setModal} />
                                            </div>
                                        </Card.Header>
                                        <Card.Body className="px-0">
                                        {(results["orders"] && results["orders"].data)?(

                                            <div className="table-responsive" style={{ width: `100%` }}>
                                                <ReactTable item={results["orders"]} col={columnsOrder} page_size={20} row_size={20} />
                                            </div>
                                                ):(<></>)}
                                            <div>
                                                <OrderModal order={order} setOrder={setOrder} form={OrderForm} modal={modal} setModal={setModal} baseUrl={REACT_APP_API_URL} entityId={campaign.id} />
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Tab.Pane >
                                <Tab.Pane eventKey="third" id="group">
                                    <Card>
                                        <Card.Header className="d-flex justify-content-between">
                                            <div className="header-title">
                                                <h4 className="card-title">Group List</h4>
                                            </div>
                                            {/* <div>
                                    <Button className="text-center btn-dark btn-icon mt-lg-0 mt-md-0 mt-3" variant="dark" >
                                       <i className="btn-inner">
                                          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                             <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                          </svg>
                                       </i>
                                       <span>New Group</span>
                                    </Button>
                                 </div> */}
                                        </Card.Header>                                        
                                        <Card.Body className="px-0">
                                            {(results["groups"] && results["groups"].data)?(

                                                <div className="table-responsive" style={{ width: `100%` }}>
                                                <ReactTable item={results["groups"]} col={columnsGroup} page_size={20} row_size={20} />
                                            </div>
                                                ):(<></>)}
                                        </Card.Body>
                                    </Card>
                                </Tab.Pane>
                                <Tab.Pane eventKey="first" id="dash">
                                    <Row className='dateButtons_sub'>
                                        <Col lg={{ span: 6, offset: 6 }}>
                                            <DateButtons setDate={setDate} date={date} />
                                        </Col>
                                    </Row>
                                        <Dash dash={results["dash"].data["dv"]} account={results["account"]} />
                                </Tab.Pane >
                                <Tab.Pane eventKey="fourth" id="settings">
                                    <Row>
                                        <Col lg="12">
                                            <InputSettings
                                                title='Add New Campaign'
                                                mode='edit'
                                                form={CampaignForm}
                                                url={url}
                                                formId={"campaignform"}
                                                model={[campaign, setCampaign]}
                                            />
                                        </Col>
                                    </Row>
                                </Tab.Pane >
                            </Tab.Content>
                        </Col>

                    </Row>
                </Tab.Container>
            </>
        );
    }
    return <><div><span><Loader></Loader></span></div></>;
}
export default (Campaign)
