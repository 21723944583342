import { React, useState, useEffect, useLayoutEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import Card from '../../../components/Card'
import Loader from '../../../components/Loader'
import { FetchFromAPI } from '../../../components/ajx'
import { columnsOrder } from '../../../components/gridColumns'
import ReactTable from '../../../components/table'

import { OrderForm } from '../../../util/order-input'
import { MetaOrderForm } from '../../../util/meta-order-input'

import { ModalButton } from '../../../components/ModalSettings'
import { OrderModal, OrderMetaModal } from '../../../components/Modals'
import { OrderModel, MetaOrderModel, MetaInputBriefModel } from '../../../components/Models'
import { useAppContext } from '../../../context/appContext'

export default function OrderList() {
   const appValue = useAppContext();
   const [dvModal, setDvModal] = useState(false);
   const [dvOrder, setDvOrder] = useState(OrderModel);
   const [metaModal, setMetaModal] = useState(false);
   const [metaOrder, setMetaOrder] = useState(MetaOrderModel);
   const [inputBrief, setInputBrief] = useState(MetaInputBriefModel);

   var REACT_APP_API_URL = appValue.apiUrl;

   const orderAPI = {
      urls: [
         {
            name: "orders",
            url: REACT_APP_API_URL + "/order/list"
         },
         {
            name: "campaigns",
            url: REACT_APP_API_URL + "/campaign/list"
         },
         {
            name: "clients",
            url: REACT_APP_API_URL + "/client/list"
         },
         {
            name: "input-briefs",
            url: REACT_APP_API_URL + "/input-brief/list",
         }
      ],
      method: "GET",

   }
   const { results, errors, complete } = FetchFromAPI(orderAPI)

   const { NODE_ENV } = process.env;

   useEffect(() => {
      if (complete) {
         if (results["campaigns"] && results["campaigns"].data) {
            for (let i = 0; i < results["campaigns"].data.length; i++) {
               const c = results["campaigns"].data[i];
               const camp_id = `${c.platform_id}`;
               const camp_label = `${c.name} (${camp_id})`;
               const ad_id = `${c.client_id}`;

               if (c.platform === 'DV') {
                  OrderForm.fields[0][0].options.push({ name: 'campaign_id', label: camp_label, value: camp_id, advertiser_id: ad_id })
               }
               if (c.platform === 'Meta') {
                  // MetaOrderForm.fields[0][6].options.push({ label: camp_label, value: c.platform_id.toString(), name: "campaign_id" })
               }
            }
         }
         if (results["clients"] && results["clients"].data) {
            for (let i = 0; i < results["clients"].data.length; i++) {
               const c = results["clients"].data[i];
               if (c.platform === "Meta") {
                  if (c.ad_labels && c.ad_labels.length > 0) {
                     for (let i = 0; i < c.ad_labels.length; i++) {
                        const a = c.ad_labels[i]
                        const ad_label = `${a.name} | ${a.id}`
                        // MetaOrderForm.fields[0][2].options.push({ label: ad_label, value: a.id.toString(), name: "adlabels" })
                     }
                  }
               }
            }
         }
         if (results["orders"] && results["orders"].data) {
            for (let i = 0; i < results["orders"].data.length; i++) {
               const o = results["orders"].data[i];
               if (o.platform === "Meta") {
                  const o_label = `${o.name} | (${o.platform_id})`;
                  // MetaOrderForm.fields[0][24].options.push({ label: o_label, value: o.platform_id.toString(), name: "source_adset_id" })
               }
            }
         }
      }

      // if (results["orders"].automizer && results["orders"].automizer && results["orders"].automizer.budgetAllocationType) {
      //    order.budget_automation_type = 'INSERTION_ORDER_AUTOMATION_TYPE_NONE'
      // }
   }, [complete]);

   useEffect(() => {
      if (dvOrder.pacing.period.includes('FLIGHT')) {
         // pacing_info[1].options.push({ name: 'pacing_type', label: 'Accelerated Value', value: 'PACING_TYPE_AHEAD' })
      }
   }, [dvOrder.pacing.period]);


   if (NODE_ENV === "development") {
      console.log(results);
   }

   const options = [
      { name: "DV360", setModal: setDvModal },
      { name: "Meta", setModal: setMetaModal },
   ]

   // push all input briefs in the "ready" stage to the dropdown opts 
   const ibOpts = [];
   if (results && results["input-briefs"] && results["input-briefs"].data) {
      for (let i = 0; i < results["input-briefs"].data.length; i++) {
         const b = results["input-briefs"].data[i];
         if (b.stage === 'ready') {
            ibOpts.push({ value: b._id, inputBrief: b, label: b.name, name: b.name })
         }
      }
   }

   // once an input brief has been selected, autofill the relevant order fields with data from the brief
   useLayoutEffect(() => {
      const ib = inputBrief;
      if (ib.name !== '') {
         metaOrder.name = ib.input_brief.order.final_order_name;
         metaOrder.start_time = ib.input_brief.order.start_time;
         metaOrder.end_time = ib.input_brief.order.end_time;
         metaOrder.targeting.geo_locations.countries = ib.input_brief.order.targeting_countries;
         metaOrder.targeting.publisher_platforms = ib.input_brief.order.publisher_platforms;
         metaOrder.targeting.facebook_positions = ib.input_brief.order.facebook_positions;
         metaOrder.targeting.instagram_positions = ib.input_brief.order.instagram_positions;
         metaOrder.targeting.messenger_positions = ib.input_brief.order.messenger_positions;
         metaOrder.targeting.excluded_custom_audiences = ib.input_brief.order.excluded_custom_audiences;
      }
   }, [inputBrief])

   if (complete) {
      // let dataf = DateFormat(results["orders"])

      return errors.length > 0 ? (<><span>Error: {errors}</span></>) : (
         <>
            <div>
               <Row>
                  <Col sm="12">
                     <Card>
                        <Card.Header className="d-flex justify-content-between">
                           <div className="header-title">
                              <h4 className="card-title">Order List</h4>
                           </div>
                           <div>
                              <ModalButton name="New Order" setModal={setDvModal} options={options} />
                           </div>
                        </Card.Header>
                        <Card.Body className="px-0">
                           <div className="table-responsive">
                              <ReactTable item={results["orders"]} col={columnsOrder} page_size={20} row_size={20} />
                           </div>
                        </Card.Body>
                     </Card>
                  </Col>
               </Row>
            </div>
            <div>
               <OrderModal order={dvOrder} setOrder={setDvOrder} form={OrderForm} formId="orderForm" modal={dvModal} setModal={setDvModal} baseUrl={REACT_APP_API_URL} />
               <OrderMetaModal order={metaOrder} setOrder={setMetaOrder} form={MetaOrderForm} formId="orderForm" modal={metaModal} setModal={setMetaModal} baseUrl={REACT_APP_API_URL} inputBriefOpts={ibOpts} setInputBrief={(e) => setInputBrief(e.inputBrief)} />

            </div>
         </>
      )
   }
   return <><div><span><Loader></Loader></span></div></>;

}
