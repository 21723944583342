import { useJwt } from "react-jwt";

import Cookies from 'universal-cookie';
// import Dashboard from '../views/dashboard/index';

function AuthCheck() {
    // console.log("Auth Check Occurring");
    const cookies = new Cookies();
    var token = cookies.get('aplus-token');    

    const { isExpired } = useJwt(token);
    var loginState;
    if (isExpired) {
        console.error("Authentication Expired Signing Out");
        document.cookie = "aplus-token=;max-age=0";
        document.cookie = "aplus-user=;max-age=0";
        loginState = false;
    }else if (token) {
        // console.log("Token Valid");
        loginState = true;
    }else {
        console.error("Unknown Authentication Error");       
        document.cookie = "aplus-token=;max-age=0";
        document.cookie = "aplus-user=;max-age=0";

        loginState = false;
    }
    
    return(
        loginState
    )

}


export default AuthCheck


