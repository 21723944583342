const client_info = [
    {
        label: "Name",
        input: 'input-text',
        name: 'name',
        size: '4',
        required: true,
        feedback: 'Client name is required'
    },
    {
        label: "Status",
        input: 'dropdown',
        name: 'status',
        options: [
            {
                label: 'Active',
                value: true
            },
            {
                label: 'Paused',
                value: false
            }
        ],
        size: '2',
        required: true,
        feedback: 'Client status is required'
    },
    {
        label: "Currency",
        input: 'dropdown',
        name: 'currency',
        options: [
            { value: "ARS", label: "Argentine Peso" },
            { value: "AUD", label: "Australian Dollar" },
            { value: "BRL", label: "Brazilian Real" },
            { value: "CAD", label: "Canadian Dollar" },
            { value: "CHF", label: "Swiss Franc" },
            { value: "CLP", label: "Chilean Peso" },
            { value: "CNY", label: "Chinese Yuan" },
            { value: "COP", label: "Colombian Peso" },
            { value: "CZK", label: "Czech Koruna" },
            { value: "DKK", label: "Danish Krone" },
            { value: "EGP", label: "Egyption Pound" },
            { value: "EUR", label: "Euro" },
            { value: "GBP", label: "British Pound" },
            { value: "HKD", label: "Hong Kong Dollar" },
            { value: "HUF", label: "Hungarian Forint" },
            { value: "IDR", label: "Indonesian Rupiah" },
            { value: "ILS", label: "Israeli Shekel" },
            { value: "INR", label: "Indian Rupee" },
            { value: "JPY", label: "Japanese Yen" },
            { value: "KRW", label: "South Korean Won" },
            { value: "MXN", label: "Mexican Pesos" },
            { value: "MYR", label: "Malaysian Ringgit" },
            { value: "NGN", label: "Nigerian Naira" },
            { value: "NOK", label: "Norwegian Krone" },
            { value: "NZD", label: "New Zealand Dollar" },
            { value: "PEN", label: "Peruvian Nuevo Sol" },
            { value: "PLN", label: "Polish Zloty" },
            { value: "RON", label: "New Romanian Leu" },
            { value: "RUB", label: "Russian Ruble" },
            { value: "SEK", label: "Swedish Krona" },
            { value: "TRY", label: "Turkish Lira" },
            { value: "TWD", label: "New Taiwan Dollar" },
            { value: "USD", label: "US Dollar" },
            { value: "ZAR", label: "South African Rand" },
        ],
        size: '3',
        required: true,
        feedback: 'Currency is required'
    },   
]

const ClientSearchForm = {
    titles: ["Client Info"],
    fields: [
        client_info
    ]
}

export { client_info, ClientSearchForm }