import { React, useState, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import Card from '../../../components/Card'
import Loader from '../../../components/Loader'
import { FetchFromAPI } from '../../../components/ajx'
import { columnsInputBrief } from '../../../components/gridColumns'
import ReactTable from '../../../components/table'

import { MetaInputBriefForm } from '../../../util/meta-qa-input'
import { InputBriefMetaModal } from '../../../components/Modals'
import { ModalButton } from '../../../components/ModalSettings'
import { MetaInputBriefModel } from '../../../components/Models'
import { useAppContext } from '../../../context/appContext'

export default function InputBriefList() {
    const appValue = useAppContext();
    const [metaModal, setMetaModal] = useState(false);
    const [metaInputBrief, setMetaInputBrief] = useState(MetaInputBriefModel);

    const { NODE_ENV } = process.env;

    var REACT_APP_API_URL = appValue.apiUrl;

    const inputBriefApi = {
        urls: [
            {
                name: "input-briefs",
                url: REACT_APP_API_URL + "/input-brief/list",
            },
            {
                name: "clients",
                url: REACT_APP_API_URL + "/client/list"
            },
        ],
        method: "GET",

    }
    const options = [
        { name: "Meta", setModal: setMetaModal }
    ]
    const { results, errors, complete } = FetchFromAPI(inputBriefApi)

    useEffect(() => {
        if (complete) {
            if (NODE_ENV === "development") {
                console.log(results)
            }

            if (results["clients"] && results["clients"].data) {
                for (let i = 0; i < results["clients"].data.length; i++) {
                    const c = results["clients"].data[i];
                    if (c.platform === 'Meta') {
                        const label = `${c.name} | ${c.platform_id}`
                        MetaInputBriefForm.fields[0][2].options.push({ label: label, value: c.name, name: 'client' })
                    }
                }
            }
        }
    }, [complete])

    console.log('input brief', metaInputBrief)

    if (complete) {
        return errors.length > 0 ? (<><span>Error: {errors}</span></>) : (
            <>
                <div>
                    <Row>
                        <Col sm="12">
                            <Card>
                                <Card.Header className="d-flex justify-content-between">
                                    <div className="header-title">
                                        <h4 className="card-title">Input Brief List</h4>
                                    </div>
                                    <div>
                                        <ModalButton name="New Input Brief" setModal={setMetaModal} options={options} />
                                    </div>
                                </Card.Header>
                                <Card.Body className="px-0">
                                    <div className="table-responsive">
                                        {results["input-briefs"] &&
                                            <ReactTable item={results["input-briefs"]} col={columnsInputBrief} page_size={20} row_size={20} />
                                        }
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <div>
                    <InputBriefMetaModal inputBrief={metaInputBrief} setInputBrief={setMetaInputBrief} formId='inputBriefForm' form={MetaInputBriefForm} modal={metaModal} setModal={setMetaModal} baseUrl={REACT_APP_API_URL} />
                </div>
            </>
        )
    }
    return <><div><span><Loader></Loader></span></div></>;

}
