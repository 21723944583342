import { Status, BidStrategy, BillingEvent, DestinationType, OptimizationGoal, OptimizationSubEvent, MultiOptimizationGoalWeight, DevicePlatform, Gender, FacebookPosition, InstagramPosition, MessengerPosition, PublisherPlatform, CountryOptsOrder, CustomEventType } from './meta-options'

const general_info = [
    {
        label: 'Campaign ID',
        name: 'campaign_id',
        input: 'dropdown',
        options: [
            { label: '', value: '' }
        ],
        size: '4',
        required: true
    },
    {
        label: "Name",
        input: 'input-text',
        name: 'name',
        size: '4',
        required: true
    },
    {
        label: "Status",
        name: 'status',
        input: 'dropdown',
        options: Status,
        size: '4',
        required: true
    },

    {
        label: 'Destination Type',
        name: 'destination_type',
        input: 'dropdown',
        options: DestinationType,
        size: '4'
    },

    {
        label: 'Is Dynamic Creative',
        name: 'is_dynamic_creative',
        input: 'dropdown',
        options: [
            { label: '', value: '' },
            { label: 'True', value: true, name: 'is_dynamic_creative' },
            { label: 'False', value: false, name: 'is_dynamic_creative' }
        ],
        size: '4'
    },
    
    {
        label: 'Source Adset ID',
        name: 'source_adset_id',
        input: 'dropdown',
        size: '4',
        options: []
    }
];
const bid_strategy = [
    {
        label: 'Start Time',
        name: 'start_time',
        input: 'date',
        size: '6'
    },
    {
        label: 'End Time',
        name: 'end_time',
        input: 'date',
        size: '6'
    },
    {
        label: 'Bid Strategy',
        name: 'bid_strategy',
        input: 'dropdown',
        options: BidStrategy,
        size: '6'
    },
    {
        label: 'Bid Amount',
        name: 'bid_amount',
        input: 'input-number',
        size: '6',
        icon:"$",
    },
    {
        label: 'Billing Event',
        name: 'billing_event',
        input: 'dropdown',
        options: BillingEvent,
        size: '4',
    },
]
const budget = [
    {
        label: "Budget Type",
        input: "dropdown",
        options: [
            { label: "Lifetime", value: "lifetime" },
            { label: "Daily", value: "daily" },
        ],
        name: "budget_type",
        size: "4",
    },   
    {
        label: 'Daily Budget',
        name: 'daily_budget',
        input: 'input-number',
        size: '4',
        dependent: 'budget_type',
        condition: 'daily',
        icon:"$"
    },
    {
        label: 'Daily Spend Cap',
        name: 'daily_spend_cap',
        input: 'input-number',
        size: '4',
        dependent: 'budget_type',
        condition: 'daily',
        icon:"$"

    },
    {
        label: 'Daily Min Spend Target',
        name: 'daily_min_spend_target',
        input: 'input-number',
        dependent: 'budget_type',
        condition: 'daily',
        size: '4',
        icon:"$"

    },
    {
        label: 'Lifetime Budget',
        name: 'lifetime_budget',
        input: 'input-number',
        size: '6',
        dependent: 'budget_type',
        condition: 'lifetime',
        icon:"$"
    },
    {
        label: 'Lifetime Impressions',
        name: 'lifetime_imps',
        input: 'input-number',
        dependent: 'budget_type',
        condition: 'lifetime',
        size: '6'
    },
    {
        label: 'Lifetime Min Spend Target',
        name: 'lifetime_min_spend_target',
        input: 'input-number',
        dependent: 'budget_type',
        condition: 'lifetime',
        size: '6',
        icon:"$"
    },
    {
        label: 'Lifetime Spend Cap',
        name: 'lifetime_spend_cap',
        input: 'input-number',
        dependent: 'budget_type',
        condition: 'lifetime',
        size: '6',
        icon: "$"
    },
]
const optimization = [ 
    {
        label: 'Optimization Goal',
        name: 'optimization_goal',
        input: 'dropdown',
        options: OptimizationGoal,
        size: '6'
    },
    {
        label: 'Optimization Sub Event',
        name: 'optimization_sub_event',
        input: 'dropdown',
        options: OptimizationSubEvent,
        size: '6'
    },
    {
        label: 'Multi Optimization Goal Weight',
        name: 'multi_optimization_goal_weight',
        input: 'dropdown',
        options: MultiOptimizationGoalWeight,
        size: '4'
    },
]
const targeting = [
    {
        label: 'Countries',
        input: 'dropdown',
        name: 'targeting.geo_locations.countries',
        options: CountryOptsOrder,
        size: '6',
        multi: true,
        required: true
    },
    {
        label: 'Device Platforms',
        input: 'dropdown',
        name: 'targeting.device_platforms',
        options: DevicePlatform,
        size: '6',
        multi: true
    },
    {
        label: 'Gender',
        input: 'dropdown',
        name: 'targeting.gender',
        options: Gender,
        size: '6',
        multi: true
    },
    {
        label: 'Facebook Positions',
        input: 'dropdown',
        name: 'targeting.facebook_positions',
        options: FacebookPosition,
        size: '6',
        multi: true
    },
    {
        label: 'Instagram Positions',
        input: 'dropdown',
        name: 'targeting.instagram_positions',
        options: InstagramPosition,
        size: '6',
        multi: true
    },
    {
        label: 'Messenger Positions',
        input: 'dropdown',
        name: 'targeting.messenger_positions',
        options: MessengerPosition,
        size: '6',
        multi: true
    },
    {
        label: 'Publisher Platforms',
        input: 'dropdown',
        name: 'targeting.publisher_platforms',
        options: PublisherPlatform,
        size: '6',
        multi: true
    },
    {
        label: 'Excluded Custom Audiences',
        input: 'dropdown',
        name: 'targeting.excluded_custom_audiences',
        options: [],
        size: '6',
        multi: true
    }
]

const promoted_object = [
    {
        label: 'Page ID',
        input: 'input-text',
        name: 'promoted_object.page_id',
        size: 6
    },
    {
        label: 'Pixel ID',
        input: 'input-text',
        name: 'promoted_object.pixel_id',
        size: 6
    },
    {
        label: 'Custom Event Type',
        input: 'dropdown',
        name: 'promoted_object.custom_event_type',
        size: 6,
        options: CustomEventType
    }
]

const MetaOrderForm = {
    titles: ["General","Bid Strategy","Budgeting","Optimization", "Targeting", "Promoted Object"],
    fields: [
        general_info,
        bid_strategy,
        budget,
        optimization,
        targeting,
        promoted_object
    ]
}

export { MetaOrderForm }
