import LoadingIcons from 'react-loading-icons'

const Loader = () => {
    
    return (
        <div id="loading" >
            <div id="loading-center">
            <LoadingIcons.Grid fill="#000"/>

              </div>
        </div>
    )
}

export default Loader
