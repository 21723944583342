import { React, useEffect, useState } from 'react'
import { Row, Col, Tab, Nav } from 'react-bootstrap'
import Card from '../../../components/Card'
import Loader from '../../../components/Loader'
import { CountEntities } from '../../../components/common'
import { useParams } from 'react-router-dom'
import { FetchDashFromAPI, FetchFromAPI } from '../../../components/ajx'
import ReactTable from '../../../components/table'

import { columnsGroup } from '../../../components/gridColumns'
// AOS
import AOS from 'aos'
import '../../../../node_modules/aos/dist/aos'
import '../../../../node_modules/aos/dist/aos.css'
// Import Swiper styles

import Dash from '../../../components/dash';
import { DateButtons } from '../../../components/dateButtons';
import Cookies from "universal-cookie";
import { TradeDeskCampaignModel } from '../../../components/Models'
import Crumbs from '../../../components/crumbs'
import InputSettings from '../../../components/InputSettings'
import { CampaignTradeDeskForm } from '../../../util/trade-desk-campaign-input'
import { useAppContext } from '../../../context/appContext'



const TradeDeskCampaign = (props) => {
    const appValue = useAppContext();
    const [campaign, setCampaign] = useState(TradeDeskCampaignModel);
    const [initialLoad, setInitialLoad] = useState(true);
    const { cid } = useParams()
    const cookies = new Cookies();
    const user = cookies.get('aplus-user');
    var REACT_APP_API_URL = appValue.apiUrl;
    
    const [date, setDate] = useState(1)
    const [dashAPI, setDashAPI] = useState({
        urls: [
            {
                name: "campaign",
                url: REACT_APP_API_URL + "/campaign/" + cid + "/" + "TD"
            },           
            {
                name: "groups",
                url: REACT_APP_API_URL + "/group/list/campaign/" + cid
            },
            {
                name: "dash",
                url: REACT_APP_API_URL + "/reports/dash/campaign/" + user.aid + "/" + cid + "/" + "TD" + "?date=" + date
            },
            {
                name: "account",
                url: REACT_APP_API_URL + "/admin/get/account/" + user.apid
            },
            {
                name: "crumbs",
                url: REACT_APP_API_URL + "/util/breadcrumb/campaigns/TD/" + cid
            },

        ],
        method: "GET",
    })
    useEffect(() => {

        AOS.init({
            startEvent: 'DOMContentLoaded',
            disable: function () {
                var maxWidth = 996;
                return window.innerWidth < maxWidth;
            },
            throttleDelay: 10,
            once: true,
            duration: 700,
            offset: 10
        });

    })




    const { NODE_ENV } = process.env;

    useEffect(() => {
        complete = false
        setDashAPI({
            urls: [
                {
                    name: "campaign",
                    url: REACT_APP_API_URL + "/campaign/" + cid + "/" + "TD"
                },              
                {
                    name: "groups",
                    url: REACT_APP_API_URL + "/group/list/campaign/" + cid
                },
                {
                    name: "dash",
                    url: REACT_APP_API_URL + "/reports/dash/campaign/" + user.aid + "/" + cid + "/" + "TD" + "?date=" + date
                },
                {
                    name: "account",
                    url: REACT_APP_API_URL + "/admin/get/account/" + user.apid
                },
                {
                    name: "crumbs",
                    url: REACT_APP_API_URL + "/util/breadcrumb/campaigns/TD/" + cid
                },

            ],
            method: "GET",
        })
    }, [date])
    var { results, errors, complete } = FetchDashFromAPI(dashAPI)

    if (complete) {
        if (NODE_ENV === 'development') {
            console.log(results)
        }
        if (initialLoad) {
            const data = results["campaign"].data;
            const c = data.trade_desk_campaign;
            campaign.id = data.trade_desk_campaign_id;
            campaign.account_id = data.trade_desk_advertiser_id;
            campaign.name = data.name;
            campaign.status = data.status;
            campaign.objective = data.objective;
            campaign.campaignType = c.campaignType;
            campaign.pacingMode = c.pacingMode;
            campaign.customROASType = c.customROASType;
            campaign.budget = c.budget;
            campaign.dailyBudget = c.dailyBudget;
            campaign.frequencySettings = c.frequencySettings;
            campaign.impressionsOnlyBudgetingCpm = c.impressionsOnlyBudgetingCpm;
            campaign.primaryGoal = c.primaryGoal;
            campaign.secondaryGoal = c.secondaryGoal;
            campaign.tertiaryGoal = c.tertiaryGoal;
            campaign.campaignFlights = c.campaignFlights;
            campaign.partnerCPCFee = c.partnerCPCFee;
            campaign.partnerCPMFee = c.partnerCPMFee;
            campaign.partnerCostPercentageFee = c.partnerCostPercentageFee;

            if (c.startDate) {
                let startDate = c.startDate.split('T');
                campaign.startDate = startDate[0];
            }

            if (c.endDate) {
                let endDate = c.endDate.split('T');
                campaign.endDate = endDate[0];
            }
        }

        let activeGroups = CountEntities(results["groups"].data)

        return errors.length > 0 ? (<><span>Error: {errors}</span></>) : (
            <>
                <Tab.Container defaultActiveKey="first">
                    <Row>
                        <Col lg="12">
                            <Card>
                                <Card.Body>
                                    <Crumbs data={results["crumbs"].data} platform="TTD" type="campaign" />

                                    <div className="d-flex flex-wrap align-items-center justify-content-between">
                                        <div className="d-flex flex-wrap align-items-center">
                                            <div className="d-flex flex-wrap align-items-center mb-3 mb-sm-0">
                                                <h4 className="me-2 h4">{results["campaign"].data.name}</h4>
                                                <span> - Campaign</span>
                                            </div>
                                        </div>
                                        <Nav as="ul" className="d-flex nav-pills mb-0 text-center profile-tab" data-toggle="slider-tab" id="profile-pills-tab" role="tablist">
                                            <Nav.Item as="li">
                                                <Nav.Link eventKey="first" className="button">Dash</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item as="li">
                                                <Nav.Link eventKey="second" className="button">Settings</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item as="li">
                                                <Nav.Link eventKey="third" className="button">Groups</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg="6">
                            <Card>
                                <Card.Header>
                                    <div className="header-title">
                                        <h4 className="card-title">Snapshot</h4>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <ul className="list-inline m-0 p-0">
                                        <li className="d-flex">
                                            <div className="new-icon me-3">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-collection-fill" viewBox="0 0 16 16"><path d="M0 13a1.5 1.5 0 0 0 1.5 1.5h13A1.5 1.5 0 0 0 16 13V6a1.5 1.5 0 0 0-1.5-1.5h-13A1.5 1.5 0 0 0 0 6v7zM2 3a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 0-1h-11A.5.5 0 0 0 2 3zm2-2a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 0-1h-7A.5.5 0 0 0 4 1z"></path></svg>
                                            </div>
                                            <p className="news-detail mb-0">{activeGroups} - Active Groups</p>

                                        </li>
                                    </ul>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg="6">
                            <Card>
                                <Card.Header>
                                    <div className="header-title">
                                        <h4 className="card-title">Campaign Info</h4>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <div className="mb-1">Campaign Id: {results["campaign"].data.trade_desk_campaign_id}</div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg="12">
                            <Tab.Content className="profile-content">
                                <Tab.Pane eventKey="third" id="group">
                                    <Card>
                                        <Card.Header className="d-flex justify-content-between">
                                            <div className="header-title">
                                                <h4 className="card-title">Group List</h4>
                                            </div>
                                        </Card.Header>
                                        <Card.Body className="px-0">
                                            <div className="table-responsive" style={{ width: `100%` }}>
                                                <ReactTable item={results["groups"]} col={columnsGroup} page_size={20} row_size={20} />
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Tab.Pane>
                                <Tab.Pane eventKey="first" id="dash">
                                    <Row className='dateButtons_sub'>
                                        <Col lg={{ span: 6, offset: 6 }}>
                                            <DateButtons setDate={setDate} date={date} />
                                        </Col>
                                    </Row>
                                    {(Object.keys(results["dash"].data["ttd"].kpi).length === 0) ? (
                                        <span>No Data Available </span>
                                    ) : (
                                        <Dash dash={results["dash"].data["ttd"]} account={results["account"]} />
                                    )}
                                </Tab.Pane >
                                <Tab.Pane eventKey="second" id="settings">
                                    <Row>
                                        <Col lg="12">
                                            <Row>
                                                <InputSettings
                                                    title="Edit Campaign"
                                                    mode="edit"
                                                    form={CampaignTradeDeskForm}
                                                    formId={"campaignForm"}
                                                    model={[campaign, setCampaign]}
                                                    disabled={true}

                                                />
                                            </Row>
                                        </Col>
                                    </Row>
                                </Tab.Pane >
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </>
        );
    }
    return <><div><span><Loader></Loader></span></div></>;
}
export default (TradeDeskCampaign)