import { React, useEffect, useState } from 'react'
import { Row, Col, Tab, Nav } from 'react-bootstrap'
import Card from '../../../components/Card'
import Loader from '../../../components/Loader'
import { useParams } from 'react-router-dom'
import { FetchDashFromAPI, FetchFromAPI } from '../../../components/ajx'
import Dash from '../../../components/dash';

// AOS
import AOS from 'aos'
import '../../../../node_modules/aos/dist/aos'
import '../../../../node_modules/aos/dist/aos.css'
import Cookies from 'universal-cookie';
import Automizer from '../../../components/automizer'
import {DateButtons} from '../../../components/dateButtons'
import { TradeDeskGroupModel } from '../../../components/Models'
import { GroupTradeDeskForm } from '../../../util/trade-desk-group-input'
import Crumbs from '../../../components/crumbs'
import InputSettings from '../../../components/InputSettings'
import { useAppContext } from '../../../context/appContext'

const TradeDeskGroup = (props) => {
    const appValue = useAppContext();
    const [group, setGroup] = useState(TradeDeskGroupModel)
    const [initialLoad, setInitialLoad] = useState(true)

    useEffect(() => {

        AOS.init({
            startEvent: 'DOMContentLoaded',
            disable: function () {
                var maxWidth = 996;
                return window.innerWidth < maxWidth;
            },
            throttleDelay: 10,
            once: true,
            duration: 700,
            offset: 10
        });

    })
    const cleanUrl = appValue.cleanUrl;

    const { gid } = useParams()

    const cookies = new Cookies();
    const user = cookies.get('aplus-user');

    var REACT_APP_API_URL = appValue.apiUrl;
    const [date, setDate] = useState(1)
    const [dashAPI, setDashAPI] = useState({
        urls: [
            {
                name: "group",
                url: REACT_APP_API_URL + "/group/" + gid + "/TD"
            },
            {
                name: "creatives",
                url: REACT_APP_API_URL + "/creative/list"
            },
            {
                name: "dash",
                url: REACT_APP_API_URL + "/reports/dash/group/" + user.apid + "/" + gid + "/" + "TD" + "?date=" + date
            },
            {
                name: "account",
                url: REACT_APP_API_URL + "/admin/get/account/" + user.apid
            },
            {
                name: "crumbs",
                url: REACT_APP_API_URL + "/util/breadcrumb/groups/TD/" + gid
            },
        ],
        method: "GET",

    })    
    useEffect(() => {
        complete = false
        setDashAPI({
            urls: [
                {
                    name: "group",
                    url: REACT_APP_API_URL + "/group/" + gid + "/TD"
                },               
                {
                    name: "creatives",
                    url: REACT_APP_API_URL + "/creative/list"
                },
                {
                    name: "dash",
                    url: REACT_APP_API_URL + "/reports/dash/group/" + user.apid + "/" + gid + "/" + "TD" + "?date=" + date
                },
                {
                    name: "account",
                    url: REACT_APP_API_URL + "/admin/get/account/" + user.apid
                },
                {
                    name: "crumbs",
                    url: REACT_APP_API_URL + "/util/breadcrumb/groups/TD/" + gid
                },
            ],
            method: "GET",
    
        })
    }, [date])
    const { NODE_ENV } = process.env;

    var { results, errors, complete } = FetchDashFromAPI(dashAPI)


    if (complete) {
        if (initialLoad) {
            const data = results['group'].data;
            const g = data.trade_desk_ad_group;
            group.adGroupName = data.name;
            group.adGroupCategory = g.adGroupCategory;
            group.campaignId = g.campaignId;
            group.description = g.description
            group.funnelLocation = g.funnelLocation;
            group.channelId = g.channelId;
            group.industryCategoryMapping = g.industryCategoryMapping;
            group.areFutureKoaFeaturesEnabled = g.areFutureKoaFeaturesEnabled;
            group.includeDefaultsFromCampaign = g.includeDefaultsFromCampaign;
            group.isBuildInProgress = g.isBuildInProgress;
            group.isEnabled = g.isEnabled;
            group.isHighFillRate = g.isHighFillRate;
            group.predictingClearingEnabled = g.predictingClearingEnabled;
            group.adbrainHouseholdCrossDeviceEnabled = g.adbrainHouseholdCrossDeviceEnabled;
            group.useIdentityAlliance = g.useIdentityAlliance;

            if (g.createdAtUTC) {
                let createdAt = g.createdAtUTC.split('T');
                group.createdAtUTC = createdAt[0];
            }

            if (g.lastupdatedAtUTC) {
                let updatedAt = g.lastupdatedAtUTC.split('T');
                group.lastupdatedAtUTC = updatedAt[0];
            }

        }

        console.log('group', group);

        if (NODE_ENV === "development") {
            console.log(results)
        }

        return errors.length > 0 ? (<><span>Error: {errors}</span></>) : (
            <><Tab.Container defaultActiveKey="first">
                <Row>
                    <Col lg="12">
                        <Card>
                            <Card.Body>

                                <Crumbs data={results["crumbs"].data} platform="TTD" type="group" />

                                <div className="d-flex flex-wrap align-items-center justify-content-between">
                                    <div className="d-flex flex-wrap align-items-center">
                                        <div className="d-flex flex-wrap align-items-center mb-3 mb-sm-0">
                                            <h4 className="me-2 h4">{results["group"].data.name}</h4>
                                            <span> - Group</span>
                                        </div>
                                    </div>
                                    <Nav as="ul" className="d-flex nav-pills mb-0 text-center profile-tab" data-toggle="slider-tab" id="profile-pills-tab" role="tablist">
                                        <Nav.Item as="li">
                                            <Nav.Link eventKey="first" className="button">Dash</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                            <Nav.Link eventKey="second" className="button">Settings</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                            <Nav.Link eventKey="third" className="button">Automizer</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg="6">
                        <Card>
                            <Card.Header>
                                <div className="header-title">
                                    <h4 className="card-title">Group Info</h4>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <div className="mb-1">ID: {results["group"].data.trade_desk_ad_group_id}</div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg="12">
                        <Tab.Content className="profile-content">
                            <Tab.Pane eventKey="first" id="dash">
                                <Row className='dateButtons_sub'>
                                    <Col lg={{ span: 6, offset: 6 }}>
                                        <DateButtons setDate={setDate} date={date} />
                                    </Col>
                                </Row>
                                {(Object.keys(results["dash"].data["ttd"].kpi).length === 0) ? (
                                    <span>No Data Available </span>
                                ) : (
                                    <Dash dash={results["dash"].data["ttd"]} account={results["account"]} />
                                )}
                            </Tab.Pane >
                            <Tab.Pane eventKey="third" id="automizer">
                                <Automizer data={results["group"]} cur={results["account"].data.Currency} label="line" />
                            </Tab.Pane>
                            <Tab.Pane eventKey="second" id="settings">
                                <Row>
                                    <Col lg="12">
                                        <InputSettings
                                            title="Edit Group"
                                            mode="edit"
                                            form={GroupTradeDeskForm}
                                            formId={"groupForm"}
                                            model={[group, setGroup]}

                                        />
                                    </Col>
                                </Row>
                            </Tab.Pane >
                        </Tab.Content>
                    </Col>

                </Row>
            </Tab.Container>
            </>
        );
    }
    return <><div><span><Loader></Loader></span></div></>;
}
export default (TradeDeskGroup)