import { useState, useEffect } from 'react'
import axios from 'axios'
import { useAppContext } from '../context/appContext';


const FetchFromAPI = (data) => {
  const appValue = useAppContext();
  const [results, setResults] = useState([]);
  const [errors, setErrors] = useState([]);
  const [complete, setComplete] = useState(false);

  useEffect(() => {
    setComplete(false)
    var errArr = []
    let resArr = []

    let apiCall = new Promise(function (resolve, reject) {
      // "Producing Code" (May take some time)
      var i = 0;
      data.urls.map(urlItem => {
        switch (data.method) {
          case "GET":
            axios
              .get(urlItem.url, appValue.config)
              .then((response) => {
                resArr[urlItem.name] = response.data
              })
              .catch((error) => {
                errArr[urlItem.name] = error.response
                // setError(error.response)
              })
              .finally(() => {
                i++;
                if (i === data.urls.length) {
                  resolve(); // when successful
                }
                // setLoaded(true)
              });
            break;
          default:
        }
      })
    });

    apiCall.finally(() => {
      setResults(resArr)
      setComplete(true)
    })

  }, []);

  return { results, errors, complete }

}

const FetchDashFromAPI = (data) => {
  const appValue = useAppContext();
  const [results, setResults] = useState([]);
  const [errors, setErrors] = useState([]);
  const [complete, setComplete] = useState(false);

  useEffect(() => {
    setComplete(false)
    var errArr = []
    let resArr = []

    let apiCall = new Promise(function (resolve, reject) {
      // "Producing Code" (May take some time)
      var i = 0;
      data.urls.map(urlItem => {
        switch (data.method) {
          case "GET":
            axios
              .get(urlItem.url, appValue.config)
              .then((response) => {
                resArr[urlItem.name] = response.data
              })
              .catch((error) => {
                errArr[urlItem.name] = error.response
                // setError(error.response)
              })
              .finally(() => {
                i++;
                if (i === data.urls.length) {
                  resolve(); // when successful
                }
                // setLoaded(true)
              });
            break;
          default:
        }
      })
    });

    apiCall.finally(() => {
      setResults(resArr)
      setComplete(true)
    })

  }, [data]);

  return { results, errors, complete }

}


const RetrieveTargetingList = (payload) => {
  const appValue = useAppContext();
  var REACT_APP_API_URL = appValue.apiUrl;
  var url = REACT_APP_API_URL + "/lib/targeting-list"

  const [targetingList, setTargetingList] = useState('');
  useEffect(() => {
    axios
      .post(url, payload, appValue.config)
      .then((response) => setTargetingList(response.data))
      .catch((error) => {
        console.log(error);
      })
  }, [])

  return { targetingList }

}
const RetrievePreview = (website,apiUrl,config) => {
  var url = apiUrl + "/util/preview/" + website
  const [data, setData] = useState('');
  axios
    .get(url, config)
    .then((response) => setData(response))
    .catch((error) => {
      console.log(error);
    })
    .finally( () => {return data})
    console.log("Break")
    return data
  // return {preview}

}
const PushUpdate = (url, payload) => {
  const appValue = useAppContext();
  const [data, setData] = useState([]);

  axios
    .put(url, payload, appValue.config)
    .then((response) => setData(response))
    .catch((error) => {
      console.log(error);
    })
  return { data }
}


export { PushUpdate, FetchFromAPI, RetrieveTargetingList, FetchDashFromAPI, RetrievePreview };