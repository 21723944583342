const demographics = [
    {
        label: 'Gender(s)',
        input: 'checkbox',
        name: 'gender',
        // dependent:"campaign_type",
        // condition:"google",    
        options: [
            { label: 'Male', value: "Male" },
            { label: 'Female', value: "Female" },
            { label: 'Unknown', value: "Unknown" }
        ],
        size: "6",
    },
    {
        label: 'Parental Status',
        input: 'checkbox',
        name: 'parental_status',
        // dependent:"campaign_type",
        // condition:"google",    
        options: [
            { label: 'Parent', value: "Parent" },
            { label: 'Not A Parent', value: "Not A Parent" },
            { label: 'Unknown', value: "Unknown" }
        ],
        size: "6",
    },
    {
        label: "Age Range",
        input: 'slider',
        name: 'age_range',
        range: [18, 65],
        options: [{ value: 18, label: "18-24", },
        { value: 25, label: "25-34", },
        { value: 35, label: "35-44", },
        { value: 45, label: "45-54", },
        { value: 55, label: "55-64", },
        { value: 65, label: "65+", },],
        size: "6",
    },  
]

const device = [
    {
        label: 'Environment',
        input: 'checkbox',
        name: 'environment',
        // dependent:"campaign_type",
        // condition:"google",    
        options: [
            { label: 'Web', value: "Web" },
            { label: 'Web (Not Optimized For Mobile)', value: "Web (Not Optimized For Mobile)" },
            { label: 'App', value: "App" },
        ],
        size: "6",
    },
    {
        label: "Device(s)",
        input: 'async-exclude',
        options: [],
        multi: true,
        required: false,
        feedback: "Status is a required field",
        size: "6",
        url: `/lib/targeting/TARGETING_TYPE_DEVICE_MAKE_MODEL/`,
        valueName: "deviceMake.displayName",
        valueId: "targetId",
        name: "devices.opts",
        nameCheck: "devices.exclude",
        negative: false,
    },
    {
        label: "Operating System(s)",
        input: 'dropdown-exclude',
        options: [],
        multi: true,
        required: false,
        negative: false,
        feedback: "Status is a required field",
        size: "6",
        name: "os.opts",
        nameCheck: "os.exclude"
    },
    {
        label: "Browser(s)",
        input: 'dropdown-exclude',
        options: [],
        multi: true,
        required: false,
        feedback: "Status is a required field",
        size: "6",
        negative: false,
        name: "browsers.opts",
        nameCheck: "browsers.exclude"
    },
]

const location = [
   {
        label: "Language(s)",
        input: 'dropdown-exclude',
        options: [],
        multi: true,
        required: false,
        feedback: "Status is a required field",
        size: "6",
        negative: false,
        name: "languages.opts",
        nameCheck: "languages.exclude"
    },
]

const content = [
    {
        label: "Categories",
        input: 'async-exclude',
        options: [],
        multi: true,
        required: false,
        feedback: "Status is a required field",
        size: "6",
        negative: false,
        url: `/lib/targeting/TARGETING_TYPE_CATEGORY/`,
        valueName: "category.displayName",
        valueId: "targetId",
        name: "categories.opts",
        nameCheck: "categories.exclude",
        size:"12"
    },
    {
        label: 'Digital Content Label Exclusion',
        input: 'checkbox',
        name: 'content_label',
        // dependent:"campaign_type",
        // condition:"google",    
        options: [
            { label: 'General Audiences', value: "General Audiences" },
            { label: 'Parental Guidance', value: "Parental Guidance" },
            { label: 'Teens', value: "Teens" },
            { label: 'Mature', value: "Mature" },
            { label: 'Unknown', value: "Unknown" },
        ],
        size: "6",
    },
    {
        label: 'Sensitive Category Exclusion',
        input: 'checkbox',
        name: 'sensitive_categories',
        // dependent:"campaign_type",
        // condition:"google",    
        options: [
            { label: 'Accidents', value: "Accidents" },
            { label: 'Adult', value: "Adult" },
            { label: 'Alcohol', value: "Alcohol" },
            { label: 'Derogatory', value: "Derogatory" },
            { label: 'Drugs', value: "Drugs" },
            { label: 'File Sharing Downloads', value: "File Sharing Downloads" },
            { label: 'Gambling', value: "Gambling" },
            { label: 'Politics', value: "Politics" },
            { label: 'Profanity', value: "Profanity" },
            { label: 'Religion', value: "Religion" },
            { label: 'Shocking', value: "Shocking" },
            { label: 'Social Issues', value: "Social Issues" },
            { label: 'Suggestive Content', value: "Suggestive Content" },
            { label: 'Tobacco', value: "Tobacco" },
            { label: 'Tragedy', value: "Tragedy" },
            { label: 'Weapons', value: "Weapons" },
            { label: 'Violence', value: "Violence" },
        ],
        size: "6",
    },
]
const TargetingForm = {
    titles: ["Demographics", "Device", "Location", "Content"],
    fields: [
        demographics,
        device,
        location,
        content        
    ]
}

const FindAndSet = (searchName,options) =>{
    TargetingForm.fields.forEach((obj,i) =>{
        obj.forEach((subObj,i)=>{
            if(subObj.name === searchName) {
                subObj.options = options
            }
        })
    })
}

export { demographics, device, location, content, TargetingForm,FindAndSet }