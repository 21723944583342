import React, { useEffect, useContext, useState } from 'react'
import { Row, Col, Image, Nav, Tab, Alert, Spinner } from 'react-bootstrap'
import Card from '../../../components/Card'

import { useParams } from 'react-router-dom'
import Loader from '../../../components/Loader'
import ReactTable from '../../../components/table'
import { FetchDashFromAPI, FetchFromAPI } from '../../../components/ajx'
import { CountEntities } from '../../../components/common'
import { columnsCampaign, columnsOrder, columnsGroup } from '../../../components/gridColumns'
import TargetContext from '../../../context/order-edit-context'

// img

import avatars11 from '../../../assets/images/avatars/01.png'
// AOS
import AOS from 'aos'
import '../../../../node_modules/aos/dist/aos'
import '../../../../node_modules/aos/dist/aos.css'
//apexcharts



// store
import Dash from '../../../components/dash';
import {DateButtons} from '../../../components/dateButtons';
import { ModalButton } from '../../../components/ModalSettings'
import { CampaignModal } from '../../../components/Modals'
import CampaignForm from '../../../util/campaign-input'
import { CampaignModel, ClientModel } from '../../../components/Models'
import InputSettings from '../../../components/InputSettings'
import { ClientForm } from '../../../util/client-input'
import { useAppContext } from '../../../context/appContext'



const Client = (props) => {
   const appValue = useAppContext();
   const [modal, setModal] = useState(false);
   const [client, setClient] = useState(ClientModel)
   const [campaign, setCampaign] = useState(CampaignModel)
   const [initialLoad, setInitialLoad] = useState(true)
   const [defaultValue, setDefaultValue] = useState()
   const [alert, setAlert] = useState({ variant: "", value: "" })


   useEffect(() => {

      AOS.init({
         startEvent: 'DOMContentLoaded',
         disable: function () {
            var maxWidth = 996;
            return window.innerWidth < maxWidth;
         },
         throttleDelay: 10,
         once: true,
         duration: 700,
         offset: 10
      });

   })

   const { clid } = useParams()
   var REACT_APP_API_URL = appValue.apiUrl;

   const { NODE_ENV } = process.env;
   const [date, setDate] = useState(1)
   const [dashAPI, setDashAPI] = useState({
      urls: [
         {
            name: "client",
            url: REACT_APP_API_URL + "/client/" + clid + "/DV"
         },
         {
            name: "campaigns",
            url: REACT_APP_API_URL + "/campaign/list/client/" + clid
         },
         {
            name: "orders",
            url: REACT_APP_API_URL + "/order/list/client/" + clid
         },
         {
            name: "groups",
            url: REACT_APP_API_URL + "/group/list/client/" + clid
         },
         {
            name: "dash",
            url: REACT_APP_API_URL + "/reports/dash/client/" + appValue.user.apid + "/" + clid + "/" + "DV" + "?date=" + date
         },
         {
            name: "account",
            url: REACT_APP_API_URL + "/admin/get/account/" + appValue.user.apid
         },
         {
            name: "crumbs",
            url: REACT_APP_API_URL + "/util/breadcrumb/clients/DV/" + clid
         }

      ],
      method: "GET",

   })
   useEffect(() => {
      complete = false
      setDashAPI({
         urls: [
            {
               name: "client",
               url: REACT_APP_API_URL + "/client/" + clid + "/DV"
            },
            {
               name: "campaigns",
               url: REACT_APP_API_URL + "/campaign/list/client/" + clid 
            },
            {
               name: "orders",
               url: REACT_APP_API_URL + "/order/list/client/" + clid
            },
            {
               name: "groups",
               url: REACT_APP_API_URL + "/group/list/client/" + clid
            },
            {
               name: "dash",
               url: REACT_APP_API_URL + "/reports/dash/client/" + appValue.user.apid + "/" + clid + "/" + "DV" + "?date=" + date
            },
            {
               name: "account",
               url: REACT_APP_API_URL + "/admin/get/account/" + appValue.user.apid
            },
            {
               name: "crumbs",
               url: REACT_APP_API_URL + "/util/breadcrumb/clients/DV/" + clid
            }
   
         ],
         method: "GET",
      })
  }, [date])

   var { results, errors, complete } = FetchDashFromAPI(dashAPI)


   let url = REACT_APP_API_URL + "/client/update/" + clid

   const rearrangeTargeting = (targeting) => {
      var newTargeting = []
      if (targeting && targeting.length >= 1) {

         targeting.map((t) => {
            newTargeting.push(t)
         })
      }

      return newTargeting
   }
   const checkForDefaultValues = (val, targets) => {
      // for (const t in targets) {
      //    var properties = targets[t]
      //    addTargetToOrder()
      //    // range through all property options to find the one that matches the defaultVal's targetingType
      //    for (let i = 0; i < properties.length; i++) {
      //       if (properties[i] === val.targetingType) {
      //          // remove the underscores and convert to lowercase
      //          const strArr = properties[i].split('TARGETING_TYPE_')
      //          var property = strArr[1].replace('_', '').toLowerCase()
      //          property = property.replace('_', '')
      //          // after reformatting the property to all lowercase w/o underscores, add it to the target
      //          addPropertyToTarget(t, property, val)
      //       }
      //    }
      // }
   }
   useEffect(() => {
      if (complete) {
         if (results["client"].data.dv_targeting) {
            let targeting = rearrangeTargeting(results["client"].data.dv_targeting.assignedTargetingOptions)
            setDefaultValue(targeting);
         }
      }
   }, [complete])

   useEffect(() => {
      // This sets up the fields to be preselected once the default values have been received
      const defaultTypes = {
         content: ['TARGETING_TYPE_CATEGORY'],
      }

      if (defaultValue && defaultValue.length >= 1) {
         for (let i = 0; i < defaultValue.length; i++) {
            checkForDefaultValues(defaultValue[i], defaultTypes)
         }
      }

   }, [defaultValue])
   if (complete) {
      if (initialLoad) {
         setInitialLoad(false)
         CampaignForm.fields[0][0].options.push({ label: results["client"].data.dv_advertiser_id.toString(), value: results["client"].data.dv_advertiser_id.toString() })

         client.name = results["client"].data.name || "";
         client.status = results["client"].data.dv_advertiser.entityStatus || "";
         client.id = results["client"].data.dv_advertiser_id.toString() || "";
         client.domain = results["client"].data.dv_advertiser.generalConfig.domainUrl || ""
         client.currency = results["client"].data.currency || ""
         campaign.advertiser_id = client.id || ""
      }

      let activeCampaigns = 0
      let activeOrders = 0
      let activeGroups = 0
      if (results["campaigns"] && results["campaigns"].data) {
         activeCampaigns = CountEntities(results["campaigns"].data);

      }
      if (results["orders"] && results["orders"].data) {
         activeOrders = CountEntities(results["orders"].data)

      }
      if (results["groups"] && results["groups"].data) {
         activeGroups = CountEntities(results["groups"].data)

      }

      //FOR DASH

      if (NODE_ENV == 'development') {
         console.log(results)
         console.log(campaign)
      }

      return errors.length > 0 ? (<><span>Error: {errors}</span></>) : (
         <>
            <Tab.Container defaultActiveKey="first">
               <Row>
                  <Col lg="12">
                     <Card>
                        <Card.Body>
                           <div className="d-flex flex-wrap align-items-center justify-content-between">
                              <div className="d-flex flex-wrap align-items-center">
                                 <div className="profile-img position-relative me-3 mb-3 mb-lg-0 profile-logo profile-logo1">
                                    <Image className="theme-color-default-img  img-fluid rounded-pill avatar-100" src={avatars11} alt="profile-pic" />
                                 </div>
                                 <div className="d-flex flex-wrap align-items-center mb-3 mb-sm-0">
                                    <h4 className="me-2 h4">{client.name}</h4>
                                    <span> - Client</span>
                                 </div>
                              </div>
                              <Nav as="ul" className="d-flex nav-pills mb-0 text-center profile-tab" data-toggle="slider-tab" id="profile-pills-tab" role="tablist">
                                 <Nav.Item as="li">
                                    <Nav.Link eventKey="first" className="button">Dash</Nav.Link>
                                 </Nav.Item>
                                 <Nav.Item as="li">
                                    <Nav.Link eventKey="fifth" className="button">Settings</Nav.Link>
                                 </Nav.Item>
                                 {/* <Nav.Item as="li">
                                    <Nav.Link eventKey="sixth" className="button">Targeting</Nav.Link>
                                 </Nav.Item> */}
                                 <Nav.Item as="li">
                                    <Nav.Link eventKey="fourth" className="button">Campaigns</Nav.Link>
                                 </Nav.Item>
                                 <Nav.Item as="li">
                                    <Nav.Link eventKey="second" className="button">Orders</Nav.Link>
                                 </Nav.Item>
                                 <Nav.Item as="li">
                                    <Nav.Link eventKey="third" className="button">Groups</Nav.Link>
                                 </Nav.Item>
                              </Nav>
                           </div>
                        </Card.Body>
                     </Card>
                  </Col>
                  <Col lg="6">
                     <Card>
                        <Card.Header>
                           <div className="header-title">
                              <h4 className="card-title">Snapshot</h4>
                           </div>
                        </Card.Header>
                        <Card.Body>
                           <ul className="list-inline m-0 p-0">
                              <li className="d-flex mb-2">
                                 <div className="news-icon me-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-postcard-fill" viewBox="0 0 16 16"><path d="M11 8h2V6h-2v2Z"></path><path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm8.5.5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7ZM2 5.5a.5.5 0 0 0 .5.5H6a.5.5 0 0 0 0-1H2.5a.5.5 0 0 0-.5.5ZM2.5 7a.5.5 0 0 0 0 1H6a.5.5 0 0 0 0-1H2.5ZM2 9.5a.5.5 0 0 0 .5.5H6a.5.5 0 0 0 0-1H2.5a.5.5 0 0 0-.5.5Zm8-4v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5Z"></path></svg>
                                 </div>
                                 <p className="news-detail mb-0">{activeCampaigns} - Active Campaigns</p>
                              </li>
                              <li className="d-flex mb-2">
                                 <div className="news-icon me-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-ubuntu" viewBox="0 0 16 16"><path d="M2.273 9.53a2.273 2.273 0 1 0 0-4.546 2.273 2.273 0 0 0 0 4.547Zm9.467-4.984a2.273 2.273 0 1 0 0-4.546 2.273 2.273 0 0 0 0 4.546ZM7.4 13.108a5.535 5.535 0 0 1-3.775-2.88 3.273 3.273 0 0 1-1.944.24 7.4 7.4 0 0 0 5.328 4.465c.53.113 1.072.169 1.614.166a3.253 3.253 0 0 1-.666-1.9 5.639 5.639 0 0 1-.557-.091Zm3.828 2.285a2.273 2.273 0 1 0 0-4.546 2.273 2.273 0 0 0 0 4.546Zm3.163-3.108a7.436 7.436 0 0 0 .373-8.726 3.276 3.276 0 0 1-1.278 1.498 5.573 5.573 0 0 1-.183 5.535 3.26 3.26 0 0 1 1.088 1.693ZM2.098 3.998a3.28 3.28 0 0 1 1.897.486 5.544 5.544 0 0 1 4.464-2.388c.037-.67.277-1.313.69-1.843a7.472 7.472 0 0 0-7.051 3.745Z"></path></svg>
                                 </div>
                                 <p className="news-detail mb-0">{activeOrders} - Active Orders</p>
                              </li>
                              <li className="d-flex">
                                 <div className="new-icon me-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-collection-fill" viewBox="0 0 16 16"><path d="M0 13a1.5 1.5 0 0 0 1.5 1.5h13A1.5 1.5 0 0 0 16 13V6a1.5 1.5 0 0 0-1.5-1.5h-13A1.5 1.5 0 0 0 0 6v7zM2 3a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 0-1h-11A.5.5 0 0 0 2 3zm2-2a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 0-1h-7A.5.5 0 0 0 4 1z"></path></svg>
                                 </div>
                                 <p className="news-detail mb-0">{activeGroups} - Active Groups</p>

                              </li>
                           </ul>
                        </Card.Body>
                     </Card>
                  </Col>
                  <Col lg="6">
                     <Card>
                        <Card.Header>
                           <div className="header-title">
                              <h4 className="card-title">Client Info</h4>
                           </div>
                        </Card.Header>
                        <Card.Body>
                           <div className="mb-1">Currency: {client.currency}</div>
                           <div className="mb-1">Website: <a href={client.domain}>{client.domain}</a></div>
                        </Card.Body>
                     </Card>
                  </Col>
                  <Col lg="12">
                     <Tab.Content className="profile-content">
                        <Tab.Pane eventKey="fourth" id="campaign">

                           <Card>
                              <Card.Header className="d-flex justify-content-between">
                                 <div className="header-title">
                                    <h4 className="card-title">Campaign List</h4>
                                 </div>
                                 <div>
                                    <ModalButton name="New Campaign" setModal={setModal} />
                                 </div>
                              </Card.Header>
                              <Card.Body className="px-0">
                                 <div className="table-responsive">
                                    <ReactTable item={results["campaigns"]} col={columnsCampaign} page_size={20} row_size={20} />
                                 </div>
                              </Card.Body>
                           </Card>
                           <CampaignModal campaign={campaign} setCampaign={setCampaign} form={CampaignForm} modal={modal} setModal={setModal} baseUrl={REACT_APP_API_URL} entityId={client.id} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="second" id="order">
                           <Card>
                              <Card.Header className="d-flex justify-content-between">
                                 <div className="header-title">
                                    <h4 className="card-title">Order List</h4>
                                 </div>
                                 {/* <div>
                                    <Button className="text-center btn-dark btn-icon mt-lg-0 mt-md-0 mt-3" variant="dark" >
                                       <i className="btn-inner">
                                          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                             <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                          </svg>
                                       </i>
                                       <span>New Order</span>
                                    </Button>
                                 </div> */}
                              </Card.Header>
                              <Card.Body className="px-0">
                                 <div className="table-responsive">
                                    <ReactTable item={results["orders"]} col={columnsOrder} page_size={20} row_size={20} />
                                 </div>
                              </Card.Body>
                           </Card>
                        </Tab.Pane >
                        <Tab.Pane eventKey="third" id="group">
                           <Card>
                              <Card.Header className="d-flex justify-content-between">
                                 <div className="header-title">
                                    <h4 className="card-title">Group List</h4>
                                 </div>
                                 {/* <div>
                                    <Button className="text-center btn-dark btn-icon mt-lg-0 mt-md-0 mt-3" variant="dark" >
                                       <i className="btn-inner">
                                          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                             <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                          </svg>
                                       </i>
                                       <span>New Group</span>
                                    </Button>
                                 </div> */}
                              </Card.Header>
                              <Card.Body className="px-0">
                                 <div className="table-responsive">
                                    <ReactTable item={results["groups"]} col={columnsGroup} page_size={20} row_size={20} />
                                 </div>
                              </Card.Body>
                           </Card>
                        </Tab.Pane >
                        <Tab.Pane eventKey="first" id="dash">
                           <Row className='dateButtons_sub'>
                              <Col lg={{ span: 6, offset: 6 }}>
                                 <DateButtons setDate={setDate} date={date} />
                              </Col>
                           </Row>
                              <Dash dash={results["dash"].data["dv"]} account={results["account"]} />                           
                        </Tab.Pane >
                        {/* <Tab.Pane eventKey="sixth" id="targeting">
                           <Row>
        
                           </Row>
                           <Row>
                              <Alert variant={alert.variant}>{alert.value}</Alert>
                           </Row>
                        </Tab.Pane> */}
                        <Tab.Pane eventKey="fifth" id="settings">
                           <Row>
                              <Col lg="12">
                                 <InputSettings
                                    title='Add New Client'
                                    mode='edit'
                                    form={ClientForm}
                                    url={url}
                                    formId={"cientform"}
                                    model={[client, setClient]}
                                 />
                              </Col>
                           </Row>
                        </Tab.Pane >
                     </Tab.Content>
                  </Col>

               </Row>
            </Tab.Container>
         </>
      )

   }
   return <><div><span><Loader></Loader></span></div></>;
}

export default (Client)