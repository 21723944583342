
const StatusDef = (defValue) => {
    let opt;
    if (defValue === true || defValue === "ENTITY_STATUS_ACTIVE") {
        opt = {
            label: 'Active',
            value: 'ENTITY_STATUS_ACTIVE'
        }
    } else {
        opt = {
            label: 'Paused',
            value: 'ENTITY_STATUS_PAUSED'
        }
    }
    return opt

}
const StatusSearchDef = (defValue) => {
    let opt;
    if (defValue === true || defValue === "Enabled") {
        opt = {
            label: 'Active',
            value: 'Enabled'
        }
    } else {
        opt = {
            label: 'Paused',
            value: 'Paused'
        }
    }
    return opt

}
const OrderStatusDef = (defValue) => {
    let opt;
    if (defValue === true || defValue === "ENTITY_STATUS_ACTIVE") {
        opt = {
            label: 'Active',
            value: 'ENTITY_STATUS_ACTIVE'
        }
    } else {
        opt = {
            label: 'Paused',
            value: 'ENTITY_STATUS_DRAFT'
        }
    }
    return opt

}
const BudgetDef = (defValue) => {
    let opt;
    if (defValue === "$") {
        opt = "$"
    }
    else if (defValue === "BUDGET_UNIT_CURRENCY") {
        opt = "$"
    } else {
        opt = "imp"
    }
    return opt
}
const DefaultValue = (defValue, optArr) => {
    let opt;
    optArr.map(option => {
        if (option.value == defValue) {
            opt = option
        }
        if (option.label == defValue) {
            opt = option
        }
        return opt
    })
    return opt
}

const MultiDefault = (defArr, optArr) => {
    let defaultOptions = []

    for (let i = 0; i < defArr.length; i++) {
        for (let x = 0; x < optArr.length; x++) {
            let d = defArr[i]
            if (defArr[i].id) {
                d = defArr[i].id
            }
            if (optArr[x].value == d) {
                defaultOptions.push(optArr[x])
                break;
            }
        }
    }

    return defaultOptions
}

export { StatusDef, DefaultValue, BudgetDef, OrderStatusDef, MultiDefault, StatusSearchDef }