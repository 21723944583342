const client_info = [
    {
        label: "Name",
        input: 'input-text',
        name: 'advertiserName',
        size: '4',
    },
    {
        label: "Name DSA",
        input: 'input-text',
        name: 'advertiserNameDsa',
        size: '4',
    },
    {
        label: "Status",
        input: 'dropdown',
        name: 'status',
        options: [
            {
                label: 'Active',
                value: true
            },
            {
                label: 'Paused',
                value: false
            }
        ],
        size: '4'
    },
    {
        label: "Currency",
        input: 'input-text',
        name: 'currencyCode',
    }, 
    {
        label: "Category",
        input: 'input-text',
        name: 'advertiserCategory.categoryName',
        size: '4',
    },  
    {
        label: "Description",
        input: 'input-text',
        name: 'description',
        size: '4',
    },
    {
        label: "Domain Address",
        input: 'input-text',
        name: 'domainAddress',
        size: '4',
    },
    {
        label: "PartnerId",
        input: 'input-text',
        name: 'partnerId',
        size: '4',
    },
    {
        label: "Payer Name DSA",
        input: 'input-text',
        name: 'payerNameDsa',
        size: '4',
    },
    {
        label: "Vetting Status",
        input: 'input-text',
        name: 'vettingStatus',
        size: '4',
    },
    {
        label: "Ignore Referral URL in Conversion",
        input: 'toggle',
        name: 'ignoreReferralUrlInConversion',
        size: '4',
    }, 
    {
        label: "Is Ballot Measure",
        input: 'toggle',
        name: 'isBallotMeasure',
        size: '4',
    }, 
    {
        label: "Is Candidate Election",
        input: 'toggle',
        name: 'isCandidateElection',
        size: '4',
    }, 
    {
        label: "Use Legacy Additional Fees",
        input: 'toggle',
        name: 'useLegacyAdditionalFees',
        size: '4',
    }, 
]

const attribution_info = [
        {
            label: 'Attribution Click Lookback Window in Seconds',
            input: 'input-number',
            name: 'attributionClickLookbackWindowInSeconds',
            size: '4'
        },
        {
            label: 'Attribution Impression Lookback Window in Seconds',
            input: 'input-number',
            name: 'attributionImpressionLookbackWindowInSeconds',
            size: '4'
        },
]

const audience_settings = [
    {
        label: "Audience Booster Enabled",
        input: 'toggle',
        name: 'advertiserAudienceSettings.audienceBoosterEnabled',
        size: '4',
    },  
    {
        label: "Audience Excluder Enabled",
        input: 'toggle',
        name: 'advertiserAudienceSettings.audienceExcluderEnabled',
        size: '4',
    },  
]

const industry_category = [
    {
        label: 'Industry Category Id',
        input: 'input-number',
        name: 'industryCategoryId',
        size: '4'
    },
    {
        label: 'Industry Category Taxonomy Id',
        input: 'input-number',
        name: 'industryCategoryTaxonomyId',
        size: '4'
    }
]


const ClientTradeDeskForm = {
    titles: ["Client Info", "Attribution Info", "Audience Settings", "Industry Category"],
    fields: [
        client_info,
        attribution_info,
        audience_settings,
        industry_category
    ]
}

export { client_info, ClientTradeDeskForm }