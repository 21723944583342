import { Language } from "./options"

const demographics = [
    {
        label: 'Gender(s)',
        input: 'checkbox',
        name: 'gender',
        // dependent:"campaign_type",
        // condition:"google",    
        options: [
            { label: 'Male', value: "Male" },
            { label: 'Female', value: "Female" },
            { label: 'Unknown', value: "Unknown" }
        ],
        size: "4",
    },
    {
        label: 'Parental Status',
        input: 'checkbox',
        name: 'parental_status',
        // dependent:"campaign_type",
        // condition:"google",    
        options: [
            { label: 'Parent', value: "Parent" },
            { label: 'Not A Parent', value: "Not A Parent" },
            { label: 'Unknown', value: "Unknown" }
        ],
        size: "4",
    },
    {
        label: "Age Range",
        input: 'slider',
        name: 'age_range',
        range: [18, 65],
        options: [{ value: 18, label: "18-24", },
        { value: 25, label: "25-34", },
        { value: 35, label: "35-44", },
        { value: 45, label: "45-54", },
        { value: 55, label: "55-64", },
        { value: 65, label: "65+", },],
        size: "6",
    },
    {
        label: "Household Income",
        input: 'slider',
        name: 'household_income',
        range: [1, 6],
        options: [{ value: 1, label: "Top 10%", },
        { value: 2, label: "Top 11-20%", },
        { value: 3, label: "Top 21-30%", },
        { value: 4, label: "Top 31-40%", },
        { value: 5, label: "Top 41-50%", },
        { value: 6, label: "Lower 50%", }],
        size: "6",
    }



    // {
    //     label: 'Advertiser ID',
    //     input: 'dropdown',
    //     options: [
    //     ],
    //     name:"advertiser_id",
    //     size:"3"
    // },
    // {
    //     label: "Campaign Name",
    //     input: 'input-text',
    //     name:"name",
    //     required: true,
    //     feedback:"Campaign name is required",
    //     size: "6",        
    // },
    // {
    //     label: "Status",
    //     input: 'dropdown',
    //     options: [
    //         {
    //             label:'Active',
    //             value: 'ENTITY_STATUS_ACTIVE'
    //         },
    //         {
    //             label: 'Paused',
    //             value: 'ENTITY_STATUS_PAUSED'
    //         }
    //     ],
    //     multi:false,
    //     required:true,
    //     feedback:"Status is a required field",
    //     size: "3",
    //     name:"status",
    // }    
]

const device = [
    {
        label: 'Device Type(s)',
        input: 'checkbox',
        name: 'device_type',
        // dependent:"campaign_type",
        // condition:"google",    
        options: [
            { label: 'Computer/Desktop', value: "Computer/Desktop" },
            { label: 'Smart Phone', value: "Smart Phone" },
            { label: 'Tablet', value: "Tablet" },
            { label: 'Connected TV', value: "Connected TV" },
        ],
        size: "6",
    },
    {
        label: 'Environment',
        input: 'checkbox',
        name: 'environment',
        // dependent:"campaign_type",
        // condition:"google",    
        options: [
            { label: 'Web', value: "Web" },
            { label: 'Web (Not Optimized For Mobile)', value: "Web (Not Optimized For Mobile)" },
            { label: 'App', value: "App" },
        ],
        size: "6",
    },
    {
        label: 'On Screen Position',
        input: 'checkbox',
        name: 'onscreenposition',
        // dependent:"campaign_type",
        // condition:"google",    
        options: [
            { label: 'Above The Fold', value: "Above The Fold" },
            { label: 'Below The Fold', value: "Below The Fold" },
            { label: 'Unknown', value: "Unknown" },
        ],
        size: "4",
    },
    {
        label: 'In Content Position',
        input: 'checkbox',
        name: 'incontentposition',
        // dependent:"campaign_type",
        // condition:"google",    
        options: [
            { label: 'In-article', value: "In-article" },
            { label: 'In-feed', value: "In-feed" },
            { label: 'Interstitial', value: "Interstitial" },
            { label: 'In-banner', value: "In-banner" },
            { label: 'Unknown', value: "Unknown" },
        ],
        size: "4",
    },
    {
        label: 'Native Position',
        input: 'checkbox',
        name: 'nativeposition',
        // dependent:"campaign_type",
        // condition:"google",    
        options: [
            { label: 'In-article', value: "In-article" },
            { label: 'In-feed', value: "In-feed" },
            { label: 'Peripheral', value: "Peripheral" },
            { label: 'Recommendation', value: "Recommendation" },
            { label: 'Unknown', value: "Unknown" },
        ],
        size: "4",
    },
    {
        label: "Device(s)",
        input: 'async-exclude',
        options: [],
        multi: true,
        required: false,
        feedback: "Status is a required field",
        size: "6",
        url: `/lib/targeting/TARGETING_TYPE_DEVICE_MAKE_MODEL/`,
        valueName: "deviceMake.displayName",
        valueId: "targetId",
        name: "devices.opts",
        nameCheck: "devices.exclude",
        negative: false,
    },
    {
        label: "Operating System(s)",
        input: 'dropdown-exclude',
        options: [],
        multi: true,
        required: false,
        negative: false,
        feedback: "Status is a required field",
        size: "6",
        name: "os.opts",
        nameCheck: "os.exclude"
    },
    {
        label: "Browser(s)",
        input: 'dropdown-exclude',
        options: [],
        multi: true,
        required: false,
        feedback: "Status is a required field",
        size: "6",
        negative: false,
        name: "browsers.opts",
        nameCheck: "browsers.exclude"
    },
    {
        label: "Carrier/ISP(s)",
        input: 'async-exclude',
        options: [],
        multi: true,
        required: false,
        feedback: "Status is a required field",
        size: "6",
        negative: false,
        url: `/lib/targeting/TARGETING_TYPE_CARRIER_AND_ISP/`,
        valueName: "carrier.displayName",
        valueId: "targetId",
        name: "carriers.opts",
        nameCheck: "carriers.exclude"
    },
]

const location = [
    {
        label: "Geo(s)",
        input: 'async-exclude',
        options: [],
        multi: true,
        required: false,
        feedback: "Status is a required field",
        size: "6",
        negative: false,
        url: `/lib/targeting/TARGETING_TYPE_GEO_REGION/`,
        valueName: "geoRegion.displayName",
        valueId: "targetId",
        name: "geos.opts",
        nameCheck: "geos.exclude"
    }, {
        label: "Language(s)",
        input: 'dropdown-exclude',
        options: [],
        multi: true,
        required: false,
        feedback: "Status is a required field",
        size: "6",
        negative: false,
        name: "languages.opts",
        nameCheck: "languages.exclude"
    },
]

const content = [
    {
        label: "Categories",
        input: 'async-exclude',
        options: [],
        multi: true,
        required: false,
        feedback: "Status is a required field",
        size: "6",
        negative: false,
        url: `/lib/targeting/TARGETING_TYPE_CATEGORY/`,
        valueName: "category.displayName",
        valueId: "targetId",
        name: "categories.opts",
        nameCheck: "categories.exclude"
    },
    {
        label: 'Digital Content Label Exclusion',
        input: 'checkbox',
        name: 'content_label',
        // dependent:"campaign_type",
        // condition:"google",    
        options: [
            { label: 'General Audiences', value: "General Audiences" },
            { label: 'Parental Guidance', value: "Parental Guidance" },
            { label: 'Teens', value: "Teens" },
            { label: 'Mature', value: "Mature" },
            { label: 'Unknown', value: "Unknown" },
        ],
        size: "4",
    },
    {
        label: 'Sensitive Category Exclusion',
        input: 'checkbox',
        name: 'sensitive_categories',
        // dependent:"campaign_type",
        // condition:"google",    
        options: [
            { label: 'Accidents', value: "Accidents" },
            { label: 'Adult', value: "Adult" },
            { label: 'Alcohol', value: "Alcohol" },
            { label: 'Derogatory', value: "Derogatory" },
            { label: 'Drugs', value: "Drugs" },
            { label: 'File Sharing Downloads', value: "File Sharing Downloads" },
            { label: 'Gambling', value: "Gambling" },
            { label: 'Politics', value: "Politics" },
            { label: 'Profanity', value: "Profanity" },
            { label: 'Religion', value: "Religion" },
            { label: 'Shocking', value: "Shocking" },
            { label: 'Social Issues', value: "Social Issues" },
            { label: 'Suggestive Content', value: "Suggestive Content" },
            { label: 'Tobacco', value: "Tobacco" },
            { label: 'Tragedy', value: "Tragedy" },
            { label: 'Weapons', value: "Weapons" },
            { label: 'Violence', value: "Violence" },
        ],
        size: "4",
    },
]
const inventory = [
    {
        label: "Exchange(s)",
        input: 'dropdown',
        options: [],
        multi: true,
        required: false,
        feedback: "Status is a required field",
        size: "6",
        name: "exchanges.opts",
    },
    {
        label: "Sub Exchange(s)",
        input: 'dropdown',
        dependent: "exchanges.opts",
        condition: "EXCHANGE_BIDSWITCH",
        options: [],
        multi: true,
        required: false,
        feedback: "Status is a required field",
        size: "6",
        url: "",
        valueName: "",
        name: "subexchanges.opts",
    },
]

const datetime = [
    {
        label: "Day Parting",
        input: 'segment',        
        required: false,
        feedback: "Status is a required field",
        size: "12",
        name: "day_time",
    },
]

const TargetingForm = {
    titles: ["Demographics", "Device", "Location", "Content", "Inventory", "DateTime"],
    fields: [
        demographics,
        device,
        location,
        content,
        inventory,
        datetime
    ]
}

const FindAndSet = (searchName,options) =>{
    TargetingForm.fields.forEach((obj,i) =>{
        obj.forEach((subObj,i)=>{
            if(subObj.name === searchName) {
                subObj.options = options
            }
        })
    })
}

export { demographics, device, location, content, inventory, TargetingForm,FindAndSet }