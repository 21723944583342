import { Button, Dropdown } from "react-bootstrap"

const InputBriefButton = (params) => {
   let length = 0
   if (params.options) {
      length = params.options.length;
   }

   return (
      (length > 0) && (<>
         <Dropdown>
            <Dropdown.Toggle variant="dark">
               <i className="btn-inner">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                     <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                  </svg>
               </i>
               <span>{params.name}</span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
               {params.options.map((e) => {
                  return (<Dropdown.Item onClick={() => params.setBrief(e)}>{e.name}</Dropdown.Item>)
               })}
            </Dropdown.Menu>
         </Dropdown>
      </>)
   )
}

export { InputBriefButton }