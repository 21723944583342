/*

This component will have

1) a search box with the ability to add the selection to a list
2) a list box table with editable fields.
- Use MUio datagrid with editable fields
Only field to edit is Bid Adjustment (default is `-` which indicates 100%)
Columns should be passed in via gridGolumns from calling page

TODO: "Should submisstion happen on each add or shoudl their be a separate submission process after all are added?"

*/

import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import AsyncSelect from 'react-select/async';
import { useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useAppContext } from "../context/appContext";


const Combolist = ({ url, name, label, columns, list, setList, submit, api, obj, setObj }) => {
    const appValue = useAppContext();
    const [query, setQuery] = useState("");
    const [item, setItem] = useState();
    const [option, setOption] = useState();
    const [val, setVal] = useState();
    const [itemList, setItemList] = useState([]);
    var loadOpt = []
    const loadOptions = () => {
        if (query.length >= 4) {

            var REACT_APP_API_URL = appValue.apiUrl;

            let apiUrl = REACT_APP_API_URL + url + `${query}`
            return new Promise((resolve, reject) => {
                axios.get(apiUrl, appValue.config).then((response) => {
                    if (response.data) {
                        if (name.includes(".")) {
                            let splstr = name.split(".")
                            for (let i = 0; i < response.data.data.length; i++) {
                                loadOpt.push({ value: String(response.data.data[i][splstr[0]][splstr[1]]), label: response.data.data[i][splstr[0]][splstr[1]] })
                            }
                        } else {
                            for (let i = 0; i < response.data.data.length; i++) {
                                loadOpt.push({ value: String(response.data.data[i][name]), label: response.data.data[i][name], })
                            }
                        }
                        resolve(loadOpt)
                    } else {
                        reject(response.error)
                    }
                })
            })
        }
    };


    const modifyList = (event, key, value) => {
        const nextList = list.map((c, i) => {
            if (c.name === key) {
                return value
            } else {
                return c
            }
        })
        setList(nextList)
        submit(event, obj, setObj, api)
    }
    const handleTerms = (terms) => {
        if (terms.includes(",")) {
            let termsArr = terms.split(",")
            setItemList(termsArr)
        } else {
            setItem(terms)
        }
    }
    const handleCSV = (e) => {
        let newList = []
        itemList.map((v, i) => {
            newList.push({ name: v, field: "100%" })
        })
        setList([...list, ...newList])
        submit(e, obj, setObj, api)

    }
    return (
        <>
            <Card>
                <Card.Header>
                    <Row>
                        <div className="header-title">
                            <h2 className="card-title">{label}</h2>
                        </div>
                    </Row>
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col lg="8">
                            {(label === "Location") ? (

                                <AsyncSelect
                                    cacheOptions
                                    loadOptions={loadOptions}
                                    name={label}
                                    value={option || ''}
                                    onChange={(e) => {
                                        setOption(e)
                                        setItem(e.value)
                                    }}
                                    onInputChange={(value) => setQuery(value)}
                                />
                            ) : (

                                <Form.Control
                                    type="text"
                                    name={name}
                                    value={val}
                                    onBlur={(event) => {
                                        handleTerms(event.target.value)
                                    }}
                                />

                            )}
                        </Col>
                        <Col lg="4">
                            <Button variant="dark" onClick={(e) => {
                                if (itemList.length >= 1) {
                                    handleCSV(e);
                                } else {
                                    setList([...list, { name: item, field: "100%" }])
                                    submit(e, obj, setObj, api)
                                }
                                setItem("")
                                setVal("")
                                setOption("")
                            }}>
                                Add
                            </Button>
                        </Col>
                    </Row>
                    <Row  >
                        <Col lg="12" className="mt-3">
                            {(list.length >= 1) ? (

                                <DataGrid
                                    initialState={{
                                        pagination: {
                                            paginationModel: { pageSize: 25, page: 0 },
                                        },
                                    }}
                                    rows={list}
                                    columns={columns}
                                    rowsPerPageOptions={20}
                                    checkboxSelection
                                    disableRowSelectionOnClick
                                    getRowId={(rows) => rows.name}
                                    editMode='cell'
                                    onCellEditStop={(params, event) => {
                                        modifyList(event, params.id, { name: params.id, field: event.target.value })
                                    }}
                                    // initalState={{filter: {filterModel:{filter}}}}
                                    slots={{ Toolbar: GridToolbar }}
                                    slotProps={{
                                        toolbar: {
                                            showQuickFilter: true,
                                            quickFilterProps: { debounceMs: 500 },
                                        },
                                    }}
                                    autoHeight
                                />
                            ) : (<></>)}
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </>
    )
}

export { Combolist }