
import { Col, Form, InputGroup, Row } from "react-bootstrap"
import Select from 'react-select'
import Card from './Card'
import AsyncSelect from 'react-select/async';
import { useState } from "react";
import axios from "axios";
import { useAppContext } from "../context/appContext";
import { CurrencySymbol } from "./common";

const ModalInput = ({ subtitle, value, data, size, changeEvent, text, label, isMulti, disabled, icon }) => {
    const [query, setQuery] = useState("");

    const SelectDropdown = ({ values }) => {
        const { options } = values

        return (
            <Select
                name={values.name}
                defaultValue={values.value}
                options={options}
                required
                readOnly={disabled}
                isMulti
            />
        )
    }


    const renderSwitch = (param) => {
        switch (param.input) {
            case "text":
                return (
                    <Col lg={size === "full" ? "12" : "6"} sm="12">
                        <Card.Header.Title>
                            {label !== undefined ? label : param.label}
                        </Card.Header.Title>
                        <div className="mt-4">
                            <Form.Control
                                type="text"
                                required
                                name={param.name}
                                value={value}
                                readOnly={disabled}
                                onChange={(e) => changeEvent(e)} />
                            <Form.Text style={{ color: 'red' }}>
                                {text}
                            </Form.Text>
                        </div>
                    </Col>


                )
            case "inputgroup":
                return (
                    <Col lg={size === "full" ? "12" : "6"} sm="12">
                        <Card.Header.Title>
                            {label !== undefined ? label : param.label}
                        </Card.Header.Title>
                        <div className="mt-4">
                            <InputGroup className="mb-3">
                                <InputGroup.Text id={param.label}>{icon}</InputGroup.Text>
                                <Form.Control
                                    type="text"
                                    required
                                    name={param.name}
                                    value={value}
                                    readOnly={disabled}
                                    onChange={(e) => changeEvent(e)} />
                                <Form.Text style={{ color: 'red' }}>
                                    {text}
                                </Form.Text>
                            </InputGroup>
                        </div>
                    </Col>


                )
            case "excludeselect":
                return (
                    <Row>
                        <Col lg="2" sm="12">
                            <Form.Check
                                inline
                                type="switch"
                                id={param.label + "Negative"}
                                label="Exclude"
                                defaultChecked={param.valueCheck}
                                defaultValue={param.valueCheck}
                                onChange={(e) => changeEvent(e)}
                            />
                        </Col>
                        <Col lg="8" sm="12">
                            <Select
                                isMulti
                                name={param.label}
                                defaultValue={param.value}
                                options={param.options}
                                onChange={(e) => changeEvent(e)} />
                        </Col>
                    </Row>
                )
            case "textarea":
                return (
                    <Col lg={size === "full" ? "12" : "6"} sm="12">
                        <Card.Header.Title>
                            {label !== undefined ? label : param.label}
                        </Card.Header.Title>
                        <div className="mt-4">
                            <Form.Control
                                as="textarea"
                                rows={3}
                                required
                                name={param.name}
                                value={value}
                                readOnly={disabled}
                                onChange={(e) => changeEvent(e)} />
                            <Form.Text style={{ color: 'red' }}>
                                {text}
                            </Form.Text>
                        </div>
                    </Col>
                )
            case "boolean":
                return (
                    <Col lg={size === "full" ? "12" : "6"} sm="12">
                        <Card.Header.Title>
                            {param.label}
                        </Card.Header.Title>
                        <div className="mt-4">

                            <Select
                                name={param.name}

                                defaultValue={() => {
                                    for (let i = 0; i < param.options.length; i++) {
                                        return param.options[i]
                                    }
                                }}
                                options={param.options}
                                required
                                text={text}
                                readOnly={disabled}
                                onChange={(e) => { changeEvent(e) }} />
                            <Form.Text style={{ color: 'red' }}>
                                {text}
                            </Form.Text>
                        </div>
                    </Col>

                )
            case "badges":
                return (
                    // <h4 className="mb-2">{param.subtitle}</h4>
                    // <hr />
                    <Row>
                        {param.subsections &&
                            param.subsections.map((sect, i) => {
                                console.log(sect)
                                return (
                                    <Col lg={param.size === 'half' ? "6" : "12"} sm="12">
                                        <Card.Header.Title>{sect.label} </Card.Header.Title>
                                        <Card.Body>
                                            {/* <Badges options={sect.options} data={sect.data}></Badges> */}
                                            <SelectDropdown values={sect} />
                                            <Form.Text style={{ color: 'red' }}>
                                                {text}
                                            </Form.Text>
                                        </Card.Body>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                )
            case "dropdown":
                return (
                    <Col lg={size === "full" ? "12" : "6"} sm="12">
                        <Card.Header.Title>
                            {param.label}
                        </Card.Header.Title>
                        <div className="mt-4">
                            <Select
                                name={param.name}
                                defaultValue={value}
                                options={param.options}
                                required
                                isDisabled={disabled}
                                isMulti={isMulti}
                                onChange={(e) => { changeEvent(e) }}
                            />
                            <Form.Text style={{ color: 'red' }}>
                                {text}
                            </Form.Text>
                        </div>
                    </Col>
                )
            case "check":
                return (
                    <>
                        <Card.Header.Title>
                            {param.label}
                        </Card.Header.Title>
                        {param.options.map((o, i) => (
                            <Col lg={size === "full" ? "12" : "6"} sm="12">
                                <Card.Body>
                                    <Form.Check
                                        key={i}
                                        label={o.label}
                                        name={param.name}
                                        type="radio"
                                        value={o.value}
                                        required
                                        readOnly={disabled}
                                        onChange={(e) => { changeEvent(e) }}
                                    />

                                    <Form.Text style={{ color: 'red' }}>
                                        {text}
                                    </Form.Text>
                                </Card.Body>
                            </Col>
                        ))}
                    </>
                )

            case "toggle":
                return (
                    <>
                        <Col lg={size === "full" ? "12" : "6"} sm="12">
                            <Card.Body>
                                <Form.Check
                                    inline
                                    label={param.label}
                                    name={param.name}
                                    type="switch"
                                    required
                                    variant="outline-primary"
                                    checked={value}
                                    value={value}
                                    onChange={(e) => { changeEvent(e) }}
                                />
                                <Form.Text style={{ color: 'red' }}>
                                    {text}
                                </Form.Text>
                            </Card.Body>
                        </Col>
                    </>
                )

            case "date":
                return (
                    <Col lg={size === "full" ? "12" : "6"} sm="12">
                        <Card.Header.Title>
                            {param.label}
                        </Card.Header.Title>
                        <div className="mt-4">
                            <Form.Control
                                type="date"
                                required
                                name={param.name}
                                value={value}
                                readOnly={disabled}
                                data-date-format="0:yyyy-MM-dd"
                                onChange={(e) => changeEvent(e)} />
                            <Form.Text style={{ color: 'red' }}>
                                {text}
                            </Form.Text>
                        </div>
                    </Col>


                )
            case "radio":
                return (
                    <>
                        <Card.Header.Title>
                            {param.label}
                        </Card.Header.Title>
                        <Col lg={size === "full" ? "12" : "6"} sm="12">
                            <Card.Body>
                                <Form.Group>
                                    {param.options.map((o, i) => (
                                        (param.required) ? (
                                            <Form.Check
                                                type="radio"
                                                inline
                                                label={o.label}
                                                value={o.value}
                                                name={param.name}
                                                onChange={(e) => { changeEvent(e) }}
                                                required />
                                        ) : (
                                            <Form.Check
                                                type="radio"
                                                inline
                                                label={o.label}
                                                value={o.value}
                                                onChange={(e) => { changeEvent(e) }}
                                                name={param.name}
                                            />
                                        )



                                    ))}
                                </Form.Group>
                            </Card.Body>
                        </Col>
                    </>
                )
            case "number":
                return (
                    <Col lg={size === "full" ? "12" : "6"} sm="12">
                        <Card.Header.Title>
                            {label !== undefined ? label : param.label}
                        </Card.Header.Title>
                        <div className="mt-4">
                            <InputGroup>
                                <InputGroup.Text>
                                    {param.currency !== '' && (
                                        <InputGroup.Text id="basic-addon1"><CurrencySymbol cur={param.currency} /></InputGroup.Text>
                                    )}
                                </InputGroup.Text>
                                <Form.Control
                                    type="number"
                                    required
                                    name={param.name}
                                    value={value}
                                    readOnly={disabled}
                                    onChange={(e) => changeEvent(e)} />
                                <Form.Text style={{ color: 'red' }}>
                                    {text}
                                </Form.Text>
                            </InputGroup>
                        </div>
                    </Col>


                )
            // case "async":
            //     var loadOpt = []
            //     const loadOptions = () => {
            //         if (query.length >= 4) {
            //             var REACT_APP_API_URL = FetchAPIurl()
            //             const { config } = BuildAPIHeader()
            //             let url = REACT_APP_API_URL + `/creative/search/${query}`
            //             return new Promise((resolve, reject) => {
            //                 axios.get(url, config).then((response) => {
            //                     if (response.data) {
            //                         for (let i = 0; i < response.data.data.length; i++) {
            //                             loadOpt.push({ value: response.data.data[i].dv_creative_id, label: response.data.data[i].name })
            //                         }
            //                         resolve(loadOpt)
            //                     } else {
            //                         reject(response.error)
            //                     }
            //                 })
            //             })
            //         }
            //     };

            //     return (
            //         <AsyncSelect
            //             isMulti
            //             name={label}
            //             defaultOptions={data}
            //             onChange={(e) => changeEvent(e)}
            //             loadOptions={loadOptions}
            //             onInputChange={(value) => setQuery(value)}

            //         />
            //     )
            case "async-default":
                var loadOpt = []
                const LoadOptions = () => {
                    const appValue = useAppContext();
                    if (query.length >= 4) {
                        var REACT_APP_API_URL = appValue.apiUrl;

                        let url = REACT_APP_API_URL + param.url + query
                        return new Promise((resolve, reject) => {
                            axios.get(url, appValue.config).then((response) => {
                                console.log(response.data)
                                if (response.data) {
                                    for (let i = 0; i < response.data.data.length; i++) {

                                        loadOpt.push({ value: response.data.data[i][param.id], label: response.data.data[i][param.name] })
                                    }
                                    console.log(response.data)
                                    console.log(loadOpt)
                                    resolve(loadOpt)
                                } else {
                                    reject(response.error)
                                }
                            })
                        })
                    }
                };
                return (
                    <Col lg={size === "full" ? "12" : "6"} sm="12">
                        <Card.Header.Title>
                            {label !== undefined ? label : param.label}
                        </Card.Header.Title>
                        <div className="mt-4">
                            <AsyncSelect
                                name={label}
                                defaultOptions={data}
                                onChange={(e) => changeEvent(e)}
                                loadOptions={LoadOptions}
                                onInputChange={(value) => setQuery(value)}

                            />
                            <Form.Text style={{ color: 'red' }}>
                                {text}
                            </Form.Text>
                        </div>
                    </Col>

                )
            default:
                return <></>;
        }
    }



    return (
        <>
            {renderSwitch(data)}
        </>
    )
}


export default ModalInput;