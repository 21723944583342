import React from 'react'
import { DataGrid,GridToolbar } from '@mui/x-data-grid';
// import { columnsClient,columnsCampaign,columnsAudience,columnsCreative,columnsExchange,columnsGroup,columnsOrder,columnsProperty,columnsSites } from './gridColumns';

export default function ReactTable({item,col,page_size,row_size}) {

    
    return (
        // <></>
            (item.data) ? (

              <DataGrid
              initialState={{
                pagination: {
                  paginationModel: { pageSize: 25, page: 0 },
                },
              }}
                rows={item.data}
                columns={col}                
                rowsPerPageOptions={[row_size]}
                checkboxSelection
                getRowId={(rows) => rows._id}
                // initalState={{filter: {filterModel:{filter}}}}
                components={{ Toolbar: GridToolbar }}                                 
                componentsProps={{
                    toolbar: {
                      showQuickFilter: true,
                      quickFilterProps: { debounceMs: 500 },
                    },
                  }}
                  autoHeight
                  />            
                  ) :(<></>)
     
    )
}

// getRowId={(rows) => rows._id}