import { React, useEffect } from 'react'
import { Row, Col, Badge, Tab, Nav } from 'react-bootstrap'
import Card from '../../../components/Card'
import Loader from '../../../components/Loader'
import { TrimString } from '../../../components/common'
import { useParams } from 'react-router-dom'
import { FetchFromAPI } from '../../../components/ajx'
import { columnsExchange } from '../../../components/gridColumns'
// AOS
import AOS from 'aos'
import '../../../../node_modules/aos/dist/aos'
import '../../../../node_modules/aos/dist/aos.css'

import ReactTable from '../../../components/table'
import { useAppContext } from '../../../context/appContext'




const Creative = (props) => {
    const appValue = useAppContext();
    useEffect(() => {

        AOS.init({
            startEvent: 'DOMContentLoaded',
            disable: function () {
                var maxWidth = 996;
                return window.innerWidth < maxWidth;
            },
            throttleDelay: 10,
            once: true,
            duration: 700,
            offset: 10
        });

    })

    const { cid } = useParams()
    var REACT_APP_API_URL = appValue.apiUrl;
    const { NODE_ENV } = process.env;
    
   const creativeAPI = {
    urls: [
       {
          name: "creatives",
          url: REACT_APP_API_URL + "/creative/" + cid
       },
    ],
    method: "GET",

 }
 
 const {results,errors,complete} = FetchFromAPI(creativeAPI)


    if (complete) {
        if (NODE_ENV == "development") {
            console.log(results)
            // console.log(data2)
            // console.log(data3)
            // console.log(data4)
            // console.log(data5)
        }
        let reviews = { data: results["creatives"].data.dv_creative.reviewStatus.exchangeReviewStatuses }
        for (let i = 0; i < reviews.data.length; i++) {
            reviews.data[i]._id = i
        }

        return errors.length > 1 ? (<><span>Error: {errors}</span></>) : (
            <>
                <Tab.Container defaultActiveKey="first">
                    <Row>
                        <Col lg="12">
                            <Card>
                                <Card.Body>
                                    <div className="d-flex flex-wrap align-items-center justify-content-between">
                                        <div className="d-flex flex-wrap align-items-center">
                                            <div className="d-flex flex-wrap align-items-center mb-3 mb-sm-0">
                                                <h6 className="me-2 h6">{results["creatives"].data.name}</h6>
                                                <span> - Creative</span>
                                            </div>
                                        </div>
                                        <Nav as="ul" className="d-flex nav-pills mb-0 text-center profile-tab" data-toggle="slider-tab" id="profile-pills-tab" role="tablist">
                                            <Nav.Item as="li">
                                                <Nav.Link eventKey="first" className="button">Settings</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item as="li">
                                                <Nav.Link eventKey="second" className="button">Approvals</Nav.Link>
                                            </Nav.Item>
                                            {/* <Nav.Item as="li">
                                                <Nav.Link eventKey="third" className="button">Groups</Nav.Link>
                                            </Nav.Item> */}
                                        </Nav>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="6">
                            <Card>
                                <Card.Header>
                                    <div className="header-title">
                                        <h4 className="card-title">Creative Info</h4>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <div className="mb-1">Type: {TrimString(results["creatives"].data.creativetype, 14)}</div>
                                    <div className="mb-1">ID: {results["creatives"].data.dv_creative_id}</div>
                                    {(results["creatives"].data.dv_creative.creativeAttributes) ? (
                                        <div className="mb-1">Attribute: TrimString(results["creatives"].data.dv_creative.creativeAttributes[0],19)</div>
                                    ) :
                                        (<></>)
                                    }
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg="12">
                            <Tab.Content className="profile-content">
                                <Tab.Pane eventKey="first" id="settings">
                                    <Row>
                                        <Col lg="12">
                                            <Card>
                                                <Card.Header className="d-flex justify-content-between">
                                                    <div className="header-title">
                                                        <h4 className="card-title">General Information</h4>
                                                    </div>
                                                </Card.Header>
                                                <Card.Body>

                                                    <Row>
                                                        <Col lg="6" sm="12">
                                                            <Card.Header.Title>
                                                                Size
                                                            </Card.Header.Title>
                                                            <Card.Body>
                                                                <Badge bg="secondary" className="badges">{results["creatives"].data.dv_creative.dimensions.widthPixels + "x" + results["creatives"].data.dv_creative.dimensions.heightPixels}</Badge>
                                                            </Card.Body>
                                                        </Col>
                                                        <Col lg="6" sm="12">
                                                            <Card.Header.Title>
                                                                Status:
                                                            </Card.Header.Title>
                                                            <Card.Body>
                                                                <span className={`badge ${results["creatives"].data.status ? "bg-success" : "bg-danger"}`}>{results["creatives"].data.status ? "Active" : "Inactive"}</span>
                                                            </Card.Body>
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>


                                            <Card>
                                                <Card.Body>
                                                    <div className="acc-privacy">
                                                        <div className="data-privacy">
                                                            <h4 className="mb-2">Settings</h4>
                                                            <Row>
                                                                <Col lg="3" sm="12">
                                                                    <Card.Header.Title>
                                                                        Dynamic
                                                                    </Card.Header.Title>
                                                                    <Card.Body>
                                                                        <span className={`badge ${results["creatives"].data.dv_creative.dynamic ? "bg-success" : "bg-danger"}`}>{results["creatives"].data.dv_creative.dynamic ? "Yes" : "No"}</span>
                                                                    </Card.Body>
                                                                </Col>
                                                                <Col lg="3" sm="12">
                                                                    <Card.Header.Title>
                                                                        Expand on Hover
                                                                    </Card.Header.Title>
                                                                    <Card.Body>
                                                                        <span className={`badge ${results["creatives"].data.dv_creative.expandOnHover ? "bg-success" : "bg-danger"}`}>{results["creatives"].data.dv_creative.expandOnHover ? "Yes" : "No"}</span>
                                                                        {(results["creatives"].data.dv_creative.expandOnHover) ? (
                                                                            <span>Skip Offset: {results["creatives"].data.dv_creative.expandingDirection}</span>
                                                                        ) : (<></>)}
                                                                    </Card.Body>
                                                                </Col>
                                                                <Col lg="3" sm="12">
                                                                    <Card.Header.Title>
                                                                        HTML5 Video
                                                                    </Card.Header.Title>
                                                                    <Card.Body>
                                                                        <span className={`badge ${results["creatives"].data.dv_creative.html5Video ? "bg-success" : "bg-danger"}`}>{results["creatives"].data.dv_creative.html5Video ? "Yes" : "No"}</span>
                                                                    </Card.Body>
                                                                </Col>
                                                                <Col lg="3" sm="12">
                                                                    <Card.Header.Title>
                                                                        Skippable
                                                                    </Card.Header.Title>
                                                                    <Card.Body>
                                                                        <span className={`badge ${results["creatives"].data.dv_creative.skippable ? "bg-success" : "bg-danger"}`}>{results["creatives"].data.dv_creative.skippable ? "Yes" : "No"}</span>
                                                                    </Card.Body>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col lg="3" sm="12">
                                                                    <Card.Header.Title>
                                                                        Require HTML5
                                                                    </Card.Header.Title>
                                                                    <Card.Body>
                                                                        <span className={`badge ${results["creatives"].data.dv_creative.requireHTML5 ? "bg-success" : "bg-danger"}`}>{results["creatives"].data.dv_creative.requireHTML5 ? "Yes" : "No"}</span>
                                                                    </Card.Body>
                                                                </Col>
                                                                <Col lg="3" sm="12">
                                                                    <Card.Header.Title>
                                                                        Require MRAID
                                                                    </Card.Header.Title>
                                                                    <Card.Body>
                                                                        <span className={`badge ${results["creatives"].data.dv_creative.requireMraid ? "bg-success" : "bg-danger"}`}>{results["creatives"].data.dv_creative.requireMraid ? "Yes" : "No"}</span>
                                                                    </Card.Body>
                                                                </Col>
                                                                <Col lg="3" sm="12">
                                                                    <Card.Header.Title>
                                                                        Require Ping for Attribution
                                                                    </Card.Header.Title>
                                                                    <Card.Body>
                                                                        <span className={`badge ${results["creatives"].data.dv_creative.requirePingAttribution ? "bg-success" : "bg-danger"}`}>{results["creatives"].data.dv_creative.requirePingAttribution ? "Yes" : "No"}</span>
                                                                    </Card.Body>
                                                                </Col>

                                                            </Row>
                                                        </div>
                                                    </div>

                                                </Card.Body>
                                            </Card>
                                            {results["creatives"].data.dv_creative.mediaDuration ? (<>
                                                <Card>
                                                    <Card.Header className="d-flex justify-content-between">
                                                        <div className="header-title">
                                                            <h4 className="card-title">Audio & Video Settings</h4>
                                                        </div>
                                                    </Card.Header>
                                                    <Card.Body>
                                                        <Row>
                                                            <Col lg="3" sm="12">
                                                                <Card.Header.Title>
                                                                    HTML5 Video
                                                                </Card.Header.Title>
                                                                <Card.Body>
                                                                    <span className={`badge ${results["creatives"].data.dv_creative.html5Video ? "bg-success" : "bg-danger"}`}>{results["creatives"].data.dv_creative.html5Video ? "Yes" : "No"}</span>
                                                                </Card.Body>
                                                            </Col>
                                                            <Col lg="3" sm="12">
                                                                <Card.Header.Title>
                                                                    Skippable
                                                                </Card.Header.Title>
                                                                <Card.Body>
                                                                    <span className={`badge ${results["creatives"].data.dv_creative.skippable ? "bg-success" : "bg-danger"}`}>{results["creatives"].data.dv_creative.skippable ? "Yes" : "No"}</span>
                                                                    {(results["creatives"].data.dv_creative.skippable) ? (
                                                                        <span>Skip Offset: {results["creatives"].data.dv_creative.skipOffset}</span>
                                                                    ) : (<></>)}
                                                                </Card.Body>
                                                            </Col>
                                                            <Col lg="3" sm="12">
                                                                <Card.Header.Title>
                                                                    VPAID
                                                                </Card.Header.Title>
                                                                <Card.Body>
                                                                    <span className={`badge ${results["creatives"].data.dv_creative.vpaid ? "bg-success" : "bg-danger"}`}>{results["creatives"].data.dv_creative.vpaid ? "Yes" : "No"}</span>
                                                                </Card.Body>
                                                            </Col>
                                                            <Col lg="3" sm="12">
                                                                <Card.Header.Title>
                                                                    Audio
                                                                </Card.Header.Title>
                                                                <Card.Body>
                                                                    {(results["creatives"].data.dv_creative.mp3Audio) ? (
                                                                        <span className={`badge ${results["creatives"].data.dv_creative.mp3Audio ? "bg-success" : "bg-danger"}`}>{results["creatives"].data.dv_creative.mp3Audio ? "Yes" : "No"}</span>

                                                                    ) : ((results["creatives"].data.dv_creative_oggAudio) ? (
                                                                        <span className={`badge ${results["creatives"].data.dv_creative.oggAudio ? "bg-success" : "bg-danger"}`}>{results["creatives"].data.dv_creative.oggAudio ? "Yes" : "No"}</span>

                                                                    ) : (
                                                                        <Badge bg="secondary" className="badges">{"Not Applicable"}</Badge>
                                                                    ))}
                                                                </Card.Body>
                                                            </Col>
                                                        </Row>
                                                        <Col lg="6" sm="12">
                                                            <Card.Header.Title>
                                                                VAST Tag
                                                            </Card.Header.Title>
                                                            <Card.Body>
                                                                {(results["creatives"].data.dv_creative.vastTagUrl !== "") ? (
                                                                    <a href={` ${results["creatives"].data.dv_creative.vastTagUrl}`} target="_blank" rel="noreferrer" >{results["creatives"].data.dv_creative.vastTagUrl}</a>
                                                                ) : (
                                                                    <Badge bg="secondary" className="badges">{"Not Applicable"}</Badge>
                                                                )}
                                                            </Card.Body>
                                                        </Col>
                                                    </Card.Body>
                                                </Card>
                                            </>
                                            ) : (<></>)}
                                        </Col>
                                    </Row>
                                </Tab.Pane >
                                <Tab.Pane eventKey="second" id="approvals">
                                    <Row>
                                        <Col lg="12" sm="12">
                                            <Card>
                                                <Card.Header className="d-flex justify-content-between">
                                                    <div className="header-title">
                                                        <h2 className="card-title">Status</h2>
                                                    </div>
                                                </Card.Header>
                                                <Card.Body>
                                                    <Row>
                                                        <Col lg="6" sm="12">
                                                            <Card.Header.Title>
                                                                Approval Status
                                                            </Card.Header.Title>
                                                            <Card.Body>
                                                                <span>{TrimString(results["creatives"].data.dv_creative.reviewStatus.approvalStatus, 16)}</span>
                                                            </Card.Body>
                                                        </Col>
                                                        <Col lg="6" sm="12">
                                                            <Card.Header.Title>
                                                                Creative and Landing Page Review
                                                            </Card.Header.Title>
                                                            <Card.Body>
                                                                <span>{TrimString(results["creatives"].data.dv_creative.reviewStatus.creativeAndLandingPageReviewStatus, 14)}</span>
                                                            </Card.Body>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Card.Header.Title>Approval By Exchange</Card.Header.Title>
                                                        <Card.Body>
                                                            <Col lg="12" sm="12">
                                                                <ReactTable item={reviews} col={columnsExchange} page_size={20} row_size={20} />
                                                            </Col>
                                                        </Card.Body>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>

                                </Tab.Pane>
                                {/* <Tab.Pane eventKey="third" id="lines">
                                    <Row>
                                        <Col lg="12" sm="12">
                                            <Card>
                                                <Card.Header className="d-flex justify-content-between">
                                                    <div className="header-title">
                                                        <h2 className="card-title">Attached Groups</h2>
                                                    </div>
                                                </Card.Header>
                                                <Card.Body>
                                                    <ReactTable item={lines} col={columnsGroup} page_size={20} row_size={20} />
                                                </Card.Body>
                                            </Card>
                                            </Col>
                                    </Row>

                                </Tab.Pane> */}
                            </Tab.Content>
                        </Col>

                    </Row>
                </Tab.Container >

            </>
        );
    }
    return <><div><span><Loader></Loader></span></div></>;
}
export default (Creative)
