import { React } from 'react'
import { Row, Col } from 'react-bootstrap'
import Card from '../../../components/Card'
import Loader from '../../../components/Loader'
import { FetchFromAPI } from '../../../components/ajx'
import { useParams } from 'react-router-dom'

import ReactTable from '../../../components/table'
import { columnsSites } from '../../../components/gridColumns'
import { useAppContext } from '../../../context/appContext'


export default function Property() {
   const appValue = useAppContext();
   const pageSizes = [10, 25, 50, 100];


   var REACT_APP_API_URL = appValue.apiUrl;

   const { NODE_ENV } = process.env;
   const { pid } = useParams()
   const propertyAPI = {
      urls: [
         {
            name: "property",
            url: REACT_APP_API_URL + "/property/" + pid
         }
      ],
      method: "GET",

   }
   const { results, errors, complete } = FetchFromAPI(propertyAPI)
   if (complete) {

      if (NODE_ENV == "development") {
         console.log(results);
      }
      let properties = { data: results["property"].data.dv_properties }
      for (let i = 0; i < properties.data.length; i++) {
         properties.data[i]._id = i
      }
      // console.log(data)
      return errors.length > 1 ? (<><span>Error: {errors}</span></>) : (
         <>
            <div>
               <Row>
                  <Col sm="12">
                     <Card>
                        <Card.Header className="d-flex justify-content-between">
                           <div className="header-title">
                              <h4 className="card-title">Property | {results["property"].data.name}</h4>
                           </div>
                           {/* <div>
                              <Button className="text-center btn-dark btn-icon mt-lg-0 mt-md-0 mt-3" variant="dark" >
                                 <i className="btn-inner">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                       <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                    </svg>
                                 </i>
                                 <span>New Group</span>
                              </Button>
                           </div> */}
                        </Card.Header>
                        <Card.Body className="px-0">
                           <div className="table-responsive">
                              <ReactTable item={properties} col={columnsSites} page_size={20} row_size={20} />
                           </div>
                        </Card.Body>
                     </Card>
                  </Col>
               </Row>
            </div>
         </>
      )
   }
   return <><div><span><Loader></Loader></span></div></>;

}
