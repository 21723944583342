import Select from 'react-select'
import axios from 'axios'
import { React, useEffect, useState } from 'react'
import { Row, Col, Button, Form, InputGroup } from 'react-bootstrap'
import Card from '../components/Card'
import { useAppContext } from '../context/appContext'


const Optimizations = (props) => {
    const appValue = useAppContext();
    const data = props.data
    const optimizationData = props.optimization
   
    let parentId = props.parent
    let entityId = props.entity
    let platform = props.platform

    if (data.hasOwnProperty('dv_line_id')) {
        parentId = data.dv_advertiser_id
        entityId = data.dv_line_id
        platform = "dv"
    } else if (data.hasOwnProperty('trade_desk_ad_group_id')) {
        parentId = data.trade_desk_advertiser_id
        entityId = data.trade_desk_ad_group_id
        platform = "ttd"
    }


    let optimizationOptions = [
        { value: "standard", label: "Standard (Click + Conversion)" },
        { value: "mer", label: "MER Algo" },
        { value: "click", label: "Click Focused" },
        { value: "conversion", label: "Conversion Focused" },
    ]
    let frequency = [
        { value: 1, label: "1 Day" },
        { value: 2, label: "2 Days" },
        { value: 3, label: "3 Days" },
        { value: 4, label: "4 Days" },
        { value: 5, label: "5 Days" },
        { value: 6, label: "6 Days" },
        { value: 7, label: "7 Days" },
    ]    
    let lookback = [
        { value: 1, label: "1 Day" },
        { value: 2, label: "2 Days" },
        { value: 3, label: "3 Days" },
        { value: 4, label: "4 Days" },
        { value: 5, label: "5 Days" },
        { value: 6, label: "6 Days" },
        { value: 7, label: "7 Days" },
        { value: 14, label: "14 Days" },
        { value: 30, label: "30 Days" },
    ]    
    // console.log(props)
    const dimensionOriginal = [
        { value: 'url', label: "Site or App" },
        { value: 'browser', label: "Browser" },
        { value: 'os', label: "Operating System" },
        { value: 'supply', label: "Supply Partner" },
        { value: 'device_type', label: "Device Type" },
        { value: 'device_make', label: "Device Make & Model" },
        { value: 'zip_code', label: "Postal Code" },
    ]
    let dimensionCopy = JSON.parse(JSON.stringify(dimensionOriginal))
    const [wasSubmitted, setWasSubmitted] = useState(false)
    const [validated,setValidated] = useState(false)
    const [dimensions, setDimensions] = useState([...dimensionCopy])
    const [optimizationArray, setOptimizationArray] = useState([])    
    const [initialRun, setInitialRun] = useState(true)

    var REACT_APP_API_URL = appValue.apiUrl;
    const { NODE_ENV } = process.env;
    // const resetDimensions = () => {
    //     console.log("Dimensions OG: ",dimensionOriginal)
    //     setDimensions([...dimensionOriginal])
    // }

    useEffect(() => {
        // resetDimensions() doesnot work
            optimizationArray.map(opt => {
            let newDimensions = dimensions.filter(dimension => dimension.value != opt.dimension)
            setDimensions([...newDimensions])
        })  
    }, [optimizationArray])


    const handleSubmit = (event,jsid) => {
        const form = event.currentTarget
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation()
            setValidated(true)
            form.reportValidity()
            // console.log("FAILED", form.reportValidity(), form.validationMessage)
            // var list = form.querySelectorAll(':invalid');
            // for (var item of list) {
            //     console.log(item)
            // }
            return false
        } else {  
            let url;          
            event.preventDefault();
            setValidated(false)
            switch(jsid) {
                case "delete":
                    url = REACT_APP_API_URL + '/optimization/delete'
                    break;
                case "upsert":
                    url = REACT_APP_API_URL + '/optimization/update'                
                    break;
                default:
                    url = REACT_APP_API_URL + '/optimization/update'
                    handleChange(jsid,"inactive",true)                    
            }
            return axios.patch(url, optimizationArray, appValue.config).then((response) => {
                if (response.data) {
                    if (NODE_ENV == 'development') {
                        console.log(response.data);
                    }
                    setWasSubmitted(true)
                } else {
                    <p>{response.error}</p>
                }
            })
        }
    }
    const handleDelete = (index,e) => {
        let newArray = optimizationArray.filter((opt, i) => opt.jsid !== index)
        if(newArray.length < 1 && wasSubmitted) {
            handleSubmit(e,"delete")
        }else if(newArray.length >= 1 && wasSubmitted) {
            handleSubmit(e,"upsert")
        }          
        setOptimizationArray(newArray)
    }
    const handleChange = ((x, name, value) => {
        let lastRun = new Date().toISOString().split('T')[0]        
        const nextOptimizationArray = optimizationArray.map((opt, i) => {

            if (x != opt.jsid) {
                return opt
            } else if (name == "delete") {
                return "deleted"
            } else {

                return {
                    ...opt,
                    [name]: value,
                    lastrun: String(lastRun),
                    parentId: String(parentId),
                    entityId: String(entityId),
                    entitySystem: platform,
                }
            }
        })
        setOptimizationArray(nextOptimizationArray.filter((v) => v != "deleted"))

    })

    if (initialRun) {
        setInitialRun(false)
        if (optimizationData.length >= 1) {            
            //If Developer Mode enable debug logging
            if (NODE_ENV === "development") {
                console.log(optimizationData)
            }
            //Loop through the optimization list if it is longer than 1   
            let DefaultValues = []
            for (let i = 0; i < optimizationData.length; i++) {
                let r = (Math.random() + 1).toString(36).substring(7);
                console.log(i, optimizationData[i])
                DefaultValues.push({                                
                    optimizationId: optimizationData[i].optimizationId,
                    entityId: String(optimizationData[i].entityId),
                    entitySystem: optimizationData[i].entitySystem,
                    parentId: String(optimizationData[i].parentId),
                    frequency: optimizationData[i].frequency,
                    lookback: optimizationData[i].lookback,
                    dimension: optimizationData[i].dimension,
                    inactive: true,
                    jsid:r
                })
            }            
            setOptimizationArray(DefaultValues)
        }
    }

    return (
        <div>
            <Card>
                <Card.Header className="d-flex justify-content-between">
                    <div className="header-title">
                        <h2 className="card-title mb-4">Optimizations</h2>
                    </div>

                    <div>
                        <Button className="text-center btn-dark btn-icon mt-lg-0 mt-md-0 mt-3" variant="dark" onClick={() => {
                            let r = (Math.random() + 1).toString(36).substring(7);
                            let newValue =  {
                                optimizationId: "",
                                entityId: "",
                                entitySystem: "",
                                parentId: "",
                                frequency: "",
                                lookback: "",
                                dimension: "",
                                inactive: true,
                                jsid: r,
                            }
                            setOptimizationArray([...optimizationArray, newValue])                   
                        }}>
                            <span>Add New</span>
                        </Button>
                    </div>
                </Card.Header>
            </Card>
            {(optimizationArray.map((opt, i) => {
                console.log(optimizationArray)
                return (
                    <Card key={opt.jsid}>
                        <Card.Body>
                            {/* <Form onSubmit={(event) => handleSubmit(event)}> */}
                            <Form noValidate validated={validated} onSubmit={(e) => {
                                
                                handleSubmit(e,opt.jsid)
                                }} > 
                                <Row >
                                    <Col lg="3">
                                        <Form.Group className="mb-3">
                                            <Form.Label>Choose Optimization</Form.Label>
                                            <Select
                                                name="optimizationId"
                                                defaultValue={() => {                                                   
                                                    for (let i = 0; i < optimizationOptions.length; i++) {
                                                        if (optimizationOptions[i].value === opt.optimizationId) {
                                                            return optimizationOptions[i]
                                                        }
                                                    }
                                                }}
                                                options={optimizationOptions}
                                                isDisabled={opt.inactive}
                                                onChange={(option) => {
                                                    handleChange(opt.jsid, "optimizationId", option.value)
                                                }}
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">Please select optimization</Form.Control.Feedback>

                                        </Form.Group>
                                    </Col>
                                    <Col lg="3">
                                        <Form.Group className="mb-3">
                                            <Form.Label>Choose Dimension</Form.Label>
                                            <Select
                                                name="dimension"
                                                defaultValue={() => {
                                                    for (let i = 0; i < dimensions.length; i++) {
                                                        if (dimensions[i].value === opt.dimension) {
                                                            return dimensions[i]
                                                        }
                                                    }
                                                }}
                                                options={dimensions}
                                                isDisabled={opt.inactive}
                                                onChange={(option) => {
                                                    handleChange(opt.jsid, "dimension", option.value)
                                                }}
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">Please select dimension</Form.Control.Feedback>

                                        </Form.Group>
                                    </Col>
                                    <Col lg="2">
                                        <Form.Group className="mb-3">
                                            <Form.Label>Set Lookback Period</Form.Label>
                                            <Select
                                                name="lookback"
                                                defaultValue={() => {
                                                    for (let i = 0; i < lookback.length; i++) {
                                                        if (lookback[i].value === opt.lookback) {
                                                            return lookback[i]
                                                        }
                                                    }
                                                }}
                                                options={lookback}
                                                isDisabled={opt.inactive}
                                                onChange={(option) => {
                                                    handleChange(opt.jsid, "lookback", option.value)
                                                }}
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">Please set lookback</Form.Control.Feedback>

                                        </Form.Group>
                                    </Col>
                                    <Col lg="2">
                                        <Form.Group className="mb-3">
                                            <Form.Label>Set Run Frequency</Form.Label>
                                            <Select
                                                name="frequency"
                                                defaultValue={() => {
                                                    for (let i = 0; i < frequency.length; i++) {
                                                        if (frequency[i].value === opt.frequency) {
                                                            return frequency[i]
                                                        }
                                                    }
                                                }}
                                                isDisabled={opt.inactive}
                                                options={frequency}
                                                onChange={(option) => {
                                                    handleChange(opt.jsid, "frequency", option.value)
                                                }}
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">Please set frequency</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col lg="2">
                                        {(opt.inactive) ? (
                                            <Button id="editButton" className="text-center btn-danger btn-icon margin-top" variant="primary" onClick={(e) => {
                                                e.preventDefault()
                                                handleChange(opt.jsid, "inactive", false)
                                            }}>Edit</Button>
                                        ) : (
                                            <Button id="submitButton" type="submit" className="text-center btn-danger btn-icon margin-top" variant="dark" >Save</Button>

                                        )}

                                        <Button className="text-center btn-danger btn-icon margin-top" variant="danger" onClick={(e) => {
                                            e.preventDefault()
                                            handleDelete(opt.jsid,e)
                     
                                        }}>Remove</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                )
            }))}
        </div>
    )

}
export default Optimizations
