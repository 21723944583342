import { React, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import Card from '../../../components/Card'
import Loader from '../../../components/Loader'
import { FetchFromAPI } from '../../../components/ajx'

import ReactTable from '../../../components/table'
import { property_group_info } from '../../../util/property-group-input'
import { columnsProperty } from '../../../components/gridColumns'
import { PropertyModal } from '../../../components/Modals'
import { ModalButton } from '../../../components/ModalSettings'
import { useAppContext } from '../../../context/appContext'


export default function PropertyList() {
   const appValue = useAppContext();
   const pageSizes = [10, 25, 50, 100];
   const [modal, setModal] = useState(false);
   const [propertyGroup, setPropertyGroup] = useState({
      group_name: '',
      sites: [''],
      url_or_app_id: '',
      err: false
   });

   var REACT_APP_API_URL = appValue.apiUrl;

   const propertyAPI = {
      urls: [
         {
            name: "property",
            url: REACT_APP_API_URL + "/property/list"
         }
      ],
      method: "GET",

   }

   const { results, errors, complete } = FetchFromAPI(propertyAPI)

   const { NODE_ENV } = process.env;


   if (complete) {
      if (NODE_ENV == "development") {
         console.log(results);
      }
      // console.log(data)
      return errors.length > 1 ? (<><span>Error: {errors}</span></>) : (
         <>
            <div>
               <Row>
                  <Col sm="12">
                     <Card>
                        <Card.Header className="d-flex justify-content-between">
                           <div className="header-title">
                              <h4 className="card-title">Property List</h4>
                           </div>
                           <div>
                              <ModalButton name="New Property Group" setModal={setModal} />
                           </div>
                        </Card.Header>
                        <Card.Body className="px-0">
                           <div className="table-responsive">
                              <ReactTable item={results["property"]} col={columnsProperty} page_size={20} row_size={20} />
                           </div>
                        </Card.Body>
                     </Card>
                  </Col>
               </Row>
            </div>
            <PropertyModal propertyGroup={propertyGroup} setPropertyGroup={setPropertyGroup} data={property_group_info} modal={modal} setModal={setModal} baseUrl={REACT_APP_API_URL} />

         </>
      )
   }
   return <><div><span><Loader></Loader></span></div></>;

}
