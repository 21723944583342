//This is your Campaign Form Builder for DV360.

//label: The UI Label shown to user
//value: The value of the element
//name: the name of the json field to update in the relevant model
//input: type of component
//options: the options for the dropdown or combobox
//required: whether the element is required
//multi: whether the dropdown or combo is multselect 
//feedback: this is the error message to show if it fails validation
//icon: for Input groups
//size: width of the element bootstrap width
//addOnName: for combo input to update model
//if/else These fields are for making a dropdown dynamic based on the input of another field.

const general_info = [
    {
        label: 'Advertiser ID',
        input: 'dropdown',
        options: [
        ],
        name: "advertiser_id",
        size: "3"
    },
    {
        label: "Campaign Name",
        input: 'input-text',
        name: "name",
        required: true,
        feedback: "Campaign name is required",
        size: "6",
    },
    {
        label: "Status",
        input: 'dropdown',
        options: [
            {
                label: 'Active',
                value: 'ENTITY_STATUS_ACTIVE'
            },
            {
                label: 'Paused',
                value: 'ENTITY_STATUS_PAUSED'
            }
        ],
        mutli: false,
        required: true,
        feedback: "Status is a required field",
        size: "3",
        name: "status",
    }
]
// Campaign Goals
const performance_goal = [
    {
        label: "Campaign Goal",
        input: "dropdown",
        options: [
            { label: 'Brand Awareness', value: 'CAMPAIGN_GOAL_TYPE_BRAND_AWARENESS' },
            { label: 'Online Action', value: 'CAMPAIGN_GOAL_TYPE_ONLINE_ACTION' },
            { label: 'Offline Action', value: 'CAMPAIGN_GOAL_TYPE_OFFLINE_ACTION' },
            { label: 'App Install', value: 'CAMPAIGN_GOAL_TYPE_APP_INSTALL' },
        ],
        name: "performance_goal.type",
        size: "4",
        feedback: "Please select a valid goal",
        required: true,
    },
    {
        label: 'Amount',
        name: 'performance_goal.amount',
        input: 'input-combo',
        size: "4",
        required: true,
        feedback: "Please enter a goal amount",
        options: ["$", "%"],
        addOnName: "performance_goal.metric",
    },
    {
        label: "Performance Goal",
        input: "dropdown",
        if: {
            condition: 'performance_goal.metric',
            value: "$",
            options: [
                { label: 'CPM', value: 'PERFORMANCE_GOAL_TYPE_CPM' },
                { label: 'CPC', value: 'PERFORMANCE_GOAL_TYPE_CPC' },
                { label: 'CPA', value: 'PERFORMANCE_GOAL_TYPE_CPA' },
                { label: 'CPIAVC', value: 'PERFORMANCE_GOAL_TYPE_CPIAVC' },
                { label: 'VCPM', value: 'PERFORMANCE_GOAL_TYPE_VCPM' },
            ],
        },
        else: {
            options: [
                { label: 'CTR', value: 'PERFORMANCE_GOAL_TYPE_CTR' },
                { label: 'Viewability', value: 'PERFORMANCE_GOAL_TYPE_VIEWABILITY' },
                { label: 'CCVR', value: 'PERFORMANCE_GOAL_TYPE_CLICK_CVR' },
                { label: 'ICVR', value: 'PERFORMANCE_GOAL_TYPE_IMPRESSION_CVR' },
                { label: 'VTR', value: 'PERFORMANCE_GOAL_TYPE_VTR' },
                { label: 'Audio Completion Rate', value: 'PERFORMANCE_GOAL_TYPE_AUDIO_COMPLETION_RATE' },
                { label: 'Video Completion Rate', value: 'PERFORMANCE_GOAL_TYPE_VIDEO_COMPLETION_RATE' }
            ],
        },
        name: "performance_goal.perf_type",
        size: "4",
    }
]

const campaign_budget = [
    {
        label: "Name",
        input: 'input-text',
        name: 'campaign_budget.name',
        size: '12',
        required: true,
        feedback: "Please enter a budget name",
    },
    {
        label: "Start Date",
        input: "date",
        name: "campaign_budget.start_date",
        size: "4"
    },
    {
        label: "End Date",
        input: "date",
        name: "campaign_budget.end_date",
        size: "4"
    },
    {
        label: 'Amount',
        name: 'campaign_budget.amount',
        input: 'input-combo',
        size: "4",
        required: true,
        feedback: "Please enter a budget amount",
        options: ["$", "%"],
        addOnName: "campaign_budget.unit",
    }

]

const frequency_cap = [
    {
        label: "Unlimited",
        input: "toggle",
        name: "frequency_cap.unlimited",
        size: "12"
    },
    {
        label: "Total Allowed Impressions",
        input: "input-number",
        name: "frequency_cap.max_impressions",
        size: "4",
        dependent: "frequency_cap.unlimited",
        condition: false,
    },
    {
        label: "Duration Count",
        input: "input-number",
        name: "frequency_cap.count",
        size: "4",
        dependent: "frequency_cap.unlimited",
        condition: false,
    },
    {
        label: 'Duration Unit',
        input: 'dropdown',
        options: [
            { label: "Lifetime", value: "TIME_UNIT_LIFETIME" },
            { label: "Months", value: "TIME_UNIT_MONTHS" },
            { label: "Weeks", value: "TIME_UNIT_WEEKS" },
            { label: "Days", value: "TIME_UNIT_DAYS" },
            { label: "Hours", value: "TIME_UNIT_HOURS" },
            { label: "Minutes", value: "TIME_UNIT_MINUTES" },
        ],
        name: 'frequency_cap.timeunit',
        dependent: "frequency_cap.unlimited",
        condition: false,
        size: "4",
    },
   
]
const CampaignForm = {
    titles: ["General", "Goals", "Budget", "Frequency Cap"],
    fields: [
        general_info,
        performance_goal,
        campaign_budget,
        frequency_cap
    ]
}

export default CampaignForm