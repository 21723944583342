import SignIn from './sign-in'
import Cookies from 'universal-cookie';

const { NODE_ENV } = process.env;

const SignOut = () => {
   const cookies = new Cookies();
   if(NODE_ENV == 'development') {
     console.log(document.cookie);
   }
   document.cookie = "aplus-token=;max-age=0; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
   document.cookie = "aplus-user=;max-age=0; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
   document.cookie = "aplus-token=;max-age=0; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/auth;";
   document.cookie = "aplus-user=;max-age=0; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/auth;";
  //  cookies.remove('aplus-token',{path:'/', domain:"localhost"})
  //  cookies.remove('aplus-user',{path:'/', domain:"localhost"})

   if(NODE_ENV == 'development') {
    console.log("All Tokens Cleared: "+cookies.get("aplus-token")+cookies.get("aplus-user"));
   }
    return (
      <SignIn/>
    )
}

export default SignOut;
