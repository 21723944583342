import { React, useEffect, useState } from 'react'
import { Row, Col, Tab, Nav } from 'react-bootstrap'
import Card from '../../../components/Card'
import Loader from '../../../components/Loader'
import { useParams } from 'react-router-dom'
import { FetchFromAPI } from '../../../components/ajx'
import Dash from '../../../components/dash';

// AOS
import AOS from 'aos'
import '../../../../node_modules/aos/dist/aos'
import '../../../../node_modules/aos/dist/aos.css'
import Cookies from 'universal-cookie';
import Automizer from '../../../components/automizer'
import {DateButtons} from '../../../components/dateButtons'
import { MetaGroupModel } from '../../../components/Models'
import { MetaGroupForm } from '../../../util/meta-group-input'
import Crumbs from '../../../components/crumbs'
import { DefaultValue, MultiDefault } from '../../../components/selectDefaults'
import InputSettings from '../../../components/InputSettings'
import { useAppContext } from '../../../context/appContext'



const MetaGroup = (props) => {
    const appValue = useAppContext();
    const [group, setGroup] = useState(MetaGroupModel)
    const [initialLoad, setInitialLoad] = useState(true)

    useEffect(() => {

        AOS.init({
            startEvent: 'DOMContentLoaded',
            disable: function () {
                var maxWidth = 996;
                return window.innerWidth < maxWidth;
            },
            throttleDelay: 10,
            once: true,
            duration: 700,
            offset: 10
        });

    })
    const date = appValue.date;
    const cleanUrl = appValue.cleanUrl;

    const { gid } = useParams()

    const cookies = new Cookies();
    const user = cookies.get('aplus-user');

    var REACT_APP_API_URL = appValue.apiUrl;
    let url = REACT_APP_API_URL + "/meta/group/update/" + gid;

    const groupAPI = {
        urls: [
            {
                name: "group",
                url: REACT_APP_API_URL + "/group/" + gid + "/" + "Meta"
            },
            {
                name: "properties",
                url: REACT_APP_API_URL + "/property/list"
            },
            {
                name: "campaigns",
                url: REACT_APP_API_URL + "/campaign/list"
            },
            {
                name: "clients",
                url: REACT_APP_API_URL + "/client/list"
            },
            {
                name: "groups",
                url: REACT_APP_API_URL + "/group/list"
            },
            {
                name: "creatives",
                url: REACT_APP_API_URL + "/creative/list"
            },
            {
                name: "dash",
                url: REACT_APP_API_URL + "/reports/dash/group/" + user.apid + "/" + gid + "/" + "Meta" + "?date=" + date
            },
            {
                name: "account",
                url: REACT_APP_API_URL + "/admin/get/account/" + user.apid
            },
            {
                name: "crumbs",
                url: REACT_APP_API_URL + "/util/breadcrumb/groups/" + gid
            },
            {
                name: "orders",
                url: REACT_APP_API_URL + "/order/list"
            }
        ],
        method: "GET",

    }
    const { NODE_ENV } = process.env;

    const { results, errors, complete } = FetchFromAPI(groupAPI)


    if (complete) {
        if (initialLoad) {
            setInitialLoad(false)
            const data = results['group'].data.meta_ad;
            group.id = data.id;
            group.name = data.name;
            group.status = data.status;
            group.bid_amount = data.bid_amount;
            group.adlabels = data.adlabels;
            group.campaign_id = data.campaign_id;
            group.conversion_domain = data.conversion_domain;
            group.creative.id = data.creative.id;
            group.meta_reward_ad_group_status = data.meta_reward_ad_group_status;
            group.source_ad_id = data.source_ad_id;
            group.adset_id = data.adset_id;
        }

        if (complete) {
            if (results["campaigns"]) {
                for (let i = 0; i < results["campaigns"].data.length; i++) {
                    const c = results["campaigns"].data[i];
                    const camp_label = `${c.name} | ${c.platform_id}`;
                    if (c.platform === "Meta") {
                        MetaGroupForm.fields[0][5].options.push({ label: camp_label, value: c.platform_id, name: "campaign_id" })
                        MetaGroupForm.fields[0][5].value = DefaultValue(group.campaign_id, MetaGroupForm.fields[0][5].options);
                    }
                }
            }
            if (results["clients"]) {
                for (let i = 0; i < results["clients"].data.length; i++) {
                    const c = results["clients"].data[i];
                    if (c.platform === "Meta") {
                        if (c.ad_labels && c.ad_labels.length > 0) {
                            for (let i = 0; i < c.ad_labels.length; i++) {
                                const a = c.ad_labels[i]
                                const ad_label = `${a.name} | ${a.id}`
                                MetaGroupForm.fields[0][3].options.push({ label: ad_label, value: a.id, name: "adlabels" })
                                if (group.adlabels) {
                                    MetaGroupForm.fields[0][3].value = MultiDefault(group.adlabels, MetaGroupForm.fields[0][3].options);
                                }
                            }
                        }
                    }
                }
            }
            if (results["groups"]) {
                for (let i = 0; i < results["groups"].data.length; i++) {
                    const g = results["groups"].data[i];
                    if (g.platform === "Meta") {
                        const ad_label = `${g.name} | ${g.platform_id}`;
                        MetaGroupForm.fields[0][8].options.push({ label: ad_label, value: g.platform_id, name: "source_ad_id" })
                        MetaGroupForm.fields[0][8].value = DefaultValue(group.source_ad_id, MetaGroupForm.fields[0][8].options);
                    }
                }
            }
            if (results["creatives"] && results["creatives"].data) {
                for (let i = 0; i < results["creatives"].data.length; i++) {
                    const c = results["creatives"].data[i]
                    const creative_label = `${c.name} | ${c.platform_id}`
                    if (c.platform === 'Meta') {
                        MetaGroupForm.fields[0][6].options.push({ label: creative_label, value: c.platform_id.toString(), name: 'creative_id' });
                        MetaGroupForm.fields[0][6].value = DefaultValue(group.creative.id, MetaGroupForm.fields[0][6].options);
                    }
                }
            }
            if (results["orders"]) {
                for (let i = 0; i < results["orders"].data.length; i++) {
                    const o = results["orders"].data[i];
                    if (o.platform === "Meta") {
                        const o_label = `${o.name} | ${o.platform_id}`;
                        MetaGroupForm.fields[0][4].options.push({ label: o_label, value: o.platform_id, name: "adset_id" })
                        MetaGroupForm.fields[0][4].value = DefaultValue(group.adset_id, MetaGroupForm.fields[0][4].options);
                    }
                }
            }
        }

        if (NODE_ENV === "development") {
            console.log(results)
        }

        return errors.length > 0 ? (<><span>Error: {errors}</span></>) : (
            <><Tab.Container defaultActiveKey="first">
                <Row>
                    <Col lg="12">
                        <Card>
                            <Card.Body>

                                <Crumbs data={results["crumbs"].data}  platform="meta" type="group" />

                                <div className="d-flex flex-wrap align-items-center justify-content-between">
                                    <div className="d-flex flex-wrap align-items-center">
                                        <div className="d-flex flex-wrap align-items-center mb-3 mb-sm-0">
                                            <h4 className="me-2 h4">{results["group"].data.meta_ad.name}</h4>
                                            <span> - Group</span>
                                        </div>
                                    </div>
                                    <Nav as="ul" className="d-flex nav-pills mb-0 text-center profile-tab" data-toggle="slider-tab" id="profile-pills-tab" role="tablist">
                                        <Nav.Item as="li">
                                            <Nav.Link eventKey="first" className="button">Dash</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                            <Nav.Link eventKey="second" className="button">Settings</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                            <Nav.Link eventKey="third" className="button">Automizer</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg="6">
                        <Card>
                            <Card.Header>
                                <div className="header-title">
                                    <h4 className="card-title">Group Info</h4>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <div className="mb-1">ID: {results["group"].data.meta_ad_id}</div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg="12">
                        <Tab.Content className="profile-content">
                            <Tab.Pane eventKey="first" id="dash">
                                <Row className='dateButtons_sub'>
                                    <Col lg={{ span: 6, offset: 6 }}>
                                        <DateButtons refreshUrl={cleanUrl} dateSelect={date} />
                                    </Col>
                                </Row>
                                {(Object.keys(results["dash"].data.kpi).length === 0) ? (
                                    <span>No Data Available </span>
                                ) : (
                                    <Dash data={results["dash"]} data2={results["targeting"]} data3={results["properties"]} data4={results["account"]} />
                                )}
                            </Tab.Pane >
                            <Tab.Pane eventKey="third" id="automizer">

                                <Automizer data={results["group"]} cur={results["account"].data.Currency} label="line" />
                            </Tab.Pane>
                            <Tab.Pane eventKey="second" id="settings">
                                <Row>
                                    <Col lg="12">
                                        <InputSettings
                                            title="Add New Group"
                                            mode="edit"
                                            form={MetaGroupForm}
                                            url={url}
                                            formId={"groupForm"}
                                            model={[group, setGroup]}

                                        />
                                    </Col>
                                </Row>
                            </Tab.Pane >
                        </Tab.Content>
                    </Col>

                </Row>
            </Tab.Container>
            </>
        );
    }
    return <><div><span><Loader></Loader></span></div></>;
}
export default (MetaGroup)