const PerformanceGoal = [
    { label: 'App Install', value: 'CAMPAIGN_GOAL_TYPE_APP_INSTALL' },
    { label: 'Brand Awareness', value: 'CAMPAIGN_GOAL_TYPE_BRAND_AWARENESS' },
    { label: 'Offline Action', value: 'CAMPAIGN_GOAL_TYPE_OFFLINE_ACTION' },
    { label: 'Online Action', value: 'CAMPAIGN_GOAL_TYPE_ONLINE_ACTION' },
]

const Status = [
    {
        label: 'Active',
        value: 'ENTITY_STATUS_ACTIVE'
    },
    {
        label: 'Paused',
        value: 'ENTITY_STATUS_PAUSED'
    },
]

const OrderStatus = [
    {
        label: 'Active',
        value: 'ENTITY_STATUS_ACTIVE'
    },
    {
        label: 'Paused',
        value: 'ENTITY_STATUS_DRAFT'
    },
]
const PacingPeriod = [
    { name: 'pacing_period', label: '', value: '' },
    { name: 'pacing_period', label: 'Daily', value: `PACING_PERIOD_DAILY` },
    { name: 'pacing_period', label: 'Entire Flight', value: `PACING_PERIOD_FLIGHT` }
]
const PacingType = [
    { name: 'pacing_type', label: '', value: '' },
    { name: 'pacing_type', label: 'ASAP', value: `PACING_TYPE_ASAP` },
    { name: 'pacing_type', label: 'Ahead', value: `PACING_TYPE_AHEAD`},
    { name: 'pacing_type', label: 'Even', value: `PACING_TYPE_EVEN` }
]
const PerformanceGoalType = [
    { label: 'CPM', value: 'PERFORMANCE_GOAL_TYPE_CPM' },
    { label: 'CPC', value: 'PERFORMANCE_GOAL_TYPE_CPC' },
    { label: 'CPA', value: 'PERFORMANCE_GOAL_TYPE_CPA' },
    { label: 'CPIAVC', value: 'PERFORMANCE_GOAL_TYPE_CPIAVC' },
    { label: 'VCPM', value: 'PERFORMANCE_GOAL_TYPE_VCPM' },
    { label: 'CTR', value: 'PERFORMANCE_GOAL_TYPE_CTR' },
    { label: 'Viewability', value: 'PERFORMANCE_GOAL_TYPE_VIEWABILITY' },
    { label: 'CCVR', value: 'PERFORMANCE_GOAL_TYPE_CLICK_CVR' },
    { label: 'ICVR', value: 'PERFORMANCE_GOAL_TYPE_IMPRESSION_CVR' },
    { label: 'VTR', value: 'PERFORMANCE_GOAL_TYPE_VTR' },
    { label: 'Audio Completion Rate', value: 'PERFORMANCE_GOAL_TYPE_AUDIO_COMPLETION_RATE' },
    { label: 'Video Completion Rate', value: 'PERFORMANCE_GOAL_TYPE_VIDEO_COMPLETION_RATE' }
]
const FrequencyCap = [
    {
        label: 'Unlimited',
        value: 'Unlimited'
    },
    {
        label: 'Capped',
        value: 'Capped'
    }
]

const PartnerRevenueModel = [
    {label: "CPM", value: 'PARTNER_REVENUE_MODEL_MARKUP_TYPE_CPM'},
    {label: 'Meda Cost', value: 'PARTNER_REVENUE_MODEL_MARKUP_TYPE_TOTAL_MEDIA_COST_MARKUP'}
]

const TimeUnit = [
    { label: '', value: '' },
    { label: "Lifetime", value: "TIME_UNIT_LIFETIME" },
    { label: "Months", value: "TIME_UNIT_MONTHS" },
    { label: "Weeks", value: "TIME_UNIT_WEEKS" },
    { label: "Days", value: "TIME_UNIT_DAYS" },
    { label: "Hours", value: "TIME_UNIT_HOURS" },
    { label: "Minutes", value: "TIME_UNIT_MINUTES" },
]
const BiddingStrategy = [
    { label: '', value: '', name: 'bidding_strategy' },
    { label: 'Fixed Bid', value: 'Fixed', name: 'bidding_strategy' },
    { label: 'Auto Bid', value: 'Auto', name: 'bidding_strategy' }
]
const BiddingStrategyLine = [
    { label: '', value: '', name: 'bidding_strategy' },
    { label: 'Fixed Bid', value: 'Fixed', name: 'bidding_strategy' },
    { label: 'Auto Bid', value: 'Auto', name: 'bidding_strategy' },
    { label: 'Performance Bid', value: 'Performance', name: 'bidding_strategy' }

]
const AutoBid = [
    { label: '', value: '' },
{ label: 'CPA', value: `BIDDING_STRATEGY_PERFORMANCE_GOAL_TYPE_CPA`, name: 'auto_bid_performance_goal_type' },
{ label: 'CPC', value: `BIDDING_STRATEGY_PERFORMANCE_GOAL_TYPE_CPC`, name: 'auto_bid_performance_goal_type' },
{ label: 'vCPM', value: `BIDDING_STRATEGY_PERFORMANCE_GOAL_TYPE_VIEWABLE_CPM`, name: 'auto_bid_performance_goal_type' },
{ label: 'Custom', value: `BIDDING_STRATEGY_PERFORMANCE_GOAL_TYPE_CUSTOM_ALGO`, name: 'auto_bid_performance_goal_type' },
{ label: 'CIVA', value: `BIDDING_STRATEGY_PERFORMANCE_GOAL_TYPE_CIVA`, name: 'auto_bid_performance_goal_type' },
{ label: 'IVO Ten', value: `BIDDING_STRATEGY_PERFORMANCE_GOAL_TYPE_IVO_TEN`, name: 'auto_bid_performance_goal_type' },
{ label: 'AV Viewed', value: `BIDDING_STRATEGY_PERFORMANCE_GOAL_TYPE_AV_VIEWED`, name: 'auto_bid_performance_goal_type' }
]
const BudgetAutomationType = [
    { name: 'budget_automation_type', label: 'Budget', value: `INSERTION_ORDER_AUTOMATION_TYPE_BUDGET` },
    { name: 'budget_automation_type', label: 'None', value: `INSERTION_ORDER_AUTOMATION_TYPE_NONE` },
    { name: 'budget_automation_type', label: 'Bid/Budget', value: `INSERTION_ORDER_AUTOMATION_TYPE_BID_BUDGET` }
]
const BudgetAllocationType = [
    { name: 'budget_allocation_type', label: 'Automatic', value: `LINE_ITEM_BUDGET_ALLOCATION_TYPE_AUTOMATIC` },
    { name: 'budget_allocation_type', label: 'Fixed', value: `LINE_ITEM_BUDGET_ALLOCATION_TYPE_FIXED` },
    { name: 'budget_allocation_type', label: 'Unlimited', value: `LINE_ITEM_BUDGET_ALLOCATION_TYPE_UNLIMITED` }
]
const InsertionOrderType = [
    { name: 'insertion_order_type', label: 'RTB', value: 'RTB' },
    { name: 'insertion_order_type', label: 'Over The Top', value: 'OVER_THE_TOP' }
]
const PacingMetric = [
    { label: "Amount ($)", value: "$", name: "pacing.daily_max_amount" },
    { label: "Impression", value: "imp", name: "pacing.daily_max_amount" }
]

const GoalMetric = [
    { label: "Amount ($)", value: "$", name: "perf_goal_metric" },
    { label: "Percentage (%)", value: "%", name: "perf_goal_metric" }
]

const BudgetUnit = [
    { label: "Amount ($)", value: "$", name: "budget_unit" },
    { label: "Impressions", value: "imp", name: "budget_unit" }
]
const Language = [
    {label: "Arabic", value: "ar"},
    {label: "Bengali", value: "bn"},
    {label: "Bulgarian", value: "bg"},
    {label: "Catalan", value: "ca"},    
    {label: "Chinese (PRC)", value: "zh_cn"},
    {label: "Chinese (Hong Kong)", value: "zh_hk"},
    {label: "Croation", value: "hr"},
    {label: "Czech", value: "cs"},
    {label: "Danish", value: "da"},
    {label: "Dutch", value: "nl"},
    {label: "English (US)", value: "en_us"},
    {label: "English (UK)", value: "en_gb"},
    {label: "English (AU)", value: "en_au"},
    {label: "Estonian", value: "et"},
    {label: "Filipino", value: "fil"},
    {label: "Finnish", value: "fi"},
    {label: "French", value: "fr"},
    {label: "German", value: "de"},
    {label: "Greek", value: "el"},
    {label: "Gujarati", value: "gu"},
    {label: "Hebrew", value: "he"},
    {label: "Hindi", value: "hi"},
    {label: "Hungarian", value: "hu"},
    {label: "Icelandic", value: "is"},
    {label: "Indonesian", value: "id"},
    {label: "Italian", value: "it"},
    {label: "Japanese", value: "ja"},
    {label: "Kannada", value: "kn"},
    {label: "Korean", value: "ko"},
    {label: "Latvian", value: "lv"},
    {label: "Lithuanian", value: "lt"},
    {label: "Malay", value: "ms"},
    {label: "Malayalam", value: "ml"},
    {label: "Marathi", value: "mr"},
    {label: "Norwegian", value: "no"},
    {label: "Persian", value: "fa"},
    {label: "Polish", value: "pl"},
    {label: "Portuguese (POR)", value: "pt"},
    {label: "Portuguese (BR)", value: "pt_br"},
    {label: "Punjabi", value: "pa"},
    {label: "Romanian", value: "ro"},
    {label: "Russian", value: "ru"},
    {label: "Serbian", value: "sr"},
    {label: "Slovak", value: "sk"},
    {label: "Slovenian", value: "sl"},
    {label: "Spanish", value: "es"},
    {label: "Spanish (ESP)", value: "es_es"},
    {label: "Swedish", value: "sv"},
    {label: "Tamil", value: "ta"},
    {label: "Telugu", value: "te"},
    {label: "Thai", value: "th"},
    {label: "Turkish", value: "tr"},
    {label: "Ukranian", value: "uk"},
    {label: "Urdu", value: "ur"},
    {label: "Vietnamese", value: "vi"},
]



export { PerformanceGoal,Language, PerformanceGoalType, Status, FrequencyCap, TimeUnit, GoalMetric, BudgetUnit, OrderStatus, PacingPeriod, PacingType, PacingMetric, BudgetAutomationType, InsertionOrderType, BiddingStrategy,AutoBid,BudgetAllocationType,BiddingStrategyLine,PartnerRevenueModel }