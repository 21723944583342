import { React, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import Card from '../../../components/Card'
import Loader from '../../../components/Loader'
import { FetchFromAPI } from '../../../components/ajx'
import { columnsCreative } from '../../../components/gridColumns'
import ReactTable from '../../../components/table'
import { ModalButton } from '../../../components/ModalSettings'
import { CreativeModal } from '../../../components/Modals'
import { useAppContext } from '../../../context/appContext'
import { CreativeRefresh } from '../../../util/creative-refresh-input'


export default function CreativeList() {
   const appValue = useAppContext();
   const pageSizes = [10, 25, 50, 100];
   const [modal, setModal] = useState(false);
   const [initialLoad, setInitialLoad] = useState(true)
   const [creativeForm, setCreativeForm] = useState(CreativeRefresh)
   const [creative,setCreative] = useState({
      aid: "",
   })
   var REACT_APP_API_URL = appValue.apiUrl;

   const creativeAPI = {
      urls: [
         {
            name: "creatives",
            url: REACT_APP_API_URL + "/creative/list/small"
         },
         {
            name: "advertisers",
            url: REACT_APP_API_URL + "/client/list"
         },

      ],
      method: "GET",

   }

   const { results, errors, complete } = FetchFromAPI(creativeAPI)
   const { NODE_ENV } = process.env;
   if (complete) {
      
      if (NODE_ENV == "development") {
         console.log(results);
      }
      if (initialLoad) {
         setInitialLoad(false)
      for (let i = 0; i < results["advertisers"].data.length; i++) {
         let adv = results["advertisers"].data[i]
         creativeForm.fields[0][0].options.push({ label: adv.name, value: adv.platform_id.toString() })
       }
      }
      // console.log(data)
      return errors.length > 0 ? (<><span>Error: {errors}</span></>) : (
         <>
            <div>
               <Row>
                  <Col sm="12">
                     <Card>
                        <Card.Header className="d-flex justify-content-between">
                           <div className="header-title">
                              <h4 className="card-title">Creative List</h4>
                           </div>
                           <div>
                              <ModalButton name="Refresh Creatives" setModal={setModal} />
                           </div>
                        </Card.Header>
                        <Card.Body className="px-0">
                           <div className="table-responsive">
                              <ReactTable item={results["creatives"]} col={columnsCreative} page_size={20} row_size={20} />
                           </div>
                        </Card.Body>
                     </Card>
                  </Col>
               </Row>
            </div>
            <div>
               {(results["advertisers"] && results["advertisers"].data) ? (
                  <CreativeModal creative={creative} setCreative={setCreative} form={creativeForm} formId="creativeForm" modal={modal} setModal={setModal} baseUrl={REACT_APP_API_URL} />
               ):(<></>)}

            </div>
         </>
      )
   }
   return <><div><span><Loader></Loader></span></div></>;

}
