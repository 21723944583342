import { Status } from './meta-options'

const meta_group = [
    {
        label: 'Name',
        input: 'input-text',
        name: 'name',
        required: true,
        size: '4'
    },
    {
        label: 'Status',
        input: 'dropdown',
        name: 'status',
        options: Status,
        required: true,
        size: '4'
    },
    {
        label: 'Bid Amount',
        input: 'input-number',
        name: 'bid_amount',
        currency: '',
        size: '4'
    },
    {
        label: 'Ad Labels',
        input: 'dropdown',
        name: 'adlabels',
        options: [
            { label: '', value: '' }
        ],
        multi: true,
        size: '4'
    },
    {
        label: 'Adset ID',
        input: 'dropdown',
        name: 'adset_id',
        options: [
            { label: '', value: '' }
        ],
        size: '4',
        required: true
    },
    {
        label: 'Campaign ID',
        input: 'dropdown',
        name: 'campaign_id',
        options: [
            { label: '', value: '' }
        ],
        size: '4'
    }, ,
    {
        label: 'Source Ad ID',
        input: 'dropdown',
        name: 'source_ad_id',
        options: [
            { label: '', value: '' }
        ],
        size: '4'
    }
]

const creative = [
    {
        label: 'ID',
        input: 'dropdown',
        name: 'creative.id',
        options: [
            { label: '', value: '' }
        ],
        size: '4',
        required: true
    },
    {
        label: 'Name',
        input: 'input-text',
        name: 'creative.name',
        size: '4',
    },
    {
        label: 'Actor ID',
        input: 'input-text',
        name: 'creative.actor_id',
        size: '4',
    },
    {
        label: 'Instagram Actor ID',
        input: 'input-text',
        name: 'creative.instagram_actor_id',
        size: '4',
    },
    {
        label: 'Call to Action Type',
        input: 'dropdown',
        name: 'creative.call_to_action_type',
        size: '4',
        options: []
    },
    {
        label: 'Destination URL',
        input: 'input-text',
        name: 'creative.object_url',
        size: '4',
    },
    {
        label: 'Display URL',
        input: 'input-text',
        name: 'creative.link_destination_display_url',
        size: '4',
    },
    {
        label: 'URL Params',
        input: 'input-text',
        name: 'creative.url_tags',
        size: '4',
    },

]

const MetaGroupForm = {
    titles: ["General", "Creative"],
    fields: [
        meta_group,
        creative
    ]
}

export { MetaGroupForm }