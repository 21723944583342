import { Button, Col, Form, Row, Modal } from "react-bootstrap";
import Card from './Card';
import ModalPopup from "./ModalPopup";
import ModalInput from "./ModalInput";
import { validateCampaign, validateOrder, validateGroup, validateProperty, validateClient, validateMetaCampaign, validateMetaGroup, validateMetaOrder } from "./validation";
import axios from "axios";
import { useState } from "react";
import Select from 'react-select'
import AsyncSelect from 'react-select/async';
import { CampaignModel, CampaignSearchModel, GroupSearchModel } from "./Models";
import InputSettings from "./InputSettings";
import { useAppContext } from "../context/appContext";

//Generic handlers for all Modals
const handleClose = (setModal, setObj, formId) => {

    //Close the Modal
    // if (setObj) {
    //     setObj(obj)
    // }
    setModal(false);
}


const HandleSubmit = (e, obj, setObj, url, entityId, setResp, redirect, valid) => {
    const appValue = useAppContext();
    // console.log("triggered")
    e.preventDefault();

    // Add API call here
    axios.post(url, obj, appValue.config)
        .then((res) => {
            if (res.data) {
                switch (res.data.message) {
                    case "Failure":
                        setResp(res)
                        break;
                    // case "Success":
                    //     if (entityId) {
                    //         window.location.href = "../" + redirect + "/" + res.data.data
                    //     } else {
                    //         window.location.href = redirect + "/" + res.data.data
                    //     }
                    // break;
                    default:
                        break;
                }
            }
        })
        .catch(function (error) {
            if (error.response) {
                console.log(error)
                let data = error.response
                setResp(data)
            }
        })
}


const CampaignSearchModal = (params) => {
    const [resp, setResp] = useState("");
    let url = params.baseUrl + "/campaign/create/search"
    return (
        <ModalPopup onClose={() => { handleClose(params.setModal, params.setCampaign) }} show={params.modal} setShow={params.setModal} title="Add New Campaign" >
            <Row>
                <Col lg="12">
                    <InputSettings
                        title='Add New Campaign'
                        mode='create'
                        form={params.form}
                        url={url}
                        name='campaign/serach'

                        model={[params.campaign, params.setCampaign]}
                    />
                </Col >
            </Row >
            {/* Input groups data found in campaign-input.js */}
        </ModalPopup >)
}

const ClientModal = (params) => {
    const [resp, setResp] = useState("");
    let url = params.baseUrl + "/client/create"

    return (
        <ModalPopup
            onClose={() => { handleClose(params.setModal, params.setClient) }}
            show={params.modal} setShow={params.setModal} title="Add New Client" response={resp}
        >
            <Row>
                <Col lg="12">
                    <InputSettings
                        title='Add New Client'
                        mode='create'
                        form={params.form}
                        name="client/DV"
                        entity={params.entityId}
                        url={url}
                        formId={params.formId}
                        model={[params.client, params.setClient]}
                    />
                </Col >
            </Row >
            {/* Input groups data found in client-input.js */}
        </ModalPopup >)
}


const CampaignModal = (params) => {
    const [resp, setResp] = useState("");
    let url = params.baseUrl + "/campaign/create"
    return (
        <ModalPopup
            onClose={() => { handleClose(params.setModal, params.setCampaign) }}
            show={params.modal} setShow={params.setModal} title="Add New Campaign" response={resp}
        >
            <Row>
                <Col lg="12">
                    <InputSettings
                        title='Add New Campaign'
                        mode='create'
                        form={params.form}
                        url={url}
                        name="campaign/DV"
                        entity={params.entityId}
                        formId={params.formId}
                        model={[params.campaign, params.setCampaign]}
                    />
                </Col >
            </Row >
            {/* Input groups data found in campaign-input.js */}
        </ModalPopup >)
}

const OrderModal = (params) => {
    // console.log(params.order)
    const [resp, setResp] = useState("");
    // let general_info = params.data.general_info
    // let pacing_info = params.data.pacing_info
    // let frequency_info = params.data.frequency_info
    // let performance_goal_info = params.data.performance_goal_info
    // let budget_info = params.data.budget_info
    // let bidding_info = params.data.bidding_info
    // let billable_info = params.data.billable_info

    let url = params.baseUrl + "/order/create"

    return (
        <ModalPopup show={params.modal} setShow={params.setModal} title='Add New Order' onClose={() => { handleClose(params.setModal, params.setOrder) }}
        >
            <Row>
                <Col lg='12'>
                    <InputSettings
                        title='Add New Order'
                        mode='create'
                        form={params.form}
                        url={url}
                        name="order/DV"
                        entity={params.entityId}
                        formId={params.formId}
                        model={[params.order, params.setOrder]}
                    />

                </Col>
            </Row>
        </ModalPopup>
    )
}

const GroupModal = (params) => {
    const [resp, setResp] = useState("");
    let url = params.baseUrl + "/group/create"

    return (
        <ModalPopup
            onClose={() => { handleClose(params.setModal, params.setGroup) }}
            show={params.modal} setShow={params.setModal} title="Add New Group" response={resp} onSubmit={(e) => {
                HandleSubmit(e, params.group, params.setGroup, url, params.entityId, setResp, "group", validateGroup)
            }}
        >
            <Row>
                <Col lg="12">
                    <InputSettings
                        title='Add New Group'
                        mode='create'
                        form={params.form}
                        url={url}
                        name="group/DV"
                        entity={params.entityId}
                        formId={params.formId}
                        model={[params.group, params.setGroup]}
                    />
                </Col >
            </Row >
            {/* Input groups data found in group-input.js */}
        </ModalPopup >)
}

const GroupSearchModal = (params) => {
    const [resp, setResp] = useState("");
    let url = params.baseUrl + "/group/create/search"

    return (
        <ModalPopup
            onClose={() => { handleClose(params.setModal, params.setGroup) }}
            show={params.modal} setShow={params.setModal} title="Add New Group" response={resp}
        >
            <Row>
                <Col lg="12">
                    <InputSettings
                        title='Add New Group'
                        mode='create'
                        form={params.form}
                        url={url}
                        name='group/search'
                        formId={params.formId}
                        model={[params.group, params.setGroup]}
                    />
                </Col >
            </Row >
            {/* Input groups data found in group-input-search.js */}
        </ModalPopup >)
}

const YTGroupModal = (params) => {
    const [resp, setResp] = useState("");
    let url = params.baseUrl + "/youtube/create"

    return (
        <ModalPopup
            onClose={() => { handleClose(params.setModal, params.setGroup) }}
            show={params.modal} setShow={params.setModal} title="Add New YouTube Group" response={resp}
        >
            <Row>
                <Col lg="12">
                    <InputSettings
                        title='Add New Group'
                        mode='create'
                        form={params.form}
                        url={url}
                        name="group/YT"
                        entity={params.entityId}
                        formId={params.formId}
                        model={[params.group, params.setGroup]}
                    />
                </Col >
            </Row >
            {/* Input groups data found in group-input.js */}
        </ModalPopup >)
}

const CreativeModal = (params) => {
    const [aid, setAid] = useState('')
    const [resp, setResp] = useState('')
    console.log(params)
    let url = params.baseUrl + "/creative/list/entity/" + params.creative.aid


    return (
        <ModalPopup show={params.modal} setShow={params.setModal} title='Refresh Creatives' onClose={() => { handleClose(params.setModal) }} response={resp}>

            <InputSettings
                title='Refresh Creatives'
                mode='create'
                form={params.form}
                url={url}
                name="creative_refresh"
                entity={params.entityId}
                formId={params.formId}
                model={[params.creative, params.setCreative]}
            />
        </ModalPopup>
    )
}
const PropertyModal = (params) => {
    let property_group_info = params.data
    const [resp, setResp] = useState("");
    const handleChange = (e, i) => {
        const { name, value } = e.target

        if (name === 'url_or_app_id') {
            params.propertyGroup.sites = value.split(',');
        }
        params.propertyGroup[name] = value

        params.setPropertyGroup({ ...params.propertyGroup });

    };
    let url = params.baseUrl + "/property/create"
    let blankProperty = {
        group_name: '',
        sites: [''],
        url_or_app_id: '',
        err: false
    }
    const HandleSubmit = (e, obj, setObj, url, entityId, setResp, redirect, valid) => {
        const appValue = useAppContext();
        // console.log("triggered")
        e.preventDefault();

        // Add API call here
        axios.post(url, obj, appValue.config)
            .then((res) => {
                if (res.data) {
                    switch (res.data.message) {
                        case "Failure":
                            setResp(res)
                            break;
                        // case "Success":
                        //     if (entityId) {
                        //         window.location.href = "../" + redirect + "/" + res.data.data
                        //     } else {
                        //         window.location.href = redirect + "/" + res.data.data
                        //     }
                        // break;
                        default:
                            break;
                    }
                }
            })
            .catch(function (error) {
                if (error.response) {
                    console.log(error)
                    let data = error.response
                    setResp(data)
                }
            })
    }
    return (
        <ModalPopup show={params.modal} setShow={params.setModal} title='Add New Property Group' onClose={() => { handleClose(params.setModal, params.setPropertyGroup, blankProperty) }} onSubmit={(e) => { HandleSubmit(e, params.propertyGroup, params.setPropertyGroup, url, params.entityId, setResp, "properties", validateProperty) }} response={resp}>
            <Row>
                <Col lg='12' sm='12'>
                    <Card>
                        <Card.Body>
                            <Col lg='12' sm='12'>

                                <Row>
                                    <ModalInput
                                        value={params.propertyGroup.group_name}
                                        data={property_group_info[0]}
                                        changeEvent={(e) => handleChange(e)}
                                    />
                                </Row>
                                <Row>
                                    <ModalInput
                                        value={params.propertyGroup.url_or_app_id}
                                        data={property_group_info[1]}
                                        changeEvent={(e) => handleChange(e)}
                                    />
                                </Row>
                                <br />
                                <Form.Text className="d-flex text-center justify-content-between" style={{ color: 'red' }}>{(params.propertyGroup.err) && 'Please add Property Group Details'}</Form.Text>
                                <br />
                            </Col>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </ModalPopup>
    )
}

const OrderMetaModal = (params) => {
    const [resp, setResp] = useState("");
    let url = params.baseUrl + "/meta/order/create"
    return (
        <ModalPopup onClose={() => { handleClose(params.setModal, params.setOrder) }} show={params.modal} setShow={params.setModal} title="Add New Order"
            response={resp}>
            <Row>
                <Col lg="12">
                    <InputSettings
                        title='Add New Order'
                        mode='create'
                        form={params.form}
                        url={url}
                        model={[params.order, params.setOrder]}
                        name='order/Meta'
                        inputBriefOpts={params.inputBriefOpts}
                        setInputBrief={params.setInputBrief}
                    />
                </Col >
            </Row >
        </ModalPopup >)
}

const GroupMetaModal = (params) => {
    const [resp, setResp] = useState("");
    let url = params.baseUrl + "/meta/group/create"
    return (
        <ModalPopup onClose={() => { handleClose(params.setModal, params.setGroup) }} show={params.modal} setShow={params.setModal} title="Add New Group"

            response={resp}>
            <Row>
                <Col lg="12">
                    <InputSettings
                        title='Add New Group'
                        mode='create'
                        form={params.form}
                        url={url}
                        model={[params.group, params.setGroup]}
                        name='group/Meta'
                        inputBriefOpts={params.inputBriefOpts}
                        setInputBrief={params.setInputBrief}
                    />
                </Col >
            </Row >
        </ModalPopup >)
}

const CampaignMetaModal = (params) => {
    const [resp, setResp] = useState("");
    let url = params.baseUrl + "/meta/campaign/create"
    return (
        <ModalPopup onClose={() => { handleClose(params.setModal, params.setCampaign) }} show={params.modal} setShow={params.setModal} title="Add New Campaign"
            response={resp}>
            <Row>
                <Col lg="12">
                    <InputSettings
                        title='Add New Campaign'
                        mode='create'
                        form={params.form}
                        url={url}
                        model={[params.campaign, params.setCampaign]}
                        name='campaign/Meta'
                        inputBriefOpts={params.inputBriefOpts}
                        setInputBrief={params.setInputBrief}
                    />
                </Col >
            </Row >
        </ModalPopup >)
}
const ClientMetaModal = (params) => {
    const [resp, setResp] = useState("");
    let url = params.baseUrl + "/meta/client/create"
    return (
        <ModalPopup onClose={() => { handleClose(params.setModal, params.setClient) }} show={params.modal} setShow={params.setModal} title="Add New Client"
            response={resp}>
            <Row>
                <Col lg="12">
                    <InputSettings
                        title='Add New Client'
                        mode='create'
                        form={params.form}
                        url={url}
                        model={[params.campaign, params.setCampaign]}
                        name='client/Meta'
                        inputBriefOpts={params.inputBriefOpts}
                        setInputBrief={params.setInputBrief}
                    />
                </Col >
            </Row >
        </ModalPopup >)
}

const InputBriefMetaModal = (params) => {
    const [resp, setResp] = useState("");
    let url = params.baseUrl + "/input-brief/create"
    return (
        <ModalPopup onClose={() => { handleClose(params.setModal, params.setInputBrief) }} show={params.modal} setShow={params.setModal} title="Add Input Brief" onSubmit={(e) => { HandleSubmit(e, params.inputBrief, params.setInputBrief, url, params.entityId, setResp, "input-brief") }} response={resp}>
            <Row>
                <Col lg="12">
                    <InputSettings
                        title='Add Input Brief'
                        mode='create'
                        form={params.form}
                        url={url}
                        model={[params.inputBrief, params.setInputBrief]}
                        name='input-brief'
                    />
                </Col >
            </Row >
        </ModalPopup >)
}

export { ClientModal, CampaignModal, OrderModal, GroupModal, CreativeModal, PropertyModal, CampaignSearchModal, GroupSearchModal, OrderMetaModal, GroupMetaModal, CampaignMetaModal, InputBriefMetaModal, YTGroupModal, ClientMetaModal }
