import { CreativeType, HostingSource, TrimString } from "./common";
//img
import meta from '../assets/images/meta-logo.png'
import dv from '../assets/images/dv360-logo.png'
import td from '../assets/images/trade-desk-logo.png'
import search from '../assets/images/sa360-logo.png'
import yt from '../assets/images/yt-logo.png'

import OnHover from "./tooltip";
import { Link } from "react-router-dom";

const determineLogo = (params) => {
   let logo = dv;
   if (params.row.platform === "Meta") {
      logo = meta;
   }
   if (params.row.platform === "TTD") {
      logo = td;
   } 
   if (params.row.platform === "Search") {
      logo = search;
   }
   return logo
}

const columnsOrder = [
   {
      field: 'name', headerName: 'Order', flex: 1, sortable: true, renderCell: (params) => (
         <OnHover id="1" tooltip={params.row.name}>
            <a href={'/app/order/' + params.row.platform + '/' + params.row._id}>{params.row.name}</a>
         </OnHover>
      ),
   },
   {
      field: 'platform', headerName: 'Platform', flex: 1, sortable: true, renderCell: (params) => {
         return <>
            <img className={"img-platform-logo"} alt={"img-platform-logo"} src={determineLogo(params)} />
         </>
      }
   },
   { field: 'client', headerName: 'Client ID', flex: 1, valueGetter: (params) => params.row?.client_id },
   { field: 'campaign', headerName: 'Campaign ID', flex: 1, valueGetter: (params) => params.row?.campaign_id },
   { field: 'order', headerName: 'Order ID', flex: 1, valueGetter: (params) => params.row?.platform_id },

   { field: 'type', headerName: 'Type', flex: 1, valueGetter: (params) => params.row?.orderType },
   {
      field: 'status', headerName: 'Status', flex: 1, sortable: true, renderCell: (params) => {
         return <>
            <span className={`badge ${params.row?.status ? "bg-success" : "bg-danger"}`}>{params.row?.status ? "Active" : "Inactive"}</span>
         </>
      }
   },
   { field: '_id', headerName: 'ID', flex: 1 },
   {
      field: "action",
      headerName: "Action",
      sortable: false,
      renderCell: (params) => {
         const onClick = (e) => {
            e.stopPropagation(); // don't select this row after clicking

            const api = params.api;
            const thisRow = {};

            api
               .getAllColumns()
               .filter((c) => c.field !== "__check__" && !!c)
               .forEach(
                  (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
               );

            return alert(JSON.stringify(thisRow, null, 4));
         };

         return <>
            {/* <Link className="btn btn-sm btn-icon btn-warning table-sm" data-toggle="tooltip" data-placement="top" title="" data-original-title="Edit" to={'clients/edit/' + params.row._id}>
             <span className="btn-inner">
                <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                   <path d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                   <path fillRule="evenodd" clipRule="evenodd" d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                   <path d="M15.1655 4.60254L19.7315 9.16854" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                </svg>
             </span>
          </Link>{' '}
             <p></p>
             <Link className="btn btn-sm btn-icon btn-danger" data-toggle="tooltip" data-placement="top" title="" data-original-title="Delete" to="#">
                <span className="btn-inner">
                   <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
                      <path d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                      <path d="M20.708 6.23975H3.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                      <path d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                   </svg>
                </span>
             </Link>{' '} */}
         </>
      }
   }
];
const columnsGroup = [
   {
      field: 'name', headerName: 'Group', flex: 1, sortable: true, renderCell: (params) => (
         <OnHover id="1" tooltip={params.row.name}>
            <a href={'/app/group/' + params.row.platform + '/' + params.row._id}>{params.row.name}</a>
         </OnHover>
      ),
   },
   {
      field: 'platform', headerName: 'Platform', flex: 1, sortable: true, renderCell: (params) => {
         return <>
            <img className={"img-platform-logo"} alt={"img-platform-logo"} src={determineLogo(params)} />
         </>
      }
   },
   { field: 'client', headerName: 'Client ID', flex: 1, valueGetter: (params) => params.row?.client_id },
   { field: 'campaign', headerName: 'Campaign ID', flex: 1, valueGetter: (params) => params.row?.campaign_id },
   { field: 'group', headerName: 'Group ID', flex: 1, valueGetter: (params) => params.row?.platform_id },

   { field: 'type', headerName: 'Type', flex: 1, renderCell: (params) => params.row.groupType },
   {
      field: 'status', headerName: 'Status', flex: 1, sortable: true, renderCell: (params) => {
         return <>
            <span className={`badge ${params.row?.status ? "bg-success" : "bg-danger"}`}>{params.row?.status ? "Active" : "Inactive"}</span>
         </>
      }
   },
   { field: '_id', headerName: 'ID', flex: 1 },
   {
      field: "action",
      headerName: "Action",
      sortable: false,
      renderCell: (params) => {
         const onClick = (e) => {
            e.stopPropagation(); // don't select this row after clicking

            const api = params.api;
            const thisRow = {};

            api
               .getAllColumns()
               .filter((c) => c.field !== "__check__" && !!c)
               .forEach(
                  (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
               );

            return alert(JSON.stringify(thisRow, null, 4));
         };

         return <>
            {/* <Link className="btn btn-sm btn-icon btn-warning table-sm" data-toggle="tooltip" data-placement="top" title="" data-original-title="Edit" to={'clients/edit/' + params.row._id}>
             <span className="btn-inner">
                <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                   <path d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                   <path fillRule="evenodd" clipRule="evenodd" d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                   <path d="M15.1655 4.60254L19.7315 9.16854" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                </svg>
             </span>
          </Link>{' '}
             <p></p>
             <Link className="btn btn-sm btn-icon btn-danger" data-toggle="tooltip" data-placement="top" title="" data-original-title="Delete" to="#">
                <span className="btn-inner">
                   <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
                      <path d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                      <path d="M20.708 6.23975H3.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                      <path d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                   </svg>
                </span>
             </Link>{' '} */}
         </>
      }
   }
];
const columnsCampaign = [
   {
      field: 'name', headerName: 'Campaign', flex: 1, sortable: true, renderCell: (params) => (
         <OnHover id="1" tooltip={params.row.name}>
            <a href={'/app/campaign/' + params.row.platform + '/' + params.row._id}>{params.row.name}</a>
         </OnHover>
      ),
   },
   {
      field: 'platform', headerName: 'Platform', flex: 1, sortable: true, renderCell: (params) => {
         return <>
            <img className={"img-platform-logo"} alt={"img-platform-logo"} src={determineLogo(params)} />
         </>
      }
   },
   { field: 'client', headerName: 'Client ID', sortable: true, flex: 1, valueGetter: (params) => params.row?.client_id },
   { field: 'campaign', headerName: 'Campaign ID', sortable: true, flex: 1, valueGetter: (params) => params.row?.platform_id },

   // { field: 'updatetime', headerName: 'Updated', flex: 1,sortable: true, renderCell: (params) => params.row.dv_campaign.updateTime},
   {
      field: 'status', headerName: 'Status', flex: 1, sortable: true, renderCell: (params) => {
         return <>
            <span className={`badge ${params.row?.status ? "bg-success" : "bg-danger"}`}>{params.row?.status ? "Active" : "Inactive"}</span>
         </>
      }
   },
   { field: '_id', headerName: 'ID', flex: 1 },
   // {
   //    field: "action",
   //    headerName: "Action",
   //    sortable: false,
   //    renderCell: (params) => {
   //       const onClick = (e) => {
   //          e.stopPropagation(); // don't select this row after clicking

   //          const api: GridApi = params.api;
   //          const thisRow: Record<string, GridCellValue> = {};

   //          api
   //             .getAllColumns()
   //             .filter((c) => c.field !== "__check__" && !!c)
   //             .forEach(
   //                (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
   //             );

   //          return alert(JSON.stringify(thisRow, null, 4));
   //       };

   //       return <>
   //          {/* <Link className="btn btn-sm btn-icon btn-warning table-sm" data-toggle="tooltip" data-placement="top" title="" data-original-title="Edit" to={'campaign/edit/' + params.row._id}>
   //          <span className="btn-inner">
   //             <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
   //                <path d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
   //                <path fillRule="evenodd" clipRule="evenodd" d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
   //                <path d="M15.1655 4.60254L19.7315 9.16854" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
   //             </svg>
   //          </span>
   //       </Link>{' '} */}
   //          <p></p>
   //          {/* <Link className="btn btn-sm btn-icon btn-danger" data-toggle="tooltip" data-placement="top" title="" data-original-title="Delete" to="#">
   //             <span className="btn-inner">
   //                <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
   //                   <path d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
   //                   <path d="M20.708 6.23975H3.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
   //                   <path d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
   //                </svg>
   //             </span>
   //          </Link> */}
   //          {' '}</>
   //    }
   // }
];
const columnsClient = [
   {
      field: 'name', headerName: 'Client', flex: 1, sortable: true, renderCell: (params) => (
         <OnHover id="1" tooltip={params.row.name}>
            <a href={'/app/client/' + params.row.platform + '/' + params.row._id}>{params.row.name}</a>
         </OnHover>
      ),
   },
   {
      field: 'platform', headerName: 'Platform', flex: 1, sortable: true, renderCell: (params) => {
         return <>
            <img className={"img-platform-logo"} alt={"img-platform-logo"} src={determineLogo(params)} />
         </>
      }
   },
   { field: 'client', headerName: 'Client ID', sortable: true, flex: 1, valueGetter: (params) => params.row?.platform_id },
   { field: 'Website', headerName: 'Website', flex: 1, sortable: true, valueGetter: (params) => params.row?.website },
   { field: 'timezone', headerName: 'Timezone', flex: 1, sortable: true, renderCell: (params) => params.row.timezone },

   { field: 'currency', headerName: 'Currency', flex: 1, sortable: true, renderCell: (params) => params.row.currency },
   // { field: 'updatetime', headerName: 'Updated', flex: 1,sortable: true, renderCell: (params) => params.row.dv_advertiser.updateTime},

   {
      field: 'status', headerName: 'Status', flex: 1, sortable: true, renderCell: (params) => {
         return <>
            <span className={`badge ${params.row?.status ? "bg-success" : "bg-danger"}`}>{params.row?.status ? "Active" : "Inactive"}</span>
         </>
      }
   },
   { field: '_id', headerName: 'ID', flex: 1 },
   // {
   //    field: "action",
   //    headerName: "Action",
   //    sortable: false,
   //    renderCell: (params) => {
   //       const onClick = (e) => {
   //          e.stopPropagation(); // don't select this row after clicking

   //          const api: GridApi = params.api;
   //          const thisRow: Record<string, GridCellValue> = {};

   //          api
   //             .getAllColumns()
   //             .filter((c) => c.field !== "__check__" && !!c)
   //             .forEach(
   //                (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
   //             );

   //          return alert(JSON.stringify(thisRow, null, 4));
   //       };

   //       return <>
   //       {/* <Link className="btn btn-sm btn-icon btn-warning table-sm" data-toggle="tooltip" data-placement="top" title="" data-original-title="Edit" to={'clients/edit/' + params.row._id}>
   //          <span className="btn-inner">
   //             <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
   //                <path d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
   //                <path fillRule="evenodd" clipRule="evenodd" d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
   //                <path d="M15.1655 4.60254L19.7315 9.16854" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
   //             </svg>
   //          </span>
   //       </Link>{' '}
   //          <p></p>
   //          <Link className="btn btn-sm btn-icon btn-danger" data-toggle="tooltip" data-placement="top" title="" data-original-title="Delete" to="#">
   //             <span className="btn-inner">
   //                <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
   //                   <path d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
   //                   <path d="M20.708 6.23975H3.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
   //                   <path d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
   //                </svg>
   //             </span>
   //          </Link> */}
   //          {' '}</>
   //    }
   // }
];


const columnsExchange = [
   {
      field: 'exchange', headerName: 'Exchange', flex: 1, sortable: true, renderCell: (params) => (TrimString(params.row.exchange, 9)),
   },
   { field: 'status', headerName: 'Status', flex: 1, renderCell: (params) => TrimString(params.row.status, 14) },
];

// const columnsGroup: GridColDef[] = [
//     {
//         field: 'name', headerName: 'Group', flex: 1, sortable: true, renderCell: (params) => (
//             <a href={'group/' + params.row._id}>{params.row.name}</a>
//         ),
//     },
//     { field: 'advertiser', headerName: 'Advertiser ID', flex: 1, renderCell: (params) => params.row.dv_advertiser_id },
//     { field: 'campaign', headerName: 'Campaign ID', flex: 1, renderCell: (params) => params.row.dv_campaign_id },
//     { field: 'type', headerName: 'Type', flex: 1, renderCell: (params) => params.row.ordertype },
//     {
//         field: 'status', headerName: 'Status', flex: 1, sortable: true, renderCell: (params) => {
//             return <>
//                 <span className={`badge ${params.row?.status ? "bg-success" : "bg-danger"}`}>{params.row?.status ? "Active" : "Inactive"}</span>
//             </>
//         }
//     },
//     { field: '_id', headerName: 'ID', flex: 1 },
// ];


const columnsCreative = [
   {
      field: 'name', headerName: 'Creative', flex: 1, sortable: true, renderCell: (params) => (
         <OnHover id="1" tooltip={params.row.name}>
            <a href={'/app/creative/' + params.row.platform+"/" + params.row._id}>{params.row.name}</a>
         </OnHover>
      ),
   },

   { field: 'advertiser', headerName: 'Advertiser ID', flex: 1, valueGetter: (params) => params.row?.client_id },
   {
      field: 'platform', headerName: 'Platform', flex: 1, sortable: true, renderCell: (params) => {
         return <>
            <img className={"img-platform-logo"} alt={"img-platform-logo"} src={determineLogo(params)} />
         </>
      }
   },
   { field: 'creative', headerName: 'Creative ID', flex: 1, valueGetter: (params) => params.row?.platform_id },
   { field: 'source', headerName: 'Source', flex: 1, value: (params) => HostingSource(params.row.source) },
   { field: 'type', headerName: 'Type', flex: 1, renderCell: (params) => CreativeType(params.row.type) },
   {
      field: 'status', headerName: 'Status', flex: 1, sortable: true, renderCell: (params) => {
         return <>
            <span className={`badge ${params.row?.status ? "bg-success" : "bg-danger"}`}>{params.row?.status ? "Active" : "Inactive"}</span>
         </>
      }
   },
   { field: '_id', headerName: 'ID', flex: 1 },
   // {
   //    field: "action",
   //    headerName: "Action",
   //    sortable: false,
   //    renderCell: (params) => {
   //       const onClick = (e) => {
   //          e.stopPropagation(); // don't select this row after clicking

   //          const api: GridApi = params.api;
   //          const thisRow: Record<string, GridCellValue> = {};

   //          api
   //             .getAllColumns()
   //             .filter((c) => c.field !== "__check__" && !!c)
   //             .forEach(
   //                (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
   //             );

   //          return alert(JSON.stringify(thisRow, null, 4));
   //       };

   //       return <>
   //       {/* <Link className="btn btn-sm btn-icon btn-warning table-sm" data-toggle="tooltip" data-placement="top" title="" data-original-title="Edit" to={'clients/edit/' + params.row._id}>
   //          <span className="btn-inner">
   //             <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
   //                <path d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
   //                <path fillRule="evenodd" clipRule="evenodd" d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
   //                <path d="M15.1655 4.60254L19.7315 9.16854" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
   //             </svg>
   //          </span>
   //       </Link>{' '}
   //          <p></p>
   //          <Link className="btn btn-sm btn-icon btn-danger" data-toggle="tooltip" data-placement="top" title="" data-original-title="Delete" to="#">
   //             <span className="btn-inner">
   //                <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
   //                   <path d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
   //                   <path d="M20.708 6.23975H3.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
   //                   <path d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
   //                </svg>
   //             </span>
   //          </Link>{' '} */}
   //          </>
   //    }
   // }
];
const columnsProperty = [
   {
      field: 'name', headerName: 'Property Group', flex: 1, sortable: true, renderCell: (params) => (
         <OnHover id="1" tooltip={params.row.name}>
            <a href={'/app/properties/' + params.row._id}>{params.row.name}</a>
         </OnHover>
      ),
   },
   { field: 'advertiser', headerName: 'Advertiser ID', flex: 1, valueGetter: (params) => params.row?.dv_advertiser_id },
   { field: 'channel', headerName: 'Channel ID', flex: 1, valueGetter: (params) => params.row?.dv_channel_id },
   { field: 'properties', headerName: 'Properties', flex: 1, renderCell: (params) => params.row.properties },

   { field: '_id', headerName: 'ID', flex: 1 },
   // {
   //    field: "action",
   //    headerName: "Action",
   //    sortable: false,
   //    renderCell: (params) => {
   //       const onClick = (e) => {
   //          e.stopPropagation(); // don't select this row after clicking

   //          const api: GridApi = params.api;
   //          const thisRow: Record<string, GridCellValue> = {};

   //          api
   //             .getAllColumns()
   //             .filter((c) => c.field !== "__check__" && !!c)
   //             .forEach(
   //                (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
   //             );

   //          return alert(JSON.stringify(thisRow, null, 4));
   //       };

   //       return <>
   //       {/* <Link className="btn btn-sm btn-icon btn-warning table-sm" data-toggle="tooltip" data-placement="top" title="" data-original-title="Edit" to={'clients/edit/' + params.row._id}>
   //          <span className="btn-inner">
   //             <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
   //                <path d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
   //                <path fillRule="evenodd" clipRule="evenodd" d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
   //                <path d="M15.1655 4.60254L19.7315 9.16854" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
   //             </svg>
   //          </span>
   //       </Link>{' '}
   //          <p></p>
   //          <Link className="btn btn-sm btn-icon btn-danger" data-toggle="tooltip" data-placement="top" title="" data-original-title="Delete" to="#">
   //             <span className="btn-inner">
   //                <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
   //                   <path d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
   //                   <path d="M20.708 6.23975H3.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
   //                   <path d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
   //                </svg>
   //             </span>
   //          </Link>{' '} */}
   //          </>
   //    }
   // }
];
const columnsSites = [
   {
      field: 'name', headerName: 'Property', flex: 1, sortable: true, renderCell: (params) => {
         var regExp = /(?<=\bAndroid\s+)\(([^)]+)\)/;
         var matches = regExp.exec(params.row.urlOrAppId);
         if (matches) {
            let text = params.row.urlOrAppId
            if (text.includes("Android")) {
               return <a href={"https://play.google.com/store/apps/details?id=" + matches[1]} target="_blank" rel="noreferrer">{params.row.urlOrAppId}</a>
            } else {

               return params.row.urlOrAppId
            }
         } else {
            return <a href={"https://" + params.row.urlOrAppId} target="_blank" rel="noreferrer">{params.row.urlOrAppId}</a>
         }
      }
   },
   // {
   //    field: "action",
   //    headerName: "Action",
   //    sortable: false,
   //    renderCell: (params) => {
   //       const onClick = (e) => {
   //          e.stopPropagation(); // don't select this row after clicking

   //          const api: GridApi = params.api;
   //          const thisRow: Record<string, GridCellValue> = {};

   //          api
   //             .getAllColumns()
   //             .filter((c) => c.field !== "__check__" && !!c)
   //             .forEach(
   //                (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
   //             );

   //          return alert(JSON.stringify(thisRow, null, 4));
   //       };

   //       return <>
   //       {/* <Link className="btn btn-sm btn-icon btn-warning table-sm" data-toggle="tooltip" data-placement="top" title="" data-original-title="Edit" to={'clients/edit/' + params.row._id}>
   //          <span className="btn-inner">
   //             <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
   //                <path d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
   //                <path fillRule="evenodd" clipRule="evenodd" d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
   //                <path d="M15.1655 4.60254L19.7315 9.16854" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
   //             </svg>
   //          </span>
   //       </Link>{' '}
   //          <p></p>
   //          <Link className="btn btn-sm btn-icon btn-danger" data-toggle="tooltip" data-placement="top" title="" data-original-title="Delete" to="#">
   //             <span className="btn-inner">
   //                <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
   //                   <path d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
   //                   <path d="M20.708 6.23975H3.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
   //                   <path d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
   //                </svg>
   //             </span>
   //          </Link>{' '} */}
   //          </>
   //    }
   // }
];

const columnsAudience = [
   {
      field: 'name', headerName: 'Audience', flex: 1, sortable: true, renderCell: (params) => (
         <OnHover id="1" tooltip={params.row.name}>
            {/* <a href={'properties/' + params.row._id}> */}
            {params.row.name}
            {/* </a> */}
         </OnHover>
      ),
   },
   { field: 'audience', headerName: 'Audience ID', flex: 1, valueGetter: (params) => params.row?.dv_audience_id },
   { field: 'source', headerName: 'Source', flex: 1, renderCell: (params) => params.row.source },
   { field: 'description', headerName: 'Description', flex: 1, renderCell: (params) => params.row.description },

   { field: '_id', headerName: 'ID', flex: 1 },
   // {
   //    field: "action",
   //    headerName: "Action",
   //    sortable: false,
   //    renderCell: (params) => {
   //       const onClick = (e) => {
   //          e.stopPropagation(); // don't select this row after clicking

   //          const api: GridApi = params.api;
   //          const thisRow: Record<string, GridCellValue> = {};

   //          api
   //             .getAllColumns()
   //             .filter((c) => c.field !== "__check__" && !!c)
   //             .forEach(
   //                (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
   //             );

   //          return alert(JSON.stringify(thisRow, null, 4));
   //       };

   //       return <>
   //       {/* <Link className="btn btn-sm btn-icon btn-warning table-sm" data-toggle="tooltip" data-placement="top" title="" data-original-title="Edit" to={'clients/edit/' + params.row._id}>
   //          <span className="btn-inner">
   //             <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
   //                <path d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
   //                <path fillRule="evenodd" clipRule="evenodd" d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
   //                <path d="M15.1655 4.60254L19.7315 9.16854" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
   //             </svg>
   //          </span>
   //       </Link>{' '}
   //          <p></p>
   //          <Link className="btn btn-sm btn-icon btn-danger" data-toggle="tooltip" data-placement="top" title="" data-original-title="Delete" to="#">
   //             <span className="btn-inner">
   //                <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
   //                   <path d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
   //                   <path d="M20.708 6.23975H3.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
   //                   <path d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
   //                </svg>
   //             </span>
   //          </Link>{' '} */}
   //          </>
   //    }
   // }
];
const columnsLocation = [
   {
      field: 'name', headerName: 'Locations', flex: 1, sortable: true, editable: false, renderCell: (params) => params.row.name,
   },
   { field: 'field', headerName: 'Bid Adjustment', flex: 1, editable: true, renderCell: (params) => params.row.field },
   {
      field: "action",
      headerName: "Action",
      sortable: false,
      renderCell: (params) => {
         const onClick = (e) => {
            console.log("delete called")
            e.stopPropagation(); // don't select this row after clicking
            console.log("delete clicked")
            const api = params.api;
            const thisRow = {};
            console.log(api.getAllColumns)
            // api
            //    .getAllColumns()
            //    .filter((c) => c.field !== "__check__" && !!c)
            //    .forEach(
            //       (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
            //    );

            // return alert(JSON.stringify(thisRow, null, 4));
         };
         return <>
            {
               <Link className="btn btn-sm btn-icon btn-danger" data-toggle="tooltip" data-placement="top" title="" data-original-title="Delete" onClick={onClick}>
                  <span className="btn-inner">
                     <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
                        <path d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                        <path d="M20.708 6.23975H3.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                        <path d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                     </svg>
                  </span>
               </Link>
            }
         </>
      }
   }
];
const columnsKeywords = [
   {
      field: 'name', headerName: 'Keyword', flex: 1, sortable: true, editable: false, renderCell: (params) => params.row.name,
   },
   { field: 'field', headerName: 'Bid Adjustment', flex: 1, editable: true, renderCell: (params) => params.row.field },
   {
      field: "action",
      headerName: "Action",
      sortable: false,
      renderCell: (params) => {
         const onClick = (e) => {
            console.log("delete called")
            e.stopPropagation(); // don't select this row after clicking
            console.log("delete clicked")
            const api = params.api;
            const thisRow = {};
            console.log(api.getAllColumns)
            // api
            //    .getAllColumns()
            //    .filter((c) => c.field !== "__check__" && !!c)
            //    .forEach(
            //       (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
            //    );

            // return alert(JSON.stringify(thisRow, null, 4));
         };
         return <>
            {
               <Link className="btn btn-sm btn-icon btn-danger" data-toggle="tooltip" data-placement="top" title="" data-original-title="Delete" onClick={onClick}>
                  <span className="btn-inner">
                     <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
                        <path d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                        <path d="M20.708 6.23975H3.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                        <path d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                     </svg>
                  </span>
               </Link>
            }
         </>
      }
   }
];

const columnsInputBrief = [
   {
      field: 'name', headerName: 'Input Brief', flex: 1, sortable: true, renderCell: (params) => (
         <OnHover id="1" tooltip={params.row.name}>
            <a href={'/app/input-brief/' + params.row._id}>{params.row.name}</a>
         </OnHover>
      )
   },
   { field: 'stage', headerName: 'Stage', sortable: true, flex: 1, valueGetter: (params) => params.row?.stage },
   {
      field: 'platform', headerName: 'Platform', flex: 1, sortable: true, renderCell: (params) => {
         return <>
            <img className={"img-platform-logo"} alt={"img-platform-logo"} src={`${params.row?.platform === "DV" ? dv : meta}`} />
         </>
      }
   },
   { field: 'client', headerName: 'Client ID', sortable: true, flex: 1, valueGetter: (params) => params.row?.client_id },
   { field: '_id', headerName: 'ID', flex: 1 },
];

export { columnsOrder, columnsGroup, columnsCampaign, columnsClient, columnsExchange, columnsCreative, columnsProperty, columnsSites, columnsAudience, columnsLocation, columnsKeywords, columnsInputBrief }