const property_group_info = [
    {
        label: 'Name',
        input: 'text',
        name: 'group_name',
    },
    {
        label: 'URL or APP ID (comma separated)',
        input: 'textarea',
        name: 'url_or_app_id'
    }
]

export { property_group_info };