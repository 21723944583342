const type_prefix = 'LINE_ITEM_TYPE_YOUTUBE_AND_PARTNERS_'
const status_prefix = 'ENTITY_STATUS_'

const group_info = [
    {
        label: 'Order ID',
        input: 'dropdown',
        name: 'order_id',
        options: [],
        size: '6',
        required: true,
        feedback: 'Order ID is required'
    },
    {
        label: 'Group Name',
        input: 'input-text',
        name: 'name',
        required: true,
        feedback: 'Group name is required',
        size: '6'
    },
    {
        label: 'Type',
        input: 'dropdown',
        name: 'type',
        options: [
            // { name: 'type', label: 'Video', value: `${type_prefix}SIMPLE` },
            // { name: 'type', label: 'Audio', value: `${type_prefix}AUDIO` },
            { name: 'type', label: 'TrueView', value: `${type_prefix}VIEW` },
        ],
        size: '4',
        required: true,
        feedback: 'Type is required'
    },
    // {
    //     label: 'Subtype',
    //     input: 'dropdown',
    //     name: 'subtype',
    //     dependent: 'type',
    //     condition: `${type_prefix}SIMPLE`,
    //     if: {
    //         condition: 'order_type',
    //         value: 'RTB',
    //         options: [
    //             { name: 'subtype', label: 'Standard', value: `${type_prefix}SIMPLE` },
    //             { name: 'subtype', label: 'Non Skippable (15 Seconds)', value: `${type_prefix}NON_SKIPPABLE` },            
    //             { name: 'subtype', label: 'More Conversions', value: `${type_prefix}ACTION` },
    //             { name: 'subtype', label: 'More Unique Users', value: `${type_prefix}REACH` },
    //             { name: 'subtype', label: 'More Views', value: `${type_prefix}VIEW` },
    //             { name: 'subtype', label: 'Frequency Cap Focused', value: `${type_prefix}TARGET_FREQUENCY` },                
    //         ],
    //     },
    //     else: {
    //         options: [        
    //             { name: 'subtype', label: 'Standard', value: `${type_prefix}NON_SKIPPABLE` },
    //             { name: 'subtype', label: 'Non Skippable (15 Seconds)', value: `${type_prefix}NON_SKIPPABLE_OVER_THE_TOP` },
    //             { name: 'subtype', label: 'More Unique Users', value: `${type_prefix}REACH_OVER_THE_TOP` },

    //         ],       
    //     },
    //     size: '4',
    //     feedback: 'Subtype is required'
    // },
    {
        label: 'Ad Format',
        input: 'dropdown',
        name: 'trueview.ad_format',
        dependent: "type",
        condition: `${type_prefix}VIEW`,
        options: [
            { name: 'trueview.ad_format', label: 'In-stream', value: `In-stream` },
            { name: 'trueview.ad_format', label: 'In-stream / In-feed Video', value: `In-stream / In-feed Video` },
            { name: 'trueview.ad_format', label: 'In-stream / Bumper', value: `In-stream / Bumper` },
            { name: 'trueview.ad_format', label: 'Bumper', value: `Bumper` },
            { name: 'trueview.ad_format', label: 'Non Skippable', value: `Non Skippable` },
            { name: 'trueview.ad_format', label: 'In-stream / Bumper / Non-skippable', value: `In-stream / Bumper / Non-skippable` },
        ],
        size: '4',
        feedback: 'AdFormat is required'
    },
    {
        label: 'Status',
        input: 'dropdown',
        name: 'status',
        options: [
            { name: 'status', label: 'Active', value: `${status_prefix}ACTIVE` },
            { name: 'status', label: 'Draft', value: `${status_prefix}DRAFT` },
            { name: 'status', label: 'Paused', value: `${status_prefix}PAUSED` }
        ],
        size: '4',
        required: true,
        feedback: 'Status is required'
    },
]


const partner_costs = [
    {
        label: 'Partner Costs',
        input: 'segment',
        name: 'partner_costs',                
        size: '12',
        required: false,
        feedback: 'Partner fee is required'
    },
]

const flight_info = [
    {
        label: 'Flight Date Type',
        input: 'dropdown',
        name: 'flight_date_type',
        options: [
            { label: 'Inherit from Order', value: `LINE_ITEM_FLIGHT_DATE_TYPE_INHERITED` },
            { label: 'Custom', value: `LINE_ITEM_FLIGHT_DATE_TYPE_CUSTOM` }
        ],
        size: '8',
        required: true,
        feedback: 'Flight date type is required'
    },
    {
        label: 'Start Date',
        input: 'date',
        name: 'flight_start_date',
        dependent:"flight_date_type",
        condition:"LINE_ITEM_FLIGHT_DATE_TYPE_CUSTOM",
        size: '6',

    },
    {
        label: 'End Date',
        input: 'date',
        name: 'flight_end_date',
        dependent:"flight_date_type",
        condition:"LINE_ITEM_FLIGHT_DATE_TYPE_CUSTOM",
        size: '6',

    }
]

const alloc_type_prefix = 'LINE_ITEM_BUDGET_ALLOCATION_TYPE_'

const budget_info = [
    {
        label: 'Allocation Type',
        input: 'dropdown',
        name: 'budget.allocation_type',
        options: [
            // { name: 'budget_allocation_type', label: 'Automatic', value: `${alloc_type_prefix}AUTOMATIC` },
            // { name: 'budget_allocation_type', label: 'Fixed', value: `${alloc_type_prefix}FIXED` },
            { name: 'budget_allocation_type', label: 'TrueView', value: `${alloc_type_prefix}TRUEVIEW` },        
            // { name: 'budget_allocation_type', label: 'Unlimited', value: `${alloc_type_prefix}UNLIMITED` }
        ],
        size: '6',
        required: true,
        feedback: 'Budget allocation type is required'
    },
    // {
    //     label: 'Max Budget',
    //     name: 'budget.max_amount',
    //     input: 'input-combo',
    //     size: "6",
    //     dependent: 'budget.allocation_type',
    //     condition: `${alloc_type_prefix}FIXED`,
    //     options: ["$", "Impressions"],
    //     addOnName: "budget.unit",
    //     // required: true,
    //     // feedback: "Please enter a budget amount",
    // },
]

const pacing_period_prefix = 'PACING_PERIOD_'
const pacing_type_prefix = 'PACING_TYPE_'

const pacing_info = [
    {
        label: 'Pacing Period',
        input: 'dropdown',
        name: 'pacing.period',
        options: [
            { name: 'pacing_period', label: 'Daily', value: `${pacing_period_prefix}DAILY` },
            { name: 'pacing_period', label: 'Entire Flight', value: `${pacing_period_prefix}FLIGHT` }
        ],
        size: '6',
        required: true,
        feedback: 'Pacing period is required'
    },
    {
        label: 'Pacing Type',
        input: 'dropdown',
        name: 'pacing.type',
        dependent: 'type',       
        if: {
            condition: 'type',
            value: `${type_prefix}VIEW`,
            options: [
                { name: 'pacing_type', label: 'ASAP', value: `${pacing_type_prefix}ASAP` },
                { name: 'pacing_type', label: 'Even', value: `${pacing_type_prefix}EVEN` }
            ],
        },
        else: {
            options: [
                { name: 'pacing_type', label: 'Accelerated Value', value: `${pacing_type_prefix}AHEAD` },
                { name: 'pacing_type', label: 'ASAP', value: `${pacing_type_prefix}ASAP` },
                { name: 'pacing_type', label: 'Even', value: `${pacing_type_prefix}EVEN` }
            ],     
        },
        size: '6',
        required: true,
        feedback: 'Pacing type is required'
    },
    {
        label: 'Max Amount',
        name: 'pacing.max_amount',
        input: 'input-combo',
        size: "6",
        dependent: 'pacing.period',
        condition: 'PACING_PERIOD_DAILY',
        options: ["$", "Impressions"],
        addOnName: "pacing.daily_max_type",
        // required: true,
        // feedback: "Please enter a budget amount",
    },
]

const frequency_info = [
    {
        label: "Unlimited",
        input: "toggle",
        name: "frequency_cap.unlimited",
        size: "12"
    },
    {
        label: "Total Allowed Impressions",
        input: "input-number",
        name: "frequency_cap.max_impressions",
        size: "4",
        dependent: "frequency_cap.unlimited",
        condition: false,
    },
    {
        label: "Duration Count",
        input: "input-number",
        name: "frequency_cap.count",
        size: "4",
        dependent: "frequency_cap.unlimited",
        condition: false,
    },
    {
        label: 'Duration Unit',
        input: 'dropdown',
        if: {
            condition: 'type',
            value: `${type_prefix}VIEW`,
            options: [
                { label: "Months", value: "TIME_UNIT_MONTHS" },
                { label: "Weeks", value: "TIME_UNIT_WEEKS" },
                { label: "Days", value: "TIME_UNIT_DAYS" },
            ],
        },
        else: {
            options: [
                { label: "Lifetime", value: "TIME_UNIT_LIFETIME" },
                { label: "Months", value: "TIME_UNIT_MONTHS" },
                { label: "Weeks", value: "TIME_UNIT_WEEKS" },
                { label: "Days", value: "TIME_UNIT_DAYS" },
                { label: "Hours", value: "TIME_UNIT_HOURS" },
                { label: "Minutes", value: "TIME_UNIT_MINUTES" },
            ],   
        },
       
        name: 'frequency_cap.timeunit',
        dependent: "frequency_cap.unlimited",
        condition: false,
        size: "4",
    },


    

]

const markup_type_prefix = 'PARTNER_REVENUE_MODEL_MARKUP_TYPE_'

const partner_revenue_model_info = [
    {
        label: 'Markup Type',
        input: 'dropdown',
        name: 'partner_revenue_model_markup_type',
        options: [
            { label: '', value: '', name: 'partner_revenue_model_markup_type' },
            { label: 'CPM', value: `${markup_type_prefix}CPM`, name: 'partner_revenue_model_markup_type' },
            { label: 'Media (%)', value: `${markup_type_prefix}TOTAL_MEDIA_COST_MARKUP`, name: 'partner_revenue_model_markup_type' }

        ],
        size: '6',
        required: true,
        feedback: 'Partner revenue markup type is required'
    },
    {
        label: 'Markup Amount',
        input: 'input-number',
        name: 'partner_revenue_model_markup_amount',
        size: '6',
        required: true,
        feedback: 'Partner revenue markup amount is required'
    }
]

const conversion_info = [
    {
        label:"Conversion Counting Type",
        input: "dropdown",
        name: "conversion_counting.type",
        options: [ 
            { label: 'Post Click', value: `POST_CLICK`, name: 'conversion_counting.type' },
            { label: '%', value: `PERCENT`, name: 'conversion_counting.type' },
            { label: 'All', value: `ALL`, name: 'conversion_counting.type' },
        ],
        required: true,
        size:"6",
        feedback: 'Conversion counting type is required'
    },
    {
        label: "% Counting Amount",
        input: "input-number",
        name: "conversion_counting.amount",
        size: "6",
        dependent: "conversion_counting.type",
        condition: 'PERCENT',
    },
]


const bid_strat_goal_type_prefix = 'YOUTUBE_AND_PARTNERS_BIDDING_STRATEGY_TYPE_'

const bidding_info = [
    {
        label: 'Strategy',
        input: 'dropdown',
        name: 'bidding_strategy',
        if: {
            condition: 'type',
            value: `${type_prefix}VIEW`,
            options: [
                { label: 'Minimize', value: `${bid_strat_goal_type_prefix}MAXIMIZE_LIFT`, name: 'auto_bid_performance_goal_type' },
                { label: 'Maximize', value: `${bid_strat_goal_type_prefix}MAXIMIZE_CONVERSIONS`, name: 'auto_bid_performance_goal_type' },
            ], 
        },
        else: {
            options: [
                { label: 'Minimize', value: `${bid_strat_goal_type_prefix}MAXIMIZE_LIFT`, name: 'auto_bid_performance_goal_type' },
                { label: 'Maximize', value: `${bid_strat_goal_type_prefix}MAXIMIZE_CONVERSIONS`, name: 'auto_bid_performance_goal_type' },
                { label: 'Beat', value: `${bid_strat_goal_type_prefix}TARGET_ROAS`, name: 'auto_bid_performance_goal_type' },
                { label: 'Optimize', value: `${bid_strat_goal_type_prefix}TARGET_CPM`, name: 'auto_bid_performance_goal_type' },
                { label: 'Fixed', value: `${bid_strat_goal_type_prefix}MANUAL_CPM`, name: 'auto_bid_performance_goal_type' },
            ], 
        },

        size: '6',
        required: true,
        feedback: 'Bidding strategy is required'
    },
    {
        label: 'Bid Value',
        input: 'input-text',
        name: 'bid_amount',
        size: '6',
        dependent: "bidding_strategy",
        condition: `${bid_strat_goal_type_prefix}TARGET_ROAS`,        
        feedback: 'Bid amount is required'
    },
    {
        label: 'Bid Value',
        input: 'input-text',
        name: 'bid_amount',
        size: '6',
        dependent: "bidding_strategy",
        condition: `${bid_strat_goal_type_prefix}TARGET_CPM`,        
        feedback: 'Bid amount is required'
    },
    {
        label: 'Bid Value',
        input: 'input-text',
        name: 'bid_amount',
        size: '6',
        dependent: "bidding_strategy",
        condition: `${bid_strat_goal_type_prefix}MANUAL_CPM`,        
        feedback: 'Bid amount is required'
    },
    {
        label: 'Bid Strategy Unit',
        input: 'dropdown',
        name: 'auto_bid_performance_goal_type',
        options: [
            { label: 'CPA', value: `CPA`, name: 'auto_bid_performance_goal_type' },
            { label: 'CPC', value: `CPC`, name: 'auto_bid_performance_goal_type' },
            { label: 'Custom', value: `custom impt. value/cost`, name: 'auto_bid_performance_goal_type' },
            { label: 'CIVA', value: `CIVA`, name: 'auto_bid_performance_goal_type' },
            { label: 'IVO Ten', value: `IVO_TEN`, name: 'auto_bid_performance_goal_type' },
            { label: 'AV Viewed', value: `AV_VIEWED`, name: 'auto_bid_performance_goal_type' }
        ],
        dependent: 'bidding_strategy',
        condition: `${bid_strat_goal_type_prefix}TARGET_ROAS`,  
        size: '6',
    },
    {
        label: 'Bid Strategy Unit',
        input: 'dropdown',
        name: 'auto_bid_performance_goal_type',
        options: [
            { label: 'CPA', value: `CPA`, name: 'auto_bid_performance_goal_type' },
            { label: 'CPC', value: `CPC`, name: 'auto_bid_performance_goal_type' },
            { label: 'Custom', value: `custom impt. value/cost`, name: 'auto_bid_performance_goal_type' },
            { label: 'CIVA', value: `CIVA`, name: 'auto_bid_performance_goal_type' },
            { label: 'IVO Ten', value: `IVO_TEN`, name: 'auto_bid_performance_goal_type' },
            { label: 'AV Viewed', value: `AV_VIEWED`, name: 'auto_bid_performance_goal_type' }
        ],
        dependent: 'bidding_strategy',
        condition: `${bid_strat_goal_type_prefix}MAXIMIZE_LIFT`,  
        size: '6',
    },    {
        label: 'Bid Strategy Unit',
        input: 'dropdown',
        name: 'auto_bid_performance_goal_type',
        options: [
            { label: 'CPA', value: `CPA`, name: 'auto_bid_performance_goal_type' },
            { label: 'CPC', value: `CPC`, name: 'auto_bid_performance_goal_type' },
            { label: 'Custom', value: `custom impt. value/cost`, name: 'auto_bid_performance_goal_type' },
            { label: 'CIVA', value: `CIVA`, name: 'auto_bid_performance_goal_type' },
            { label: 'IVO Ten', value: `IVO_TEN`, name: 'auto_bid_performance_goal_type' },
            { label: 'AV Viewed', value: `AV_VIEWED`, name: 'auto_bid_performance_goal_type' }
        ],
        dependent: 'bidding_strategy',
        condition: `${bid_strat_goal_type_prefix}MAXIMIZE_CONVERSIONS`,  
        size: '6',
    },
    {
        label: 'Bid Cap Value',
        input: 'input-text',
        name: 'bid_cap',
        size: '6',
        dependent: "bidding_strategy",
        condition: `${bid_strat_goal_type_prefix}TARGET_ROAS`,        
        feedback: 'Bid amount is required'
    },
    {
        label: 'Bid Cap Value',
        input: 'input-text',
        name: 'bid_cap',
        size: '6',
        dependent: "bidding_strategy",
        condition: `${bid_strat_goal_type_prefix}MAXIMIZE_LIFT`,        
        feedback: 'Bid amount is required'
    },
    {
        label: 'Bid Cap Value',
        input: 'input-text',
        name: 'bid_cap',
        size: '6',
        dependent: "bidding_strategy",
        condition: `${bid_strat_goal_type_prefix}MAXIMIZE_CONVERSIONS`,        
        feedback: 'Bid amount is required'
    },
    {
        label: 'Apply Floor Price For Deals',
        input: 'toggle',
        name: 'price_for_deal',
        size: '6',
        dependent: "bidding_strategy",
        condition: `${bid_strat_goal_type_prefix}MAXIMIZE_LIFT`,        
        feedback: 'Bid amount is required'
    },
    {
        label: 'Apply Floor Price For Deals',
        input: 'toggle',
        name: 'price_for_deals',
        size: '6',
        dependent: "bidding_strategy",
        condition: `${bid_strat_goal_type_prefix}MAXIMIZE_CONVERSIONS`,        
        feedback: 'Bid amount is required'
    },
    {
        label: 'Apply Floor Price For Deals',
        input: 'toggle',
        name: 'price_for_deals',
        size: '6',
        dependent: "bidding_strategy",
        condition: `${bid_strat_goal_type_prefix}TARGET_ROAS`,        
        feedback: 'Bid amount is required'
    },
   
]
const trueview_info = [
    {
        label:"Bid Stragegy",
        input:"dropdown",
        name:"trueview.bid_strategy_type",
        options: [
            {label: 'Manual CPV', value: `Manual CPV` },
            {label: 'Manual CPM', value: `Manual CPM` },
            {label: 'Target CPM', value: `Target CPM` },
            {label: 'Target CPA', value: `Target CPA` },
            {label: 'Target ROAS', value: `Target ROAS`},
            {label: 'Maximize Conversions', value: `Maximize Conversions` },
            {label: 'Maximize Conversions Value', value: `Maximize Conversions Value` },

        ],
        size:"6"
    },
    {
        label: "Bid Strategy Value",
        input: "input-number",
        name:"trueview.bid_strategy_value",
        size:"6"
    }
]

const creatives_info = [
    {
        label: 'Creatives',
        input: 'async-combo',
        name: 'creatives', 
        options: [
            { label: '', value: '', name: 'creatives', advertiser_id: '' },
        ],
        size: '6',
        // required: true,
        // feedback: 'Creatives are required'
    }
]

const YTGroupForm = {
    titles: ["General", "Partner","Conversions", "Flight", "Budget", "Pacing", "Frequency", "Partner Revenue", "Creatives", "Bidding","TrueView Strategies"],
    fields: [
        group_info,
        partner_costs,
        conversion_info,
        flight_info,
        budget_info,
        pacing_info,
        frequency_info,
        partner_revenue_model_info,
        creatives_info,
        bidding_info,
        trueview_info
    ]
}

export { YTGroupForm }