const general_info = [
    {
        label: 'Name',
        input: 'input-text',
        name: 'adGroupName',
        size: '4',
    },
    {
        label: 'Category',
        input: 'input-text',
        name: 'adGroupCategory.categoryName',
        size: '4',
    },
    {
        label: 'Campaign ID',
        input: 'input-text',
        name: 'campaignId',
        size: '4',
    },
    {
        label: 'Description',
        input: 'input-text',
        name: 'description',
        size: '4',
    },
    {
        label: 'Funnel Location',
        input: 'input-text',
        name: 'funnelLocation',
        size: '4'
    },
    {
        label: 'Channel ID',
        input: 'input-text',
        name: 'channelId',
        size: '4'
    },
    {
        label: 'Bidder Service ID',
        input: 'input-text',
        name: 'bidderServiceId',
        size: '4'
    },
    {
        label: 'Created At',
        input: 'date',
        name: 'createdAtUTC',
        size: '4'
    },
    {
        label: 'Last Updated At',
        input: 'date',
        name: 'lastupdatedAtUTC',
        size: '4'
    },

    {
        label: 'Future Koa Features Enabled',
        input: 'toggle',
        name: 'areFutureKoaFeaturesEnabled',
        size: '4'
    },
    {
        label: 'Include Defaults From Campaign',
        input: 'toggle',
        name: 'includeDefaultsFromCampaign',
        size: '4'
    },
    {
        label: 'Build in Progress',
        input: 'toggle',
        name: 'isBuildInProgress',
        size: '4'
    },
    {
        label: 'Enabled',
        input: 'toggle',
        name: 'isEnabled',
        size: '4'
    },
    {
        label: 'Is High Fill Rate',
        input: 'toggle',
        name: 'isHighFillRate',
        size: '4'
    },
    {
        label: 'Predictive Clearing Enabled',
        input: 'toggle',
        name: 'predictiveClearingEnabled',
        size: '4'
    },
    {
        label: 'Adbrain Household Cross Device Enabled',
        input: 'toggle',
        name: 'adbrainHouseholdCrossDeviceEnabled',
        size: '4'
    },
    {
        label: 'Use Identity Alliance',
        input: 'toggle',
        name: 'useIdentityAlliance',
        size: '4'
    },
]

const industry_cat_mapping = [
    {
        label: 'Category ID',
        input: 'input-number',
        name: 'industryCategoryMapping.categoryId',
        size: '4'
    },
    {
        label: 'Category Taxonomy ID',
        input: 'input-number',
        name: 'industryCategoryMapping.categoryTaxonomyId',
        size: '4'
    },
    {
        label: 'External ID',
        input: 'input-text',
        name: 'industryCategoryMapping.externalId',
        size: '4'
    },

]

const GroupTradeDeskForm = {
    titles: ["General Info", "Industry Category Mapping"],
    fields: [
        general_info,
        industry_cat_mapping
    ]
}

export { GroupTradeDeskForm }