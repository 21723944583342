const client_info = [
    {
        label: "Name",
        input: 'input-text',
        name: 'name',
        size: '6'
    },
    {
        label: "Account Status",
        input: 'input-text',
        name: 'account_status',
        size: '6'
    },
    {
        label: "Tax Status",
        input: 'input-text',
        name: 'tax_status',
        size: '6'
    },
    {
        label: "Disabled Reasons",
        input: 'input-text',
        name: 'disabled_reasons',
        size: '6'
    },
    {
        label: "Ad Labels",
        input: 'dropdown',
        name: 'ad_labels',
        multi: true,
        options: []
    },
]

const MetaClientForm = {    
    titles: ["Client Info"],
    fields: [
        client_info
    ]
}

export { MetaClientForm }