import { React, useState } from 'react'
import { Row, Col, Button, Form } from 'react-bootstrap'
import Card from '../../../components/Card'
import Select from 'react-select'
import axios from 'axios'
import Loader from '../../../components/Loader'

import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import DatePickerValue from '../../../components/date';
import { useAppContext } from '../../../context/appContext'

const generateRandom = () => {
    var length = 8,
        charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
        retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
}

const savedReports = [
    { value: "imp", label: "Impression" },
    { value: "prop", label: "Properties" },
    { value: "unknown", label: "Anonymous Users" },
    { value: "yt", label: "Youtube Report" },
    { value: "loc", label: "Location" },
    { value: "exc", label: "Exchange Report" },

]
const timeOptions = [
    { value: "1", label: "Yesterday" },
    { value: "tweek", label: "This Week" },
    { value: "tmonth", label: "This Month" },
    { value: "tyear", label: "This Year" },
    { value: "lweek", label: "Last Week" },
    { value: "7", label: "Last 7 Days" },
    { value: "lmonth", label: "Last Month" },
    { value: "30", label: "Last 30 Days" },
    { value: "60", label: "Last 60 Days" },
]

export default function SavedReports() {
    const appValue = useAppContext();
    var today = (new Date()).toISOString().split('T')[0];
    const defCol = [{}]
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const [columns, setColumns] = useState(defCol)
    const [show, setShow] = useState(false)
    const [sdate, setSdate] = useState(today)
    const [edate, setEdate] = useState(today)
    const pageSizes = [10, 25, 50, 100];



    var REACT_APP_API_URL = appValue.apiUrl;
    const { NODE_ENV } = process.env;
    const LoadReport = (event) => {
        event.preventDefault();
        setLoading(true)
        let report
        switch (event.target.report.value) {
            case 'imp':
                let col = [
                    { field: 'date', headerName: 'Date', flex: 1, sortable: true },
                    { field: 'aid', headerName: 'Advertiser', flex: 1, sortable: true },
                    { field: 'cid', headerName: 'Campaign', flex: 1, sortable: true },
                    { field: 'oid', headerName: 'Order', flex: 1, sortable: true },
                    { field: 'lid', headerName: 'Group', flex: 1, sortable: true },
                    // { field: 'channel', headerName: 'Channel', flex: 1, sortable: true },
                    { field: 'impressions', headerName: 'Impressions', flex: 1, sortable: true },
                    { field: 'clicks', headerName: 'Clicks', flex: 1, sortable: true },
                    { field: 'conversions', headerName: 'Conversions', flex: 1, sortable: true },
                    // { field: 'last_click', headerName: 'Last Click Conversions', flex: 1, sortable: true },
                    { field: 'cost', headerName: 'Cost', flex: 1, sortable: true },
                ]
                setColumns(col)
                report = "imp"

                break;
            case 'prop':
                let col2 = [
                    { field: 'date', headerName: 'Date', flex: 1, sortable: true },
                    { field: 'aid', headerName: 'Advertiser', flex: 1, sortable: true },
                    { field: 'cid', headerName: 'Campaign', flex: 1, sortable: true },
                    { field: 'oid', headerName: 'Order', flex: 1, sortable: true },
                    { field: 'lid', headerName: 'Group', flex: 1, sortable: true },
                    { field: 'properties', headerName: 'Property', flex: 1, sortable: true },
                    { field: 'impressions', headerName: 'Impressions', flex: 1, sortable: true },
                    { field: 'clicks', headerName: 'Clicks', flex: 1, sortable: true },
                    { field: 'conversions', headerName: 'Conversions', flex: 1, sortable: true },
                    // { field: 'last_click', headerName: 'Last Click Conversions', flex: 1, sortable: true },
                    { field: 'cost', headerName: 'Cost', flex: 1, sortable: true },
                ]
                setColumns(col2)
                report = "prop"

                break;
            case 'unknown':
                let col3 = [
                    { field: 'date', headerName: 'Date', flex: 1, sortable: true },
                    { field: 'aid', headerName: 'Advertiser', flex: 1, sortable: true },
                    { field: 'cid', headerName: 'Campaign', flex: 1, sortable: true },
                    { field: 'oid', headerName: 'Order', flex: 1, sortable: true },
                    { field: 'lid', headerName: 'Group', flex: 1, sortable: true },
                    { field: 'source', headerName: 'Exchange', flex: 1, sortable: true },
                    { field: 'channel', headerName: 'Channel', flex: 1, sortable: true },
                    // { field: 'device_make', headerName: 'Make', flex: 1, sortable: true },
                    // { field: 'device_model', headerName: 'Model', flex: 1, sortable: true },
                    // { field: 'browser', headerName: 'Browser', flex: 1, sortable: true },
                    // { field: 'os', headerName: 'Operating System', flex: 1, sortable: true },
                    // { field: 'region', headerName: 'Region', flex: 1, sortable: true },
                    { field: 'country', headerName: 'Country', flex: 1, sortable: true },
                    { field: 'impressions', headerName: 'Impressions', flex: 1, sortable: true },
                    { field: 'clicks', headerName: 'Clicks', flex: 1, sortable: true },
                    { field: 'conversions', headerName: 'Conversions', flex: 1, sortable: true },
                    // { field: 'last_click', headerName: 'Last Click Conversions', flex: 1, sortable: true },
                    { field: 'cost', headerName: 'Cost', flex: 1, sortable: true },
                ]
                setColumns(col3)
                report = "unknown"

                break;
            case 'loc':
                let col4  = [
                    { field: 'date', headerName: 'Date', flex: 1, sortable: true },
                    { field: 'aid', headerName: 'Advertiser', flex: 1, sortable: true },
                    { field: 'cid', headerName: 'Campaign', flex: 1, sortable: true },
                    { field: 'oid', headerName: 'Order', flex: 1, sortable: true },
                    { field: 'lid', headerName: 'Group', flex: 1, sortable: true },
                    { field: 'city', headerName: 'City', flex: 1, sortable: true },
                    { field: 'region', headerName: 'Region', flex: 1, sortable: true },
                    { field: 'postal_code', headerName: 'Postal Code', flex: 1, sortable: true },
                    { field: 'country', headerName: 'Country', flex: 1, sortable: true },
                    { field: 'impressions', headerName: 'Impressions', flex: 1, sortable: true },
                    { field: 'clicks', headerName: 'Clicks', flex: 1, sortable: true },
                    { field: 'conversions', headerName: 'Conversions', flex: 1, sortable: true },
                    // { field: 'last_click', headerName: 'Last Click Conversions', flex: 1, sortable: true },
                    { field: 'cost', headerName: 'Cost', flex: 1, sortable: true },
                ]
                setColumns(col4)
                report = "loc"

                break;
            case 'exc':
                let col5 = [
                    { field: 'date', headerName: 'Date', flex: 1, sortable: true },
                    { field: 'aid', headerName: 'Advertiser', flex: 1, sortable: true },
                    { field: 'cid', headerName: 'Campaign', flex: 1, sortable: true },
                    { field: 'oid', headerName: 'Order', flex: 1, sortable: true },
                    { field: 'lid', headerName: 'Group', flex: 1, sortable: true },
                    { field: 'source', headerName: 'Exchange', flex: 1, sortable: true },
                    { field: 'impressions', headerName: 'Impressions', flex: 1, sortable: true },
                    { field: 'clicks', headerName: 'Clicks', flex: 1, sortable: true },
                    { field: 'conversions', headerName: 'Conversions', flex: 1, sortable: true },
                    // { field: 'last_click', headerName: 'Last Click Conversions', flex: 1, sortable: true },
                    { field: 'cost', headerName: 'Cost', flex: 1, sortable: true },
                ]
                setColumns(col5)
                report = "exc"

                break;
            case 'yt':
                let col6 = [
                    { field: 'date', headerName: 'Date', flex: 1, sortable: true },
                    { field: 'aid', headerName: 'Advertiser', flex: 1, sortable: true },
                    { field: 'oid', headerName: 'Order', flex: 1, sortable: true },
                    { field: 'lid', headerName: 'Line', flex: 1, sortable: true },
                    { field: 'youtube', headerName: 'YouTubeGroup ID', flex: 1, sortable: true },
                    { field: 'properties', headerName: 'Properties', flex: 1, sortable: true, renderCell: (params) => (<a href={"//" + params.row.source} target='_blank' rel="noreferrer">{params.row.properties}</a>) },
                    { field: 'impressions', headerName: 'Impressions', flex: 1, sortable: true },
                    { field: 'trueview', headerName: 'TrueView', flex: 1, sortable: true },
                    { field: 'tvr', headerName: 'TVR', flex: 1, sortable: true },
                    { field: 'cost', headerName: 'Cost', flex: 1, sortable: true },
                ]
                setColumns(col6)
                report = "yt"

                break;
            default:
        }
        let postData = {
            "startDate": sdate,
            "endDate": edate,
            "report": report,
        }
        return axios.post(REACT_APP_API_URL + "/reports/saved", postData, appValue.config).then((response) => {
            if (response.data) {
                setData(response.data)
                setLoading(false)
                if (NODE_ENV == 'development') {
                    console.log(response.data)
                }
            } else {
                <p>{response.error}</p>
            }
        })
    }
    const OnDateSelect = (event) => {
        let val = event.value
        let start;
        let end;
        let today = new Date();
        let date = new Date()
        let dow = date.getDay()

        switch (val) {
            case "1":
                start = date
                start.setDate(date.getDate() - 1);
                end = start
                break;
            case "tweek":
                if (dow > 0) {
                    start = date
                    start.setDate(date.getDate() - dow);
                    end = today
                } else {
                    start = today
                    end = today
                }
                break;
            case "tmonth":
                date.setDate(1)
                start = date;
                end = today
                break;
            case "tyear":
                date.setMonth(0, 1)
                start = date;
                end = today
                break;
            case "lweek":
                start = date
                start.setDate(start.getDate() - (dow + 7));
                end = today
                end.setDate(end.getDate() - (dow + 1));
                break;
            case "7":
                date.setDate(date.getDate() - 7);
                start = date
                end = today
                break;
            case "lmonth":
                start = date;
                start.setMonth(start.getMonth() - 1, 1)
                end = today;
                end.setMonth(end.getMonth(), 0)
                break;
            case "30":
                start = date;
                start.setDate(start.getDate() - 30);
                end = today
                break;
            case "60":
                start = date;
                start.setDate(start.getDate() - 60);
                end = today
                break;
            default:
                start = today
                end = today
        }
        let formatStart = start.toISOString().split('T')[0]
        let formatEnd = end.toISOString().split('T')[0] 
        setSdate(formatStart)
        setEdate(formatEnd)
    }
    return <>
        <div>
            <Row>
                <Col sm="12">
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">Reporting</h4>
                            </div>
                            {/* <div>
                                <Button className="text-center btn-dark btn-icon mt-lg-0 mt-md-0 mt-3" variant="dark" >
                                    <i className="btn-inner">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                        </svg>
                                    </i>
                                    <span>New Report</span>
                                </Button>
                            </div> */}
                        </Card.Header>
                        <Card.Body className="px-0">
                            <Row>
                                <div className="mb-4 px-5" style={{ 'z-index': 1000000 }}>
                                    <Form onSubmit={LoadReport}>
                                        <Form.Group as={Row}>
                                            <Row>
                                                <Col lg="4" sm="4" className='mb-3'>
                                                    <Select className="report-select" options={timeOptions} onChange={OnDateSelect} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="4" sm="4" className='mb-3'>
                                                   <DatePickerValue start={sdate} end={edate} sState={setSdate} eState={setEdate}/>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="4" sm="4">
                                                    <Select name="report" options={savedReports} />
                                                </Col>
                                                <Col lg="2">
                                                    <Button variant="dark" type='submit' >
                                                        Run Report
                                                    </Button>{' '}
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Form>
                                </div>
                            </Row>
                            <Row>
                                <Col lg="12" sm="12">
                                    {(loading) ? (<Loader></Loader>) : (<></>)}
                                    {(data.data && !loading) ? (
                                        <DataGrid
                                            rows={data.data ?? []}
                                            columns={columns}
                                            // pageSize={page_size}
                                            // rowsPerPageOptions={[row_size]}
                                            // checkboxSelection
                                            getRowId={(row) => generateRandom()}
                                            // initalState={{filter: {filterModel:{filter}}}}
                                            slots={{ toolbar: GridToolbar }}
                                            slotProps={{
                                                toolbar: {
                                                    showQuickFilter: true,
                                                    quickFilterProps: { debounceMs: 500 },
                                                },
                                            }}
                                            autoHeight
                                        />
                                    ) : (
                                        <></>
                                    )}

                                </Col>

                            </Row>

                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    </>

}
