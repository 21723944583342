import Select from 'react-select'
import axios from 'axios'
import { React, useState } from 'react'
import { Row, Col, Button, Form, InputGroup } from 'react-bootstrap'
import Card from '../components/Card'
import { CurrencySymbol } from './common';
import { Slider } from '@mui/material';
import { useAppContext } from '../context/appContext'

const Automizer = (props) => {
    const appValue = useAppContext();
    const data = props.data
    let options = [];
    let dimensions = [];
    let metrics = [];

    // console.log(props)
    const [editAuto, setEditAuto] = useState(false);
    const [initialLoad, setInitialLoad] = useState(true)
    const [toggleState, setToggleState] = useState({
        order: false,
        line: false
    })
    const [labels, setLabels] = useState({
        dimension: "",
        metric: ""
    })
    const [automizer, setAutomizer] = useState({
        optimization: null,
        threshold: 20,
        othreshold: 10,
        dimension: null,
        metric: null,
        frequency: null,
        aggregation: null,
        cid: data.data._id,
        kpi: null,
        weight: 50,
    })

    var REACT_APP_API_URL = appValue.apiUrl;
    const { NODE_ENV } = process.env;

    const toggleHandle = (name) => {
        if (name === "line") {
            setToggleState({ ...toggleState, line: true, order: false },)
        }
        if (name === "order") {
            setToggleState({ ...toggleState, order: true, line: false },)
        }
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        if (NODE_ENV == 'development') {
            console.log(automizer)
        }

        let url;
        if (props.label === "order") {
            url = REACT_APP_API_URL + '/automizer/update/order';

        } else {
            url = REACT_APP_API_URL + '/automizer/update/line';
        }

        return axios.patch(url, automizer, appValue.config).then((response) => {
            if (response.data) {
                if (NODE_ENV == 'development') {

                    console.log(response.data);
                }
                setEditAuto(false)
            } else {
                <p>{response.error}</p>
            }
        })
    }


    if (props.label === "order") {
        options = [
            { value: "none", label: "None" },
            { value: "auto_lba", label: "Line Budget Allocator" }
        ]
    } else if (props.label === "line") {
        options = [
            { value: "none", label: "None" },
            { value: "auto_ba", label: "Bid Adjuster" }
        ]
        dimensions = [
            // { value: "dim_geo", label: "Location" },
            // { value: "dim_os", label: "Operating System" },
            { value: "dim_sai", label: "Site/App ID" },
            // { value: "dim_tod", label: "Time of Day" },
        ]
        metrics = [
            { value: "met_pcv", label: "Player Complete Views" },
            { value: "met_tcc", label: "Total Click+Conversions" },
        ]
    }
    if (initialLoad) {
        if (data.data.automizer) {
            if (NODE_ENV === "development") {
                console.log(data.data.automizer)
            }
            if (data.data.automizer.optimization === "auto_ba") {
                for (let i = 0; i < dimensions.length; i++) {
                    if (dimensions[i].value === data.data.automizer.dimension) {
                        labels.dimension = dimensions[i].label
                    }
                }
                for (let x = 0; x < metrics.length; x++) {
                    if (metrics[x].value === data.data.automizer.metric) {
                        labels.metric = metrics[x].label
                    }
                }
                if (data.data.automizer.aggregation === "order") {
                    toggleState.order = true
                }
                if (data.data.automizer.aggregation === "line") {
                    toggleState.line = true
                }
                //Set Defaults 
                automizer.optimization = data.data.automizer.optimization
                automizer.threshold = data.data.automizer.threshold
                automizer.othreshold = data.data.automizer.othreshold
                automizer.dimension = data.data.automizer.dimension
                automizer.metric = data.data.automizer.metric
                automizer.frequency = data.data.automizer.frequency
                automizer.aggregation = data.data.automizer.aggregation
            }
            if (data.data.automizer.optimization !== "auto_ba") {

                automizer.optimization = data.data.automizer.optimization
                automizer.kpi = data.data.automizer.kpi
                automizer.weight = data.data.automizer.weight

                if (data.data.automizer.kpi === "cp") {
                    toggleState.line = true
                }
                if (data.data.automizer.kpi === "roas") {
                    toggleState.order = true
                }
            }
        }
    }
    const valuetext = (value) => {
        return `${value}`;
    }

    return (
        <Card>
            <Card.Header className="d-flex justify-content-between">
                <div className="header-title">
                    <h2 className="card-title">Optimizations</h2>
                </div>

                <div>
                    <Button className="text-center btn-dark btn-icon mt-lg-0 mt-md-0 mt-3" variant="dark" onClick={() => {
                        setEditAuto(true);
                        if (NODE_ENV === "development") {
                            console.log(data.data.automizer)
                        }
                    }}>
                        <span>Edit</span>
                    </Button>
                </div>
            </Card.Header>
            {(editAuto) ? (
                <Card.Body>
                    <Form onSubmit={
                        (event) => {
                            handleSubmit(event);
                        }
                    }>
                        <Row>
                            <Col lg="4" sm="12">

                                <Form.Group className="mb-3" >
                                    <Form.Label>Choose Optimization</Form.Label>
                                    <Select
                                        name="optimization"
                                        defaultValue={() => {
                                            for (let i = 0; i < options.length; i++) {
                                                if (options[i].value === automizer.optimization) {
                                                    return options[i]
                                                }
                                            }
                                        }}
                                        options={options} required onChange={(option) => { setInitialLoad(false); setAutomizer({ ...automizer, optimization: option.value }); }}
                                    />
                                </Form.Group>
                            </Col>
                            {
                                (automizer.optimization === "auto_lba") ? (<>
                                    <Col lg="4" sm="12">
                                        <Form.Label>KPI</Form.Label>
                                        <Form.Group className="mb-3" >
                                            <Form.Check
                                                inline
                                                label="Cost Per"
                                                name="kpi"
                                                type="radio"
                                                id={`kpi-1`}
                                                onChange={(e) => { setAutomizer({ ...automizer, kpi: "cp" }); setToggleState({ ...toggleState, line: true, order: false }) }}
                                                defaultChecked={toggleState.line}
                                            />
                                            <Form.Check
                                                inline
                                                label="ROAS"
                                                name="kpi"
                                                type="radio"
                                                onChange={(e) => { setAutomizer({ ...automizer, kpi: "roas" }); setToggleState({ ...toggleState, line: false, order: true }) }}
                                                id={`kpi-2`}
                                                defaultChecked={toggleState.order}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col lg="4" sm="12">
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Spend Weight</Form.Label>
                                            <Slider
                                                getAriaLabel={() => 'Spend Weight'}
                                                value={automizer.weight}
                                                onChange={(e) => setAutomizer({ ...automizer, weight: e.target.value })}
                                                name="sweight"
                                                valueLabelDisplay="auto"
                                                min={0}
                                                max={100}
                                                getAriaValueText={valuetext}
                                            />
                                        </Form.Group>
                                    </Col>

                                </>
                                ) : (<></>)}
                        </Row>
                        {(automizer.optimization === "auto_ba") ? (
                            <><Row>
                                <Col lg="4" sm="12">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Select Dimension</Form.Label>
                                        <Select
                                            name="dimension"
                                            defaultValue={() => {
                                                for (let i = 0; i < dimensions.length; i++) {
                                                    if (dimensions[i].value === automizer.dimension) {
                                                        return dimensions[i]
                                                    }
                                                }
                                            }}
                                            onChange={(e) => { setAutomizer({ ...automizer, dimension: e.value }); setLabels({ ...labels, dimension: e.label }) }}
                                            options={dimensions} required
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg="4" sm="12">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Select Metric</Form.Label>
                                        <Select
                                            name="metric"
                                            defaultValue={() => {
                                                for (let i = 0; i < metrics.length; i++) {
                                                    if (metrics[i].value === automizer.metric) {
                                                        return metrics[i]
                                                    }
                                                }
                                            }}
                                            onChange={(e) => { setAutomizer({ ...automizer, metric: e.value }); setLabels({ ...labels, metric: e.label }) }}

                                            options={metrics} required
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg="4" sm="12">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Set Frequency</Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Control type="number"
                                                defaultValue={automizer.frequency} name="frequency" min="1" max="5"
                                                onChange={(e) => { setAutomizer({ ...automizer, frequency: e.target.value }) }}

                                            />
                                            <InputGroup.Text id="basic-addon1">Days</InputGroup.Text>
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                            </Row>
                                <Row>
                                    <Col lg="4" sm="12">
                                        <Form.Group className="mb-3">
                                            <Form.Label>Spend Threshold</Form.Label>
                                            <InputGroup className="mb-3">
                                                <InputGroup.Text id="basic-addon1"><CurrencySymbol cur={props.cur} /></InputGroup.Text>
                                                <Form.Control type="number" defaultValue={automizer.threshold} name="threshold" min="1"
                                                    onChange={(e) => setAutomizer({ ...automizer, threshold: e.value })}

                                                />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col lg="4" sm="12">
                                        <Form.Group className="mb-3">
                                            <Form.Label>Optimization Threshold</Form.Label>
                                            <InputGroup className="mb-3">
                                                <InputGroup.Text id="basic-addon1"><CurrencySymbol cur={props.cur} /></InputGroup.Text>
                                                <Form.Control type="number" defaultValue={automizer.othreshold} name="othreshold" min="1"
                                                    onChange={(e) => setAutomizer({ ...automizer, othreshold: Number(e.value) })}

                                                />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col lg="4" sm="12">
                                        <Form.Label>Aggregation Level</Form.Label>
                                        <Form.Group className="mb-3" >
                                            <Form.Check
                                                inline
                                                label="Group"
                                                name="agg"
                                                type="radio"
                                                onChange={(e) => { setAutomizer({ ...automizer, aggregation: "line" }); toggleHandle("line") }}
                                                id={`agg-1`}
                                                defaultChecked={toggleState.line}
                                            />
                                            <Form.Check
                                                inline
                                                label="Order"
                                                name="agg"
                                                type="radio"
                                                onChange={(e) => { setAutomizer({ ...automizer, aggregation: "order" }); toggleHandle("order") }}
                                                id={`agg-2`}
                                                defaultChecked={toggleState.order}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row></>
                        ) : (<></>)}
                        <div className="d-flex justify-content-between">
                            <Button variant="danger" onClick={() => setEditAuto(false)}>
                                Cancel
                            </Button>
                            <Button variant="dark" type='submit'>
                                Update
                            </Button>{' '}
                        </div>
                    </Form>
                </Card.Body>
            ) : (
                <Card.Body>
                    {(props.label === "order") ? (
                        <Row>
                            {(automizer.optimization === "auto_lba") ? (<>
                                <Col lg="4" sm="12">
                                    <Card.Header.Title>
                                        Auto Bid Allocation
                                    </Card.Header.Title>
                                    <Card.Body>
                                        <Row>
                                            <Col lg="6" sm="12">
                                                <span className={`badge ${automizer.optimization === "auto_lba" ? "bg-success" : "bg-danger"}`}>{automizer.optimization === "auto_lba" ? "Active" : "Inactive"}</span>
                                            </Col>

                                        </Row>
                                    </Card.Body>
                                </Col>
                                <Col lg="4" sm="12">
                                    <Card.Header.Title>
                                        KPI
                                    </Card.Header.Title>
                                    <Card.Body>
                                        <Row>
                                            <Col lg="6" sm="12">
                                                <span className={`badge ${automizer.kpi ? "bg-success" : "bg-danger"}`}>{automizer.kpi ? automizer.kpi : "Not Set"}</span>
                                            </Col>

                                        </Row>
                                    </Card.Body>
                                </Col></>
                            ) : (<>
                                <Row>
                                    <Col lg="4" sm="12">
                                        <Card.Header.Title>
                                            Auto Bid Allocation
                                        </Card.Header.Title>
                                        <Card.Body>
                                            <span className={`badge ${false ? "bg-success" : "bg-danger"}`}>{false ? "Active" : "Inactive"}</span>
                                        </Card.Body>
                                    </Col>
                                </Row></>
                            )}

                        </Row>

                    ) : (<></>)}

                    {(props.label === "line") ? (

                        <Row>
                            <Col lg="6" sm="12">
                                <Card.Header.Title>
                                    Bid Algorithm
                                </Card.Header.Title>
                                <Card.Body>
                                    {(automizer.optimization === "auto_ba") ? (
                                        <>
                                            <Row>
                                                <Col lg="12">
                                                    <span>{labels.metric} by {labels.dimension} every {automizer.frequency} days</span>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="4" sm="12">
                                                    <span>Optimziation Threshold: {automizer.othreshold}</span>
                                                </Col>
                                                <Col lg="4" sm="12">
                                                    <span>Spend Threshold: {automizer.threshold}</span>
                                                </Col>
                                                <Col lg="4" sm="12">
                                                    <span>Agreggation Level: {automizer.aggregation}</span>
                                                </Col>
                                            </Row>
                                        </>
                                    ) : (<span className={`badge  bg-danger`}>Not Set</span>)}
                                </Card.Body>
                            </Col>

                        </Row>
                    ) : (<></>)}
                </Card.Body>
            )}
        </Card>
    )

}
export default Automizer
