import { React, useState, useEffect, useLayoutEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import Card from '../../../components/Card'
import Loader from '../../../components/Loader'
import { FetchFromAPI } from '../../../components/ajx'
import { columnsGroup } from '../../../components/gridColumns'
import ReactTable from '../../../components/table'
import { DateFormat } from '../../../components/common'

import { GroupForm } from '../../../util/group-input'
import { SearchGroupForm } from '../../../util/group-input-search'
import { MetaGroupForm } from '../../../util/meta-group-input'
import { GroupModal, GroupSearchModal, GroupMetaModal, YTGroupModal } from '../../../components/Modals'
import { ModalButton } from '../../../components/ModalSettings'
import { GroupSearchModel, GroupModel, MetaGroupModel, MetaInputBriefModel } from '../../../components/Models'
import { useAppContext } from '../../../context/appContext'
import { YTGroupForm } from '../../../util/youtube-input'

export default function GroupList() {
   const appValue = useAppContext();
   const [dvModal, setDvModal] = useState(false);
   const [searchModal, setSearchModal] = useState(false);
   const [metaModal, setMetaModal] = useState(false);
   const [YTModal, setYTModal] = useState(false);
   const [searchGroup, setSearchGroup] = useState(GroupSearchModel);
   //TODO: Figure out out how to reset variable research Shallow Copy and Deep Copy
   const [dvGroup, setDvGroup] = useState(GroupModel);
   const [metaGroup, setMetaGroup] = useState(MetaGroupModel);
   const [inputBrief, setInputBrief] = useState(MetaInputBriefModel);

   const { NODE_ENV } = process.env;

   var REACT_APP_API_URL = appValue.apiUrl;

   const groupApi = {
      urls: [
         {
            name: "groups",
            url: REACT_APP_API_URL + "/group/list",
         },
         {
            name: "orders",
            url: REACT_APP_API_URL + "/order/list",
         },
         {
            name: "creatives",
            url: REACT_APP_API_URL + "/creative/list",
         },
         {
            name: "clients",
            url: REACT_APP_API_URL + "/client/list"
         },
         {
            name: "campaigns",
            url: REACT_APP_API_URL + "/campaign/list"
         },
         {
            name: "input-briefs",
            url: REACT_APP_API_URL + "/input-brief/list",
         }
      ],
      method: "GET",

   }

   const { results, errors, complete } = FetchFromAPI(groupApi);

   const options = [
      { name: "DV360", setModal: setDvModal },
      { name: "SA360", setModal: setSearchModal },
      { name: "Meta", setModal: setMetaModal },
      { name: "YouTube", setModal: setYTModal }
   ]

   // push all input briefs in the "ready" stage to the dropdown opts 
   const ibOpts = [];
   if (results && results["input-briefs"] && results["input-briefs"].data) {
      for (let i = 0; i < results["input-briefs"].data.length; i++) {
         const b = results["input-briefs"].data[i];
         if (b.stage === 'ready') {
            ibOpts.push({ value: b._id, inputBrief: b, label: b.name, name: b.name })
         }
      }
   }

   // once an input brief has been selected, autofill the relevant group fields with data from the brief
   useLayoutEffect(() => {
      const ib = inputBrief;
      if (ib.name !== '') {
         metaGroup.name = ib.input_brief.group.final_group_name;
         metaGroup.creative.name = ib.input_brief.group.creative_name;
         metaGroup.creative.call_to_action_type = ib.input_brief.group.creative_type;
         metaGroup.creative.id = ib.input_brief.group.creative_id;
         metaGroup.creative.object_url = ib.input_brief.group.landing_page_url;
         metaGroup.creative.link_destination_display_url = ib.input_brief.group.display_link;
         metaGroup.creative.url_tags = ib.input_brief.group.url_params;
      }
   }, [inputBrief])

   useEffect(() => {
      if (complete) {
         if (NODE_ENV === "development") {
            console.log(results)
         }
         // if (results["groups"] && results["groups"].data && results["groups"].data[0].dv_line && results["groups"].data[0].dv_line.flight && results["groups"].data[0].dv_line.flight.dateRange) {
         //    group.flight_start_date = results["groups"].data[0].dv_line.flight.dateRange.startDate
         //    group.flight_end_date = results["groups"].data[0].dv_line.flight.dateRange.endDate
         // }
         // if (results["orders"] && results["orders"].data && results["orders"].data[0].dv_order && results["orders"].data[0].dv_order.budget && results["orders"].data[0].dv_order.budget.budgetUnit) {
         //    group.budget_unit = results["orders"].data[0].dv_order.budget.budgetUnit
         // }
         if (results["orders"] && results["orders"].data) {
            for (let i = 0; i < results["orders"].data.length; i++) {
               const o = results["orders"].data[i];
               const order_label = `${o.name} | ${o.platform_id}`;

               if (o.platform === "DV") {
                  if (results["orders"].data && o.dv_order && o.budget && o.budget.budgetSegments) {
                     const startDate = o.dv_order.budget.budgetSegments[0].dateRange.startDate;
                     const endDate = o.dv_order.budget.budgetSegments[0].dateRange.endDate;
                     const formattedStartDate = DateFormat(startDate.year, startDate.month, startDate.day)
                     const formattedEndDate = DateFormat(endDate.year, endDate.month, endDate.day)
                     GroupForm.fields[0][0].options.push({ value: o.platform_id.toString(), label: order_label, name: 'order_id', start_date: formattedStartDate, end_date: formattedEndDate })
                  } else {
                     GroupForm.fields[0][0].options.push({ value: o.platform_id.toString(), label: order_label, name: 'order_id', advertiser_id: o.client_id.toString(), campaign_id: o.campaign_id.toString() })
                     YTGroupForm.fields[0][0].options.push({ value: o.platform_id.toString(), label: order_label, name: 'order_id', advertiser_id: o.client_id.toString(), campaign_id: o.campaign_id.toString() })

                  }
               }
               if (o.platform === "Meta") {
                  MetaGroupForm.fields[0][4].options.push({ label: order_label, value: o.platform_id.toString(), name: 'adset_id' })
               }
            }
         }
         if (results["creatives"] && results["creatives"].data && results["creatives"].data.length > 0) {
            for (let i = 0; i < results["creatives"].data.length; i++) {
               const c = results["creatives"].data[i];
               const creative_label = `${c.name} | ${c.platform_id}`

               if (c.platform === 'DV') {
                  GroupForm.fields[2][0].options.push({ label: creative_label, value: c.platform_id.toString(), name: 'creatives', advertiser_id: c.platform_id.toString() })
               }
               if (c.platform === 'Meta') {
                  MetaGroupForm.fields[1][0].options.push({ label: creative_label, value: c.platform_id.toString(), name: 'creative.id', advertiser_id: c.client_id.toString() })
               }
            }
         }
         if (results["campaigns"] && results["campaigns"].data) {
            for (let i = 0; i < results["campaigns"].data.length; i++) {
               const c = results["campaigns"].data[i];
               const campaign_label = `${c.name} | ${c.platform_id}`
               if (c.platform === "Meta") {
                  MetaGroupForm.fields[0][5].options.push({ label: campaign_label, value: c.platform_id.toString(), name: 'campaign_id' })
               }
            }
         }
         if (results["clients"] && results["clients"].data) {
            for (let i = 0; i < results["clients"].data.length; i++) {
               const c = results["clients"].data[i];
               if (c.platform === 'Meta') {
                  if (c.ad_labels && c.ad_labels.length > 0) {
                     for (let i = 0; i < c.ad_labels.length; i++) {
                        const a = c.ad_labels[i]
                        const ad_label = `${a.name} | ${a.id}`
                        MetaGroupForm.fields[0][3].options.push({ label: ad_label, value: a.id, name: 'adlabels' })
                     }
                  }
               }
            }
         }
         if (results["groups"] && results["groups"].data) {
            for (let i = 0; i < results["groups"].data.length; i++) {
               const g = results["groups"].data[i];
               if (g.platform === "Meta") {
                  const g_label = `${g.name} | ${g.id}`
                  MetaGroupForm.fields[0][7].options.push({ label: g_label, value: g.id, name: 'source_ad_id' })
               }
            }
         }
      }
   }, [complete])

   if (complete) {
      return errors.length > 0 ? (<><span>Error: {errors}</span></>) : (
         <>
            <div>
               <Row>
                  <Col sm="12">
                     <Card>
                        <Card.Header className="d-flex justify-content-between">
                           <div className="header-title">
                              <h4 className="card-title">Group List</h4>
                           </div>
                           <div>
                              <ModalButton name="New Group" setModal={setDvModal} options={options} />
                           </div>
                        </Card.Header>
                        <Card.Body className="px-0">
                           <div className="table-responsive">
                              <ReactTable item={results["groups"]} col={columnsGroup} page_size={20} row_size={20} />
                           </div>
                        </Card.Body>
                     </Card>
                  </Col>
               </Row>
            </div>
            <div>
               <GroupModal group={dvGroup} setGroup={setDvGroup} form={GroupForm} formId='groupForm' modal={dvModal} setModal={setDvModal} baseUrl={REACT_APP_API_URL} />
               <GroupSearchModal group={searchGroup} setGroup={setSearchGroup} form={SearchGroupForm} formId='groupForm' modal={searchModal} setModal={setSearchModal} baseUrl={REACT_APP_API_URL} />
               <GroupMetaModal group={metaGroup} setGroup={setMetaGroup} form={MetaGroupForm} formId='groupForm' modal={metaModal} setModal={setMetaModal} baseUrl={REACT_APP_API_URL} inputBriefOpts={ibOpts} setInputBrief={(e) => setInputBrief(e.inputBrief)} />
               <YTGroupModal group={dvGroup} setGroup={setDvGroup} form={YTGroupForm} formId='groupForm' modal={YTModal} setModal={setYTModal} baseUrl={REACT_APP_API_URL} />

            </div>
         </>
      )
   }
   return <><div><span><Loader></Loader></span></div></>;

}
