import Breadcrumb from 'react-bootstrap/Breadcrumb';

function Crumbs(params) {
    let crumbs = params.data

    if (params.type === "campaign") {
        let url = "/app/client/"+params.platform+"/" + crumbs
        return (

            <Breadcrumb>


                <Breadcrumb.Item href={url}>Client</Breadcrumb.Item>

            </Breadcrumb>
        )
    } else {

        return (
            <Breadcrumb>
                {(crumbs.length > 0) ? (
                    crumbs.map((crumb, i) => {
                        switch (params.type) {

                            case "order":
                                if (i === 0) {
                                    let url = "/app/client/"+params.platform+"/" + crumb
                                    return (
                                        <Breadcrumb.Item href={url} key={i}>Client</Breadcrumb.Item>
                                    )
                                } else {
                                    let url = "/app/campaign/"+params.platform+"/" + crumb
                                    return (
                                        <Breadcrumb.Item href={url} key={i}>Campaign</Breadcrumb.Item>
                                    )
                                }
                            case "group":
                                if (i === 0) {
                                    let url = "/app/client/"+params.platform+"/" + crumb
                                    return (
                                        <Breadcrumb.Item href={url} key={i}>Client</Breadcrumb.Item>
                                    )
                                } else if (i === 1) {
                                    let url = "/app/campaign/"+params.platform+"/" + crumb
                                    return (
                                        <Breadcrumb.Item href={url} key={i}>Campaign</Breadcrumb.Item>
                                    )
                                } else if (params.platform == "TTD"){
                                    let url = "/app/campaign/"+params.platform+"/" + crumb
                                    return (
                                        <Breadcrumb.Item href={url} key={i}>Campaign</Breadcrumb.Item>
                                    )
                                }else {
                                    let url = "/app/order/"+params.platform+"/" + crumb
                                    return (
                                        <Breadcrumb.Item href={url} key={i}>Order</Breadcrumb.Item>
                                    )
                                }
                            default:

                        }
                    })
                ) : (

                    <></>
                )}
            </Breadcrumb>
        );
    }
}

export default Crumbs;