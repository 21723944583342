const countries = [
    'AD', 'AE', 'AF', 'AG', 'AI', 'AL', 'AM', 'AN', 'AO', 'AQ', 'AR', 'AS', 'AT', 'AU', 'AW', 'AX', 'AZ', 'BA', 'BB', 'BD', 'BE', 'BF', 'BG', 'BH', 'BI', 'BJ', 'BL', 'BM', 'BN', 'BO', 'BQ', 'BR', 'BS', 'BT', 'BV', 'BW', 'BY', 'BZ', 'CA', 'CC', 'CD', 'CF', 'CG', 'CH', 'CI', 'CK', 'CL', 'CM', 'CN', 'CO', 'CR', 'CU', 'CV', 'CW', 'CX', 'CY', 'CZ', 'DE', 'DJ', 'DK', 'DM', 'DO', 'DZ', 'EC', 'EE', 'EG', 'EH', 'ER', 'ES', 'ET', 'FI', 'FJ', 'FK', 'FM', 'FO', 'FR', 'GA', 'GB', 'GD', 'GE', 'GF', 'GG', 'GH', 'GI', 'GL', 'GM', 'GN', 'GP', 'GQ', 'GR', 'GS', 'GT', 'GU', 'GW', 'GY', 'HK', 'HM', 'HN', 'HR', 'HT', 'HU', 'ID', 'IE', 'IL', 'IM', 'IN', 'IO', 'IQ', 'IR', 'IS', 'IT', 'JE', 'JM', 'JO', 'JP', 'KE', 'KG', 'KH', 'KI', 'KM', 'KN', 'KP', 'KR', 'KW', 'KY', 'KZ', 'LA', 'LB', 'LC', 'LI', 'LK', 'LR', 'LS', 'LT', 'LU', 'LV', 'LY', 'MA', 'MC', 'MD', 'ME', 'MF', 'MG', 'MH', 'MK', 'ML', 'MM', 'MN', 'MO', 'MP', 'MQ', 'MR', 'MS', 'MT', 'MU', 'MV', 'MW', 'MX', 'MY', 'MZ', 'NA', 'NC', 'NE', 'NF', 'NG', 'NI', 'NL', 'NO', 'NP', 'NR', 'NU', 'NZ', 'OM', 'PA', 'PE', 'PF', 'PG', 'PH', 'PK', 'PL', 'PM', 'PN', 'PR', 'PS', 'PT', 'PW', 'PY', 'QA', 'RE', 'RO', 'RS', 'RU', 'RW', 'SA', 'SB', 'SC', 'SD', 'SE', 'SG', 'SH', 'SI', 'SJ', 'SK', 'SL', 'SM', 'SN', 'SO', 'SR', 'SS', 'ST', 'SV', 'SX', 'SY', 'SZ', 'TC', 'TD', 'TF', 'TG', 'TH', 'TJ', 'TK', 'TL', 'TM', 'TN', 'TO', 'TR', 'TT', 'TV', 'TW', 'TZ', 'UA', 'UG', 'UM', 'US', 'UY', 'UZ', 'VA', 'VC', 'VE', 'VG', 'VI', 'VN', 'VU', 'WF', 'WS', 'XK', 'YE', 'YT', 'ZA', 'ZM', 'ZW'
];

const CountryOptsCampaign = countries.map(c => ({
    label: c,
    value: c,
    name: 'special_ad_category_country'
}));

const CountryOptsOrder = countries.map(c => ({
    label: c,
    value: c,
    name: 'countries'
}));

const CountryOptsOrderInputBrief = countries.map(c => ({
    label: c,
    value: c,
    name: 'order.countries'
}));

const Objective = [
    // { label: 'App Installs', value: 'APP_INSTALLS', name: 'objective' },
    // { label: 'Brand Awareness', value: 'BRAND_AWARENESS', name: 'objective' },
    // { label: 'Conversions', value: 'CONVERSIONS', name: 'objective' },
    // { label: 'Event Responses', value: 'EVENT_RESPONSES', name: 'objective' },
    // { label: 'Lead Generation', value: 'LEAD_GENERATION', name: 'objective' },
    // { label: 'Link Clicks', value: 'LINK_CLICKS', name: 'objective' },
    // { label: 'Local Awareness', value: 'LOCAL_AWARENESS', name: 'objective' },
    // { label: 'Messages', value: 'MESSAGES', name: 'objective' },
    // { label: 'Offer Claims', value: 'OFFER_CLAIMS', name: 'objective' },
    { label: 'App Promotion', value: 'OUTCOME_APP_PROMOTION', name: 'objective' },
    { label: 'Awareness', value: 'OUTCOME_AWARENESS', name: 'objective' },
    { label: 'Enagement', value: 'OUTCOME_ENGAGEMENT', name: 'objective' },
    { label: 'Leads', value: 'OUTCOME_LEADS', name: 'objective' },
    { label: 'Sales', value: 'OUTCOME_SALES', name: 'objective' },
    { label: 'Traffic', value: 'OUTCOME_TRAFFIC', name: 'objective' },
    // { label: 'Page Likes', value: 'PAGE_LIKES', name: 'objective' },
    // { label: 'Post Engagement', value: 'POST_ENGAGEMENT', name: 'objective' },
    // { label: 'Product Catalog Sales', value: 'PRODUCT_CATALOG_SALES', name: 'objective' },
    // { label: 'Reach', value: 'REACH', name: 'objective' },
    // { label: 'Store Visits', value: 'STORE_VISITS', name: 'objective' },
    // { label: 'Video Visits', value: 'VIDEO_VISITS', name: 'objective' }
]

const Status = [
    { label: 'Active', value: 'ACTIVE', name: 'status' },
    { label: 'Paused', value: 'PAUSED', name: 'status' },
    { label: 'Deleted', value: 'DELETED', name: 'status' },
    { label: 'Archived', value: 'ARCHIVED', name: 'status' },
    { label: 'In Process', value: 'IN_PROCESS', name: 'status' },
    { label: 'With Issues', value: 'WITH_ISSUES', name: 'status' }
]

const BidStrategy = [
    { label: 'Lowest Cost without Cap', value: 'LOWEST_COST_WITHOUT_CAP', name: 'bid_strategy' },
    { label: 'Lowest Cost with Bid Cap', value: 'LOWEST_COST_WITH_BID_CAP', name: 'bid_strategy' },
    { label: 'Cost Cap', value: 'COST_CAP', name: 'bid_strategy' }
]

const BuyingType = [
    { label: 'Auction', value: 'AUCTION', name: 'buying_type' },
    { label: 'Reserved', value: 'RESERVED', name: 'buying_type' }
]

const SpecialAdCategory = [
    { label: 'None', value: 'NONE', name: 'special_ad_categories' },
    { label: 'Employment', value: 'EMPLOYMENT', name: 'special_ad_categories' },
    { label: 'Housing', value: 'HOUSING', name: 'special_ad_categories' },
    { label: 'Credit', value: 'CREDIT', name: 'special_ad_categories' },
    { label: 'Issues, Elections, and Politics', value: 'ISSUES_ELECTIONS_POLITICS', name: 'special_ad_categories' },
    { label: 'Online Gambling and Gaming', value: 'ONLINE_GAMBLING_AND_GAMING', name: 'special_ad_categories' }
]

const SmartPromotionType = [
    { label: 'Guided Creation', value: 'guided_creation', name: 'smart_promotion_type' },
    { label: 'Smart App Promotion', value: 'smart_app_promotion', name: 'smart_promotion_type' },
]

const BillingEvent = [
    { label: 'App Installs', value: 'APP_INSTALLS', name: 'billing_event' },
    { label: 'Clicks', value: 'CLICKS', name: 'billing_event' },
    { label: 'Impressions', value: 'IMPRESSIONS', name: 'billing_event' },
    { label: 'Link Clicks', value: 'LINK_CLICKS', name: 'billing_event' },
    { label: 'Offer Claims', value: 'OFFER_CLAIMS', name: 'billing_event' },
    { label: 'Page Likes', value: 'PAGE_LIKES', name: 'billing_event' },
    { label: 'Post Engagement', value: 'POST_ENGAGEMENT', name: 'billing_event' },
    { label: 'Thruplay', value: 'THRUPLAY', name: 'billing_event' },
    { label: 'Purchase', value: 'PURCHASE', name: 'billing_event' },
    { label: 'Listing Interaction', value: 'LISTING_INTERACTION', name: 'billing_event' }
]

const DestinationType = [
    { label: 'Undefined', value: 'UNDEFINED', name: 'destination_type' },
    { label: 'Website', value: 'WEBSITE', name: 'destination_type' },
    { label: 'App', value: 'APP', name: 'destination_type' },
    { label: 'Messenger', value: 'MESSENGER', name: 'destination_type' },
    { label: 'App Links Automatic', value: 'APPLINKS_AUTOMATIC', name: 'destination_type' },
    { label: 'Facebook', value: 'FACEBOOK', name: 'destination_type' }
]

const FrequencyEvent = [
    { label: 'Impressions', value: 'IMPRESSIONS', name: 'event' },
    { label: 'Video Views', value: 'VIDEO_VIEWS', name: 'event' },
    { label: 'Video Views 2 Seconds', value: 'VIDEO_VIEWS_2S', name: 'event' },
    { label: 'Video Views 15 Seconds', value: 'VIDEO_VIEWS_15S', name: 'event' }
]

const MultiOptimizationGoalWeight = [
    { label: 'Undefined', value: 'UNDEFINED', name: 'multi_optimization_goal_weight' },
    { label: 'Balanced', value: 'BALANCED', name: 'multi_optimization_goal_weight' },
    { label: 'Prefer Install', value: 'PREFER_INSTALL', name: 'multi_optmization_goal_weight' },
    { label: 'Prefer Event', value: 'PREFER_EVENT', name: 'multi_optimization_goal_weight' }
]

const OptimizationGoal = [
    { label: 'None', value: 'NONE', name: 'optimization_goal' },
    { label: 'App Installs', value: 'APP_INSTALLS', name: 'optimization_goal' },
    { label: 'Ad Recall Lift', value: 'Ad Recall Lift', name: 'optimization_goal' },
    { label: 'Engaged Users', value: 'ENGAGED_USERS', name: 'optimization_goal' },
    { label: 'Event Responses', value: 'EVENT_RESPONSES', name: 'optmization_goal' },
    { label: 'Impressions', value: 'IMPRESSIONS', name: 'optimization_goal' },
    { label: 'Lead Generation', value: 'LEAD_GENERATION', name: 'optimization_goal' },
    { label: 'Quality Lead', value: 'QUALITY_LEAD', name: 'optimization_goal' },
    { label: 'Link Clicks', value: 'LINK_CLICKS', name: 'optimization_goal' },
    { label: 'Offsite Conversions', value: 'OFFSITE_CONVERSIONS', name: 'optimization_goal' },
    { label: 'Page Likes', value: 'PAGE_LIKES', name: 'optimization_goal' },
    { label: 'Post Engagement', value: 'POST_ENGAGEMENT', name: 'optimization_goal' },
    { label: 'Quality Call', value: 'QUALITY_CALL', name: 'optimization_goal' },
    { label: 'Reach', value: 'REACH', name: 'optimization_goal' },
    { label: 'Landing Page Views', value: 'LANDING_PAGE_VIEWS', name: 'optimization_goal' },
    { label: 'Visit Instagram Profile', value: 'VISIT_INSTAGRAM_PROFILE', name: 'optimization_goal' },
    { label: 'Value', value: 'VALUE', name: 'optimization_goal' },
    { label: 'Thruplay', value: 'THRUPLAY', name: 'optimization_goal' },
    { label: 'Derived Events', value: 'DERIVED_EVENTS', name: 'optimization_goal' },
    { label: 'App Installs and Offsite Conversions', value: 'APP_INSTALLS_AND_OFFSITE_CONVERSIONS', name: 'optimization_goal' },
    { label: 'Conversions', value: 'CONVERSIONS', name: 'optimization_goal' },
    { label: 'In App Value', value: 'IN_APP_VALUE', name: 'optimization_goal' },
    { label: 'Messaging Purchase Conversion', value: 'MESSAGING_PURCHASE_CONVERSION', name: 'optimization_goal' },
    { label: 'Messaging Appointment Conversion', value: 'MESSAGING_APPOINTMENT_CONVERSION', name: 'optimization_goal' },
    { label: 'Subscribers', value: 'SUBSCRIBERS', name: 'optimization_goal' },
    { label: 'Reminders Set', value: 'REMINDERS_SET', name: 'optimization_goal' }
]
const OptimizationSubEvent = [
    { label: 'None', value: 'NONE', name: 'opt' },
    { label: 'Video Sound On', value: 'VIDEO_SOUND_ON', name: 'opt' },
    { label: 'Trip Consideration', value: 'TRIP_CONSIDERATION', name: 'opt' },
    { label: 'Travel Intent', value: 'TRAVEL_INTENT', name: 'opt' },
    { label: 'Travel Intent No Destination Intent', value: 'TRAVEL_INTENT_NO_DESTINATION_INTENT', name: 'opt' },
    { label: 'Travel Intent Bucket 1', value: 'TRAVEL_INTENT_BUCKET_01', name: 'opt' },
    { label: 'Travel Intent Bucket 2', value: 'TRAVEL_INTENT_BUCKET_02', name: 'opt' },
    { label: 'Travel Intent Bucket 3', value: 'TRAVEL_INTENT_BUCKET_03', name: 'opt' },
    { label: 'Travel Intent Bucket 4', value: 'TRAVEL_INTENT_BUCKET_04', name: 'opt' },
    { label: 'Travel Intent Bucket 5', value: 'TRAVEL_INTENT_BUCKET_05', name: 'opt' },
]

const DevicePlatform = [
    { label: 'Mobile', value: 'mobile', name: 'device_platforms' },
    { label: 'Desktop', value: 'desktop', name: 'device_platforms' }
]


const PublisherPlatform = [
    { label: 'Facebook', value: 'facebook', name: 'publisher_platforms' },
    { label: 'Instagram', value: 'instagram', name: 'publisher_platforms' },
    { label: 'Messenger', value: 'messenger', name: 'publisher_platforms' },
    { label: 'Audience Network', value: 'audience_network', name: 'publisher_platforms' }
]

const FacebookPosition = [
    { label: 'Feed', value: 'feed', name: 'facebook_positions' },
    { label: 'Right Hand Column', value: 'right_hand_column', name: 'facebook_positions' },
    { label: 'Marketplace', value: 'marketplace', name: 'facebook_positions' },
    { label: 'Video Feeds', value: 'video_feeds', name: 'facebook_positions' },
    { label: 'Story', value: 'story', name: 'facebook_positions' },
    { label: 'Search', value: 'search', name: 'facebook_positions' },
    { label: 'Instream Video', value: 'instream_video', name: 'facebook_positions' },
    { label: 'Facebook Reels', value: 'facebook_reels', name: 'facebook_positions' }
]

const InstagramPosition = [
    { label: 'Stream', value: 'stream', name: 'instagram_positions' },
    { label: 'Story', value: 'story', name: 'instagram_positions' },
    { label: 'Explore', value: 'explore', name: 'instagram_positions' },
    { label: 'Explore Home', value: 'explore_home', name: 'instagram_positions' },
    { label: 'Reels', value: 'reels', name: 'instagram_positions' },
    { label: 'Profile Feed', value: 'profile_feed', name: 'instagram_positions' },
    { label: 'IG Search', value: 'ig_search', name: 'instagram_positions' }
]

const MessengerPosition = [
    { label: 'Messenger Home', value: 'messenger_home', name: 'messenger_positions' },
    { label: 'Sponsored Messages', value: 'sponsored_messages', name: 'messenger_positions' },
    { label: 'Story', value: 'story', name: 'messenger_positions' }
]

const Gender = [
    { label: 'Male', value: 1, name: 'gender' },
    { label: 'Female', value: 2, name: 'gender' }
]

const Days = [
    { label: 'Sunday', value: 0, name: 'days' },
    { label: 'Monday', value: 1, name: 'days' },
    { label: 'Tuesday', value: 2, name: 'days' },
    { label: 'Wednesday', value: 3, name: 'days' },
    { label: 'Thursday', value: 4, name: 'days' },
    { label: 'Friday', value: 5, name: 'days' },
    { label: 'Saturday', value: 6, name: 'days' }
]

const TimezoneType = [
    { label: 'User', value: 'USER', name: 'timezone_type' },
    { label: 'Advertiser', value: 'ADVERTISER', name: 'timezone_type' }
]

const CustomEventType = [
    { label: 'Rate', value: 'RATE', name: 'promoted_object.custom_event_type' },
    { label: 'Tutorial Completion', value: 'TUTORIAL_COMPLETION', name: 'promoted_object.custom_event_type' },
    { label: 'Contact', value: 'CONTACT', name: 'promoted_object.custom_event_type' },
    { label: 'Customize Product', value: 'CUSTOMIZE_PRODUCT', name: 'promoted_object.custom_event_type' },
    { label: 'Donate', value: 'DONATE', name: 'promoted_object.custom_event_type' },
    { label: 'Find Location', value: 'FIND_LOCATION', name: 'promoted_object.custom_event_type' },
    { label: 'Schedule', value: 'SCHEDULE', name: 'promoted_object.custom_event_type' },
    { label: 'Start Trial', value: 'START_TRIAL', name: 'promoted_object.custom_event_type' },
    { label: 'Submit Application', value: 'SUBMIT_APPLICATION', name: 'promoted_object.custom_event_type' },
    { label: 'Subscribe', value: 'SUBSCRIBE', name: 'promoted_object.custom_event_type' },
    { label: 'Add to Cart', value: 'ADD_TO_CART', name: 'promoted_object.custom_event_type' },
    { label: 'Add to Wishlist', value: 'ADD_TO_WISHLIST', name: 'promoted_object.custom_event_type' },
    { label: 'Initiated Checkout', value: 'INITIATED_CHECKOUT', name: 'promoted_object.custom_event_type' },
    { label: 'Add Payment Info', value: 'ADD_PAYMENT_INFO', name: 'promoted_object.custom_event_type' },
    { label: 'Purchase', value: 'PURCHASE', name: 'promoted_object.custom_event_type' },
    { label: 'Lead', value: 'LEAD', name: 'promoted_object.custom_event_type' },
    { label: 'Complete Registration', value: 'COMPLETE REGISTRATION', name: 'promoted_object.custom_event_type' },
    { label: 'Content View', value: 'CONTENT_VIEW', name: 'promoted_object.custom_event_type' },
    { label: 'Search', value: 'SEARCH', name: 'promoted_object.custom_event_type' },
    { label: 'Service Booking Request', value: 'SERVICE_BOOKING_REQUEST', name: 'promoted_object.custom_event_type' },
    { label: 'Messagong Conversation Started 7 Days', value: 'MESSAGING_CONVERSATION_STARTED_7D', name: 'promoted_object.custom_event_type' },
    { label: 'Level Achieved', value: 'LEVEL_ACHIEVED', name: 'promoted_object.custom_event_type' },
    { label: 'Achievement Unlocked', value: 'ACHIEVEMENT_UNLOCKED', name: 'promoted_object.custom_event_type' },
    { label: 'Spent Credits', value: 'SPENT_CREDITS', name: 'promoted_object.custom_event_type' },
    { label: 'Listing Interaction', value: 'LISTING_INTERACTION', name: 'promoted_object.custom_event_type' },
    { label: 'D2 Retention', value: 'D2_RETENTION', name: 'promoted_object.custom_event_type' },
    { label: 'D7 Retention', value: 'D7_RETENTION', name: 'promoted_object.custom_event_type' },
    { label: 'Other', value: 'OTHER', name: 'promoted_object.custom_event_type' }
]

export { CountryOptsCampaign, CountryOptsOrder, Objective, Status, BidStrategy, BuyingType, SpecialAdCategory, SmartPromotionType, BillingEvent, DestinationType, FrequencyEvent, MultiOptimizationGoalWeight, OptimizationGoal, OptimizationSubEvent, DevicePlatform, PublisherPlatform, FacebookPosition, InstagramPosition, MessengerPosition, Gender, Days, TimezoneType, CustomEventType, CountryOptsOrderInputBrief }