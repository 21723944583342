import { React, useEffect, useState } from 'react'
import { Row, Col, Tab, Nav } from 'react-bootstrap'
import Card from '../../../components/Card'
import Loader from '../../../components/Loader'
import { useParams } from 'react-router-dom'
import { FetchFromAPI } from '../../../components/ajx'

import AOS from 'aos'
import '../../../../node_modules/aos/dist/aos'
import '../../../../node_modules/aos/dist/aos.css'

import { MetaInputBriefModel } from '../../../components/Models'
import { MetaInputBriefForm } from '../../../util/meta-qa-input'
import InputSettings from '../../../components/InputSettings'
import { useAppContext } from '../../../context/appContext'


const InputBrief = (props) => {
    const appValue = useAppContext();
    const [inputBrief, setInputBrief] = useState(MetaInputBriefModel)
    const [initialLoad, setInitialLoad] = useState(true)
    useEffect(() => {

        AOS.init({
            startEvent: 'DOMContentLoaded',
            disable: function () {
                var maxWidth = 996;
                return window.innerWidth < maxWidth;
            },
            throttleDelay: 10,
            once: true,
            duration: 700,
            offset: 10
        });

    })

    const { ibid } = useParams()
    var REACT_APP_API_URL = appValue.apiUrl;

    const { NODE_ENV } = process.env;

    const inputBriefAPI = {
        urls: [
            {
                name: "input-brief",
                url: REACT_APP_API_URL + "/input-brief/" + ibid
            },
        ],
        method: "GET",

    }
    const { results, errors, complete } = FetchFromAPI(inputBriefAPI)
    let url = REACT_APP_API_URL + "/input-brief/update/" + ibid

    if (complete) {
        if (NODE_ENV === 'development') {
            console.log(results)
        }
        if (initialLoad) {
            setInitialLoad(false)
            const data = results["input-brief"].data;

            inputBrief.name = data.name;
            inputBrief.stage = data.stage;
            inputBrief.campaign = data.input_brief.campaign;
            inputBrief.order = data.input_brief.order;
            inputBrief.group = data.input_brief.group;
        }

        return errors.length > 0 ? (<><span>Error: {errors}</span></>) : (
            <>
                <Tab.Container defaultActiveKey="first">
                    <Row>
                        <Col lg="12">
                            <Card>
                                <Card.Body>
                                    <div className="d-flex flex-wrap align-items-center justify-content-between">
                                        <div className="d-flex flex-wrap align-items-center">
                                            <div className="d-flex flex-wrap align-items-center mb-3 mb-sm-0">
                                                <h4 className="me-2 h4">{results["input-brief"].data.name}</h4>
                                                <span> - Input Brief</span>
                                            </div>
                                        </div>
                                        <Nav as="ul" className="d-flex nav-pills mb-0 text-center profile-tab" data-toggle="slider-tab" id="profile-pills-tab" role="tablist">
                                            <Nav.Item as="li">
                                                <Nav.Link eventKey="first" className="button">Settings</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg="6">
                            <Card>
                                <Card.Header>
                                    <div className="header-title">
                                        <h4 className="card-title">Input Brief Info</h4>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <div className="mb-1">Input Brief Id: {results["input-brief"].data._id}</div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg="12">
                            <Tab.Content className="profile-content">
                                <Tab.Pane eventKey="first" id="settings">
                                    <Row>
                                        <Col lg="12">
                                            <InputSettings
                                                title='Input Brief'
                                                mode='edit'
                                                form={MetaInputBriefForm}
                                                url={url}
                                                formId={"inputBriefform"}
                                                model={[inputBrief, setInputBrief]}
                                                disable={inputBrief.stage === 'ready'}
                                            />
                                        </Col>
                                    </Row>
                                </Tab.Pane >
                            </Tab.Content>
                        </Col>

                    </Row>
                </Tab.Container>
            </>
        );
    }
    return <><div><span><Loader></Loader></span></div></>;
}
export default (InputBrief)
