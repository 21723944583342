const GroupModel = {
    name: '',
    order_id: '',
    order_type: '',
    type: '',
    subtype: '',
    mobile_app_id: '',
    status: '',
    trueview: {
        ad_format: ''
    },
    excludes_new_exchanges: true,
    partner_costs: [],
    edit_flight: false,
    flight_date_type: 'LINE_ITEM_FLIGHT_DATE_TYPE_INHERITED',
    flight_start_date: '',
    flight_end_date: '',
    budget_allocation_type: '',
    max_budget: '',
    partner_revenue_model_markup_type: '',
    partner_revenue_model_markup_amount: '',
    conversion_counting_post_view_count: '',
    floodlights: [],
    conversion_counting_post_click_look_back_window: '',
    conversion_counting_post_view_look_back_window: '',
    creatives: [],
    creative_optimization: false,
    edit_bidding: false,
    bidding_strategy: '',
    bid_amount: '',
    auto_bid_performance_goal_type: '',
    conversion_counting: {
        type: '',
        amount: '',
    },
    pacing: {
        type: '',
        daily_max_type: '',
        max_amount: '',
        max_impressions: '',
        period: '',
    },
    budget: {
        automation_type: '',
        unit: '',
        segments: [],
    },
    performance_goal: {
        type: '',
        metric: '',
        perf_type: ''
    },
    frequency_cap: {
        unlimited: false,
        count: '',
        time_unit: '',
        max_impressions: ''
    }
}

const OrderModel = {
    name: '',
    order_id: '',
    advertiser_id: '',
    campaign_id: '',
    status: '',
    insertion_order_type: '',
    edit_bidding: false,
    bidding_strategy: '',
    bid_amount: '',
    auto_bid_max_bid: '',
    auto_bid_performance_goal_amount: '',
    auto_bid_performance_goal_type: '',
    billable_outcome: '',
    pacing: {
        type: '',
        daily_max_type: '',
        max_amount: '',
        max_impressions: '',
        period: '',
    },
    budget: {
        automation_type: '',
        unit: '',
    },
    budget_segments: [],
    performance_goal: {
        type: '',
        metric: '',
        perf_amount: ''
    },
    frequency_cap: {
        unlimited: false,
        count: '',
        time_unit: '',
        max_impressions: ''
    },
    partner_costs: []
}

const CampaignModel = {
    name: '',
    status: '',
    id: '',
    advertiser_id: '',
    performance_goal: {
        type: '',
        metric: '',
        perf_type: '',
        amount: ''
    },
    campaign_budget: {
        name: '',
        amount: '',
        start_date: '',
        end_date: '',
        unit: ''
    },
    frequency_cap: {
        unlimited: true,
        count: '',
        timeunit: '',
        max_impressions: ''
    }
}

const ClientModel = {
    name: '',
    id: '',
    cmAccountId: '',
    status: '',
    domain: '',
    currency: '',
    floodlightId: '',
    ias: '',
    oba: false,
    dynamiccreative: false,
    mediaocean: false,
    name_error: false,
    cm_account_id_error: false,
    status_error: false,
    domain_error: false,
    currency_error: false,
    floodlight_id_error: false,
    ias_error: false,
}

const ClientSearchModel = {
    name: '',
    id: '',
    status: '',
    currency: '',
    platform_id: '',
    timezone: '',
}

const CampaignSearchModel = {
    status: "",
    customer_id: "",
    campaign_id: "",
    platform: "",
    name: "",
    campaign_type: "",
    networks: [],
    budget: 0,
    budget_type: "",
    bid_strategy_type: "",
    bid_strategy: "",
    start_date: "",
    end_date: "",
    language: { language: [], excluded: false },
    location: [{
        city: "",
        region: "",
        country: "",
        radius: 0,
        radius_metric: "",
        bid_adjustment: 0,
        exclude: false,
    }],
    devices: [{
        device: "",
        bid_adjustment: 0,
    }],
    target_cpa: 0,
    target_roas: 0,
    target_impression_share: 0,
    max_cpc_bid_limit_is: 0,
    location_goal_is: 0,
    enhanced_cpc: [],
    tracking_template: "",
    final_url_suffix: "",
    custom_parameter: "",

}


const GroupSearchModel = {
    status: "",
    customer_id: "",
    campaign_id: "",
    campaign_name: "",
    adgroup_id: "",
    name: "",
    type: "",
    ad_rotation: "",
    default_bid: "",
    cpc_per: "",
    max_cpm: "",
    max_cpv: "",
    target_cpa: "",
    target_cpm: "",
    target_cpv: "",
    tracking_template: "",
    final_url_suffix: "",
    custom_parameter: "",
    target_roas: "",
    keywords: [],
}
const AdSearchModel = {
    status: "",
    customer_id: "",
    campaign_id: "",
    adgid: "",
    adid: "",
    adgroup: "",
    ad_creatives: {
        headlines: [],
        headline_positions: [],
        description: [],
        description_postions: [],
        paths: [],
    },
    final_url: "",
    ad_type: "",
}

const MetaCampaignModel = {
    id: '',
    account_id: '',
    adlabels: [],
    bid_strategy: '',
    boosted_object_id: '',
    budget_rebalance_flag: undefined,
    budget_remaining: '',
    buying_type: '',
    can_create_brand_life_study: undefined,
    can_use_spend_cap: undefined,
    created_time: '',
    daily_budget: 0,
    effective_status: '',
    has_secondary_skadnetwork_reporting: undefined,
    issues_info: [
        {
            error_code: 0,
            error_message: '',
            error_summary: '',
            error_type: '',
            level: ''
        }
    ],
    last_budget_toggline_time: '',
    lifetime_budget: 0,
    name: '',
    objective: '',
    pacing_type: [],
    promotion_type:'',
    primary_attribution: '',
    promoted_object: {},
    recommendations: [],
    smart_promotion_type: '',
    source_campaign_id: '',
    special_ad_categories: [],
    special_ad_category_country: [],
    spend_cap: 0,
    start_time: '',
    stop_time: '',
    status: '',
    topline_id: '',
    updated_time: '',
    name_error: false,
    objective_error: false,
    special_ad_category_error: false,
    budget_error: false,
}

const MetaOrderModel = {
    adlabels: [],
    adset_schedule: [],
    bid_amount: 0,
    bid_strategy: '',
    billing_event: '',
    budget_type:'',
    campaign_attribution: '',
    campaign_id: '',
    daily_budget: 0,
    daily_spend_cap: 0,
    daily_min_spend_target: 0,
    destination_type: '',
    dsa_beneficiary: '',
    dsa_payor: '',
    start_time: '',
    end_time: '',
    is_dynamic_creative: false,
    lifetime_budget: 0,
    lifetime_imps: 0,
    lifetime_min_spend_target: 0,
    lifetime_spend_cap: 0,
    multi_optimization_goal_weight: '',
    name: '',
    optimization_goal: '',
    optimization_sub_event: '',
    promoted_object: {},
    rf_prediction_id: '',
    source_adset_id: '',
    status: '',
    targeting: {
        geo_locations: {
            countries: []
        },
        device_platforms: [],
        facebook_positions: [],
        gender: [],
        instagram_positions: [],
        messenger_positions: [],
        publisher_platforms: [],
        excluded_custom_audiences: []
    }
}

const MetaGroupModel = {
    id: '',
    adlabels: [],
    adset_id: '',
    bid_amount: 0,
    campaign_id: '',
    conversion_domain: '',
    creative: {
        id: '',
        actor_id: '',
        instagram_actor_id: '',
        name: '',
        call_to_action_type: '',
        object_url: '',
        link_destination_display_url: '',
        url_tags: ''
    },
    meta_reward_ad_group_status: '',
    name: '',
    source_ad_id: '',
    status: ''
}

const MetaClientModel = {
    name: '',
    account_status: '',
    tax_status: '',
    disabled_reasons: '',
    ad_labels: ''
}


const TargetingModel = {
    devices: {
        opts: [

        ],
        exclude: false
    },
    os: {
        opts: [

        ],
        exclude: false,
    },
    browsers: {
        opts: [

        ],
        exclude: false,
    },
    carriers: {
        opts: [

        ],
        exclude: false,
    },
    exchanges: {
        opts: [

        ],
    },
    subexchanges: {
        opts: [

        ],
    },
    channels: {
        opts: [

        ],
        exclude: false,
    },
    geos: {
        opts: [

        ],
        exclude: false,
    },
    languages: {
        opts: [

        ],
        exclude: false,
    },
    categories: {
        opts: [

        ],
        exclude: false,
    },
    content: {
        opts: [

        ],
        exclude: false,
    },
    device_type: [],
    environment: [],
    onscreenposition: [],
    outstreamposition: [],
    nativeposition: [],
    incontentposition: [],
    gender: [],
    age_range: {
        enabled: false,
        unknown: false,
        temp: [],
        value: [],
    },
    parental_status: [],
    household_income: {
        enabled: false,
        unknown: false,
        temp: [],
        value: [],
    },
    content_label: [],
    content_label_inherited: [],
    sensitive_categories: [],
    sensitive_categories_inherited: [],
    day_time: [],
}

const MetaInputBriefModel = {
    name: '',
    stage: '',
    client: '',
    campaign: {
        account_id: '',
        client_campaign_name: '',
        status: '',
        objective: '',
        funnel: '',
        date: '',
        kpi: '',
        budget_type: '',
        budget_amount: 0,
        final_campaign_name: '',
    },
    order:
    {
        client_order_name: '',
        status: '',
        placement: '',
        targeting_strategy: '',
        targeting_tactic: '',
        data_source: '',
        final_order_name: '',
        dynamic_creative: undefined,
        optimization_goal: '',
        start_time: '',
        end_time: '',
        saved_audience: '',
        targeting_countries: [],
        age_min: 0,
        age_max: 0,
        publisher_platforms: [],
        facebook_positions: [],
        instagram_positions: [],
        messenger_positions: [],
        excluded_custom_audiences: []
    },
    group:
    {
        client_group_name: '',
        status: '',
        creative_type: '',
        creative_name: '',
        creative_id: '',
        post_type: '',
        creative_assets: '',
        primary_intro_text: '',
        headline: '',
        description_text: '',
        cta: '',
        landing_page_url: '',
        display_link: '',
        url_params: ''
    }
}

const TradeDeskAdvertiserModel = {
    partnerId: "",
    advertiserId: "",
    advertiserName: "",
    advertiserCategory: {},
    advertiserAudienceSettings: {},
    description: "",
    currencyCode: "",
    attributionClickLookbackWindowInSeconds: 0,
    attributionImpressionLookbackWindowInSeconds: 0,
    clickDedupWindowInSeconds: 0,
    conversionDedupWindowInSeconds: 0,
    defaultRightMediaOfferTypeId: 0,
    industryCategoryId: 0,
    keywords: [],
    availability: "",
    logoURL: "",
    domainAddress: "",
    customLabels: [],
    associatedBidLists: [],
    defaultBidLists: [],
    ignoreReferralUrlInConversion: false,
    useLegacyAdditionalFees: false,
    isBallotMeasure: false,
    isCandidateElection: false,
    candidateProfiles: [],
    vettingStatus: "",
    increments: [],
    newFrequencyConfigs: [],
    dataPolicies: [],
    brands: [],
    industryCategoryId: 0,
    industryCategoryTaxonomyId: 0,
    industryCategoryMapping: {},
    advertiserNameDsa: "",
    payerNameDsa: "",
    advertiserAudienceSettings: {},
};

const TradeDeskGroupModel = {
    campaignId: "",
    adGroupId: "",
    adGroupCategory: {},
    bidderServiceId: "",
    adGroupName: "",
    description: "",
    isEnabled: false,
    isHighFillRate: false,
    industryCategoryId: 0,
    highFillAttributes: {},
    merchantInformation: {},
    smbAttributionInformation: {},
    availability: "",
    createdAtUTC: "",
    lastUpdatedAtUTC: "",
    associatedBidLists: [],
    includeDefaultsFromCampaign: false,
    availableRecommendations: [],
    newBidLists: [],
    customLabels: [],
    areFutureKoaFeaturesEnabled: false,
    funnelLocation: "",
    predictingClearingEnabled: false,
    useIdentityAlliance: false,
    currentAndFutureAdditionalFeeCards: [],
    isBuildInProgress: false,
    adbrainHouseholdCrossDeviceEnabled: false,
    increments: [],
    newFrequencyConfigs: [],
    channelId: "",
    enabledValueAddedProviders: [],
    industryCategoryTaxonomyId: 0,
    industryCategoryMapping: {},
};

const TradeDeskCampaignModel = {
    budget: {},
    budgetInImpressions: 0,
    impressionsOnlyBudgetingCpm: 0.0,
    dailyBudget: {},
    dailyBudgetInImpressions: 0,
    startDate: "",
    endDate: "",
    autoAllocatorEnabled: false,
    autoPrioritizationEnabled: false,
    minimumAdGroupSpendInAdvertiserCurrency: 0.0,
    ismediaOceanCampaign: false,
    pacingMode: "",
    customLabels: [],
    customCPAType: "",
    customCPAClickWeight: 0.0,
    customCPAViewthroughWeight: 0.0,
    customROASType: "",
    isbuildInProgress: false,
    associatedBidLists: [],
    includeDefaultsFromAdvertiser: false,
    defaultBidLists: [],
    currentAndFutureAdditionalFeeCards: [],
    additionalFeeCardOnCreate: {},
    campaignType: "",
    ballotMeasureProfile: {},
    isBallotMeasure: false,
    increments: [],
    newFrequencyConfigs: [],
    objective: "",
    primaryGoal: {},
    secondaryGoal: {},
    tertiaryGoal: {},
    primaryChannel: "",
    enabledValueAddedProviders: [],
    advertiserId: "",
    campaignId: "",
    campaignName: "",
    description: "",
    partnerCostPercentageFee: 0.0,
    partnerCPMFee: {},
    partnerCPCFee: {},
    campaignConversionReportingColumns: [],
    availability: "",
    ctvTargetingAndAttribution: false,
    createdAtUTC: "",
    lastupdatedAtUTC: "",
    frequencySettings: {},
    purchaseOrderNumber: "",
    timeZone: "",
};

const TradeDeskPartnerModel = {
    partnerId: "",
    partnerIdInteger: 0,
    partnerName: "",
    associatedBidLists: [],
};


export { GroupModel, CampaignModel, OrderModel, ClientModel, CampaignSearchModel, GroupSearchModel, AdSearchModel, TargetingModel, MetaCampaignModel, MetaGroupModel, MetaOrderModel, MetaClientModel, ClientSearchModel, TradeDeskCampaignModel, TradeDeskAdvertiserModel, TradeDeskGroupModel, TradeDeskPartnerModel, MetaInputBriefModel }

