import { React, useEffect, useState, useLayoutEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import Card from '../../../components/Card'
import Loader from '../../../components/Loader'
import { FetchFromAPI } from '../../../components/ajx'
import ReactTable from '../../../components/table'
import { columnsCampaign } from '../../../components/gridColumns';
import CampaignForm from '../../../util/campaign-input'

import { ModalButton } from '../../../components/ModalSettings'
import { CampaignModal, CampaignSearchModal, CampaignMetaModal } from '../../../components/Modals'
import { CampaignSearchForm } from '../../../util/campaign-input-search'
import { MetaCampaignForm } from '../../../util/meta-campaign-input'
import { CampaignModel, CampaignSearchModel, MetaCampaignModel, MetaInputBriefModel } from '../../../components/Models'
import { useAppContext } from '../../../context/appContext'

export default function CampaignList() {
   const appValue = useAppContext();
   const [modal, setModal] = useState(false);
   const [searchModal, setSearchModal] = useState(false);
   const [metaModal, setMetaModal] = useState(false);
   const [searchCampaign, setSearchCampaign] = useState(CampaignSearchModel);
   const [campaign, setCampaign] = useState(CampaignModel)
   const [metaCampaign, setMetaCampaign] = useState(MetaCampaignModel);
   const [inputBrief, setInputBrief] = useState(MetaInputBriefModel);

   var REACT_APP_API_URL = appValue.apiUrl;

   const campaignAPI = {
      urls: [
         {
            name: "campaigns",
            url: REACT_APP_API_URL + "/campaign/list"
         },
         {
            name: "clients",
            url: REACT_APP_API_URL + "/client/list"
         },
         {
            name: "input-briefs",
            url: REACT_APP_API_URL + "/input-brief/list",
         }
      ],
      method: "GET",
   }

   const { results, errors, complete } = FetchFromAPI(campaignAPI)
   const { NODE_ENV } = process.env;

   useEffect(() => {
      if (complete) {
         if (results["clients"].data) {
            for (let i = 0; i < results["clients"].data.length; i++) {
               const c = results["clients"].data[i];
               if (c.platform === 'DV') {
                  const ad_id = `${c.platform_id}`;
                  const ad_label = `${c.name} (${ad_id})`;
                  CampaignForm.fields[0][0].options.push({ name: 'advertiser_id', label: ad_label, value: ad_id })
               }              
            }
            if (results["campaigns"].data) {
               for (let i = 0; i < results["campaigns"].data.length; i++) {
                  const c = results["campaigns"].data[i];
                  if (c.platform === 'Meta') {
                     const c_label = `${c.name} | ${c.platform_id}`;
                     MetaCampaignForm.fields[2][2].options.push({ name: 'source_campaign_id', label: c_label, value: c.platform_id })
                  }
               }
            }
         }
      }
   }, [complete]);


   if (NODE_ENV === "development") {
      console.log(results);
   }
   const options = [
      { name: "DV360", setModal: setModal },
      { name: "SA360", setModal: setSearchModal },
      { name: "Meta", setModal: setMetaModal }
   ]

   // push all input briefs in the "ready" stage to the dropdown opts 
   const ibOpts = [];
   if (results && results["input-briefs"] && results["input-briefs"].data) {
      for (let i = 0; i < results["input-briefs"].data.length; i++) {
         const b = results["input-briefs"].data[i];
         if (b.stage === 'ready') {
            ibOpts.push({ value: b._id, inputBrief: b, label: b.name, name: b.name })
         }
      }
   }

   // once an input brief has been selected, autofill the relevant campaign fields with data from the brief
   useLayoutEffect(() => {
      const ib = inputBrief;
      if (ib.name !== '') {
         metaCampaign.name = ib.input_brief.campaign.final_campaign_name;
         metaCampaign.objective = ib.input_brief.campaign.objective;
         if (ib.input_brief.campaign.budget_type === 'lifetime') {
            metaCampaign.lifetime_budget = ib.input_brief.campaign.budget_amount;
         } else {
            metaCampaign.daily_budget = ib.input_brief.campaign.budget_amount;
         }

         // TO-DO: SET BID STRATEGY HERE ONCE BARRY PROVIDES INPUT BRIEF -> META FIELD MAPPING
      }
   }, [inputBrief])

   if (complete) {
      return errors.length > 0 ? (<><span>Error: {errors}</span></>) : (
         <>
            <div>
               <Row>
                  <Col sm="12">
                     <Card>
                        <Card.Header className="d-flex justify-content-between">
                           <div className="header-title">
                              <h4 className="card-title">Campaign List</h4>
                           </div>
                           <div>
                              <ModalButton name="New Campaign" setModal={setModal} options={options} />
                           </div>
                        </Card.Header>
                        <Card.Body className="px-0">
                           <div className="table-responsive">
                              <ReactTable item={results["campaigns"]} col={columnsCampaign} page_size={20} row_size={20} />
                           </div>
                        </Card.Body>
                     </Card>
                  </Col>
               </Row>
            </div>

            <CampaignModal campaign={campaign} setCampaign={setCampaign} form={CampaignForm} formId="campaignForm" modal={modal} setModal={setModal} baseUrl={REACT_APP_API_URL} />
            <CampaignSearchModal campaign={searchCampaign} setCampaign={setSearchCampaign} form={CampaignSearchForm} modal={searchModal} setModal={setSearchModal} baseUrl={REACT_APP_API_URL} />
            <CampaignMetaModal campaign={metaCampaign} setCampaign={setMetaCampaign} form={MetaCampaignForm} modal={metaModal} setModal={setMetaModal} baseUrl={REACT_APP_API_URL} inputBriefOpts={ibOpts} setInputBrief={(e) => setInputBrief(e.inputBrief)} />
         </>
      )
   }
   return <><div><span><Loader></Loader></span></div></>;

}
