import React, { useEffect, useState } from 'react'
import { Row, Col, Image, Nav, Tab } from 'react-bootstrap'
import Card from '../../../components/Card'

import { useParams } from 'react-router-dom'
import Loader from '../../../components/Loader'
import ReactTable from '../../../components/table'
import { FetchFromAPI } from '../../../components/ajx'
import { CountEntities } from '../../../components/common'
import { columnsCampaign, columnsGroup } from '../../../components/gridColumns'

// img

import avatars11 from '../../../assets/images/avatars/01.png'
// AOS
import AOS from 'aos'
import '../../../../node_modules/aos/dist/aos'
import '../../../../node_modules/aos/dist/aos.css'
//apexcharts



// store
import Dash from '../../../components/dash';
import {DateButtons} from '../../../components/dateButtons';
import { ModalButton } from '../../../components/ModalSettings'
import { CampaignModal } from '../../../components/Modals'
import CampaignForm from '../../../util/campaign-input'
import { CampaignModel, ClientSearchModel } from '../../../components/Models'
import InputSettings from '../../../components/InputSettings'
import { ClientSearchForm } from '../../../util/client-input-search'
import { useAppContext } from '../../../context/appContext'



const Client = (props) => {
   const appValue = useAppContext();
   const [modal, setModal] = useState(false);
   const [client, setClient] = useState(ClientSearchModel)
   const [campaign, setCampaign] = useState(CampaignModel)
   const [initialLoad, setInitialLoad] = useState(true)


   useEffect(() => {

      AOS.init({
         startEvent: 'DOMContentLoaded',
         disable: function () {
            var maxWidth = 996;
            return window.innerWidth < maxWidth;
         },
         throttleDelay: 10,
         once: true,
         duration: 700,
         offset: 10
      });

   })

   const { clid } = useParams()
   var REACT_APP_API_URL = appValue.apiUrl;

   const { NODE_ENV } = process.env;

   const clientAPI = {
      urls: [
         {
            name: "client",
            url: REACT_APP_API_URL + "/client/" + clid+"/"+"Search"
         },
         {
            name: "campaigns",
            url: REACT_APP_API_URL + "/campaign/list/client/" + clid
         },
         {
            name: "groups",
            url: REACT_APP_API_URL + "/group/list/client/" + clid
         },
         {
            name: "dash",
            url: REACT_APP_API_URL + "/reports/dash/client/" + appValue.user.apid + "/" + clid+"/"+"Search" + "?date=" + appValue.date
         },
         {
            name: "account",
            url: REACT_APP_API_URL + "/admin/get/account/" + appValue.user.apid
         },
         {
            name: "crumbs",
            url: REACT_APP_API_URL + "/util/breadcrumb/clients/" + clid
         }

      ],
      method: "GET",

   }

   const { results, errors, complete } = FetchFromAPI(clientAPI)


   let url = REACT_APP_API_URL + "/client/update/" + clid   

   if (complete) {
    if (NODE_ENV == 'development') {
        console.log(results)
     }
      if (initialLoad) {
        
         setInitialLoad(false)
         client.name = results["client"].data.name || "";
         client.status = results["client"].data.status || "";
         client.id = results["client"].data.clientId.toString() || "";
         client.currency = results["client"].data.currency || ""
         campaign.advertiser_id = client.id || ""
      }

      let activeCampaigns = CountEntities(results["campaigns"].data);      
      let activeGroups = CountEntities(results["groups"].data)

      return errors.length > 0 ? (<><span>Error: {errors}</span></>) : (
         <>
            <Tab.Container defaultActiveKey="first">
               <Row>
                  <Col lg="12">
                     <Card>
                        <Card.Body>
                           <div className="d-flex flex-wrap align-items-center justify-content-between">
                              <div className="d-flex flex-wrap align-items-center">
                                 <div className="profile-img position-relative me-3 mb-3 mb-lg-0 profile-logo profile-logo1">
                                    <Image className="theme-color-default-img  img-fluid rounded-pill avatar-100" src={avatars11} alt="profile-pic" />
                                 </div>
                                 <div className="d-flex flex-wrap align-items-center mb-3 mb-sm-0">
                                    <h4 className="me-2 h4">{results["client"].data.name}</h4>
                                    <span> - Client</span>
                                 </div>
                              </div>
                              <Nav as="ul" className="d-flex nav-pills mb-0 text-center profile-tab" data-toggle="slider-tab" id="profile-pills-tab" role="tablist">
                                 <Nav.Item as="li">
                                    <Nav.Link eventKey="first" className="button">Dash</Nav.Link>
                                 </Nav.Item>
                                 <Nav.Item as="li">
                                    <Nav.Link eventKey="fifth" className="button">Settings</Nav.Link>
                                 </Nav.Item>
                                 <Nav.Item as="li">
                                    <Nav.Link eventKey="fourth" className="button">Campaigns</Nav.Link>
                                 </Nav.Item>
                                 <Nav.Item as="li">
                                    <Nav.Link eventKey="third" className="button">Groups</Nav.Link>
                                 </Nav.Item>
                              </Nav>
                           </div>
                        </Card.Body>
                     </Card>
                  </Col>
                  <Col lg="6">
                     <Card>
                        <Card.Header>
                           <div className="header-title">
                              <h4 className="card-title">Snapshot</h4>
                           </div>
                        </Card.Header>
                        <Card.Body>
                           <ul className="list-inline m-0 p-0">
                              <li className="d-flex mb-2">
                                 <div className="news-icon me-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-postcard-fill" viewBox="0 0 16 16"><path d="M11 8h2V6h-2v2Z"></path><path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm8.5.5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7ZM2 5.5a.5.5 0 0 0 .5.5H6a.5.5 0 0 0 0-1H2.5a.5.5 0 0 0-.5.5ZM2.5 7a.5.5 0 0 0 0 1H6a.5.5 0 0 0 0-1H2.5ZM2 9.5a.5.5 0 0 0 .5.5H6a.5.5 0 0 0 0-1H2.5a.5.5 0 0 0-.5.5Zm8-4v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5Z"></path></svg>
                                 </div>
                                 <p className="news-detail mb-0">{activeCampaigns} - Active Campaigns</p>
                              </li>
                              <li className="d-flex">
                                 <div className="new-icon me-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-collection-fill" viewBox="0 0 16 16"><path d="M0 13a1.5 1.5 0 0 0 1.5 1.5h13A1.5 1.5 0 0 0 16 13V6a1.5 1.5 0 0 0-1.5-1.5h-13A1.5 1.5 0 0 0 0 6v7zM2 3a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 0-1h-11A.5.5 0 0 0 2 3zm2-2a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 0-1h-7A.5.5 0 0 0 4 1z"></path></svg>
                                 </div>
                                 <p className="news-detail mb-0">{activeGroups} - Active Groups</p>

                              </li>
                           </ul>
                        </Card.Body>
                     </Card>
                  </Col>
                  <Col lg="6">
                     <Card>
                        <Card.Header>
                           <div className="header-title">
                              <h4 className="card-title">Client Info</h4>
                           </div>
                        </Card.Header>
                        <Card.Body>
                           <div className="mb-1">Currency: {client.currency}</div>                           
                        </Card.Body>
                     </Card>
                  </Col>
                  <Col lg="12">
                     <Tab.Content className="profile-content">
                        <Tab.Pane eventKey="fourth" id="campaign">

                           <Card>
                              <Card.Header className="d-flex justify-content-between">
                                 <div className="header-title">
                                    <h4 className="card-title">Campaign List</h4>
                                 </div>
                                 <div>
                                    <ModalButton name="New Campaign" setModal={setModal} />
                                 </div>
                              </Card.Header>
                              <Card.Body className="px-0">
                                 <div className="table-responsive">
                                    <ReactTable item={results["campaigns"]} col={columnsCampaign} page_size={20} row_size={20} />
                                 </div>
                              </Card.Body>
                           </Card>
                           <CampaignModal campaign={campaign} setCampaign={setCampaign} data={CampaignForm} modal={modal} setModal={setModal} baseUrl={REACT_APP_API_URL} entityId={results["client"].data.dv_advertiser_id} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="third" id="group">
                           <Card>
                              <Card.Header className="d-flex justify-content-between">
                                 <div className="header-title">
                                    <h4 className="card-title">Group List</h4>
                                 </div>
                                 {/* <div>
                                    <Button className="text-center btn-dark btn-icon mt-lg-0 mt-md-0 mt-3" variant="dark" >
                                       <i className="btn-inner">
                                          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                             <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                          </svg>
                                       </i>
                                       <span>New Group</span>
                                    </Button>
                                 </div> */}
                              </Card.Header>
                              <Card.Body className="px-0">
                                 <div className="table-responsive">
                                    <ReactTable item={results["groups"]} col={columnsGroup} page_size={20} row_size={20} />
                                 </div>
                              </Card.Body>
                           </Card>
                        </Tab.Pane >
                        <Tab.Pane eventKey="first" id="dash">
                           <Row className='dateButtons_sub'>
                              <Col lg={{ span: 6, offset: 6 }}>
                                 <DateButtons />
                              </Col>
                           </Row>
                           {(Object.keys(results["dash"].data.kpi).length === 0) ? (
                              <span>No Data Available </span>
                           ) : (
                              <Dash data={results["dash"]} data2={results["campaigns"]} data3={results["orders"]} data4={results["account"]} />
                           )}
                        </Tab.Pane >
                        <Tab.Pane eventKey="fifth" id="settings">
                           <Row>
                              <Col lg="12">
                                 <InputSettings
                                    title='Add New Client'
                                    mode='edit'
                                    form={ClientSearchForm}
                                    url={url}
                                    formId={"cientform"}
                                    model={[client, setClient]}
                                 />
                              </Col>
                           </Row>
                        </Tab.Pane >
                     </Tab.Content>
                  </Col>

               </Row>
            </Tab.Container>
         </>
      )

   }
   return <><div><span><Loader></Loader></span></div></>;
}

export default (Client)