import { React, useEffect, useState } from 'react'
import { Row, Col, Tab, Nav, Spinner, Alert } from 'react-bootstrap'
import Card from '../../../components/Card'
import Loader from '../../../components/Loader'
import { TrimString, Micros, DateFormat, BuildOptions, SortByString, Status, ConvertStatus, ConvertBudget, SetTargeting, HandleRanges, SetDefaultValue } from '../../../components/common'
import { useParams } from 'react-router-dom'
import { FetchDashFromAPI, FetchFromAPI, RetrieveTargetingList } from '../../../components/ajx'
import Dash from '../../../components/dash';

// AOS
import AOS from 'aos'
import '../../../../node_modules/aos/dist/aos'
import '../../../../node_modules/aos/dist/aos.css'
import Automizer from '../../../components/automizer'
import {DateButtons} from '../../../components/dateButtons'
import { GroupModel, TargetingModel } from '../../../components/Models'
import { GroupForm } from '../../../util/group-input'
import Crumbs from '../../../components/crumbs'
import { FindAndSet, TargetingForm } from '../../../util/targeting-input'
import InputSettings from '../../../components/InputSettings'
import { useAppContext } from '../../../context/appContext'
import Optimizations from '../../../components/optimizations'



const Group = (props) => {
    const appValue = useAppContext();
    const [group, setGroup] = useState(GroupModel)
    const [gtype, setGtype] = useState("")
    const [initialLoad, setInitialLoad] = useState(true)
    const [defaultValue, setDefaultValue] = useState()
    const [alert, setAlert] = useState({ variant: "", value: "" })
    const [targeting, setTargeting] = useState(TargetingModel)

    useEffect(() => {

        AOS.init({
            startEvent: 'DOMContentLoaded',
            disable: function () {
                var maxWidth = 996;
                return window.innerWidth < maxWidth;
            },
            throttleDelay: 10,
            once: true,
            duration: 700,
            offset: 10
        });

    })


    const { gid } = useParams()
    var REACT_APP_API_URL = appValue.apiUrl;
    var payload = {
        "targeting_types": ["TARGETING_TYPE_SUB_EXCHANGE", "TARGETING_TYPE_LANGUAGE",
            "TARGETING_TYPE_CATEGORY", "TARGETING_TYPE_GEO_REGION", "TARGETING_TYPE_CARRIER_AND_ISP",
            "TARGETING_TYPE_DEVICE_MAKE_MODEL", "TARGETING_TYPE_BROWSER", "TARGETING_TYPE_OPERATING_SYSTEM"]
    }


    var targetingList = RetrieveTargetingList(payload)
    const [date, setDate] = useState(1)
    const [dashAPI, setDashAPI] = useState({
        urls: [
            {
                name: "group",
                url: REACT_APP_API_URL + "/group/" + gid+"/"+"DV"
            },
            {
                name: "targeting",
                url: REACT_APP_API_URL + "/lib/targeting"
            },
            {
                name: "properties",
                url: REACT_APP_API_URL + "/property/list"
            },
            {
                name: "creatives",
                url: REACT_APP_API_URL + "/creative/list/small"
            },
            {
                name: "optimizations",
                url: REACT_APP_API_URL + "/optimization/list/"+gid
            },
            {
                name: "dash",
                url: REACT_APP_API_URL + "/reports/dash/group/" + appValue.user.apid + "/" + gid + "?date=" + date
            },
            {
                name: "account",
                url: REACT_APP_API_URL + "/admin/get/account/" + appValue.user.apid
            },
            {
                name: "crumbs",
                url: REACT_APP_API_URL + "/util/breadcrumb/groups/DV/" + gid
            },
            
        ],
        method: "GET",

    })
    useEffect(() => {
        complete = false
        setDashAPI({
            urls: [
                {
                    name: "group",
                    url: REACT_APP_API_URL + "/group/" + gid+"/"+"DV"
                },
                {
                    name: "targeting",
                    url: REACT_APP_API_URL + "/lib/targeting"
                },
                {
                    name: "properties",
                    url: REACT_APP_API_URL + "/property/list"
                },
                {
                    name: "creatives",
                    url: REACT_APP_API_URL + "/creative/list/small"
                },
                {
                    name: "optimizations",
                    url: REACT_APP_API_URL + "/optimization/list/"+gid
                },
                {
                    name: "dash",
                    url: REACT_APP_API_URL + "/reports/dash/group/" + appValue.user.apid + "/" + gid + "?date=" + date
                },
                {
                    name: "account",
                    url: REACT_APP_API_URL + "/admin/get/account/" + appValue.user.apid
                },
                {
                    name: "crumbs",
                    url: REACT_APP_API_URL + "/util/breadcrumb/groups/DV/" + gid
                },
                
            ],
            method: "GET",
    
        })
    }, [date])

    const { NODE_ENV } = process.env;


    const rearrangeTargeting = (targeting) => {
        var newTargeting = []
        if (targeting && targeting.length >= 1) {

            targeting.map((t) => {
                newTargeting.push(t.assignedTargetingOption)
            })
        }

        return newTargeting
    }



    let url = REACT_APP_API_URL + "/group/update/" + gid



    var { results, errors, complete } = FetchDashFromAPI(dashAPI)

    useEffect(() => {
        if (complete) {
            if (results["group"].data.dv_targeting) {
                let targeting = rearrangeTargeting(results["group"].data.dv_targeting.lineItemAssignedTargetingOptions)
                setDefaultValue(targeting);
            }
            if (results["group"].data.grouptype) {
                let grouptype = results["group"].data.grouptype
                if (grouptype.includes("DISPLAY")) {
                    setGtype("Display")
                } else {
                    setGtype("Video")
                }
            }

        }
    }, [complete])

    useEffect(()=>{
        if(targetingList.targetingList.data){

            Object.keys(targetingList.targetingList.data).forEach((key)=>{
                SortByString(targetingList.targetingList.data[key], "label")
            })
            FindAndSet("browsers.opts",targetingList.targetingList.data.browser)
            FindAndSet("os.opts",targetingList.targetingList.data.os)
            FindAndSet("languages.opts",targetingList.targetingList.data.language)
            FindAndSet("subexchanges.opts",targetingList.targetingList.data.subExchange)
        }

    },[targetingList])

    if (complete) {   
        if (results["optimizations"]) {
            var optimizations = results["optimizations"].data
        }else {
            var optimizations = []
        }
        if (NODE_ENV == "development") {
            console.log("API Result:",results,group,targeting)
        }
        if (initialLoad) {
            setInitialLoad(false)

            //Load default Options into TargetingForm, SetDefault Values for GroupModel or any Model 
            if (results["targeting"].data.sources.length > 0) {
                let options = BuildOptions(results["targeting"].data.sources, 9)
                FindAndSet("exchanges.opts",options)
                // TargetingForm.fields[4][0].options = options                
            }           

            if (results["group"].data && results["group"].data.dv_line) {                   
                //Push the order information to the group order selection field.
                GroupForm.fields[0][0].options.push({label: results["group"].data.dv_order_id.toString(),value:results["group"].data.dv_order_id.toString()})
           
                //Sets the Default Values
                if (results["group"].data.dv_line.flight) {
                    var sDate = results["group"].data.dv_line.flight.dateRange.startDate ? results["group"].data.dv_line.flight.dateRange.startDate : ""
                    var eDate = results["group"].data.dv_line.flight.dateRange.endDate ? results["group"].data.dv_line.flight.dateRange.endDate : ""
                }
                
                var groupDefault = {
                    advertiser_id: SetDefaultValue(results["group"].data.dv_advertiser_id.toString()),
                    order_id: SetDefaultValue(results["group"].data.dv_order_id.toString()),
                    id: SetDefaultValue(results["group"].data.dv_line_id.toString()),
                    campaign_id: SetDefaultValue(results["group"].data.dv_campaign_id.toString()),
                    name: SetDefaultValue(results["group"].data.name),
                    status: SetDefaultValue(results["group"].data.dv_line.entityStatus),
                    type: SetDefaultValue(results["group"].data.grouptype),
                    frequency_cap: !results["group"].data.dv_line.frequencyCap.unlimited ? {
                            unlimited: "Capped",
                            count: SetDefaultValue(results["group"].data.dv_line.frequencyCap.timeUnitCount),
                            time_unit: SetDefaultValue(results["group"].data.dv_line.frequencyCap.timeUnit),
                            max_impressions: SetDefaultValue(results["group"].data.dv_line.frequencyCap.maxImpressions),
                        }: {
                            unlimited: "Unlimited"
                        },
                    budget : results["group"].data.dv_line.budget ? {
                        allocation_type: SetDefaultValue(results["group"].data.dv_line.budget.budgetAllocationType),
                        unit: results["group"].data.dv_line.budget.budgetUnit ? ConvertBudget(results["group"].data.dv_line.budget.budgetUnit) : "",
                        max_amount: results["group"].data.dv_line.budget.budgetAllocationType === "LINE_ITEM_BUDGET_ALLOCATION_TYPE_FIXED" ? (results["group"].data.dv_line.budget.maxAmount / Micros).toString() : "",
                    } : "",
                    pacing: results["group"].data.dv_line.pacing ? {
                        period: SetDefaultValue(results["group"].data.dv_line.pacing.pacingPeriod),
                        type: SetDefaultValue(results["group"].data.dv_line.pacing.pacingType),
                        daily_max_type: results["group"].data.dv_line.pacing.dailyMaxMicros != '' ? '$' : 'imp',
                        max_amount: results["group"].data.dv_line.pacing.dailyMaxMicros != '' ? (results['group'].data.dv_line.pacing.dailyMaxMicros / Micros).toString() : results['group'].data.dv_line.pacing.dailyMaxImpressions,
                    } : "",
                    creative: SetDefaultValue(results["group"].data.dv_line.creativeIds),
                    bidding_strategy: results["group"].data.dv_line.bidStrategy ? "Fixed" : "",
                    bid_amount: results["group"].data.dv_line.bidStrategy.fixedBid ? (results["group"].data.dv_line.bidStrategy.fixedBid.bidAmountMicros / Micros).toString() : "",
                    flight_date_type: SetDefaultValue(results["group"].data.dv_line.flight.flightDateType),
                    flight_start_date: results["group"].data.dv_line.flight.dateRange.startDate ? DateFormat(sDate.year, sDate.month, sDate.day) : "",
                    flight_end_date: results["group"].data.dv_line.flight.dateRange.endDate  ? DateFormat(eDate.year, eDate.month, eDate.day) : "",
                    partner_costs: [],
                    partner_revenue_model_markup_amount: results["group"].data.dv_line.partnerRevenueModel ? (results["group"].data.dv_line.partnerRevenueModel.markupAmount / Micros).toString()  : "",
                    partner_revenue_model_markup_type: results["group"].data.dv_line.partnerRevenueModel ? results["group"].data.dv_line.partnerRevenueModel.markupType : "",               
                }
                //Cycle through partner costs push to default array
                if (results["group"].data.dv_line.partnerCosts) {
                    results["group"].data.dv_line.partnerCosts.map(cost => {
                        if(cost.costType !== "PARTNER_COST_TYPE_DEFAULT"){
                            if (cost.feePercentageMillis) {
                               groupDefault.partner_costs.push({ cost_type: cost.costType, fee_amount: (cost.feePercentageMillis / 1000).toString(), fee_type: cost.feeType, invoice: cost.invoiceType })
                            } else if (cost.feeAmount) {
                                groupDefault.partner_costs.push({ cost_type: cost.costType, fee_amount: (cost.feeAmount / Micros).toString(), fee_type: cost.feeType, invoice: cost.invoiceType })
                            } else {
                                
                            }
                        }
                    })
                }            
                //Push creatives to creative form field.
                if (results["creatives"] && results["creatives"].data) {
                    for (let i = 0; i < results["creatives"].data.length; i++) {
                        if (results["creatives"].data[i].dv_advertiser_id === results["group"].data.dv_advertiser_id) {
                            const creative_label = `${results["creatives"].data[i].name} | ${results["creatives"].data[i].dv_creative_id}`
                            
                            GroupForm.fields[0][0].options.push({ label: creative_label, value: results["creatives"].data[i].dv_creative_id.toString(), name: 'creatives', advertiser_id: results["creatives"].data[i].dv_advertiser_id.toString() })
                        }
                    }
                }
            }

            if(results["group"].data.dv_targeting) {
                SetTargeting(targeting,results["group"].data.dv_targeting)              
            }
            //Set the state to the default values
            setGroup(groupDefault)
        }       

        return errors.length > 0 ? (<><span>Error: {errors}</span></>) : (
            <><Tab.Container defaultActiveKey="first">
                <Row>
                    <Col lg="12">
                        <Card>
                            <Card.Body>

                                <Crumbs data={results["crumbs"].data} platform="DV" type="group" />

                                <div className="d-flex flex-wrap align-items-center justify-content-between">
                                    <div className="d-flex flex-wrap align-items-center">
                                        <div className="d-flex flex-wrap align-items-center mb-3 mb-sm-0">
                                            <h4 className="me-2 h4">{group.name}</h4>
                                            <span> - Group</span>
                                        </div>
                                    </div>
                                    <Nav as="ul" className="d-flex nav-pills mb-0 text-center profile-tab" data-toggle="slider-tab" id="profile-pills-tab" role="tablist">
                                        <Nav.Item as="li">
                                            <Nav.Link eventKey="first" className="button">Dash</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                            <Nav.Link eventKey="second" className="button">Settings</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                            <Nav.Link eventKey="fifth" className="button">Targeting</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                            <Nav.Link eventKey="fourth" className="button">Optimizations</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg="6">
                        <Card>
                            <Card.Header>
                                <div className="header-title">
                                    <h4 className="card-title">Group Info</h4>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <div className="mb-1">Type: {TrimString(group.type, 15)}</div>
                                <div className="mb-1">ID: {group.id}</div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg="12">
                        <Tab.Content className="profile-content">
                            <Tab.Pane eventKey="first" id="dash">
                                <Row className='dateButtons_sub'>
                                    <Col lg={{ span: 6, offset: 6 }}>
                                         <DateButtons setDate={setDate} date={date} />
                                    </Col>
                                </Row>

                                    <Dash dash={results["dash"].data["dv"]} account={results["account"]} />
                            </Tab.Pane >
                            <Tab.Pane eventKey="fourth" id="automizer">
                                <Optimizations data={results["group"].data} optimization={optimizations} />
                                {/* <Automizer data={results["group"]} cur={results["account"].data.currency} label="line" /> */}
                            </Tab.Pane>
                            <Tab.Pane eventKey="second" id="settings">
                                <Row>
                                    <Col lg="12">
                                        <InputSettings
                                            title='Group Settings'
                                            mode='edit'
                                            form={GroupForm}
                                            url={url}
                                            formId={"groupform"}
                                            model={[group, setGroup]}
                                        />
                                    </Col>
                                </Row>
                            </Tab.Pane >
                            <Tab.Pane eventKey="fifth" id="targeting">
                                <Row>

                                    {/* <button onClick={(() => dispatch({ type: ACTION.TG_COLOR }))}>Color</button> */}
                                    <Col lg="12">

                                        <Col lg="12">
                                            <InputSettings
                                                title='Add Targeting'
                                                mode='edit'
                                                form={TargetingForm}
                                                url={url}
                                                formId={"targeting"}
                                                model={[targeting, setTargeting]}
                                                parentModel={[group,setGroup]}
                                            />
                                        </Col>
                                    </Col>
                                </Row>
                                <Row>

                                    <Alert variant={alert.variant}>{alert.value}</Alert>
                                </Row>
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>

                </Row>
            </Tab.Container>
            </>
        );
    }
    return <><div><span><Loader></Loader></span></div></>;
}
export default (Group)
