import axios from "axios"
import { BuildAPIHeader } from "../components/common"
import { Language } from "./options"

//This is your Campaign Form Builder for DV360.

//label: The UI Label shown to user
//name: the name of the json field to update in the relevant model
//input: type of component
//options: the options for the dropdown or combobox
//required: whether the element is required
//multi: whether the dropdown or combo is multselect 
//feedback: this is the error message to show if it fails validation
//icon: for Input groups
//size: width of the element bootstrap width
//addOnName: for combo input to update model
//if/else These fields are for making a dropdown dynamic based on the input of another field.

// onst CampaignSearchModel = {
//     status: "",
//     customer_id: "",
//     campaign_id: "",
//     platform: "",
//     name: "",
//     campaign_type: "",
//     networks: [""],
//     budget: 0,
//     budget_type: "",
//     bid_strategy_type: "",
//     bid_strategy: "",
//     start_date: "",
//     end_date: "",
//     language: { language: [], excluded: false },
//     location: [{
//         city: "",
//         region: "",
//         country: "",
//         radius: 0,
//         radius_metric: "",
//         bid_adjustment: 0,
//         exclude: false,
//     }],
//     devices: [{
//         device: "",
//         bid_adjustment: 0,
//     }],
//     target_cpa: 0,
//     target_roas: 0,
//     target_impression_share: 0,
//     max_cpc_bid_limit_is: 0,
//     location_goal_is: 0,
//     tracking_template: "",
//     final_url_suffix: "",
//     custom_parameter: "",

// }
const general_info = [
    {
        label: 'Customer ID',
        input: 'dropdown',
        name: "customer_id",
        options: [],
        required: true,
        feedback: "Customer Id is a required field",
        size: "3",
    },
    {
        label: "Campaign Name",
        name: "name",
        input: 'input-text',
        required: true,
        feedback: "Campaign name is a requied field",
        size: 6,
    },
    {
        label: "Status",
        input: 'dropdown',
        name: "status",
        options: [
            {
                label: 'Active',
                value: 'Enabled'
            },
            {
                label: 'Paused',
                value: 'Paused'
            }
        ],
        required: true,
        feedback: "Status is a required field",
        size: "3",
    },
    {
        label: 'Search Platform',
        input: 'dropdown',
        name: "campaign_type",
        options: [
            { label: 'Google', value: 'google' },
            { label: 'Bing', value: 'bing' },
            { label: 'Yahoo Japan', value: 'yahoojp' },
            { label: 'Baidu', value: 'baidu' }
        ],
        required: true,
        feedback: "Search platform is a required field",
        size: "4"
    },
    {
        label: 'Networks',
        input: 'checkbox',
        name: 'networks',
        dependent:"campaign_type",
        condition:"google",    
        options: [
            { label: 'Google Search', value: 'Google search' },
            { label: 'Google Search Partners', value: 'Search partners' }
        ],
        size: "4"
    }
]


const campaign_flight = [
    {
        label: 'Start Date',
        input: 'date',
        name: 'start_date',
        size: "6",
        required: true,
        feedback: "Start date is a required field",
    },
    {
        label: 'End Date',
        input: 'date',
        name: 'end_date',
        size: "6"
    }
]
//label: The UI Label shown to user
//name: the name of the json field to update in the relevant model
//input: type of component
//options: the options for the dropdown or combobox
//required: whether the element is required
//multi: whether the dropdown or combo is multselect 
//feedback: this is the error message to show if it fails validation
//icon: for Input groups
//size: width of the element bootstrap width
//addOnName: for combo input to update model
const campaign_budget = [
    {
        label: 'Amount',
        name: "budget",
        input: 'input-number',
        size: "6",
        icon: "$",
    },
    {
        label: 'Budget Type',
        input: 'dropdown',
        name: "budget_type",
        dependent:"campaign_type",
        condition:"google",
        options: [
            { label: 'Daily', value: 'Daily' },
            { label: 'Monthly', value: 'Monthly' },
            { label: 'Lifetime', value: 'Campaign Total' },
        ],      
        size: "6",
    },
]

//     - Automated Bid Strategies | There are Some that are Portfolio Strategies (user Made) | Others are support a google standard
//     - Target CPA
//     - Target ROAS - Before opting into target ROAS, wait until the account that set up conversion tracking has received at least 15 conversions in the last 30 days.
//     - Google Ads Maximize Clicks | Standard -> Set Max CPC bid limit
//     - Google Ads Maximize Conversions -> Set Target CPA
//     - Google Ads Maximize Conversion Value -> Set Target ROAS
//     - Target Impression Share - Where do you want your ads to appear, Percent impression share to target, maximum CPC limit,
// - Manual Bid Strategies
//     - Manual CPC
//         - Enhanced CPC
//             - Optimize for Conversions
//             - Optimize for conversion value
//TODO: Add list of user generated bid strategies to this, shoudl be pulled from backend data

const bid_strategy = [
    {
        label: "Strategy",
        input: "dropdown",
        name: "bid_strategy_type",
        options: [
            { label: 'Manual CPC', value: 'Manual CPC' },
            { label: 'Target CPA (Auto)', value: 'Target CPA' },
            { label: 'Target ROAS (Auto)', value: 'Target ROAS' },
            { label: 'Google Ads Maximize Clicks (Auto)', value: 'Maximize clicks' },
            { label: 'Google Ads Maximize Conversions (Auto)', value: 'Maximize Conversions' },
            { label: 'Google Ads Maximize Conversion Value (Auto)', value: 'Maximize Conversion Value' },
            { label: 'Target Impression Share (Auto)', value: 'Target Impression Share' },
        ],
        size: "6"
    },
    {
        label: "Target CPA",
        input: "input-number",
        name: "target_cpa",
        size:"4",
        dependent:"bid_strategy_type",
        condition:"Target CPA",
        icon: "$",
    },
    {
        label: "Target ROAS",
        input: "input-number",
        name: "target_roas",
        size:"4",
        dependent:"bid_strategy_type",
        condition:"Target ROAS",
        icon: "$",
    },    {
        label: 'Enable Enhanced CPC',
        dependent: "bid_strategy_type",
        condition: "Manual CPC",
        input: 'checkbox',
        name: 'enhanced_cpc',
        size:"4",
        options: [
            { name: 'CPC (enhanced)', label: 'CPC (Enhanced)', value: 'CPC (enhanced)' },
        ]
    },
    {
        label: "Search Page Position",
        input: "dropdown",
        name: "location_goal_is",
        dependent:"bid_strategy_type",
        condition:"Target Impression Share",
        size:"6",
        options: [
            { label: 'Anywhere on results page', value: 'Anywhere on results page' },
            { label: 'Top of results page', value: 'Top of results page' },
            { label: 'Absolute top of results page', value: 'Absolute top of results page' },
        ]
    }, {
        label: "Impression Share of Position",
        input: "input-number",
        name: "target_impression_share",
        icon: "%",
        dependent:"bid_strategy_type",
        condition:"Target Impression Share",
        size:"6",
    }, {
        label: "Max CPC Bid Limit",
        input: "input-number",
        name: "max_cpc_bid_limit_is",
        dependent:"bid_strategy_type",
        condition:"Target Impression Share",
        size:"6",
        icon: "$",
    }

]



const targeting = [
    {
        label: "Language",
        input: "dropdown-exclude",
        name: "language.language",
        nameCheck: "language.excluded",
        negative: false,
        options: Language,
        size:"10",
    }
    //TODO: Add Geo Targeting. Repurpose existing logic and call ASYNC. Build ASYNC for Settings.
]



const CampaignSearchData = {
    campaign_budget: campaign_budget,
    general_info: general_info,
    bid_strategy: bid_strategy,  
    campaign_flight: campaign_flight,
    targeting: targeting,
}

const CampaignSearchForm = {
    titles: ["General", "Flight", "Budget", "Bidding Strategy"],
    fields: [
        general_info,
        campaign_flight,
        campaign_budget,
        bid_strategy,
        targeting,
        // performance_goal,
        // campaign_budget,
        // frequency_cap
    ]
}


export { general_info, bid_strategy, campaign_budget, campaign_flight, CampaignSearchData, CampaignSearchForm }