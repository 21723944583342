import React, { createContext, useContext, useState } from 'react';
import { useSearchParams } from 'react-router-dom'
import Cookies from 'universal-cookie';

export const AppState = {
    apiUrl: '',
    config: {},
    baseUrl:'',
    user: {},
    date: '',
    cleanUrl: ''
}

export const AppContext = createContext(AppState);

export const AppProvider = (props) => {
    const { children } = props;
    const [appState] = useState(AppState)

    function BuildAPIHeader() {
        const cookies = new Cookies();
        const tokenValue = cookies.get('aplus-token');
        const user = cookies.get('aplus-user');

        var config = {
            headers: {
                Token: tokenValue,
            }
        };

        appState.config = config;
        appState.user = user;
    }


    function FetchAPIurl() {
        var { REACT_APP_API_URL, REACT_APP_DEV_URL, REACT_APP_BASE_URL } = process.env
        //testing
        if (window.location.href.includes("dev.cheilmedia.com")) {
            REACT_APP_API_URL = REACT_APP_DEV_URL
        }       
        appState.apiUrl = REACT_APP_API_URL;
        appState.baseUrl = REACT_APP_BASE_URL
    }


    const FetchUrlInfo = () => {
        let [searchParams] = useSearchParams();
        let date = searchParams.get("date")
        let url = window.location.href
        let params = window.location.search
        const cleanUrl = url.replace(params, "")
        if (!date) {
            date = "1"
        }
        appState.date = date;
        appState.cleanUrl = cleanUrl;
    }

    // call all funcs
    BuildAPIHeader();
    FetchUrlInfo();
    FetchAPIurl();

    return (
        <AppContext.Provider value={appState}>
            {children}
        </AppContext.Provider>
    )
};

export const useAppContext = () => {
    const context = useContext(AppContext)

    if (!context) {
        throw new Error('useAppContext must be used within a AppProvider');
    }
    return context;
};