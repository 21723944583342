import { React, useEffect, useState } from 'react'
import { Row, Col, Button, Form, ButtonGroup, ToggleButton } from 'react-bootstrap'
import Card from '../../../components/Card'
import Select from 'react-select'
import axios from 'axios'
import Loader from '../../../components/Loader'

import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import DatePickerValue from '../../../components/date';
import { useAppContext } from '../../../context/appContext';
import { DefaultValue } from '../../../components/selectDefaults'

const generateRandom = () => {
    var length = 8,
        charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
        retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
}



export default function CustomReports() {
    const appValue = useAppContext();
    var today = (new Date()).toISOString().split('T')[0];
    const defCol = [{}]
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [dimensions, setDimensions] = useState([])
    const [metrics, setMetrics] = useState([])
    const [filter, setFilter] = useState([])
    const [columns, setColumns] = useState(defCol)
    const [dimCol, setDimCol] = useState([])
    const [metCol, setMetCol] = useState([])
    const [sdate, setSdate] = useState(today)
    const [edate, setEdate] = useState(today)
    const [platform, setPlatform] = useState("dv")
    const [dimensionsOptions, setDimensionsOptions] = useState([
            { value: "aid", label: "Client Id" },
            { value: "cid", label: "Campaign Id" },
            { value: "oid", label: "Order Id" },
            { value: "lid", label: "Group Id" },
            { value: "date", label: "Date" },
            { value: "source", label: "Exchange" },
            { value: "device_type", label: "Device Type" },
            { value: "device_make", label: "Device Make" },
            { value: "device_model", label: "Device Model" },
            { value: "app_url", label: "App/URL" },
            { value: "os", label: "Operating System" },
            { value: "browser", label: "Browser" },
            { value: "city", label: "City" },
            { value: "region", label: "Region" },
            { value: "zip_code", label: "Postal Code" },
            { value: "country", label: "Country" },
        ]
    )
    const [metricsOptions,setMetricsOptions] = useState([
        { value: "impressions", label: "Impressions" },
        { value: "clicks", label: "Clicks" },
        { value: "conversions", label: "Conversions" },
        { value: "ctr", label: "Click-Through Rate" },
        { value: "cpa", label: "Cost Per Acquisition" },
        { value: "cpc", label: "Cost Per Click" },
        { value: "cpm", label: "Cost Per Impression" },
        { value: "cost", label: "Cost" },
    ])

    var timeOptions = [
        { value: "1", label: "Yesterday" },
        { value: "tweek", label: "This Week" },
        { value: "tmonth", label: "This Month" },
        { value: "tyear", label: "This Year" },
        { value: "lweek", label: "Last Week" },
        { value: "7", label: "Last 7 Days" },
        { value: "lmonth", label: "Last Month" },
        { value: "30", label: "Last 30 Days" },
        { value: "60", label: "Last 60 Days" },
    ]

   
    useEffect(() => {
        if (platform === 'ttd') {
            setDimensionsOptions(
                [
                    { value: "advertiserid", label: "Client Id" },
                    { value: "advertiser", label: "Client Name" },
                    { value: "campaign", label: "Campaign Name" },                    
                    { value: "campaignid", label: "Campaign Id" },                    
                    { value: "adgroup", label: "Group Name" },
                    { value: "adgroupid", label: "Group Id" },
                    { value: "date", label: "Date" },
                    { value: "supplyvendor", label: "Exchange" },
                    { value: "devicetype", label: "Device Type" },
                    { value: "mediatype", label: "Media Type" },
                    { value: "app", label: "App" },
                    { value: "site", label: "Site" },
                    { value: "operatingsystem", label: "Operating System" },
                    { value: "browser", label: "Browser" },
                ] 
            )
            setMetricsOptions(
                [
                    { value: "impressions", label: "Impressions" },
                    { value: "clicks", label: "Clicks" },
                    { value: "totalconversions", label: "Conversions" },
                    { value: "ctr", label: "Click-Through Rate" },
                    { value: "cpa", label: "Cost Per Acquisition" },
                    { value: "cpc", label: "Cost Per Click" },
                    { value: "cpm", label: "Cost Per Impression" },
                    { value: "advertisercost", label: "Cost" },
                ]
            )
        }

    },[platform])

    const pageSizes = [10, 25, 50, 100];

    var REACT_APP_API_URL = appValue.apiUrl;
    const { NODE_ENV } = process.env;

    const LoadReport = (event) => {
        event.preventDefault();
        setLoading(true)
        //Build Columns
        let combVals = dimensions.concat(metrics)
        let combLabs = dimCol.concat(metCol)
        let col = []
        for (let i = 0; i < dimensions.length; i++) {
            col.push({ field: dimensions[i], headerName: dimCol[i], flex: 1, sortable: true, type: "string" })
        }
        for (let i = 0; i < metrics.length; i++) {
            col.push({ field: metrics[i], headerName: metCol[i], flex: 1, sortable: true, type: "number" })
        }


        setColumns(col)
        let postData = {
            "startDate": sdate,
            "endDate": edate,
            "dimensions": dimensions,
            "metrics": metrics,
            "filters": filter
        }
        // console.log(postData);
        return axios.post(REACT_APP_API_URL + "/reports/custom/"+platform, postData, appValue.config).then((response) => {
            if (response.data) {
                let formattedData = FormatGoJSON(response.data)
                setData(formattedData)
                setLoading(false)
                if (NODE_ENV == 'development') {
                    console.log(response.data)
                }
            } else {
                <p>{response.error}</p>
            }
        })
    }

    const FormatGoJSON = (data) => {
        let formattedData
        let arrData = data.data.results
        if (arrData == null) {
            formattedData = {}
            return formattedData
        }
        let arrCol = data.data.columns
        let len = Object.keys(arrData).length
        for (let i = 0; i < len; i++) {

            let jsonObj = {}
            let nLen = Object.keys(arrData[i]).length
            // let values = Object.keys(arrData[i]).map(function (key) {return arrData[i]});
            // console.log(values) 
            for (let x = 0; x < nLen; x++) {
                // console.log(arrData[i][x])

                jsonObj[arrCol[i][x].toLowerCase()] = arrData[i][x];

            }
            let newJson = JSON.stringify(jsonObj)

            if (i == 0) {
                formattedData = { "data": [jsonObj] }
            } else {
                formattedData.data.push(jsonObj)
            }
        }
        return formattedData
    }

    const OnDateSelect = (event) => {
        let val = event.value
        let start;
        let end;
        let today = new Date();
        let date = new Date()
        let dow = date.getDay()

        switch (val) {
            case "1":
                start = date
                start.setDate(date.getDate() - 1);
                end = start
                break;
            case "tweek":
                if (dow > 0) {
                    start = date
                    start.setDate(date.getDate() - dow);
                    end = today
                } else {
                    start = today
                    end = today
                }
                break;
            case "tmonth":
                date.setDate(1)
                start = date;
                end = today
                break;
            case "tyear":
                date.setMonth(0, 1)
                start = date;
                end = today
                break;
            case "lweek":
                start = date
                start.setDate(start.getDate() - (dow + 7));
                end = today
                end.setDate(end.getDate() - (dow + 1));
                break;
            case "7":
                date.setDate(date.getDate() - 7);
                start = date
                end = today
                break;
            case "lmonth":
                start = date;
                start.setMonth(start.getMonth() - 1, 1)
                end = today;
                end.setMonth(end.getMonth(), 0)
                break;
            case "30":
                start = date;
                start.setDate(start.getDate() - 30);
                end = today
                break;
            case "60":
                start = date;
                start.setDate(start.getDate() - 60);
                end = today
                break;
            default:
                start = today
                end = today
        }

        let formatStart = start.toISOString().split('T')[0]
        let formatEnd = end.toISOString().split('T')[0]

        setSdate(formatStart)
        setEdate(formatEnd)
    }
    const OnDimensionChange = (event) => {
        let dimArr = [];
        let labArr = [];
        for (let i = 0; i < event.length; i++) {
            dimArr.push(event[i].value)
            labArr.push(event[i].label)
        }
        setDimCol(labArr)
        setDimensions(dimArr)
    }
    const OnMetricChange = (event) => {
        let metArr = [];
        let labArr = [];
        for (let i = 0; i < event.length; i++) {
            metArr.push(event[i].value)
            labArr.push(event[i].label)

        }
        setMetCol(labArr)
        setMetrics(metArr)
    }
    const FilterComponent = ({ defaultVal, index, name }) => {
        let OperandOpt = [
            { value: "=", label: "Equals", },
            { value: ">", label: "Greater Than", },
            { value: "<", label: "Less Than", },
            { value: ">=", label: "Greater Than or Equal to" },
            { value: "<=", label: "Less Than or Equal to" },
            { value: "<>", label: "Is Not", },
        ]
        let JoinOpt = [
            { value: "AND", label: "And", },
            { value: "OR", label: "Or", },
        ]
        let Field = []
        metCol.forEach((m, i) => {
            Field.push({ value: metrics[i], label: m })
        })
        dimCol.forEach((d, i) => {
            Field.push({ value: dimensions[i], label: d })
        })
        let OperandValue = DefaultValue(defaultVal.operand, OperandOpt) || ""
        let FieldsValue = DefaultValue(defaultVal.filter, Field) || ""
        let JoinsValue;
        if (index > 0) {
            JoinsValue = DefaultValue(defaultVal.join, JoinOpt) || ""
        }


        return (<><Row>
            <Row className="mt-4">
                {(index > 0) ? (<>
                    <Col lg="2" sm="12">
                        <span>Join Condition</span>
                    </Col>
                </>) : (<></>)}
                <Col lg="3" sm="12">
                    <span>Filter Field</span>
                </Col>
                <Col lg="3" sm="12">
                    <span>Operand</span>
                </Col>
                <Col lg="3" sm="12">
                    <span>Value</span>
                </Col>
            </Row>
            <Row className="mb-2">
                {(index > 0) ? (<>
                    <Col lg="2" sm="12">
                        <Select
                            name={"join_" + index}
                            options={JoinOpt}
                            defaultValue={JoinsValue}
                            onChange={(event) => {
                                OnFilterChange(defaultVal, "Join", index, event)
                            }}
                        />
                    </Col>
                </>) : (<></>)}
                <Col lg="3" sm="12">
                    <Select
                        name={"field_" + index}
                        options={Field}
                        defaultValue={FieldsValue}
                        onChange={(event) => {
                            OnFilterChange(defaultVal, "Filter", index, event)
                        }}
                    />
                </Col>
                <Col lg="3" sm="12">
                    <Select
                        name={"operand_" + index}
                        options={OperandOpt}
                        defaultValue={OperandValue}
                        onChange={(event) => {
                            OnFilterChange(defaultVal, "Operand", index, event)
                        }}
                    />
                </Col>
                <Col lg="3" sm="12">
                    <Form.Control
                        type="text"
                        name="value"
                        defaultValue={defaultVal.value}
                        onBlur={event => {
                            OnFilterChange(defaultVal, "Value", index, event)
                        }}
                    />
                </Col>

                <Col lg="1">
                    <Button className="text-center btn-dark btn-icon mt-lg-0 mt-md-0 mt-3" variant="dark" onClick={(event) => { OnFilterChange(event, "Remove", index) }}>
                        <i className="btn-inner">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-square" viewBox="0 0 16 16">
                                <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                            </svg>
                        </i>
                    </Button>
                </Col>
            </Row>
        </Row></>)
    }
    const OnFilterChange = (defaultValue, type, i, event) => {
        let FilterLen = filter.length
        if (type === "Remove") {
            filter.splice(i, 1)
            setFilter([...filter])

        }
        if (type === "Add") {
            if (FilterLen === 0) {
                filter.push({ filter: "", operand: "", value: "" })
            } else {
                filter.push({ filter: "", operand: "", value: "", join: "" })
            }

            setFilter([...filter])
        }
        let joinDef
        if (i > 0) {
            joinDef = defaultValue.join
        } else {
            joinDef = ""
        }
        if (type === "Operand") {
            filter[i] = { filter: defaultValue.filter, operand: event.value, value: defaultValue.value, join: joinDef }
            setFilter([...filter])
        }
        if (type === "Filter") {
            filter[i] = { filter: event.value, operand: defaultValue.operand, value: defaultValue.value, join: joinDef }
            setFilter([...filter])
        }
        if (type === "Value") {
            filter[i] = { filter: defaultValue.filter, operand: defaultValue.operand, value: event.target.value, join: joinDef }
            setFilter([...filter])
        }
        if (type === "Join") {
            filter[i] = { filter: defaultValue.filter, operand: defaultValue.operand, value: defaultValue.value, join: event.value }
            setFilter([...filter])
        }
    }

    return <>
        <div>
            <Row>
                <Col sm="12">
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">Reporting</h4>
                            </div>
                            {/* <div>
                                <Button className="text-center btn-dark btn-icon mt-lg-0 mt-md-0 mt-3" variant="dark" >
                                    <i className="btn-inner">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                        </svg>
                                    </i>
                                    <span>New Report</span>
                                </Button>
                            </div> */}
                        </Card.Header>
                        <Card.Body className="px-1">
                            <Row>
                                <div className="mb-4 px-5" style={{ 'z-index': 1000000 }}>
                                    <Form onSubmit={LoadReport}>
                                        <Form.Group as={Row}>
                                            <Row>
                                                <Col lg="4" sm="4" className='mb-3'>
                                                    <Select name="time" options={timeOptions} onChange={OnDateSelect} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="4" sm="4" className='mb-3'>
                                                    <DatePickerValue start={sdate} end={edate} sState={setSdate} eState={setEdate} />
                                                </Col>
                                                <Col lg="4" sm="4" className='mb-3'>
                                                    <ButtonGroup>
                                                        <ToggleButton
                                                            key={"dv"}
                                                            id={`platform-dv`}
                                                            type="radio"
                                                            variant={'outline-success' }
                                                            name="radio"
                                                            value={'dv'}
                                                            checked={platform === 'dv'}
                                                            onChange={(e) => setPlatform(e.currentTarget.value)}
                                                            >DV360</ToggleButton>
                                                        <ToggleButton
                                                            key={"ttd"}
                                                            id={`platform-ttd`}
                                                            type="radio"
                                                            variant={'outline-info'}
                                                            name="radio"
                                                            value={'ttd'}
                                                            checked={platform === 'ttd'}
                                                            onChange={(e) => setPlatform(e.currentTarget.value)}
                                                        >The Trade Desk</ToggleButton>
                                                    </ButtonGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="4" sm="4">
                                                    <Form.Label>Dimensions</Form.Label>
                                                    <Select
                                                        isMulti
                                                        isSearchable
                                                        name="dimensions"
                                                        options={dimensionsOptions}
                                                        onChange={event => OnDimensionChange(event)}
                                                    />
                                                </Col>
                                                <Col lg="4" sm="4">
                                                    <Form.Label>Metrics</Form.Label>
                                                    <Select
                                                        isMulti
                                                        name="metrics"
                                                        options={metricsOptions}
                                                        onChange={event => OnMetricChange(event)}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="mt-4">
                                                <Col lg="3">
                                                    <Button variant="dark" onClick={() => {
                                                        OnFilterChange("", "Add", 0)
                                                    }}>Add Filter</Button>
                                                </Col>
                                            </Row>
                                            <Row className="test">
                                                {filter.map((f, i) => {
                                                    return (
                                                        <FilterComponent defaultVal={f} index={i} />
                                                    )
                                                })}
                                            </Row>
                                            <Row>
                                                <Col lg="2" className="mt-3">
                                                    <Button variant="dark" type='submit' >
                                                        Run Report
                                                    </Button>{' '}
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Form>
                                </div>
                            </Row>
                            <Row>
                                <Col lg="12" sm="12">
                                    {(loading) ? (<Loader></Loader>) : (<></>)}
                                    {(data.data && !loading) ? (
                                        <DataGrid
                                            rows={data.data ?? []}
                                            columns={columns}
                                            // pageSize={page_size}
                                            // rowsPerPageOptions={[row_size]}
                                            // checkboxSelection
                                            getRowId={(row) => generateRandom()}
                                            // initalState={{filter: {filterModel:{filter}}}}
                                            slots={{ toolbar: GridToolbar }}
                                            slotProps={{
                                                toolbar: {
                                                    showQuickFilter: true,
                                                    quickFilterProps: { debounceMs: 500 },
                                                },
                                            }}
                                            autoHeight
                                        />
                                    ) : (
                                        <></>
                                    )}

                                </Col>

                            </Row>

                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    </>

}
