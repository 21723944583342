import React, { useEffect, useState } from 'react'
import { Row, Col, Image, Nav, Tab } from 'react-bootstrap'
import Card from '../../../components/Card'

import { useParams } from 'react-router-dom'
import Loader from '../../../components/Loader'
import ReactTable from '../../../components/table'
import { FetchDashFromAPI} from '../../../components/ajx'
import { CountEntities } from '../../../components/common'
import { columnsCampaign, columnsGroup } from '../../../components/gridColumns'

// img

import avatars11 from '../../../assets/images/avatars/01.png'
// AOS
import AOS from 'aos'
import '../../../../node_modules/aos/dist/aos'
import '../../../../node_modules/aos/dist/aos.css'
//apexcharts



// store
import Dash from '../../../components/dash';
import { DateButtons } from '../../../components/dateButtons';
import { TradeDeskCampaignModel, TradeDeskAdvertiserModel } from '../../../components/Models'
import InputSettings from '../../../components/InputSettings'
import { ClientTradeDeskForm } from '../../../util/client-input-trade-desk'
import { useAppContext } from '../../../context/appContext'



const TradeDeskClient = (props) => {
    const appValue = useAppContext();
    const { clid } = useParams()
    var REACT_APP_API_URL = appValue.apiUrl;
    
    const [client, setClient] = useState(TradeDeskAdvertiserModel)
    const [campaign] = useState(TradeDeskCampaignModel)
    const [initialLoad, setInitialLoad] = useState(true)
    const [date, setDate] = useState(1)
    const [platform, setPlatform] = useState("ttd")
    const [dashAPI, setDashAPI] = useState({
        urls: [
            {
                name: "client",
                url: REACT_APP_API_URL + "/client/" + clid + "/" + "TD"
            },
            {
                name: "campaigns",
                url: REACT_APP_API_URL + "/campaign/list/client/" + clid
            },
            {
                name: "groups",
                url: REACT_APP_API_URL + "/group/list/client/" + clid
            },
            {
                name: "dash",
                url: REACT_APP_API_URL + "/reports/dash/client/" + appValue.user.apid + "/" + clid + "/" + "TD" + "?date=" + date
            },
            {
                name: "account",
                url: REACT_APP_API_URL + "/admin/get/account/" + appValue.user.apid
            },

        ],
        method: "GET",

    })
    useEffect(() => {
        complete = false
        setDashAPI({
            urls: [
                {
                    name: "client",
                    url: REACT_APP_API_URL + "/client/" + clid + "/" + "TD"
                },
                {
                    name: "campaigns",
                    url: REACT_APP_API_URL + "/campaign/list/client/" + clid
                },
                {
                    name: "groups",
                    url: REACT_APP_API_URL + "/group/list/client/" + clid
                },
                {
                    name: "dash",
                    url: REACT_APP_API_URL + "/reports/dash/client/" + appValue.user.apid + "/" + clid + "/" + "TD" + "?date=" + date
                },
                {
                    name: "account",
                    url: REACT_APP_API_URL + "/admin/get/account/" + appValue.user.apid
                },

            ],
            method: "GET",

        })
    }, [date])

    useEffect(() => {

        AOS.init({
            startEvent: 'DOMContentLoaded',
            disable: function () {
                var maxWidth = 996;
                return window.innerWidth < maxWidth;
            },
            throttleDelay: 10,
            once: true,
            duration: 700,
            offset: 10
        });

    })



    const { NODE_ENV } = process.env;

    var { results, errors, complete } = FetchDashFromAPI(dashAPI)

    if (complete) {
        if (NODE_ENV == 'development') {
            console.log(results)
        }
        if (initialLoad) {
            setInitialLoad(false)
            const data = results["client"].data;
            const c = data.trade_desk_advertiser;
            client.advertiserName = data.name;
            client.advertiserNameDsa = c.advertiserNameDsa;
            client.status = data.status;
            client.id = data.advertiserId;
            client.currencyCode = data.currency;
            client.description = c.description;
            client.domainAddress = c.domainAddress;
            client.partnerId = c.partnerId;
            client.payerNameDsa = c.payerNameDsa;
            client.vettingStatus = c.vettingStatus;
            client.advertiserCategory = c.advertiserCategory;
            client.advertiserAudienceSettings = c.advertiserAudienceSettings;
            client.industryCategoryId = c.industryCategoryId;
            client.industryCategoryTaxonomyId = c.industryCategoryTaxonomyId;
            client.attributionClickLookbackWindowInSeconds = c.attributionClickLookbackWindowInSeconds;
            client.attributionImpressionLookbackWindowInSeconds = c.attributionImpressionLookbackWindowInSeconds;
            client.ignoreReferralUrlInConversion = c.ignoreReferralUrlInConversion;
            client.isBallotMeasure = c.isBallotMeasure;
            client.isCandidateElection = c.isCandidateElection;
            client.useLegacyAdditionalFees = c.useLegacyAdditionalFees;
        }

        let activeCampaigns = CountEntities(results["campaigns"].data);
        let activeGroups = CountEntities(results["groups"].data)

        return errors.length > 0 ? (<><span>Error: {errors}</span></>) : (
            <>
                <Tab.Container defaultActiveKey="first">
                    <Row>
                        <Col lg="12">
                            <Card>
                                <Card.Body>
                                    <div className="d-flex flex-wrap align-items-center justify-content-between">
                                        <div className="d-flex flex-wrap align-items-center">
                                            <div className="profile-img position-relative me-3 mb-3 mb-lg-0 profile-logo profile-logo1">
                                                <Image className="theme-color-default-img  img-fluid rounded-pill avatar-100" src={avatars11} alt="profile-pic" />
                                            </div>
                                            <div className="d-flex flex-wrap align-items-center mb-3 mb-sm-0">
                                                <h4 className="me-2 h4">{results["client"].data.name}</h4>
                                                <span> - Client</span>
                                            </div>
                                        </div>
                                        <Nav as="ul" className="d-flex nav-pills mb-0 text-center profile-tab" data-toggle="slider-tab" id="profile-pills-tab" role="tablist">
                                            <Nav.Item as="li">
                                                <Nav.Link eventKey="first" className="button">Dash</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item as="li">
                                                <Nav.Link eventKey="fifth" className="button">Settings</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item as="li">
                                                <Nav.Link eventKey="fourth" className="button">Campaigns</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item as="li">
                                                <Nav.Link eventKey="third" className="button">Groups</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg="6">
                            <Card>
                                <Card.Header>
                                    <div className="header-title">
                                        <h4 className="card-title">Snapshot</h4>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <ul className="list-inline m-0 p-0">
                                        <li className="d-flex mb-2">
                                            <div className="news-icon me-3">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-postcard-fill" viewBox="0 0 16 16"><path d="M11 8h2V6h-2v2Z"></path><path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm8.5.5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7ZM2 5.5a.5.5 0 0 0 .5.5H6a.5.5 0 0 0 0-1H2.5a.5.5 0 0 0-.5.5ZM2.5 7a.5.5 0 0 0 0 1H6a.5.5 0 0 0 0-1H2.5ZM2 9.5a.5.5 0 0 0 .5.5H6a.5.5 0 0 0 0-1H2.5a.5.5 0 0 0-.5.5Zm8-4v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5Z"></path></svg>
                                            </div>
                                            <p className="news-detail mb-0">{activeCampaigns} - Active Campaigns</p>
                                        </li>
                                        <li className="d-flex">
                                            <div className="new-icon me-3">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-collection-fill" viewBox="0 0 16 16"><path d="M0 13a1.5 1.5 0 0 0 1.5 1.5h13A1.5 1.5 0 0 0 16 13V6a1.5 1.5 0 0 0-1.5-1.5h-13A1.5 1.5 0 0 0 0 6v7zM2 3a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 0-1h-11A.5.5 0 0 0 2 3zm2-2a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 0-1h-7A.5.5 0 0 0 4 1z"></path></svg>
                                            </div>
                                            <p className="news-detail mb-0">{activeGroups} - Active Groups</p>

                                        </li>
                                    </ul>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg="6">
                            <Card>
                                <Card.Header>
                                    <div className="header-title">
                                        <h4 className="card-title">Client Info</h4>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <div className="mb-1">Currency: {client.currencyCode}</div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg="12">
                            <Tab.Content className="profile-content">
                                <Tab.Pane eventKey="fourth" id="campaign">

                                    <Card>
                                        <Card.Header className="d-flex justify-content-between">
                                            <div className="header-title">
                                                <h4 className="card-title">Campaign List</h4>
                                            </div>
                                        </Card.Header>
                                        <Card.Body className="px-0">
                                            <div className="table-responsive">
                                                <ReactTable item={results["campaigns"]} col={columnsCampaign} page_size={20} row_size={20} />
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Tab.Pane>
                                <Tab.Pane eventKey="third" id="group">
                                    <Card>
                                        <Card.Header className="d-flex justify-content-between">
                                            <div className="header-title">
                                                <h4 className="card-title">Group List</h4>
                                            </div>
                                        </Card.Header>
                                        <Card.Body className="px-0">
                                            <div className="table-responsive">
                                                <ReactTable item={results["groups"]} col={columnsGroup} page_size={20} row_size={20} />
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Tab.Pane >
                                <Tab.Pane eventKey="first" id="dash">
                                    <Row className='dateButtons_sub'>
                                    <Col lg={{ span: 6, offset: 6 }}>
                
                                            <DateButtons setDate={setDate} date={date} />
                                        </Col>
                                    </Row>

                                    {(Object.keys(results["dash"].data[platform].kpi).length === 0) ? (
                                        <span>No Data Available </span>
                                    ) : (
                                        <Dash dash={results["dash"].data[platform]} account={results["account"]} />

                                    )}
                                </Tab.Pane >
                                <Tab.Pane eventKey="fifth" id="settings">
                                    <Row>
                                        <Col lg="12">
                                            <InputSettings
                                                title='Edit Client'
                                                mode='edit'
                                                form={ClientTradeDeskForm}
                                                formId={"clientform"}
                                                model={[client, setClient]}
                                                disabled={true}
                                            />
                                        </Col>
                                    </Row>
                                </Tab.Pane >
                            </Tab.Content>
                        </Col>

                    </Row>
                </Tab.Container>
            </>
        )

    }
    return <><div><span><Loader></Loader></span></div></>;
}

export default (TradeDeskClient)