const MetaCampaignModel = {
    id: '',
    account_id: '',
    ad_labels: [
        { label: '', value: '' }
    ],
    bid_strategy: '',
    boosted_object_id: '',
    budget_rebalance_flag: undefined,
    budget_remaining: '',
    buying_type: '',
    can_create_brand_life_study: undefined,
    can_use_spend_cap: undefined,
    created_time: '', 
    budget_type:'',
    daily_budget: '',
    effective_status: '',
    has_secondary_skadnetwork_reporting: undefined,
    issues_info: [
        {
            error_code: 0,
            error_message: '', 
            error_summary: '',
            error_type: '',
            level: ''
        }
    ],
    last_budget_toggline_time: '',
    lifetime_budget: '',
    name: '',
    objective: '',
    pacing_type: [],
    primary_attribution: '',
    promotion_type:'',
    promoted_object: {},
    recommendations: [],
    smart_promotion_type: '',
    source_campaign_id: '',
    special_ad_category: '',
    special_ad_category_country: [''],
    spend_cap: '',
    start_time: '',
    stop_time: '',
    status: '',
    topline_id: '',
    updated_time: '',
    name_error: false,
    objective_error: false,
    special_ad_category_error: false,
    budget_error: false,
}

const MetaOrderModel = {
    ad_labels: [],
    adset_schedule: [],
    bid_amount: 0,
    bid_strategy: '',
    billing_event: '',
    campaign_attribution: '',
    campaign_id: '',
    daily_budget: '',
    daily_spend_cap: '',
    daily_min_spend_target: '',
    destination_type: '',
    dsa_beneficiary: '',
    dsa_payor: '',
    start_time: new Date(),
    end_time: new Date(),
    is_dynamic_creative: undefined,
    lifetime_budget: '',
    lifetime_imps: 0,
    lifetime_min_spend_target: '',
    lifetime_spend_cap: '',
    multi_optimization_goal_weight: '',
    name: '',
    optimization_goal: '',
    optimization_sub_event: '',
    rf_prediction_id: '',
    source_adset_id: '',
    status: '',
    targeting: {}
}

const MetaGroupModel = {
    id: '',
    ad_labels: [],
    adset_id: '',
    bid_amount: 0,
    campaign_id: '',
    conversion_domain: '',
    creative: {
        id: ''
    },
    meta_reward_ad_group_status: '',
    name: '',
    source_ad_id: '',
    status: '',
    tracking_specs: [
        {
            action_type: [],
            page: [],
            post: [],
            fb_pixel: [],
            conversion_id: [],
            post_wall: []
        }
    ]
}

const MetaPageModel = {
    id: '',
    engagement: {
        count: undefined,
        count_string: '',
        count_string_with_links: '',
        count_string_without_links: '',
        social_sentence: '',
        social_sentence_with_links: ''
    },
    fan_count: undefined,
    followers_count: undefined,
    name: '',
    talking_about_count: undefined,
}

export { MetaCampaignModel, MetaGroupModel, MetaOrderModel, MetaPageModel }