import { React, useState } from 'react'
import { Row, Col, Button, Form, Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Card from '../../../components/Card'
import axios from 'axios'
import Loader from '../../../components/Loader'
import ReactTable from '../../../components/table'
import { FetchFromAPI } from '../../../components/ajx'
import { Status } from '../../../components/common'
import Select from 'react-select'
import { useAppContext } from '../../../context/appContext'



export default function UserList() {
   const appValue = useAppContext();
   const [values, setValues] = useState({
      username: "",
      role: "",
      status: "",
   });

   const [account, setAccount] = useState('')
   const [show, setShow] = useState(false);
   const [showDelete, setShowDelete] = useState(false);

   const [newUser, setNewUser] = useState(true);
   const [showErrorMessage, setShowErrorMessage] = useState(false);

   const handleClose = () => {
      setShow(false);
      setShowDelete(false);
   }
   function handleShow(params, type) {
      if (type === "edit") {
         // console.log("edit");
         setValues({
            username: params.row.username,
            role: params.row.role,
            status: params.row.status,
         });
         setNewUser(false);
         setAccount(params.row.aid);
      } else {
         setValues({
            username: "",
            role: "",
            status: "",
         });
         setNewUser(true);
         setAccount("");
      }

      if (type === "delete") {
         setShowDelete(true);
         // console.log("Delete Called")
         setValues({ id: params.row._id })
      } else {
         setShow(true);
      }
   }
   var REACT_APP_API_URL = appValue.apiUrl;
   const { NODE_ENV } = process.env;

   const userCreate = async (json) => {
      const url = REACT_APP_API_URL + '/user/create';


      return axios.post(url, json, appValue.config).then((response) => {
         if (response.data) {
            if (NODE_ENV == 'development') {
               console.log(response.data);
            }
            window.location.reload(true)

         } else {
            <p>{response.error}</p>
         }
      })
   }

   const userEdit = async (json) => {
      console.log("updating...")
      const url = REACT_APP_API_URL + '/user/update';


      return axios.put(url, json, appValue.config).then((response) => {
         if (response.data) {
            if (NODE_ENV == 'development') {

               console.log(response.data);
            }
            window.location.reload(true)
         } else {
            <p>{response.error}</p>
         }
      })
   }

   const userDelete = async (json) => {
      if (NODE_ENV == 'development') {
         console.log("deleting...")

      }
      const url = REACT_APP_API_URL + '/user/delete';

      return axios.delete(url, appValue.config).then((response) => {
         if (response.data) {
            if (NODE_ENV == 'development') {
               console.log(response.data);
            }
            window.location.reload(true)
         } else {
            <p>{response.error}</p>
         }
      })
   }
   function handleDelete(event) {
      event.preventDefault();
      if (NODE_ENV == 'development') {
         console.log(event.target.id.value)
      }
      const json = JSON.stringify({
         id: event.target.id.value,
      });
      userDelete(json);
      handleClose();
   }

   function handleSubmit(event) {
      event.preventDefault();
      if (NODE_ENV == 'development') {
         console.log(newUser);
      }
      if (event.target.password.value && newUser) {
         if (event.target.password.value !== event.target.cpassword.value) {
            setShowErrorMessage(true);
         } else {
            setShowErrorMessage(false);
            const json = JSON.stringify({
               username: event.target.username.value,
               password: event.target.password.value,
               role: event.target.role.value,
               status: event.target.status.value,
               apid: event.target.apid.value,
            });
            if (NODE_ENV == 'development') {
               console.log(json);
            }
            userCreate(json);
            handleClose();
         }
      } else if (event.target.password.value) {
         if (event.target.password.value !== event.target.cpassword.value) {
            setShowErrorMessage(true);
         } else {
            setShowErrorMessage(false);
            const json = JSON.stringify({
               username: event.target.username.value,
               password: event.target.password.value,
               role: event.target.role.value,
               status: event.target.status.value,
               apid: event.target.apid.value,
            });

            userEdit(json);
            handleClose();

         }
      } else {
         const json = JSON.stringify({
            username: event.target.username.value,
            role: event.target.role.value,
            status: event.target.status.value,
            apid: event.target.apid.value,
         });

         userEdit(json);
         handleClose();
      }
   }
   const userAPI = {
      urls: [
         {
            name: "users",
            url: REACT_APP_API_URL + "/user/list"
         },
         {
            name: "roles",
            url: REACT_APP_API_URL + "/admin/list/roles"
         },
         {
            name: "user",
            url: REACT_APP_API_URL + "/user/get"
         },
         {
            name: "accounts",
            url: REACT_APP_API_URL + "/admin/list/accounts"
         },

      ],
      method: "GET",

   }

   const { results, errors, complete } = FetchFromAPI(userAPI)
   if (complete) {

      const options = []
      for (let i = 0; i < results["roles"].data.length; i++) {
         options.push({ value: results["roles"].data[i]._id, label: results["roles"].data[i].Name })
      }

      const accountOptions = []
      for (let i = 0; i < results["accounts"].data.length; i++) {
         accountOptions.push({ value: results["accounts"].data[i]._id, label: results["accounts"].data[i].name })
      }
      const columns = [
         {
            field: 'Name', headerName: 'Username', flex: 1, sortable: true, renderCell: (params) => (
               <a href={'user/' + params.row._id}>{params.row.username}</a>
            ),
         },
         { field: 'Role', headerName: 'Role', flex: 1, renderCell: (params) => params.row.role_name },
         { field: 'AccountId', headerName: 'Account Id', flex: 1, sortable: true, renderCell: (params) => params.row.aid },
         {
            field: 'Status', headerName: 'Status', flex: 1, sortable: true, renderCell: (params) => {
               return <>
                  <span className={`badge ${params.row.status ? "bg-success" : "bg-danger"}`}>{params.row.status ? "Active" : "Inactive"}</span>
               </>
            }
         },
         { field: '_id', headerName: 'ID', flex: 1 },
         {
            field: "action",
            headerName: "Action",
            sortable: false,
            renderCell: (params) => {
               const onClick = (e) => {
                  e.stopPropagation(); // don't select this row after clicking

                  const api: GridApi = params.api;
                  const thisRow: Record<string, GridCellValue> = {};

                  api
                     .getAllColumns()
                     .filter((c) => c.field !== "__check__" && !!c)
                     .forEach(
                        (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
                     );

                  return alert(JSON.stringify(thisRow, null, 4));
               };

               return <><Link className="btn btn-sm btn-icon btn-warning table-sm" data-toggle="tooltip" data-placement="top" title="" data-original-title="Edit" to="#" onClick={() => { handleShow(params, "edit") }}>
                  <span className="btn-inner">
                     <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                        <path fillRule="evenodd" clipRule="evenodd" d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                        <path d="M15.1655 4.60254L19.7315 9.16854" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                     </svg>
                  </span>
               </Link>{' '}
                  <p></p>
                  <Link className="btn btn-sm btn-icon btn-danger" data-toggle="tooltip" data-placement="top" title="" data-original-title="Delete" to="#" onClick={() => { handleShow(params, "delete") }}>
                     <span className="btn-inner">
                        <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
                           <path d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                           <path d="M20.708 6.23975H3.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                           <path d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                        </svg>
                     </span>
                  </Link>{' '}</>
            }
         }
      ];
      return errors.length > 0 ? (<><span>Error: {errors}</span></>) : (
         <>
            <div>
               <Row>
                  <Col sm="12">
                     <Card>
                        <Card.Header className="d-flex justify-content-between">
                           <div className="header-title">
                              <h4 className="card-title">User List</h4>
                           </div>
                           <div>
                              <Button className="text-center btn-icon mt-lg-0 mt-md-0 mt-3" variant="dark" onClick={() => { handleShow("none", "add") }}>
                                 <i className="btn-inner">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                       <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                    </svg>
                                 </i>
                                 <span>New User</span>
                              </Button>
                              {/* Modal Popup */}
                              <Modal show={show} onHide={handleClose}>
                                 <Modal.Header closeButton>
                                    <Modal.Title>Add new user</Modal.Title>
                                 </Modal.Header>
                                 <Modal.Body>
                                    <Form onSubmit={
                                       (event) => {
                                          setAccount(results["user"].user.aid);
                                          handleSubmit(event);
                                          // userpush()
                                          // handleClose()
                                       }
                                    }>
                                       <Form.Group className="mb-3" >
                                          <Form.Label>Username (email)</Form.Label>
                                          <Form.Control type="text" defaultValue={values.username} name="username" placeholder="Email" required />
                                       </Form.Group>
                                       <Form.Group className="mb-3" controlId="formBasicPassword">
                                          <Form.Label>Password</Form.Label>
                                          <Form.Control type="password" name="password" placeholder="Password" />
                                       </Form.Group>
                                       <Form.Group className="mb-3" controlId="formBasicPasswordConfirm">
                                          <Form.Label>Password</Form.Label>
                                          <Form.Control type="password" name="cpassword" placeholder="Confirm Password" />
                                       </Form.Group>
                                       {showErrorMessage ? <div> Passwords did not match </div> : ''}
                                       <Form.Group className="mb-3" >
                                          <Form.Label>Role</Form.Label>
                                          <Select
                                             name="role"
                                             defaultValue={() => {
                                                for (let i = 0; i < options.length; i++) {
                                                   if (options[i].value === values.role) {
                                                      return options[i]
                                                   }
                                                }
                                             }}
                                             options={options} required
                                          />
                                       </Form.Group>
                                       <Form.Group>
                                          <Form.Label>Account(s)</Form.Label>
                                          <Select name="apid" options={accountOptions} defaultValue={() => {
                                             for (let i = 0; i < accountOptions.length; i++) {
                                                if (accountOptions[i].value === account) {
                                                   return accountOptions[i]
                                                }
                                             }
                                          }} isMulti required>

                                          </Select>
                                       </Form.Group>
                                       <Form.Group className="form-group">
                                          <Form.Label htmlFor="status">Status:</Form.Label>
                                          <Status value={values.status} name="status" required />
                                       </Form.Group>
                                       <div className="d-flex justify-content-between">

                                          <Button variant="danger" onClick={handleClose}>
                                             Cancel
                                          </Button>
                                          <Button variant="dark" type='submit' >
                                             Save
                                          </Button>{' '}
                                       </div>
                                    </Form>
                                 </Modal.Body>
                              </Modal>

                              {/* Modal Popup - Delete */}
                              <Modal show={showDelete} onHide={handleClose}>
                                 <Modal.Header closeButton>
                                    <Modal.Title>Delete user</Modal.Title>
                                 </Modal.Header>
                                 <Modal.Body>
                                    <Form onSubmit={
                                       (event) => {
                                          setAccount(results["user"].user.aid);
                                          handleDelete(event);
                                       }
                                    }>
                                       <Form.Group className="mb-3" >
                                          <Form.Control type="hidden" defaultValue={values.id} name="id" />
                                       </Form.Group>
                                       <div className="d-flex justify-content-between">
                                          <Button variant="danger" onClick={handleClose}>
                                             Cancel
                                          </Button>
                                          <Button variant="dark" type='submit' >
                                             Delete
                                          </Button>{' '}
                                       </div>
                                    </Form>
                                 </Modal.Body>
                              </Modal>
                           </div>
                        </Card.Header>
                        <Card.Body className="px-0">
                           <div className="table-responsive" style={{ display: 'flex', height: 'auto' }}>
                              <ReactTable item={results["users"]} col={columns} page_size={20} row_size={20} />
                           </div>
                        </Card.Body>
                     </Card>
                  </Col>
               </Row>
            </div>
         </>
      )
   }
   return <><tr><td><Loader></Loader></td></tr></>;
}