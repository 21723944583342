import { React, useEffect, useState } from 'react'
import { Row, Col, Tab, Nav } from 'react-bootstrap'
import Card from '../../../components/Card'
import Loader from '../../../components/Loader'
import { useParams } from 'react-router-dom'
import { FetchFromAPI } from '../../../components/ajx'
import { columnsGroup } from '../../../components/gridColumns'
import ReactTable from '../../../components/table'
import Dash from '../../../components/dash';

// AOS
import AOS from 'aos'
import '../../../../node_modules/aos/dist/aos'
import '../../../../node_modules/aos/dist/aos.css'
import Cookies from 'universal-cookie';
import Automizer from '../../../components/automizer'
import {DateButtons} from '../../../components/dateButtons'
import { MetaOrderModel, MetaGroupModel } from '../../../components/Models';
import { MetaOrderForm } from '../../../util/meta-order-input'
import Crumbs from '../../../components/crumbs'
import { DefaultValue, MultiDefault } from '../../../components/selectDefaults'
import { GroupMetaModal } from '../../../components/Modals'
import { ModalButton } from '../../../components/ModalSettings'
import InputSettings from '../../../components/InputSettings'
import { useAppContext } from '../../../context/appContext'
import { MetaGroupForm } from '../../../util/meta-group-input'



const MetaOrder = (props) => {
    const appValue = useAppContext();
    const [order, setOrder] = useState(MetaOrderModel);
    const [metaGroupModal, setMetaGroupModal] = useState(false);
    const [initialLoad, setInitialLoad] = useState(true);
    const [metaGroup, setMetaGroup] = useState(MetaGroupModel);

    useEffect(() => {
        AOS.init({
            startEvent: 'DOMContentLoaded',
            disable: function () {
                var maxWidth = 996;
                return window.innerWidth < maxWidth;
            },
            throttleDelay: 10,
            once: true,
            duration: 700,
            offset: 10
        });

    })

    const date = appValue.date;
    const cleanUrl = appValue.cleanUrl;

    const { oid } = useParams()

    const cookies = new Cookies();
    const user = cookies.get('aplus-user');

    var REACT_APP_API_URL = appValue.apiUrl;
    let url = REACT_APP_API_URL + "/meta/order/update/" + oid

    const orderAPI = {
        urls: [
            {
                name: "order",
                url: REACT_APP_API_URL + "/order/" + oid + "/" + "Meta"
            },
            {
                name: "properties",
                url: REACT_APP_API_URL + "/property/list"
            },
            {
                name: "groups",
                url: REACT_APP_API_URL + "/group/list/small/" + oid + "/group"
            },
            {
                name: "orders",
                url: REACT_APP_API_URL + "/order/list"
            },
            {
                name: "dash",
                url: REACT_APP_API_URL + "/reports/dash/order/" + user.apid + "/" + oid + "?date=" + date
            },
            {
                name: "account",
                url: REACT_APP_API_URL + "/admin/get/account/" + user.apid
            },
            {
                name: "crumbs",
                url: REACT_APP_API_URL + "/util/breadcrumb/orders/" + oid
            },
            {
                name: "clients",
                url: REACT_APP_API_URL + "/client/list"
            },
            {
                name: "campaigns",
                url: REACT_APP_API_URL + "/campaign/list"
            }
        ],
        method: "GET",
    }

    const { NODE_ENV } = process.env;

    const { results, errors, complete } = FetchFromAPI(orderAPI)

    if (complete) {
        if (initialLoad) {
            setInitialLoad(false)
            const data = results['order'].data.meta_adset;
            order.id = data.id;
            order.name = data.name;
            order.status = data.status || data.configured_status;
            order.adlabels = data.adlabels;
            order.bid_amount = data.bid_amount;
            order.bid_strategy = data.bid_strategy;
            order.billing_event = data.billing_event;
            order.campaign_attribution = data.campaign_attribution;
            order.campaign_id = data.campaign_id;
            order.creative_sequence = data.creative_sequence;
            order.daily_budget = data.daily_budget;
            order.daily_spend_cap = data.daily_spend_cap;
            order.daily_min_spend_target = data.daily_min_spend_target;
            order.destination_type = data.destination_type;
            order.dsa_beneficiary = data.dsa_beneficiary;
            order.dsa_payor = data.dsa_payor;
            order.start_time = data.start_time;
            order.end_time = data.end_time;
            order.is_dynamic_creative = data.undefined;
            order.lifetime_budget = data.lifetime_budget;
            order.lifetime_imps = data.lifetime_imps;
            order.lifetime_min_spend_target = data.lifetime_min_spend_target;
            order.lifetime_spend_cap = data.lifetime_spend_cap;
            order.multi_optimization_goal_weight = data.multi_optimization_goal_weight;
            order.optimization_goal = data.optimization_goal;
            order.optimization_sub_event = data.optimization_sub_event;
            order.rf_prediction_id = data.rf_prediction_id;
            order.source_adset_id = data.source_adset_id;
            if (data.targeting) {
                order.targeting = data.targeting;
            }

            if (data.start_time) {
                let startTime = data.start_time.split('T');
                let endTime = data.end_time.split('T');
                order.start_time = startTime[0];
                order.end_time = endTime[0];

            }

            console.log('order', order)
            if (results["clients"]) {
                for (let i = 0; i < results["clients"].data.length; i++) {
                    let c = results["clients"].data[i];
                    if (c.platform === "Meta") {
                        if (c.ad_labels && c.ad_labels.length > 0) {
                            for (let i = 0; i < c.ad_labels.length; i++) {
                                let a = c.ad_labels[i]
                                let ad_label = a.name + " | " + a.id
                                MetaOrderForm.fields[0][2].options.push({ label: ad_label, value: a.id, name: "adlabels" })
                                if (order.adlabels) {
                                    MetaOrderForm.fields[0][2].value = MultiDefault(order.adlabels, MetaOrderForm.fields[0][2].options)
                                }
                            }
                        }
                    }
                }


            }
            if (results["orders"]) {
                for (let i = 0; i < results["orders"].data.length; i++) {
                    let o = results["orders"].data[i];
                    if (o.platform === "Meta") {
                        let o_label = `${o.name} | ${o.platform_id}`;
                        MetaOrderForm.fields[0][24].options.push({ label: o_label, value: o.platform_id });
                        MetaOrderForm.fields[0][24].value = DefaultValue(order.source_adset_id, MetaOrderForm.fields[0][24].options);
                    }
                }
            }
            if (results["campaigns"]) {
                for (let i = 0; i < results["campaigns"].data.length; i++) {
                    let c = results["campaigns"].data[i];
                    if (c.platform === "Meta") {
                        let c_label = `${c.name} | ${c.platform_id}`;
                        MetaOrderForm.fields[0][6].options.push({ label: c_label, value: c.platform_id });
                        MetaOrderForm.fields[0][6].value = DefaultValue(order.campaign_id, MetaOrderForm.fields[0][6].options);
                    }
                }
            }

        }




        if (NODE_ENV === "development") {
            console.log(results)
        }

        return errors.length > 0 ? (<><span>Error: {errors}</span></>) : (
            <><Tab.Container defaultActiveKey="first">
                <Row>
                    <Col lg="12">
                        <Card>
                            <Card.Body>

                                <Crumbs data={results["crumbs"].data}  platform="meta" type="order" />

                                <div className="d-flex flex-wrap align-items-center justify-content-between">
                                    <div className="d-flex flex-wrap align-items-center">
                                        <div className="d-flex flex-wrap align-items-center mb-3 mb-sm-0">
                                            <h4 className="me-2 h4">{results["order"].data.meta_adset.name}</h4>
                                            <span> - Order</span>
                                        </div>
                                    </div>
                                    <Nav as="ul" className="d-flex nav-pills mb-0 text-center profile-tab" data-toggle="slider-tab" id="profile-pills-tab" role="tablist">
                                        <Nav.Item as="li">
                                            <Nav.Link eventKey="first" className="button">Dash</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                            <Nav.Link eventKey="second" className="button">Settings</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                            <Nav.Link eventKey="third" className="button">Groups</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                            <Nav.Link eventKey="fourth" className="button">Automizer</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg="6">
                        <Card>
                            <Card.Header>
                                <div className="header-title">
                                    <h4 className="card-title">Order Info</h4>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <div className="mb-1">ID: {results["order"].data.meta_adset.id}</div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg="12">
                        <Tab.Content className="profile-content">
                            <Tab.Pane eventKey="first" id="dash">
                                <Row className='dateButtons_sub'>
                                    <Col lg={{ span: 6, offset: 6 }}>
                                        <DateButtons refreshUrl={cleanUrl} dateSelect={date} />
                                    </Col>
                                </Row>
                                {(Object.keys(results["dash"].data.kpi).length === 0) ? (
                                    <span>No Data Available </span>
                                ) : (
                                    <Dash data={results["dash"]} data3={results["properties"]} data4={results["account"]} />
                                )}
                            </Tab.Pane >
                            <Tab.Pane eventKey="third" id="group">
                                <Card>
                                    <Card.Header className="d-flex justify-content-between">
                                        <div className="header-title">
                                            <h4 className="card-title">Group List</h4>
                                        </div>
                                        <div>
                                            <ModalButton name="New Group" setModal={setMetaGroupModal} />
                                        </div>
                                    </Card.Header>
                                    <Card.Body className="px-0">
                                        <div className="table-responsive">
                                            <ReactTable item={results["groups"]} col={columnsGroup} page_size={20} row_size={20} />
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Tab.Pane>
                            <Tab.Pane eventKey="fourth" id="automizer">
                                <Automizer data={results["order"]} cur={results["account"].data.Currency} label="line" />
                            </Tab.Pane>
                            <Tab.Pane eventKey="second" id="settings">
                                <Row>
                                    <Col lg="12">
                                        <InputSettings
                                            title="Add New Order"
                                            mode="edit"
                                            form={MetaOrderForm}
                                            url={url}
                                            formId={"orderForm"}
                                            model={[order, setOrder]}

                                        />
                                    </Col>
                                </Row>
                            </Tab.Pane >
                        </Tab.Content>
                    </Col>

                </Row>
            </Tab.Container>
                <GroupMetaModal group={metaGroup} setGroup={setMetaGroup} data={MetaGroupForm} modal={metaGroupModal} setModal={setMetaGroupModal} baseUrl={REACT_APP_API_URL} />
            </>
        );
    }
    return <><div><span><Loader></Loader></span></div></>;
}

export default (MetaOrder)