import { BuyingType, BidStrategy, Objective, SpecialAdCategory, CountryOptsCampaign, Status, CustomEventType } from "./meta-options"

const meta_campaign = [
    {
        label: 'Name',
        input: 'input-text',
        name: 'name',
        size: '12',
        required: true,
        feedback: 'Campaign name is required'
    },
    {
        label: 'Buying Type',
        input: 'dropdown',
        name: 'buying_type',
        options: BuyingType,
        size: '6',
        required: true
    },
    {
        label: 'Bid Strategy',
        input: 'dropdown',
        name: 'bid_strategy',
        options: BidStrategy,
        size: '6'
    },
    {
        label: 'Objective',
        input: 'dropdown',
        name: 'objective',
        options: Objective,
        size: '6',
        required: true,
        feedback: 'Objective is required'
    },
    {
        label: 'Status',
        input: 'dropdown',
        name: 'status',
        options: Status,
        size: '6'
    },
    {
        label: 'Enable SKAdNetwork',
        input: 'toggle',
        name: 'is_skadnetwork_atttribution',
        size: '6'
    },
    {
        label: 'Is Using 13 Schedule',
        input: 'toggle',
        name: 'is_using_13_schedule',
        size: '6'
    },
]

const meta_delivery = [
    {
        label: 'Start Time',
        input: 'date',
        name: 'start_time',
        size: '6'
    },
    {
        label: 'Stop Time',
        input: 'date',
        name: 'stop_time',
        size: '6'
    },
    {
        label: "Budget Type",
        input: "dropdown",
        options: [
            { label: "Lifetime", value: "lifetime" },
            { label: "Daily", value: "daily" },
        ],
        name: "budget_type",
        size: "4",
        dependent: 'buying_type',
        condition: 'AUCTION',
    },    
    {
        label: 'Daily Budget',
        input: 'input-number',
        name: 'daily_budget',
        size: '4',
        dependent: 'budget_type',
        condition: "daily",
        icon: "$",
    },
    {
        label: 'Lifetime Budget',
        input: 'input-number',
        name: 'lifetime_budget',
        size: '4',
        dependent: 'budget_type',
        condition: "lifetime",
        icon: "$",
    },
    {
        label: 'Spend Cap',
        input: 'input-number',
        name: 'spend_cap',
        size: '8',
        icon: "$",
    },
   
]


const meta_other = [
    {
        label: 'Special Ad Categories',
        input: 'dropdown',
        name: 'special_ad_categories',
        options: SpecialAdCategory,
        size: '6',
        multi: true
    },
    {
        label: 'Special Ad Category Country',
        input: 'dropdown',
        name: 'special_ad_category_country',
        options: CountryOptsCampaign,
        size: '6',
        required: true,
        feedback: 'Special ad category country is required',
        multi: true

    },

    {
        label: 'Source Campaign ID',
        input: 'dropdown',
        name: 'source_campaign_id',
        size: '6',
        options: [
            { label: '', value: '' }
        ]
    },

]

const promoted_object = [
    {
        label: "Choose What To Promote",
        input: "dropdown",
        options: [
            { label: "Mobile App", value: "app" },
            { label: "Instagram Profile", value: "insta" },
            { label: "Facebook Page", value: "page" },
            { label: "Facebook Event", value: "event" },
            { label: "Online Store", value: "store" },
            { label: "Online Offer", value: "offer" },

        ],
        name: "promotion_type",
        size: "12",
    },
    {
        label: 'Application ID',
        input: 'input-text',
        name: 'promoted_object.application_id',
        size: '9',
        dependent: 'promotion_type',
        condition: 'app',
    },
    {
        label: 'Event ID',
        input: 'input-text',
        name: 'promoted_object.event_id',
        size: '9',
        dependent: 'promotion_type',
        condition: 'event',
    },
    {
        label: 'Instagram Profile ID',
        input: 'input-text',
        name: 'promoted_object.instagram_profile_id',
        size: '9',
        dependent: 'promotion_type',
        condition: 'insta',
    },
    {
        label: 'Offer ID',
        input: 'input-text',
        name: 'promoted_object.offer_id',
        size: '9',
        dependent: 'promotion_type',
        condition: 'offer',
    },
    {
        label: 'Page ID',
        input: 'dropdown',
        name: 'promoted_object.page_id',
        size: '9',
        dependent: 'promotion_type',
        condition: 'page',
        options: []
    },
    {
        label: 'Object Store URL',
        input: 'input-text',
        name: 'promoted_object.object_store_url',
        size: '4',
        dependent: 'promotion_type',
        condition: 'store',
    },
    {
        label: 'Product Catalog ID',
        input: 'input-text',
        name: 'promoted_object.product_catalog_id',
        size: '4',
        dependent: 'promotion_type',
        condition: 'store',
    },
    {
        label: 'Product Set ID',
        input: 'input-text',
        name: 'promoted_object.product_set_id',
        size: '4',
        dependent: 'promotion_type',
        condition: 'store',
    },
    {
        label: 'Conversion Goal ID',
        input: 'input-text',
        name: 'promoted_object.conversion_goal_id',
        size: '4'
    },
    {
        label: 'Custom Event Type',
        input: 'dropdown',
        name: 'promoted_object.custom_event_type',
        size: '4',
        options: CustomEventType
    },
    {
        label: 'Custom Conversion ID',
        input: 'input-text',
        name: 'promoted_object.custom_conversion_id',
        size: '4',
    },

    {
        label: 'Offline Conversion Data Set ID',
        input: 'input-text',
        name: 'promoted_object.offline_conversion_data_set_id',
        size: '6'
    },
    {
        label: 'Offline Conversion Event ID',
        input: 'input-text',
        name: 'promoted_object.offline_conversion_event_id',
        size: '6'
    },
    {
        label: 'Pixel Rule',
        input: 'input-text',
        name: 'promoted_object.pixel_rule',
        size: 4
    },
    {
        label: 'Pixel Aggregation Rule',
        input: 'input-text',
        name: 'promoted_object.pixel_aggregation_rule',
        size: 4
    },
    {
        label: 'Pixel ID',
        input: 'input-text',
        name: 'promoted_object.pixel_id',
        size: 4
    },

]

const MetaCampaignForm = {
    titles: ["General Info", "Delivery","Other Settings","Promoted Object", "Budget Schedule Specs"],
    fields: [
        meta_campaign,
        meta_delivery,
        meta_other,
        promoted_object
    ]
}

export { MetaCampaignForm }
