import { CountryOptsOrderInputBrief } from "./meta-options"

const general_input_brief = [
    {
        label: "Input Brief Name",
        input: 'input-text',
        name: 'name',
        required: true,
        size: 4
    },
    {
        label: "Stage",
        input: 'dropdown',
        name: 'stage',
        required: true,
        size: 4,
        options: [
            { label: 'Draft', value: 'draft', name: 'stage' },
            { label: 'Planning', value: 'planning', name: 'stage' },
            { label: 'Review', value: 'review', name: 'stage' },
            { label: 'Ready', value: 'ready', name: 'stage' }
        ]
    },
    {
        label: "Client",
        input: 'dropdown',
        name: 'client',
        required: true,
        size: 4,
        options: []
    },
]

const meta_campaign_brief = [
    {
        label: "Client Campaign Name",
        input: 'input-text',
        name: 'campaign.client_campaign_name',
        size: 4
    },
    {
        label: "Status",
        input: 'dropdown',
        name: 'campaign.status',
        size: 4,
        options: [
            { label: 'Live', value: 'live', name: 'campaign.status' },
            { label: 'Partially Live', value: 'partially_live', name: 'campaign.status' }
        ]
    },
    {
        label: "Objective",
        input: 'dropdown',
        name: 'campaign.objective',
        size: 4,
        options: [
            { label: 'Site Traffic', value: 'OUTCOME_TRAFFIC', name: 'campaign.objective' },
            { label: 'Awareness', value: 'OUTCOME_AWARENESS', name: 'campaign.objective' },
            { label: 'Lead', value: 'OUTCOME_LEADS', name: 'campaign.objective' },
            { label: 'Engagement', value: 'OUTCOME_ENGAGEMENT', name: 'campaign.objective' },
            { label: 'App promo', value: 'OUTCOME_APP_PROMOTION', name: 'campaign.objective' },
        ]
    },
    {
        label: "Funnel",
        input: 'dropdown',
        name: 'campaign.funnel',
        size: 4,
        options: [
            { label: 'Bottom', value: 'bottom', name: 'campaign.funnel' },
            { label: 'Mid', value: 'mid', name: 'campaign.funnel' },
            { label: 'Top', value: 'top', name: 'campaign.funnel' }
        ]
    },
    {
        label: "KPI",
        input: 'dropdown',
        name: 'campaign.kpi',
        size: 4,
        options: [
            { label: 'CPM', value: 'cpm', name: 'campaign.kpi' },
            { label: 'CPC', value: 'cpc', name: 'campaign.kpi' },
            { label: 'CPV', value: 'cpv', name: 'campaign.kpi' },
            { label: 'CPE', value: 'cpe', name: 'campaign.kpi' },
            { label: 'CPLPV', value: 'cplpv', name: 'campaign.kpi' },
            { label: 'CPA', value: 'cpa', name: 'campaign.kpi' },
            { label: 'ROAS', value: 'roas', name: 'campaign.kpi' }
        ]
    },
    {
        label: 'Budget Type',
        input: 'dropdown',
        name: 'campaign.budget_type',
        size: 4,
        options: [
            { label: 'Daily', value: 'daily', name: 'campaign.budget_type' },
            { label: 'Lifetime', value: 'lifetime', name: 'campaign.budget_type' }
        ]
    },
    {
        label: 'Budget Amount',
        input: 'input-number',
        name: 'campaign.budget_amount',
        size: 4
    }
]

const meta_order_brief = [
    {
        label: 'Name',
        input: 'input-text',
        name: 'order.client_order_name',
        size: 4
    },
    {
        label: 'Status',
        input: 'dropdown',
        name: 'order.status',
        size: 4,
        options: [
            { label: 'Live', value: 'live', name: 'campaign.status' },
            { label: 'Partially Live', value: 'partially_live', name: 'campaign.status' }
        ]
    },
    {
        label: 'Placement',
        input: 'input-text',
        name: 'order.placement',
        size: 4
    },
    {
        label: 'Targeting Strategy',
        input: 'dropdown',
        name: 'order.targeting_strategy',
        size: 4,
        options: [
            { label: 'LAL', value: 'lal', name: 'order.targeting_strategy' },
            { label: 'RTG', value: 'rtg', name: 'order.targeting_strategy' },
            { label: 'INT', value: 'int', name: 'order.targeting_strategy' },
            { label: 'DEMO', value: 'demo', name: 'order.targeting_strategy' },
        ]
    },
    {
        label: 'Targeting Tactic',
        input: 'input-text',
        name: 'order.targeting_tactic',
        size: 4
    },
    {
        label: 'Data Source',
        input: 'dropdown',
        name: 'order.data_source',
        size: 4,
        options: [
            { label: '1P', value: '1P', name: 'order.data_source' },
            { label: '2P', value: '2P', name: 'order.data_source' },
            { label: '3P', value: '3P', name: 'order.data_source' },
        ]
    },
    {
        label: 'Dynamic Creative',
        input: 'toggle',
        name: 'order.dynamic_creative',
        size: 4
    },
    {
        label: 'Optimization Goal',
        input: 'input-text',
        name: 'order.optimization_goal',
        size: 4
    },
    {
        label: 'Start Time',
        input: 'input-date',
        name: 'order.start_time',
        size: 4
    },
    {
        label: 'End Time',
        input: 'input-date',
        name: 'order.end_time',
        size: 4
    },
    {
        label: 'Saved Audience',
        input: 'input-text',
        name: 'order.saved_audience',
        size: 4
    },
    {
        label: 'Targeting Countries',
        input: 'dropdown',
        name: 'order.targeting_countries',
        size: 4,
        options: CountryOptsOrderInputBrief,
        multi: true
    },
    {
        label: 'Age Min',
        input: 'input-number',
        name: 'order.age_min',
        size: 4
    },
    {
        label: 'Age Max',
        input: 'input-number',
        name: 'order.age_max',
        size: 4
    },
    {
        label: 'Publisher Platforms',
        input: 'dropdown',
        name: 'order.publisher_platforms',
        size: 4,
        options: [
            { label: '', value: '' },
            { label: 'Facebook', value: 'facebook', name: 'order.publisher_platforms' },
            { label: 'Instagram', value: 'instagram', name: 'order.publisher_platforms' },
            { label: 'Messenger', value: 'messenger', name: 'order.publisher_platforms' },
            { label: 'Audience Network', value: 'audience_network', name: 'order.publisher_platforms' }
        ],
        multi: true
    },
    {
        label: 'Facebook Positions',
        input: 'dropdown',
        name: 'order.facebook_positions',
        size: 4,
        options: [
            { label: '', value: '' },
            { label: 'Feed', value: 'feed', name: 'order.facebook_positions' },
            { label: 'Right Hand Column', value: 'right_hand_column', name: 'order.facebook_positions' },
            { label: 'Marketplace', value: 'marketplace', name: 'order.facebook_positions' },
            { label: 'Video Feeds', value: 'video_feeds', name: 'order.facebook_positions' },
            { label: 'Story', value: 'story', name: 'order.facebook_positions' },
            { label: 'Search', value: 'search', name: 'order.facebook_positions' },
            { label: 'Instream Video', value: 'instream_video', name: 'order.facebook_positions' },
            { label: 'Facebook Reels', value: 'facebook_reels', name: 'order.facebook_positions' }
        ],
        multi: true
    },
    {
        label: 'Instagram Positions',
        input: 'dropdown',
        name: 'order.instagram_positions',
        size: 4,
        options: [
            { label: '', value: '' },
            { label: 'Stream', value: 'stream', name: 'order.instagram_positions' },
            { label: 'Story', value: 'story', name: 'order.instagram_positions' },
            { label: 'Explore', value: 'explore', name: 'order.instagram_positions' },
            { label: 'Explore Home', value: 'explore_home', name: 'order.instagram_positions' },
            { label: 'Reels', value: 'reels', name: 'order.instagram_positions' },
            { label: 'Profile Feed', value: 'profile_feed', name: 'order.instagram_positions' },
            { label: 'IG Search', value: 'ig_search', name: 'order.instagram_positions' }
        ],
        multi: true
    },
    {
        label: 'Messenger Positions',
        input: 'dropdown',
        name: 'order.messenger_positions',
        size: 4,
        options: [
            { label: '', value: '' },
            { label: 'Messenger Home', value: 'messenger_home', name: 'order.messenger_positions' },
            { label: 'Sponsored Messages', value: 'sponsored_messages', name: 'order.messenger_positions' },
            { label: 'Story', value: 'story', name: 'order.messenger_positions' }
        ],
        multi: true
    }
]

const meta_group_brief = [
    {
        label: 'Name',
        input: 'input-text',
        name: 'group.name',
        size: 4
    },
    {
        label: 'Status',
        input: 'dropdown',
        name: 'group.status',
        size: 4,
        options: [
            { label: 'Live', value: 'live', name: 'group.status' },
            { label: 'Partially Live', value: 'partially_live', name: 'group.status' }
        ]
    },
    {
        label: 'Creative Type',
        input: 'dropdown',
        name: 'group.creative_type',
        size: 4,
        options: [
            { label: 'Video', value: 'video', name: 'group.creative_type' },
            { label: 'Single Image', value: 'single_image', name: 'group.creative_type' },
            { label: 'GIF', value: 'gif', name: 'group.creative_type' }
        ]
    },
    {
        label: 'Creative Name',
        input: 'input-text',
        name: 'group.creative_name',
        size: 4,
    },
    {
        label: 'Post Type',
        input: 'dropdown',
        name: 'group.post_type',
        size: 4,
        options: [
            { label: 'Img', value: 'img', name: 'group.post_type' },
            { label: 'Vid', value: 'vid', vidname: 'group.post_type' },
            { label: 'Car', value: 'car', name: 'group.post_type' },
            { label: 'Fil', value: 'fil', name: 'group.post_type' },
            { label: 'Lns', value: 'lns', name: 'group.post_type' }
        ]
    },
    {
        label: 'Primary/Intro Text',
        input: 'input-text',
        name: 'group.primary_intro_text',
        size: 4
    },
    {
        label: 'Headline',
        input: 'input-text',
        name: 'group.headline',
        size: 4
    },
    {
        label: 'Description Text',
        input: 'input-text',
        name: 'group.description_text',
        size: 4
    },
    {
        label: 'CTA',
        input: 'dropdown',
        name: 'group.cta',
        size: 4,
        options: [
            { label: 'Learn more', value: 'learn_more', name: 'group.cta' },
            { label: 'Order now', value: 'order_now', name: 'group.cta' },
            { label: 'Download', value: 'download', name: 'group.cta' },
            { label: 'See menu', value: 'see_menu', name: 'group.cta' },
            { label: 'Get access', value: 'get_access', name: 'group.cta' },
            { label: 'Request time', value: 'request_time', name: 'group.cta' },
            { label: 'Get updates', value: 'get_updates', name: 'group.cta' },
            { label: 'Shop Now', value: 'shop_now', name: 'group.cta' },
            { label: 'Sign up', value: 'sign_up', name: 'group.cta' },
            { label: 'Subscribe', value: 'subscribe', name: 'group.cta' },
            { label: 'Watch more', value: 'watch_more', name: 'group.cta' },
            { label: 'Get promotions', value: 'get_promotions', name: 'group.cta' },
            { label: 'Apply now', value: 'apply_now', name: 'group.cta' },
            { label: 'Book now', value: 'book_now', name: 'group.cta' },
            { label: 'Contact us', value: 'contact_us', name: 'group.cta' },
            { label: 'Donate now', value: 'donate_now', name: 'group.cta' },
            { label: 'Get offer', value: 'get_offer', name: 'group.cta' },
            { label: 'Get quote', value: 'get_quote', name: 'group.cta' }
        ]
    },
    {
        label: 'Landing Page URL',
        input: 'input-text',
        name: 'group.landing_page_url',
        size: 4
    },
    {
        label: 'Display Link',
        input: 'input-text',
        name: 'group.display_link',
        size: 4
    },
    {
        label: 'URL Params',
        input: 'input-text',
        name: 'group.url_params',
        size: 4
    }
]

const MetaInputBriefForm = {
    titles: ["General", "Campaign", "Order", "Group"],
    fields: [
        general_input_brief,
        meta_campaign_brief,
        meta_order_brief,
        meta_group_brief
    ]
}

export { MetaInputBriefForm }