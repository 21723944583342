import axios from "axios"
import { BuildAPIHeader } from "../components/common"
import { useAppContext } from "../context/appContext"

/* For Search
    - Ad Group Type | Dynamic - Standard
        -A dynamic ad group contains only Dynamic Search Ads (DSA) and a set of related dynamic ad targets. DSA automatically brings the most relevant traffic to your defined set of webpages. To create your own keywords, text ads, or responsive search ads, create a standard ad group instead.

    - Name
    - Default Bid <- Manual CPC 
    - Keywords (comma separated List) 
        - Keyword will be broad matched 
        - "Keyword" will be phrase matched
        - [Keyword] will be exact match            
*/
const group_type = [
    { label: 'Standard', value: 'Standard' },
    { label: 'Dynamic', value: 'Dynamic' },
]
const general_info = [
    {
        label: "Group Name",
        name: "name",
        input: 'text'

    },
    {
        label: "Status",
        input: 'boolean',
        name: "status",
        options: [
            { label: '', value: '' },
            {
                label: 'Active',
                value: 'Enabled'
            },
            {
                label: 'Paused',
                value: 'Paused'
            }
        ]
    },

    {
        label: 'Customer ID',
        input: 'async-default',
        url: "/client/list/search/",
        id: "clientId",
        name: "name",
        options: [
            { label: '', value: '' }
        ]
    },
    {
        label: 'Campaign ID',
        input: 'async-default',
        url: "/campaign/list/search/",
        id: "campaign_id",
        name: "name",
        options: [
            { label: '', value: '' }
        ]
    },
    {
        label: 'Type',
        input: 'dropdown',
        name: "type",
        options: group_type
    }
]


const default_cpc = [
    {
        label: 'Default Max CPC',
        input: 'inputgroup',
        name: 'default_bid',
    },
]
const ad_rotation = [
    {
        label: 'Optimize Ad Rotation',
        input: 'dropdown',
        name: 'ad_rotation',
        options: [
            { label: '', value: '' },
            {
                label: 'Active',
                value: 'Optimize'
            },
            {
                label: 'Disabled',
                value: 'Do not optimize'
            }
        ]
    },
]


//     - Automated Bid Strategies | There are Some that are Portfolio Strategies (user Made) | Others are support a google standard
//     - Target CPA
//     - Target ROAS - Before opting into target ROAS, wait until the account that set up conversion tracking has received at least 15 conversions in the last 30 days.
//     - Google Ads Maximize Clicks | Standard -> Set Max CPC bid limit
//     - Google Ads Maximize Conversions -> Set Target CPA
//     - Google Ads Maximize Conversion Value -> Set Target ROAS
//     - Target Impression Share - Where do you want your ads to appear, Percent impression share to target, maximum CPC limit,
// - Manual Bid Strategies
//     - Manual CPC
//         - Enhanced CPC
//             - Optimize for Conversions
//             - Optimize for conversion value
//TODO: Add list of user generated bid strategies to this, shoudl be pulled from backend data

const SearchGroupForm = {
    titles: ["General", "CPC", "Ad Rotation"],
    fields: [
        general_info,
        default_cpc,
        ad_rotation
    ]
}


const GlobalSetInitialValues = (obj, payload) => {
    Object.keys(payload).forEach(function (key) {
        obj[key] = payload[key];
    })
    return obj
}

const GlobalChangeEvent = (name, e, obj, setInitialLoad, setObj) => {
    setInitialLoad(false)
    var value;
    //Set Value
    //Determine If Has Target
    // console.log(e)    
    if (e.target) {
        //Determine If Checkbox
        if (e.target.type == "checkbox") {
            value = e.target.checked
        } else {
            value = e.target.value
        }
    } else if (e.length >= 1) {
        value = e
    } else {
        value = e.value
    }
    //Check if nested name      
    if (name.indexOf('.') !== -1) {
        let names = name.split(".")
        obj[names[0]][names[1]] = value
    } else {
        obj[name] = value
    }
    setObj({ ...obj });
    // console.log(obj)

};

const GlobalSubmitEvent = (e, obj, setObj, url) => {
    const appValue = useAppContext();
    // if(!validateCampaign(campaign,setCampaign)) {
    //     return
    // }       
    const { config } = appValue.config

    // let url = REACT_APP_API_URL + "/campaign/update/" + cid
    e.preventDefault();
    // Add API call here
    let resp = axios.patch(url, obj, config)
        .then((res) => {
            if (res.data) {
                switch (res.data.message) {
                    case "Failure":
                        return { err: res.data.error, result: false }
                    case "Success":
                        return { err: "", result: true }
                    default:
                        return { err: "Unknown Error", result: false }
                }
            }
        })
        .catch(function (error) {
            if (error.response) {
                let data = error.response.data
                return { err: data, result: false }
            } else {
                return { err: error, result: false }

            }
        })

    setObj({ ...obj });
    return resp
};

export { general_info, default_cpc, ad_rotation, SearchGroupForm, GlobalChangeEvent, GlobalSubmitEvent, GlobalSetInitialValues }