import { React, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import Card from '../../../components/Card'
import { FetchFromAPI} from '../../../components/ajx'
import { columnsClient } from '../../../components/gridColumns'
import ReactTable from '../../../components/table'
import { ClientForm } from '../../../util/client-input'
import { ModalButton } from '../../../components/ModalSettings'
import { ClientModel,MetaClientModel } from '../../../components/Models'
import { ClientMetaModal, ClientModal } from '../../../components/Modals'
import { useAppContext } from '../../../context/appContext'
import { MetaClientForm } from '../../../util/meta-client-input'


export default function ClientList() {
   const appValue = useAppContext();
   const [modal, setModal] = useState(false);
   // const [metaModal, setMetaModal] = useState(false);
   const [client, setClient] = useState(ClientModel);
   // const [metaClient, setMetaClient] = useState(MetaClientModel);

   var REACT_APP_API_URL = appValue.apiUrl;

   const { NODE_ENV } = process.env;

   const clientAPI = {
      urls: [
         {
            name: "clients",
            url: REACT_APP_API_URL + "/client/list"
         },
      ],
      method: "GET",

   }
   
   const {results,errors,complete} = FetchFromAPI(clientAPI)

   // const options = [
   //    { name: "DV360", setModal: setModal },
   //    { name: "Meta", setModal: setMetaModal }
   // ]
   if (complete) {
      if (NODE_ENV == 'development') {
         console.log(results);
      }
      return  errors.length > 0  ? (<><span>Error: {errors}</span></>) : (
         <>
            <div>
               <Row>
                  <Col sm="12">
                     <Card>
                        <Card.Header className="d-flex justify-content-between">
                           <div className="header-title">
                              <h4 className="card-title">Client List</h4>
                           </div>
                           <div>
                              <ModalButton name="New Client" setModal={setModal}  />
                           </div>
                        </Card.Header>
                        <Card.Body className="px-0">
                           <div className="table-responsive">
                              <ReactTable item={results["clients"]} col={columnsClient} page_size={20} row_size={20} />
                           </div>
                        </Card.Body>
                     </Card>
                  </Col>
               </Row>
            </div>

           
            <ClientModal client={client} setClient={setClient} form={ClientForm} formId="clientForm" modal={modal} setModal={setModal} baseUrl={REACT_APP_API_URL} />
            {/* <ClientMetaModal client={metaClient} setClient={setMetaClient} form={MetaClientForm} formId="metaClientForm" modal={metaModal} setModal={setMetaModal} baseUrl={REACT_APP_API_URL} /> */}

         </>
      )
   }
   return <><div><span></span></div></>;

}
