import { Button, Dropdown } from "react-bootstrap"

const ModalButton = (params) => {
   let length = 0
   if (params.options) {
      length = params.options.length || 0
   }

   return (
      (length >= 1) ? (<>
      <Dropdown>
         <Dropdown.Toggle variant="dark">
         <i className="btn-inner">
               <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
               </svg>
            </i>
            <span>{params.name}</span>
         </Dropdown.Toggle>
         <Dropdown.Menu>
         {params.options.map((element) => {
            return(<Dropdown.Item onClick={() => element.setModal(true)}>{element.name}</Dropdown.Item>)
         })}
         </Dropdown.Menu>
      </Dropdown>
      </>) : (<>
         <Button className="text-center btn-dark btn-icon mt-lg-0 mt-md-0 mt-3" variant="dark" onClick={() => params.setModal(true)}>
            <i className="btn-inner">
               <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
               </svg>
            </i>
            <span>{params.name}</span>
         </Button>
      </>)
   )
}

export { ModalButton }