import { React, useEffect, useState } from 'react'
import { Row, Col, Tab, Nav } from 'react-bootstrap'
import Card from '../../../components/Card'
import Loader from '../../../components/Loader'
import { CurrencySymbol, GlobalSetInitialValues, GlobalSubmitEvent } from '../../../components/common'
import { useParams } from 'react-router-dom'
import { FetchFromAPI } from '../../../components/ajx'
import { DefaultValue } from '../../../components/selectDefaults'
import { columnsKeywords } from '../../../components/gridColumns'
// AOS
import AOS from 'aos'
import '../../../../node_modules/aos/dist/aos'
import '../../../../node_modules/aos/dist/aos.css'

// Import Swiper styles
import { StatusSearchDef } from '../../../components/selectDefaults'
import { GroupSearchModel } from '../../../components/Models'
import { Combolist } from '../../../components/combolist'
import InputSettings from '../../../components/InputSettings'
import { useAppContext } from '../../../context/appContext'


const GroupSearch = (props) => {
    const appValue = useAppContext();
    const [modal, setModal] = useState(false);
    const [group, setGroup] = useState(GroupSearchModel)
    const [keywordList, setKeywordList] = useState([])
    const [initialLoad, setInitialLoad] = useState(true)

    useEffect(() => {

        AOS.init({
            startEvent: 'DOMContentLoaded',
            disable: function () {
                var maxWidth = 996;
                return window.innerWidth < maxWidth;
            },
            throttleDelay: 10,
            once: true,
            duration: 700,
            offset: 10
        });

    })

    const { gid } = useParams()
    var REACT_APP_API_URL = appValue.apiUrl;


    const url = REACT_APP_API_URL + "/group/update/" + gid

    const { NODE_ENV } = process.env;

    const groupAPI = {
        urls: [
            {
                name: "account",
                url: REACT_APP_API_URL + "/admin/get/account/" + appValue.user.apid
            },

        ],
        method: "GET",

    }
    const { results, errors, complete } = FetchFromAPI(groupAPI)

    if (complete) {
        if (NODE_ENV == 'development') {
            console.log(results)
        }
        if (initialLoad) {
            if (results["group"]) {
                GlobalSetInitialValues(group, results["group"])
            }
        }
        // let activeGroups = CountEntities(results["groups"].data)
        const type_options = [
            { label: 'Standard', value: 'Standard' },
            { label: 'Dynamic', value: 'Dynamic' },
        ]

        //label: The UI Label shown to user
        //value: The value of the element
        //name: the name of the json field to update in the relevant model
        //input: type of component
        //options: the options for the dropdown or combobox
        //required: whether the element is required
        //multi: whether the dropdown or combo is multselect 
        //feedback: this is the error message to show if it fails validation
        //icon: for Input groups
        //size: width of the element bootstrap width

        const general_info = [
            {
                label: "Group Name",
                value: group.name,
                name: "name",
                input: 'input-text',
                size: '6',
                required: true,
                feedback: "You must provide a name for this group"
            },
            {
                label: "Status",
                input: 'dropdown',
                name: "status",
                value: StatusSearchDef(group.status),
                options: [
                    { label: '', value: '' },
                    {
                        label: 'Active',
                        value: 'Enabled'
                    },
                    {
                        label: 'Paused',
                        value: 'Paused'
                    }
                ],
                required: true,
                multi: false,
                size: '3'
            },
            {
                label: 'Ad Group Type',
                input: 'dropdown',
                name: "type",
                value: DefaultValue(group.type, type_options),
                options: type_options,
                size: '3',
                required: true,
            },
        ]


        const default_bid = [
            {
                label: 'Default Bid',
                input: 'input-number',
                value: group.default_bid,
                name: 'default_bid',
                size: "3",
                required: true,
                feedback: "Please enter a valid bid value eg. 1.05 or 1",
                icon: <CurrencySymbol cur={results["account"].data.currency} />
            }
        ]

        const form = {
            titles: ["General", "Bidding"],
            fields: [
                general_info,
                default_bid,
            ]
        }




        // const targeting = [
        //     {
        //         label: "Language",
        //         input: "excludeselect",
        //         name: "language.language",
        //         value: campaign.language.language,
        //         valueCheck: campaign.language.excluded,
        //         nameCheck: "language.excluded",
        //         negative: false,
        //         options: Language
        //     }
        //     //TODO: Add Geo Targeting. Repurpose existing logic and call ASYNC. Build ASYNC for Settings.
        // ]


        /* Flow for non Search
        - Select Campaign Objective -> Vanity Not needed
        - Select Campaign Type - Options [Search, Performance Max, Display, Shopping, Video, App, Smart, Local, Demand Gen]
            - Demand Gen (Display, InFeed Video Ads)
            - App (Display, Search, (YT In Feed Ads), Discover)
        - Campaign Name
        - Campaign Goal
            - Conversion -> Will retuied a conversion goal setup -< Target Cost Per Action will be set.
            - Clicks
        - Budget and Dates
        - Setting Location and Language is recommended at the Ad Group level. Setting at campaign level cannot be changed and eraes any ad group level location and language settings.
        - Device Targeting
            - Devices: Computer, Mobile Phones, Tablets, TV Screens
            (FOR MOBILE AND TABLET ONLY)
            - Operating Systems
            - Device Models
            - Mobile Networks
        - Ad Schedule
        - Campaign URL options, Tracking Template, Final URL suffix, Custom Parameters.
        */

        /* Flow for Search
        - Select Campaign Objective -> Vanity Not needed
        - Select Campaign Type - Options [Search, Performance Max, Display, Shopping, Video, App, Smart, Local, Demand Gen]
        - Campaign Name
        - Networks (Search, Search Partners, Display, Display Network)
        - Start and End dates
        - Campaign URL Options
        - Dynamic Search Ads Settings (In uploads?) 
        - Ad Schedule
        - Locations
        - Languages
        - Audience Segments (access?)
            - Targeting settings for this campaign (Targeting, Observation)
        - Budget and Dates        
        - Bidding
            - Strategies
            - Automated Bid Strategies | There are Some that are Portfolio Strategies (user Made) | Others are support a google standard
                - Target CPA
                - Target ROAS - Before opting into target ROAS, wait until the account that set up conversion tracking has received at least 15 conversions in the last 30 days.
                - Google Ads Maximize Clicks | Standard -> Set Max CPC bid limit
                - Google Ads Maximize Conversions -> Set Target CPA
                - Google Ads Maximize Conversion Value -> Set Target ROAS
                - Target Impression Share - Where do you want your ads to appear, Percent impression share to target, maximum CPC limit,
            - Manual Bid Strategies
                - Manual CPC
                    - Enhanced CPC
                        - Optimize for Conversions
                        - Optimize for conversion value
        - Ad Rotation 
            - Optimize Prefer Best performing ads
            - Do Not Optimize: Rotate Ads indefinitely
        */

        /* For Search
            - Ad Group Type | Dynamic - Standard
                -A dynamic ad group contains only Dynamic Search Ads (DSA) and a set of related dynamic ad targets. DSA automatically brings the most relevant traffic to your defined set of webpages. To create your own keywords, text ads, or responsive search ads, create a standard ad group instead.

            - Name
            - Default Bid <- Manual CPC 
            - Keywords (comma separated List) 
                - Keyword will be broad matched 
                - "Keyword" will be phrase matched
                - [Keyword] will be exact match            
        */

        /* For Search
            - Final URL
            - display path
            - healines (3 required, 15 supported)
            - Descriptions (2 required, 4 supported)
            - Logo, Business Name (Has restriction to access)
            - Sitelinks (Separate entity supports 4 minimum needed to use is 2)
            - Additonal callouts (Promotions, Prices, Phone, Call outs, Structured Snippets, Lead Forms, Apps)
            - Ad Url options

        */


        /* Ad Group
        - Ad Group Name
        - Locations
        - Languages
        - Audience: User created, System provided (Can I fetch this list?),
        - Optimized Targeting? Google expands targeting without input.
        - Ad Group URL options, Tracking Template, Final URL suffix, Custom Parameters.        
        */

        /* Ad 
        - Type - Single Image Ad, Video Ad, Carousel Image Ad
        - Ad Name
        - Media
            - Upload an Image, or linka youtube video (Example of your in-feed video ad on YouTube)
        - Text 
            - Headlines
            - Long Headlines
            - Description
            - Call to Action
            - Business Name
            - Sitelinks ( Separately Uploaded)
            - Url and other options
        */
        return errors.length > 0 ? (<><span>Error: {errors}</span></>) : (
            <>
                <Tab.Container defaultActiveKey="first">
                    <Row>
                        <Col lg="12">
                            <Card>
                                <Card.Body>
                                    {/* <Crumbs data={results["crumbs"].data} type="campaign" /> */}

                                    <div className="d-flex flex-wrap align-items-center justify-content-between">
                                        <div className="d-flex flex-wrap align-items-center">
                                            <div className="d-flex flex-wrap align-items-center mb-3 mb-sm-0">
                                                <h4 className="me-2 h4">{group.name}</h4>
                                                <span> - Group</span>
                                            </div>
                                        </div>
                                        <Nav as="ul" className="d-flex nav-pills mb-0 text-center profile-tab" data-toggle="slider-tab" id="profile-pills-tab" role="tablist">
                                            <Nav.Item as="li">
                                                <Nav.Link eventKey="first" className="button">Dash</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item as="li">
                                                <Nav.Link eventKey="fourth" className="button">Settings</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item as="li">
                                                <Nav.Link eventKey="second" className="button">Ads</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item as="li">
                                                <Nav.Link eventKey="third" className="button">Keywords</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg="6">
                            <Card>
                                <Card.Header>
                                    <div className="header-title">
                                        <h4 className="card-title">Snapshot</h4>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <ul className="list-inline m-0 p-0">
                                        <li className="d-flex mb-2">
                                            <div className="news-icon me-3">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-ubuntu" viewBox="0 0 16 16"><path d="M2.273 9.53a2.273 2.273 0 1 0 0-4.546 2.273 2.273 0 0 0 0 4.547Zm9.467-4.984a2.273 2.273 0 1 0 0-4.546 2.273 2.273 0 0 0 0 4.546ZM7.4 13.108a5.535 5.535 0 0 1-3.775-2.88 3.273 3.273 0 0 1-1.944.24 7.4 7.4 0 0 0 5.328 4.465c.53.113 1.072.169 1.614.166a3.253 3.253 0 0 1-.666-1.9 5.639 5.639 0 0 1-.557-.091Zm3.828 2.285a2.273 2.273 0 1 0 0-4.546 2.273 2.273 0 0 0 0 4.546Zm3.163-3.108a7.436 7.436 0 0 0 .373-8.726 3.276 3.276 0 0 1-1.278 1.498 5.573 5.573 0 0 1-.183 5.535 3.26 3.26 0 0 1 1.088 1.693ZM2.098 3.998a3.28 3.28 0 0 1 1.897.486 5.544 5.544 0 0 1 4.464-2.388c.037-.67.277-1.313.69-1.843a7.472 7.472 0 0 0-7.051 3.745Z"></path></svg>
                                            </div>
                                            {/* <p className="news-detail mb-0">{activeOrders} - Active Orders</p> */}
                                        </li>
                                        <li className="d-flex">
                                            <div className="new-icon me-3">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-collection-fill" viewBox="0 0 16 16"><path d="M0 13a1.5 1.5 0 0 0 1.5 1.5h13A1.5 1.5 0 0 0 16 13V6a1.5 1.5 0 0 0-1.5-1.5h-13A1.5 1.5 0 0 0 0 6v7zM2 3a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 0-1h-11A.5.5 0 0 0 2 3zm2-2a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 0-1h-7A.5.5 0 0 0 4 1z"></path></svg>
                                            </div>
                                            {/* <p className="news-detail mb-0">{activeGroups} - Active Groups</p> */}

                                        </li>
                                    </ul>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg="6">
                            <Card>
                                <Card.Header>
                                    <div className="header-title">
                                        <h4 className="card-title">Group Info</h4>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <div className="mb-1">Group Id: {group.id}</div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg="12">
                            <Tab.Content className="profile-content">
                                <Tab.Pane eventKey="second" id="order">
                                    <Card>
                                        <Card.Header className="d-flex justify-content-between">
                                            <div className="header-title">
                                                <h4 className="card-title">Ads List</h4>
                                            </div>
                                            <div>
                                                {/* <ModalButton name="New Order" setModal={setModal} /> */}
                                            </div>
                                        </Card.Header>
                                        <Card.Body className="px-0">
                                            <div className="table-responsive" style={{ width: `100%` }}>
                                                {/* <ReactTable item={results["orders"]} col={columnsOrder} page_size={20} row_size={20} /> */}
                                            </div>
                                            <div>
                                                {/* <OrderModal order={order} setOrder={setOrder} data={OrderData} modal={modal} setModal={setModal} baseUrl={REACT_APP_API_URL} entityId={results["campaign"].data.dv_campaign_id}/> */}
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Tab.Pane >
                                <Tab.Pane eventKey="third" id="group">
                                    <Row>
                                        <Col lg="12">
                                            <Combolist
                                                url={""}
                                                name="keyword"
                                                label="Keywords"
                                                columns={columnsKeywords}
                                                list={keywordList}
                                                setList={setKeywordList}
                                                api={url}
                                                obj={group}
                                                setObj={setGroup}
                                                submit={GlobalSubmitEvent}
                                            />
                                        </Col>
                                    </Row>
                                </Tab.Pane>
                                <Tab.Pane eventKey="first" id="dash">
                                    <Row className='dateButtons_sub'>
                                        <Col lg={{ span: 6, offset: 6 }}>
                                            {/* <DateButtons refreshUrl={cleanUrl} dateSelect={date} /> */}
                                        </Col>
                                    </Row>
                                    {/* {(Object.keys(results["dash"].data.kpi).length === 0) ? (
                                        <span>No Data Available </span>
                                    ) : (
                                        // <Dash data={results["dash"]} data2={results["targeting"]} data3={results["orders"]} data4={results["account"]} colorP={props.colorprimarymode} colorI={props.cololrinfomode} />
                                    )} */}
                                </Tab.Pane >
                                <Tab.Pane eventKey="fourth" id="settings">
                                    <Row>
                                        <Col lg="12">
                                            {/*
                                            title: Form Title
                                            form: OBJ of all Form fields/sections
                                            url: form url for submission
                                            handleChange: Funciton to handle when fields change
                                            model: the model to make updates to with teh setState as well
                                            onLoad: funciton to call for first loading of data
                                            handleSubmit: function to call when form is submitted <- redundant if all the same function globally...
                                            mode: form mode
                                            */}
                                            <InputSettings
                                                title='General Info'
                                                mode='edit'
                                                form={form}
                                                url={url}
                                                formId="groupsearchform"
                                                model={[group, setGroup]}
                                            />
                                        </Col>
                                    </Row>
                                </Tab.Pane >
                            </Tab.Content>
                        </Col>

                    </Row>
                </Tab.Container>
            </>
        );
    }
    return <><div><span><Loader></Loader></span></div></>;
}
export default (GroupSearch)
