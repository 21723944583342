import { ButtonGroup, ToggleButton } from "react-bootstrap";

//TODO: Convert logic to use states and not need to refresh the page
const DateButtons = (params) => {
    const radios = [
        { name: 'Yesterday', value: '1' },
        { name: 'This Week', value: '2' },
        { name: 'Last Week', value: '3' },
        { name: 'Last 7 Days', value: '4' },
    ];
    return (
        <>
            <ButtonGroup aria-label="dateButtons" className="float-end" >
                {radios.map((radio, idx) => (
                    <ToggleButton
                        key={idx}
                        id={`radio-${idx}`}
                        type="radio"
                        variant={String(params.date) === radio.value ? 'dark' : 'light'}
                        name="radio"
                        value={radio.value}
                        checked={String(params.date) === radio.value}
                        onChange={(e) => {
                            params.setDate(Number(radio.value))
                        }
                        }
                    >
                        {radio.name}
                    </ToggleButton>
                ))}
            </ButtonGroup>
        </>
    );
}
//TODO: Not finished with logic
const PlatformButtons = (params) => {    
    var platformRadios;

    if (params.account.data._id === "6509c54feb76cc552276192c") {
        platformRadios = [
            { name: 'All', value: 'all' },
            { name: 'DV360', value: 'dv' },
            { name: 'The Trade Desk', value: 'ttd' },
            { name: 'Meta', value: 'meta' },
            // { name: 'Search', value: 'search' },
        ]
    }else {
        platformRadios = [
            { name: 'All', value: 'all' },
            { name: 'DV360', value: 'dv' },
            // { name: 'Meta', value: 'meta' },
            // { name: 'The Trade Desk', value: 'ttd' },
            // { name: 'Search', value: 'search' },
        ]
    }
    return (
        <>
            <ButtonGroup aria-label="platformButtons" className="float-start" >
                {platformRadios.map((radio, idx) => (
                    <ToggleButton
                        key={idx}
                        id={`platformRadio-${idx}`}
                        type="radio"
                        variant={params.platform === radio.value ? 'dark' : 'light'}
                        name="platformRadio"
                        value={radio.value}
                        checked={params.platform === radio.value}
                        onChange={(e) => {
                          params.setPlatform(radio.value)                          

                        }
                        }
                    >
                        {radio.name}
                    </ToggleButton>
                ))}
            </ButtonGroup>
        </>
    );
}
export {DateButtons, PlatformButtons }