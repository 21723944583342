import { useEffect, useState } from "react"
import { Alert, Button, Card, Col, Dropdown, DropdownButton, Form, InputGroup, Row, Spinner } from "react-bootstrap"
import Select from 'react-select'
import { GlobalChangeEvent, ValueGetter, GlobalSubmitEvent } from "./common"
import { DefaultValue, MultiDefault } from "./selectDefaults"
import AsyncSelect from 'react-select/async';
import axios from "axios"
import DropdownItem from "react-bootstrap/esm/DropdownItem"
import { Slider } from "@mui/material"
import { useAppContext } from "../context/appContext"
import { InputBriefButton } from "./InputBriefButton"

const InputSettings = ({ title, form, model, url, mode, onLoad, formId, entity, name, parentModel, disabled, inputBriefOpts, setInputBrief }) => {
    const appValue = useAppContext();
    const [edit, setEdit] = useState(false);
    const [validated, setValidated] = useState(false);
    const [subText, setSubText] = useState("Update");
    const [alert, setAlert] = useState({ variant: "", value: "" })
    let obj = model[0]
    let setObj = model[1]

    let load = onLoad
    const loadSpinner = () => {
        setSubText(<Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"></Spinner>)
    }
    const closeSpinner = (err) => {
        if (mode !== "create") {
            setSubText("Update")
        } else {
            setSubText("Submit")
        }

        // setAlert( <>broken</>)
    }

    useEffect(() => {
        if (mode === "create") {
            setEdit(true)
            setSubText("Create")
        }
    }, [])    ///THE COMPONENTS
    //Handles all inputs and input groups that are text based.
    const InputText = ({ values }) => {

        return (
            (values.icon) ? (
                <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">{values.icon}</InputGroup.Text>
                    <Form.Control disabled={!edit} type="text" name={values.name} defaultValue={ValueGetter(obj, values.name, values.index)} onBlur={event => { GlobalChangeEvent(values.name, event, obj, load, setObj, values.index); }} required={values.required} />
                    <Form.Control.Feedback type="invalid">{values.feedback}</Form.Control.Feedback>
                </InputGroup>

            ) : (
                <>
                    <Form.Group controlId="validationCustom05">
                        <Form.Control disabled={!edit} type="text" name={values.name} defaultValue={ValueGetter(obj, values.name, values.index)} onBlur={event => { GlobalChangeEvent(values.name, event, obj, load, setObj, values.index); }} required={values.required} />
                        <Form.Control.Feedback type="invalid">{values.feedback}</Form.Control.Feedback>
                    </Form.Group>
                </>

            )
        )
    };

    //Handles input groups with option selections in the add on icon.
    const InputCombo = ({ values }) => {

        return (
            <InputGroup className="mb-3">
                <DropdownButton title={ValueGetter(obj, values.addOnName)} variant='dark' menuVariant='dark' onSelect={(eventKey, event) => { GlobalChangeEvent(values.addOnName, event, obj, load, setObj, values.index) }}>
                    {(values.options) ? (
                        values.options.map((option) => {
                            return (
                                <DropdownItem>{option}</DropdownItem>
                            )
                        })
                    ) : (<></>)}
                </DropdownButton>
                <Form.Control disabled={!edit} type="text" name={values.name} defaultValue={ValueGetter(obj, values.name, values.index)} onBlur={event => { GlobalChangeEvent(values.name, event, obj, load, setObj, values.index) }} required={values.required} />
                <Form.Control.Feedback type="invalid">{values.feedback}</Form.Control.Feedback>
            </InputGroup>
        )
    };
    //Handles all inputs and input groups that are number based.
    const InputNumber = ({ values }) => {

        return (
            (values.icon) ? (
                <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">{values.icon}</InputGroup.Text>
                    <Form.Control disabled={!edit} type="number" name={values.name} defaultValue={ValueGetter(obj, values.name, values.index)} onBlur={event => { GlobalChangeEvent(values.name, event, obj, load, setObj, values.index) }} required={values.required} />
                    <Form.Control.Feedback type="invalid">{values.feedback}</Form.Control.Feedback>
                </InputGroup>

            ) : (<>
                <Form.Control disabled={!edit} type="number" name={values.name} defaultValue={ValueGetter(obj, values.name, values.index)} onBlur={event => { GlobalChangeEvent(values.name, event, obj, load, setObj, values.index) }} required={values.required} />
                <Form.Control.Feedback type="invalid">{values.feedback}</Form.Control.Feedback>
            </>
            )
        )
    };

    //Handles all date selection inputs
    const DateSelect = ({ values }) => {
        return (<>
            <Form.Control disabled={!edit} type="date" name={values.name} defaultValue={ValueGetter(obj, values.name, values.index)} onChange={event => { GlobalChangeEvent(values.name, event, obj, load, setObj, values.index) }} required={values.required} />
            <Form.Control.Feedback type="invalid">{values.feedback}</Form.Control.Feedback>
        </>
        )
    };

    //Handles Asynchronous combo inputs
    const AsyncCombo = ({ values }) => {
        const [query, setQuery] = useState("");
        var loadOpt = []
        const loadOptions = () => {
            if (query.length >= 4) {
                var REACT_APP_API_URL = appValue.apiUrl;
                let url = REACT_APP_API_URL + values.url + `${query}`
                return new Promise((resolve, reject) => {
                    axios.get(url, appValue.config).then((response) => {
                        if (response.data) {
                            if (values.valueName.includes(".")) {
                                let splstr = values.valueName.split(".")
                                for (let i = 0; i < response.data.data.length; i++) {
                                    loadOpt.push({ value: String(response.data.data[i][splstr[0]][splstr[1]]), label: response.data.data[i][splstr[0]][splstr[1]], name: values.name })
                                }
                            } else {
                                for (let i = 0; i < response.data.data.length; i++) {
                                    loadOpt.push({ value: String(response.data.data[i][values.valueId]), label: response.data.data[i][values.valueName], name: values.name })
                                }
                            }
                            resolve(loadOpt)
                        } else {
                            reject(response.error)
                        }
                    })
                })
            }
        };
        let multiChoice;
        if (values.multi) {
            multiChoice = true
        } else {
            multiChoice = false
        }
        return (<>
            <AsyncSelect
                placeholder={"Enter Text To Search"}
                isMulti={multiChoice}
                name={values.label}
                isDisabled={!edit}
                defaultValue={ValueGetter(obj, values.name, values.index)}
                onChange={(e) => { GlobalChangeEvent(values.name, e, obj, load, setObj, values.index) }}
                loadOptions={loadOptions}
                onInputChange={(value) => setQuery(value)}
                required={values.required}
            />
            <Form.Control.Feedback type="invalid">{values.feedback}</Form.Control.Feedback>
        </>


        )
    }
    const AsyncExclude = ({ values }) => {
        const [query, setQuery] = useState("");
        var loadOpt = []
        const loadOptions = () => {
            if (query.length >= 4) {
                var REACT_APP_API_URL = appValue.apiUrl;
                let url = REACT_APP_API_URL + values.url + `${query}`
                return new Promise((resolve, reject) => {
                    axios.get(url, appValue.config).then((response) => {
                        if (response.data) {
                            if (values.valueName.includes(".")) {
                                let splstr = values.valueName.split(".")
                                for (let i = 0; i < response.data.data.length; i++) {

                                    loadOpt.push({ value: Number(response.data.data[i][values.valueId]), label: response.data.data[i][splstr[0]][splstr[1]], name: values.name })
                                }
                            } else {
                                for (let i = 0; i < response.data.data.length; i++) {
                                    loadOpt.push({ value: Number(response.data.data[i][values.valueId]), label: response.data.data[i][values.valueName], name: values.name })
                                }
                            }
                            resolve(loadOpt)
                        } else {
                            reject(response.error)
                        }
                    })
                })
            }
        };
        let multiChoice;
        if (values.multi) {
            multiChoice = true
        } else {
            multiChoice = false
        }
        return (
            <Row>
                <Col lg="4" sm="12">
                    <Form.Check
                        inline
                        type="switch"
                        id={values.label + "Negative"}
                        label="Exclude"
                        disabled={!edit}
                        defaultChecked={ValueGetter(obj, values.nameCheck, values.index)}
                        defaultValue={ValueGetter(obj, values.nameCheck, values.index)}
                        onChange={event => {
                            GlobalChangeEvent(values.nameCheck, event, obj, load, setObj, values.index)
                        }}
                    />
                </Col>
                <Col lg="8" sm="12">
                    <AsyncSelect
                        isMulti={multiChoice}
                        placeholder={"Enter Text To Search"}
                        name={values.label}
                        isDisabled={!edit}
                        defaultValue={ValueGetter(obj, values.name, values.index)}
                        onChange={(e) => {
                            GlobalChangeEvent(values.name, e, obj, load, setObj, values.index)
                        }}
                        loadOptions={loadOptions}
                        onInputChange={(value) => setQuery(value)}
                        required={values.required}
                    />
                    <Form.Control.Feedback type="invalid">{values.feedback}</Form.Control.Feedback>

                </Col>
            </Row>
        )
    }

    //Handles the Dropdown with Exclude options.
    const ExcludeDropdown = ({ values }) => {
        const { options } = values
        // console.log(multi)
        let multiChoice;
        if (values.multi) {
            multiChoice = true
        } else {
            multiChoice = false
        }
        let defValue = ValueGetter(obj, values.name, values.index)
        return (
            <Row>
                <Col lg="4" sm="12">
                    <Form.Check
                        inline
                        type="switch"
                        id={values.label + "Negative"}
                        label="Exclude"
                        disabled={!edit}
                        defaultChecked={ValueGetter(obj, values.nameCheck, values.index)}
                        defaultValue={ValueGetter(obj, values.nameCheck, values.index)}
                        onChange={event => {
                            GlobalChangeEvent(values.nameCheck, event, obj, load, setObj, values.index)
                        }}
                    />
                </Col>
                <Col lg="8" sm="12">
                    <Select
                        isMulti={multiChoice}
                        name={values.label}
                        isDisabled={!edit}
                        defaultValue={defValue}
                        options={options}
                        onChange={event => {
                            GlobalChangeEvent(values.name, event, obj, load, setObj, values.index)
                        }}
                        required={values.required}
                    />
                    <Form.Control.Feedback type="invalid">{values.feedback}</Form.Control.Feedback>

                </Col>
            </Row>
        )
    }

    //Handles all Dropdown boxes including Multi select dropdowns
    const Dropdown = ({ values }) => {
        const { options } = values
        let opts

        if (values.if) {
            let condition = ValueGetter(obj, values.if.condition)

            if (condition === values.if.value) {
                opts = values.if.options
            } else {
                opts = values.else.options
            }
        } else {
            opts = options;
        }
        let multiChoice;
        if (values.multi) {
            multiChoice = true
        } else {
            multiChoice = false
        }
        let defaultValue = ValueGetter(obj, values.name, values.index)
        let defValue
        if (typeof defaultValue !== 'object') {

            if (multiChoice && defaultValue) {
                defValue = MultiDefault(defaultValue, opts)
            } else {
                defValue = DefaultValue(defaultValue, opts)
            }
        } else {
            defValue = defaultValue
        }
        return (
            <>
                <Select
                    name={values.label}
                    defaultValue={defValue}
                    isDisabled={!edit}
                    options={opts}
                    isMulti={multiChoice}
                    onChange={event => {
                        GlobalChangeEvent(values.name, event, obj, load, setObj, values.index)
                    }
                    }
                    required={values.required} />
                <Form.Control.Feedback type="invalid">{values.feedback}</Form.Control.Feedback>
            </>

        )
    }
    //Handles Radio Inputs
    const Radio = ({ values }) => {
        const { options } = values;
        return (
            <>
                <Form.Group>
                    {(options.map(option => {
                        let val = ValueGetter(obj, values.name, values.index)
                        if (val == option.value) {
                            return (
                                <>
                                    <Form.Check
                                        type="radio"
                                        inline
                                        key={option.label}
                                        disabled={!edit}
                                        label={option.label}
                                        value={option.value}
                                        name={option.name}
                                        onChange={event => {
                                            GlobalChangeEvent(values.name, event, obj, load, setObj, values.index)
                                        }}
                                        required={values.required}
                                        defaultChecked
                                    />
                                    <Form.Control.Feedback type="invalid">{values.feedback}</Form.Control.Feedback>
                                </>

                            )
                        } else {
                            return (
                                <>
                                    <Form.Check
                                        type="radio"
                                        inline
                                        disabled={!edit}
                                        key={option.label}
                                        label={option.label}
                                        value={option.value}
                                        name={option.name}
                                        onChange={event => {
                                            GlobalChangeEvent(values.name, event, obj, load, setObj, values.index)
                                        }}
                                        required={values.required}
                                    />
                                    <Form.Control.Feedback type="invalid">{values.feedback}</Form.Control.Feedback>
                                </>

                            )
                        }
                    }))}
                </Form.Group>
            </>
        )

    }
    const Checkbox = ({ values }) => {
        const { options } = values
        let opts = options;
        let defaultVals = ValueGetter(obj, values.name, values.index)

        return (
            <>
                {(opts.map((option, i) => {
                    let checkState = defaultVals.includes(option.value)
                    return (<div key={option.label}>
                        <Form.Check

                            inline
                            label={option.label}
                            name={values.name}
                            disabled={!edit}
                            type="checkbox"
                            required={values.required}
                            variant="outline-primary"
                            checked={checkState}
                            value={option.value}
                            onChange={(event) => {
                                GlobalChangeEvent(values.name, event, obj, load, setObj, values.index)

                            }}
                        /></div>)
                }))}

                <Form.Control.Feedback type="invalid">{values.feedback}</Form.Control.Feedback>

            </>
        )
    }
    const Toggle = ({ values }) => {

        let defaultVal = ValueGetter(obj, values.name, values.index)
        return (
            <>
                <Form.Check
                    inline
                    label={values.label}
                    name={values.name}
                    type="switch"
                    disabled={!edit}
                    required={values.required}
                    variant="outline-primary"
                    checked={defaultVal}
                    onChange={(event) => { GlobalChangeEvent(values.name, event, obj, load, setObj, values.index) }}
                />
                <Form.Control.Feedback type="invalid">{values.feedback}</Form.Control.Feedback>

            </>
        )
    }


    const TextArea = ({ values }) => {

        return (
            <>
                <Form.Group>
                    <Form.Control
                        as="textarea"
                        row={10}
                        label={values.label}
                        defaultValue={ValueGetter(obj, values.name, values.index)}
                        onChange={event => {
                            GlobalChangeEvent(values.name, event, obj, load, setObj, values.index)
                        }}
                    >

                    </Form.Control>
                    <Form.Control.Feedback type="invalid">{values.feedback}</Form.Control.Feedback>

                </Form.Group>
            </>
        )


    }
    const SliderCombo = ({ values }) => {
        const { options } = values
        let defaultVal = ValueGetter(obj, values.name)
        let enabledName = values.name + ".enabled"
        let unknownName = values.name + ".unknown"
        let valueName = values.name + ".value"
        function valuetext(value) {
            return `${value}`
        }
        if (defaultVal.value.length < 1) {
            defaultVal.value = [values.range[0], values.range[1]]
        }
        return (
            <>
                <Form.Check
                    inline
                    type="switch"
                    disabled={!edit}
                    label={values.label}
                    checked={defaultVal.enabled}
                    onChange={(event) => {
                        GlobalChangeEvent(enabledName, event, obj, load, setObj)
                    }}
                />
                {(defaultVal.enabled === true) ? (
                    <><Form.Check
                        inline
                        disabled={!edit}
                        type="switch"
                        label="Allow Unknown"
                        onChange={(event) => {
                            GlobalChangeEvent(unknownName, event, obj, load, setObj)
                        }}
                        checked={defaultVal.unknown}
                    />
                        <Slider
                            disabled={!edit}
                            getAriaLabel={() => values.label}
                            defaultValue={defaultVal.value}
                            value={defaultVal.value}
                            onChange={(event) => {
                                GlobalChangeEvent(valueName, event, obj, load, setObj)
                            }}
                            valueLabelDisplay="off"
                            marks={options}
                            min={values.range[0]}
                            max={values.range[1]}
                            step={null}
                            getAriaValueText={valuetext}
                        />
                    </>
                ) : (<></>)}

            </>
        )
    }

    const DateTimeObj = ({ defaultVal, i, name }) => {

        let dateOpt = [
            { value: "MONDAY", label: "Monday", },
            { value: "TUESDAY", label: "Tuesday", },
            { value: "WEDNESDAY", label: "Wednesday", },
            { value: "THURSDAY", label: "Thursday", },
            { value: "FRIDAY", label: "Friday", },
            { value: "SATURDAY", label: "Saturday", },
            { value: "SUNDAY", label: "Sunday", },
        ]
        let sTimeOpt = [
            { value: 0, label: "12:00 AM", },
            { value: 1, label: "1:00 AM", },
            { value: 2, label: "2:00 AM", },
            { value: 3, label: "3:00 AM", },
            { value: 4, label: "4:00 AM", },
            { value: 5, label: "5:00 AM", },
            { value: 6, label: "6:00 AM", },
            { value: 7, label: "7:00 AM", },
            { value: 8, label: "8:00 AM", },
            { value: 9, label: "9:00 AM", },
            { value: 10, label: "10:00 AM", },
            { value: 11, label: "11:00 AM", },
            { value: 12, label: "12:00 PM", },
            { value: 13, label: "1:00 PM", },
            { value: 14, label: "2:00 PM", },
            { value: 15, label: "3:00 PM", },
            { value: 16, label: "4:00 PM", },
            { value: 17, label: "5:00 PM", },
            { value: 18, label: "6:00 PM", },
            { value: 19, label: "7:00 PM", },
            { value: 20, label: "8:00 PM", },
            { value: 21, label: "9:00 PM", },
            { value: 22, label: "10:00 PM", },
            { value: 23, label: "11:00 PM", },
        ]
        let eTimeOpt = [
            { value: 1, label: "1:00 AM", },
            { value: 2, label: "2:00 AM", },
            { value: 3, label: "3:00 AM", },
            { value: 4, label: "4:00 AM", },
            { value: 5, label: "5:00 AM", },
            { value: 6, label: "6:00 AM", },
            { value: 7, label: "7:00 AM", },
            { value: 8, label: "8:00 AM", },
            { value: 9, label: "9:00 AM", },
            { value: 10, label: "10:00 AM", },
            { value: 11, label: "11:00 AM", },
            { value: 12, label: "12:00 PM", },
            { value: 13, label: "1:00 PM", },
            { value: 14, label: "2:00 PM", },
            { value: 15, label: "3:00 PM", },
            { value: 16, label: "4:00 PM", },
            { value: 17, label: "5:00 PM", },
            { value: 18, label: "6:00 PM", },
            { value: 19, label: "7:00 PM", },
            { value: 20, label: "8:00 PM", },
            { value: 21, label: "9:00 PM", },
            { value: 22, label: "10:00 PM", },
            { value: 23, label: "11:00 PM", },
            { value: 24, label: "12:00 AM", },
        ]
        let day = DefaultValue(defaultVal[i].day, dateOpt)
        let startTime = DefaultValue(defaultVal[i].start_time, sTimeOpt)
        let endTime = DefaultValue(defaultVal[i].end_time, eTimeOpt)
        //Need to take current object array update the value then push change event.        

        return (<>
            <Row>
                <Col lg="4" sm="12">
                    <span>Day</span>
                </Col>
                <Col lg="8" sm="12">
                    <span>Time</span>
                </Col>
            </Row>
            <Row className="mb-2">
                <Col lg="4" sm="12">
                    <Select
                        name={"date" + i}
                        defaultValue={day}
                        options={dateOpt}
                        isDisabled={!edit}
                        onChange={(event) => {
                            let localName = name + "[].day"
                            GlobalChangeEvent(localName, event, obj, load, setObj, i)

                        }}
                    />
                </Col>
                <Col lg="3" sm="12">
                    <Select
                        name={"timeStart" + i}
                        defaultValue={startTime}
                        isDisabled={!edit}
                        options={sTimeOpt}
                        onChange={(event) => {
                            let localName = name + "[].start_time"
                            GlobalChangeEvent(localName, event, obj, load, setObj, i)
                        }}
                    />
                </Col>
                <Col lg="1">
                    <span>to</span>
                </Col>
                <Col lg="3" sm="12">
                    <Select
                        name={"timeEnd" + i}
                        defaultValue={endTime}
                        options={eTimeOpt}
                        isDisabled={!edit}
                        onChange={(event) => {
                            let localName = name + "[].end_time"
                            GlobalChangeEvent(localName, event, obj, load, setObj, i)
                        }}
                    />
                </Col>
                <Col lg="1">
                    {(edit) ? (
                        <Button className="text-center btn-dark btn-icon mt-lg-0 mt-md-0 mt-3" variant="dark" onClick={() => {
                            defaultVal.splice(i, 1)
                            let e = { value: defaultVal }
                            GlobalChangeEvent(name, e, obj, load, setObj, i)
                        }} >
                            <i className="btn-inner">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-square" viewBox="0 0 16 16">
                                    <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                                </svg>
                            </i>
                        </Button>
                    ) : (<></>)}
                </Col>
            </Row></>
        )
    }
    const PartnerCostObj = ({ defaultVal, i, name }) => {

        let costOpt = [
            { value: "PARTNER_COST_TYPE_ADLOOX", label: "AdLoox", },
            { value: "PARTNER_COST_TYPE_AGENCY_TRADING_DESK", label: "A+", },
            { value: "PARTNER_COST_TYPE_THIRD_PARTY_AD_SERVER", label: "CM360" },
            { value: "PARTNER_COST_TYPE_DATA_MANAGEMENT_PLATFORM", label: "CDP", },
            { value: "PARTNER_COST_TYPE_DEFAULT", label: "Other", },
            { value: "PARTNER_COST_TYPE_DOUBLE_VERIFY", label: "Double Verify", },
            { value: "PARTNER_COST_TYPE_INTEGRAL_AD_SCIENCE_VIDEO", label: "IAS" }
        ]
        let feeOpt = [
            { value: "PARTNER_COST_FEE_TYPE_CPM_FEE", label: "$", },
            { value: "PARTNER_COST_FEE_TYPE_MEDIA_FEE", label: "%", },
        ]
        let cost = DefaultValue(defaultVal[i].cost_type, costOpt) || ""
        let feeType = DefaultValue(defaultVal[i].fee_type, feeOpt) || ""
        //Need to take current object array update the value then push change event.        

        return (<>
            <Row>
                <Col lg="4" sm="12">
                    <span>Cost Type</span>
                </Col>
                <Col lg="8" sm="12">
                    <span>Fee</span>
                </Col>
            </Row>
            <Row className="mb-2">
                <Col lg="4" sm="12">
                    <Select
                        name={"cost_type" + i}
                        defaultValue={cost}
                        options={costOpt}
                        isDisabled={!edit}
                        onChange={(event) => {
                            let localName = name + "[].cost_type"
                            GlobalChangeEvent(localName, event, obj, load, setObj, i)
                        }}
                    />
                </Col>
                <Col lg="3" sm="12">
                    <InputGroup className="mb-3">
                        <DropdownButton title={feeType.label} variant='dark' menuVariant='dark'
                            onSelect={(eventKey, event) => {
                                let localName = name + "[].fee_type"
                                GlobalChangeEvent(localName, event, obj, load, setObj, i)
                            }}>
                            {
                                feeOpt.map((option) => {
                                    return (
                                        <DropdownItem>{option.label}</DropdownItem>
                                    )
                                })
                            }
                        </DropdownButton>
                        <Form.Control
                            disabled={!edit}
                            type="text"
                            name="fee_amount"
                            defaultValue={defaultVal[i].fee_amount}
                            onBlur={event => {
                                let localName = name + "[].fee_amount"
                                GlobalChangeEvent(localName, event, obj, load, setObj, i)
                            }}
                        />
                    </InputGroup>
                </Col>
                <Col lg="1">
                    {(edit) ? (
                        <Button className="text-center btn-dark btn-icon mt-lg-0 mt-md-0 mt-3" variant="dark" onClick={() => {
                            defaultVal.splice(i, 1)
                            let e = { value: defaultVal }
                            GlobalChangeEvent(name, e, obj, load, setObj, i)
                        }} >
                            <i className="btn-inner">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-square" viewBox="0 0 16 16">
                                    <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                                </svg>
                            </i>
                        </Button>
                    ) : (<></>)}
                </Col>
            </Row></>
        )
    }
    const BudgetObj = ({ defaultVal, i, name }) => {
        return (<>
            <Row>
                <Col lg="3" sm="12">
                    <span>Budget Amount</span>
                </Col>
                <Col lg="3" sm="12">
                    <span>Description</span>
                </Col>
                <Col lg="2" sm="12">
                    <span>Start Date</span>
                </Col>
                <Col lg="2" sm="12">
                    <span>End Date</span>
                </Col>
            </Row>
            <Row className="mb-2">
                <Col lg="3" sm="12">
                    <Form.Control
                        disabled={!edit}
                        type="number"
                        name={"budget_amount" + i}
                        defaultValue={defaultVal[i].budget_amount}
                        onBlur={event => {
                            let localName = name + "[].budget_amount"
                            GlobalChangeEvent(localName, event, obj, load, setObj, i)
                        }}
                    />
                </Col>
                <Col lg="3" sm="12">
                    <Form.Control
                        disabled={!edit}
                        type="text"
                        name={"budget_description" + i}
                        defaultValue={defaultVal[i].budget_description}
                        onBlur={event => {
                            let localName = name + "[].budget_description"
                            GlobalChangeEvent(localName, event, obj, load, setObj, i)
                        }}
                    />
                </Col>
                <Col lg="2" sm="12">
                    <Form.Control
                        disabled={!edit}
                        type="date"
                        name={"start_date" + i}
                        defaultValue={defaultVal[i].start_date}
                        onChange={event => {
                            let localName = name + "[].start_date"
                            GlobalChangeEvent(localName, event, obj, load, setObj, i)
                        }}
                    />
                </Col>
                <Col lg="2" sm="12">
                    <Form.Control
                        disabled={!edit}
                        type="date"
                        name={"end_date" + i}
                        defaultValue={defaultVal[i].end_date}
                        onChange={event => {
                            let localName = name + "[].end_date"
                            GlobalChangeEvent(localName, event, obj, load, setObj, i)
                        }}
                    />
                </Col>
                <Col lg="1">
                    {(edit) ? (
                        <Button className="text-center btn-dark btn-icon mt-lg-0 mt-md-0 mt-3" variant="dark" onClick={() => {
                            defaultVal.splice(i, 1)
                            let e = { value: defaultVal }
                            GlobalChangeEvent(name, e, obj, load, setObj, i)
                        }} >
                            <i className="btn-inner">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-square" viewBox="0 0 16 16">
                                    <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                                </svg>
                            </i>
                        </Button>
                    ) : (<></>)}
                </Col>
            </Row></>
        )
    }
    const Segment = ({ values }) => {
        //Get data from Model
        let defaultVal = ValueGetter(obj, values.name)

        //has object argument as part of params to generate the layout.
        const addSegment = () => {
            defaultVal.push({ control: "new" })
            obj[values.name] = defaultVal
            setObj({ ...obj })
        }

        return (
            <Row>
                <Col lg="12" sm="12" >
                    {(defaultVal.length > 0) ? (
                        defaultVal.map((val, i) => {
                            //Can this be the object and the change takes the data and chagnes the values only in the model?
                            return (<> <div key={i + "t"}>
                                {(values.name === "day_time") ? (
                                    <DateTimeObj defaultVal={defaultVal} i={i} name={values.name} />
                                ) : (
                                    (values.name === "budget_segments") ? (
                                        <BudgetObj defaultVal={defaultVal} i={i} name={values.name} key={i} />
                                    ) : (
                                        <PartnerCostObj defaultVal={defaultVal} i={i} name={values.name} key={i} />
                                    ))}
                            </div></>
                            )
                        })
                    ) : (<></>)}
                    {(edit) ? (
                        <Row>
                            <Col lg="4" sm="12">
                                <Button className="text-center btn-dark btn-icon mt-lg-0 mt-md-0 mt-3" variant="dark" onClick={addSegment}>
                                    <i className="btn-inner">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                        </svg>
                                    </i>
                                    <span>Add</span>
                                </Button>
                            </Col>
                        </Row>
                    ) : (<></>)}
                </Col>
            </Row>
        )
    }

    const CardTemplate = ({ children, info }) => {
        return (
            <Col lg={info.size} sm="12">
                <Card.Body>
                    <Card.Title>{info.label} {info.required ? ("*") : ("")} </Card.Title>
                    {children}
                </Card.Body>
            </Col>
        )
    }

    const renderSwitch = (param) => {
        const { input, dependent, condition, index } = param;
        let i;
        if (index) {
            i = parseInt(index);
        }
        switch (input) {
            case "input-text":
                if (dependent) {
                    let dependentVal = ValueGetter(obj, dependent, i)
                    if (condition !== dependentVal) {
                        return (<></>)
                    }
                }
                return (
                    <CardTemplate info={param} key={i}>
                        <InputText values={param} />
                    </CardTemplate>
                )
            case "input-number":
                if (dependent) {
                    let dependentVal = ValueGetter(obj, dependent, i)
                    if (condition !== dependentVal) {
                        return (<></>)
                    }
                }
                return (
                    <CardTemplate info={param} key={i}>
                        <InputNumber values={param} />
                    </CardTemplate>
                )
            case "input-combo":
                if (dependent) {
                    let dependentVal = ValueGetter(obj, dependent, i)
                    if (condition !== dependentVal) {
                        return (<></>)
                    }
                }
                return (
                    <CardTemplate info={param} key={i}>
                        <InputCombo values={param} />
                    </CardTemplate>
                )
            case "dropdown":
                if (dependent) {
                    let dependentVal = ValueGetter(obj, dependent, i)
                    if (condition !== dependentVal && dependentVal.constructor !== Array) {
                        return (<></>)
                    } else if (dependentVal.constructor === Array) {
                        let found = dependentVal.some(el => el.value === condition)
                        if (!found) {
                            return (<></>)
                        }
                    }
                }
                return (
                    <CardTemplate info={param} key={i}>
                        <Dropdown values={param} />
                    </CardTemplate>

                )
            case "dropdown-exclude":
                if (dependent) {
                    let dependentVal = ValueGetter(obj, dependent, i)
                    if (condition !== dependentVal) {
                        return (<></>)
                    }
                }
                return (
                    <CardTemplate info={param} key={i}>
                        <ExcludeDropdown values={param} />
                    </CardTemplate>
                )
            case "textarea":
                if (dependent) {
                    let dependentVal = ValueGetter(obj, dependent, i)
                    if (condition !== dependentVal) {
                        return (<></>)
                    }
                }
                return (
                    <CardTemplate info={param} key={i}>
                        <TextArea values={param} />
                    </CardTemplate>
                )
            case "async-combo":
                if (dependent) {
                    let dependentVal = ValueGetter(obj, dependent, i)
                    if (condition !== dependentVal) {
                        return (<></>)
                    }
                }
                return (
                    <CardTemplate info={param} key={i}>
                        <AsyncCombo values={param} />
                    </CardTemplate>
                )
            case "async-exclude":
                if (dependent) {
                    let dependentVal = ValueGetter(obj, dependent, i)
                    if (condition !== dependentVal) {
                        return (<></>)
                    }
                }
                return (
                    <CardTemplate info={param} key={i}>
                        <AsyncExclude values={param} />
                    </CardTemplate>
                )

            case "radio":
                if (dependent) {
                    let dependentVal = ValueGetter(obj, dependent, i)
                    if (condition !== dependentVal) {
                        return (<></>)
                    }
                }
                return (
                    <CardTemplate info={param} key={i}>
                        <Radio values={param} />
                    </CardTemplate>

                )
            case "toggle":
                if (dependent) {
                    let dependentVal = ValueGetter(obj, dependent, i)
                    if (condition !== dependentVal) {
                        return (<></>)
                    }
                }
                return (
                    <CardTemplate info={param} key={i}>
                        <Toggle values={param} />
                    </CardTemplate>

                )
            case "checkbox":
                if (dependent) {
                    let dependentVal = ValueGetter(obj, dependent, i)
                    if (condition !== dependentVal) {
                        return (<></>)
                    }
                }
                return (
                    <CardTemplate info={param} key={i}>
                        <Checkbox values={param} />
                    </CardTemplate>
                )
            case "date":
                if (dependent) {
                    let dependentVal = ValueGetter(obj, dependent, i)
                    if (condition !== dependentVal) {
                        return (<></>)
                    }
                }
                return (
                    <CardTemplate info={param} key={i}>
                        <DateSelect values={param} />
                    </CardTemplate>
                )
            case "segment":
                if (dependent) {
                    let dependentVal = ValueGetter(obj, dependent, i)
                    if (condition !== dependentVal) {
                        return (<></>)
                    }
                }
                return (
                    <CardTemplate info={param} key={i}>
                        <Segment values={param} />
                    </CardTemplate>
                )
            case "slider":
                if (param.dependent) {
                    let dependentVal = ValueGetter(obj, param.dependent)
                    if (param.condition !== dependentVal) {
                        return (<></>)
                    }
                }
                return (
                    <CardTemplate info={param} key={i}>
                        <SliderCombo values={param} />
                    </CardTemplate>
                )

            default:
                return <></>;
        }
    }

    //Rendered Form 
    return (<>
        {/* {alert} */}
        <Form noValidate validated={validated} id={formId} onSubmit={(event) => {
            event.preventDefault();
            loadSpinner()
            let resp;
            if (formId === "targeting") {
                parentModel[0].targeting = obj;
                resp = GlobalSubmitEvent(event, parentModel[0], parentModel[1], url, mode, appValue.config)
            } else {
                resp = GlobalSubmitEvent(event, obj, setObj, url, mode, appValue.config)
            }

            setValidated(true)
            if (resp !== false) {
                resp.then(({ result, err, data }) => {
                    if (result) {
                        if (mode !== "create") {
                            setEdit(false)
                            setAlert({ variant: "success", value: "Updated" })
                        } else {
                            window.location.href = appValue.baseUrl + "/" + name + "/" + data.data
                        }
                        closeSpinner()

                    } else {
                        closeSpinner()
                        let errMsg = err.error
                        if (err.error === undefined) {
                            errMsg = err.message
                        }
                        setAlert({ variant: "danger", value: "Failed: " + errMsg })
                        //Goto newly created item.
                    }
                })
            } else {
                closeSpinner()
                setAlert({ variant: "danger", value: "Form missing required values" })
            }
        }}>
            <Card>
                <Card.Header className="d-flex justify-content-between bg-dark">
                    <Row>
                        <Alert variant={alert.variant}>{alert.value}</Alert>
                    </Row>
                    {(mode !== "create") ? (
                        <div>

                            {!edit && !disabled &&
                                <Button className="mx-2" variant="light" onClick={() => {
                                    setEdit(true);
                                }}>
                                    <span>Edit</span>
                                </Button>
                            }
                            {edit &&
                                <div className="d-flex justify-content-between">
                                    <Button variant="danger" onClick={() => setEdit(false)}>
                                        Cancel
                                    </Button>
                                    <Button variant="light" className="mx-2" type='submit'>
                                        {subText}
                                    </Button>
                                </div>
                            }
                        </div>
                    ) : (<></>)}
                </Card.Header>
                <Card.Body className="bg-dark px-5">
                    <div>
                        <div>
                            {/* <hr /> */}
                            <Row>
                                {
                                    form.fields.map((val, i) => {
                                        return (

                                            <Card key={i}>
                                                <Card.Header>
                                                    <Card.Title>
                                                        {form.titles[i]}
                                                    </Card.Title>
                                                </Card.Header>
                                                <Card.Body>
                                                    <Row>
                                                        {val.map((v, x) => {
                                                            return (<>{renderSwitch(v)}</>)
                                                        })}
                                                    </Row>
                                                </Card.Body>
                                            </Card>

                                        )
                                    })
                                }
                            </Row>
                            <div>
                                {(mode === "create") ? (
                                    <>
                                        <Button variant="light" className="float-end" type='submit'>
                                            {subText}
                                        </Button>
                                        <InputBriefButton name="Select Input Brief" options={inputBriefOpts} setBrief={setInputBrief} />
                                    </>
                                ) : (<></>)}
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </Form></>
    )
}

export default InputSettings;