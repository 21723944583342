import { Modal, Form} from 'react-bootstrap'

const ModalPopup = ({ show, setShow, title, children, submitText, onClose,response}) => {
    return (
        <Modal 
            show={show} 
            size="xl"
            onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    {children}
                </Form.Group>                                                    
            </Modal.Body>
        </Modal>
    )
}

export default ModalPopup;