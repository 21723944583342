import React, { useState } from 'react'
import { Row, Col, Image, Form, Alert } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Card from '../../../components/Card'
import axios from 'axios';
import Cookies from 'universal-cookie';
// img

import cheil from '../../../assets/images/brands/Cheil_Logo_Black_Transparent.png'
import auth1 from '../../../assets/images/auth/bg_login.jpg'
import aplus from '../../../assets/images/brands/aplus.png'
import { useAppContext } from '../../../context/appContext';


export default function SignIn() {
   const appValue = useAppContext();
   var REACT_APP_API_URL = appValue.apiUrl;
   // console.log(REACT_APP_API_URL)
   // const { REACT_APP_API_URL } = process.env
   const { NODE_ENV } = process.env;

   const [values, setValues] = useState({
      email: "",
      password: "",
   });
   const [message, setMessage] = useState("")
   const [error, setError] = useState(false)

   const handleInputChange = (e) => {
      // const name = e.target.name 
      // const value = e.target.value 
      const value = e.target.value;
      setValues({
         ...values,
         [e.target.id]: value,
      });
   };
   const handleSubmit = event => {
      event.preventDefault();
      userLogin();
   }
   const userLogin = async () => {
      const cookies = new Cookies();
      const url = REACT_APP_API_URL + '/auth/login';

      const json = JSON.stringify({ username: values.email, password: values.password });      
      return axios.patch(url, json).then((response) => {  
         if (response.data.token) {            
            cookies.set('aplus-token', response.data.token, { path: '/' });
            cookies.set('aplus-user', JSON.stringify(response.data.data), { path: '/' });

            if (NODE_ENV === 'development') {
               console.log(cookies.get('aplus-token'));
               console.log(cookies.get('aplus-user'));
               console.log("Development")
               console.log(url)
            }
            window.location = "/dashboard";

         }
      }).catch(function (error) {
         console.log('error', error)
         setError(true)
         setMessage(error.response.data.error)
      })
   }
   return (
      <>
         <section className="login-content">
            <Row className="m-0 align-items-center bg-white vh-100">
               <Col md="6">
                  <Row className="justify-content-center">
                     <Col md="10">
                        <Card className="card-transparent shadow-none d-flex justify-content-center mb-0 auth-card">
                           {(error) ? (
                              <Alert key="danger" variant="danger">{message}</Alert>
                           ) : (<></>)}
                           <Card.Body>
                              <img src={cheil} className="Image-fluid logo-small animated-scaleX" alt="images" />
                              {/* <h4 className="logo-title ms-3">Hope UI</h4> */}
                              <h2 className="mb-2 text-center">Sign In</h2>
                              <p className="text-center">Login to A+</p>
                              <Form onSubmit={handleSubmit}>
                                 <Row>
                                    <Col lg="12">
                                       <Form.Group className="form-group">
                                          <Form.Label htmlFor="email" className="">Email</Form.Label>
                                          <Form.Control type="email" className="" autoComplete="email" id="email" aria-describedby="email" value={values.email} placeholder=" " onChange={handleInputChange} />
                                       </Form.Group >
                                    </Col>
                                    <Col lg="12" className="">
                                       <Form.Group className="form-group">
                                          <Form.Label htmlFor="password" className="">Password</Form.Label>
                                          <Form.Control autoComplete="password" type="password" className="" id="password" aria-describedby="password" value={values.password} placeholder=" " onChange={handleInputChange} />
                                       </Form.Group>
                                    </Col>
                                    <Col lg="12" className="d-flex justify-content-between">
                                    </Col>
                                 </Row>
                                 <div className="d-flex justify-content-center">
                                    <input type="submit" className="btn btn-dark" value="Sign In" />
                                 </div>
                              </Form>
                           </Card.Body>
                        </Card>
                     </Col>
                  </Row>
                  <Row>
                     <div style={{ position: 'absolute', bottom: 0 }}>
                        
                     <Col lg="6" sm="12">
                        <Row className="justify-content-center">
                           <Col md="10">
                              <Card className="card-transparent shadow-none d-flex justify-content-center mb-0 auth-card" >
                                 <Card.Body>
                                    <div style={{ textAlign: 'center', bottom: '0' }}><h4>DEMOCRATIZE<br />THE POWER OF PERFORMANCE</h4></div>
                                    <div style={{ textAlign: 'center', marginTop: '1em' }}>
                                       contact us to learn more at <Link to="mailto:aplus@cheil.com">aplus@cheil.com</Link>
                                    </div>
                                 </Card.Body>
                              </Card>
                           </Col>
                        </Row>
                     </Col>
                     </div>
                  </Row>
                  <div className="sign-bg">
                     <img src={aplus} className="Image-fluid logo-medium animated-scaleX" alt="images" />
                  </div>
               </Col>
               <Col md="6" className="d-md-block d-none bg-primary p-0 mt-n1 vh-100 overflow-hidden">
                  <Image src={auth1} className="Image-fluid gradient-main animated-scaleX" alt="images" />
               </Col>
            </Row>
         </section>
      </>
   )
}
