import { React, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export default function DatePickerValue(params) {
  
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  
  var sdate = dayjs(params.start)
  var edate = dayjs(params.end)
  // setStart(sdate)  
  useEffect(() => {
    setStart(sdate)
    setEnd(edate)
  },[params])

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DatePicker', 'DatePicker']}>
        <DatePicker 
            label="Start Date" 
            value={start}
            className="datePicker"
            onChange={(newValue) => {
              setStart(newValue)
              let newStart = newValue.toISOString().split('T')[0]     
              params.sState(newStart)
            }}
           />
        <DatePicker
          label="End Date"
          value={end}
          className="datePicker"
          onChange={(newValue) => {
            setEnd(newValue)
            let newEnd = newValue.toISOString().split('T')[0]
            params.eState(newEnd)           
    
          }}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}